import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AlertsTableSmComponent } from '@app/shared/components/alerts-table-sm/alerts-table-sm.component';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { EquipmentAlarmNotification } from '@app/notifications/shared/events/alert-status';
//import { AppLoadService } from '@app/app-load.service';
import { Alert } from '@alerts/shared/alert';
import { Observable, Subject } from 'rxjs';
import { Warehouse } from '@app/core/shared/warehouse';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { AlertsService } from '@alerts/shared/alerts.service';
import { VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { MapGlobalState, MapState, initialStates } from '@app/shared/models/map-state';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import * as _ from 'lodash-es';

const { ALERT_STATE_CHANGED, LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;

@Component({
    templateUrl: './areas.component.html',
    styleUrls: ['./areas.component.scss'],
})
export class AreasComponent extends MapContainerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('alertsTable') alertsTableSmComponent: AlertsTableSmComponent;
    floorId = '0';
    areaId = '0';
    //liteView: boolean;
    warehouse: Warehouse;
    alertsView = true;
    //variablesView: boolean;
    priority$ = ['All', '1', '2', '3'];
    map: any;
    areaAlerts: Array<Alert>;
    areaKpis: any;
    zoneAlerts: Array<Alert>;
    equipmentTypes: Array<string> = [];
    linesVisible: Array<{
        floorId;
        areaId;
        zoneId;
        lineId;
    }> = [];
    isFullscreen = false;
    showFilters = false;
    configuration: any;
    userName: string;
    userEmail: string;
    mapRender = false;
    groupName: string;
    //groupNameWithArea: string;
    configurationFilterValues: Array<MapGlobalState>;
    lineChange$: Observable<LineStatusNotification>;
    supportedEvents = [ALERT_STATE_CHANGED, LINE_STATE_CHANGED];
    tabState: VisibilityStates = VisibilityStates.active;

    lineToShow = '';
    activateZoom = true;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        //private appLoadService: AppLoadService,
        private route: ActivatedRoute,
        private configurationsService: ConfigurationsService,
        private authenticationService: AuthenticationService,
        private warehouseStatusService: WarehouseStatusService,
        private alertsService: AlertsService,
        //private pageLifeCycleService: PageLifecycleService
    ) {
        super();
        this.configuration = this.route.snapshot.data.area.configuration;
        this.warehouse = this.route.snapshot.data.area.warehouse;
        this.map = this.route.snapshot.data.area.map;
        this.areaAlerts = _.cloneDeep(this.route.snapshot.data.area.alerts);
        this.equipmentTypes = ['All', ...(this.route.snapshot.data.area.equipmentTypes || []).sort()];

        if (this.map === undefined || this.map === null) {
            this.map = { id: 0, zones: [] };
            this.router.navigate(['/']);
        }

        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
            if (params.floorId && params.areaId) {
                this.floorId = params.floorId;
                this.areaId = params.areaId;
            }
            if (this.map?.id !== this.floorId) this.router.navigate(['warehouse', this.warehouse.warehouse]);
            else this.mapRender = true;

            //connect to all floor notifications (to paint close areas states)
            this.groupName = `${this.warehouse.warehouse}-${this.floorId}`; //-${this.areaId}
            //this.groupNameWithArea = `${this.warehouse.warehouse}-${this.floorId}-${this.areaId}`;
        });

        if (this.configuration.stateFilter) {
            this.configurationFilterValues = JSON.parse(this.configuration.stateFilter);
            const valueInArea = this.configurationFilterValues.filter((item) => item.id === 'area')[0]?.states;
            this.availableStates = valueInArea ?? initialStates;
        } else {
            this.availableStates = initialStates;
        }
        this.changeVisibleStates(this.availableStates);

        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
    }

    async ngAfterViewInit(): Promise<void> {
        this.stateService.isFullscreen.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isFullscreen) => {
            this.isFullscreen = isFullscreen;
        });
        this.stateService.showFilters.pipe(takeUntil(this.ngUnsubscribe)).subscribe((showFilters) => {
            this.showFilters = showFilters;
        });
        // TODO: This was commented to avoid causing performance issues in Redis and will be restored when Redis will be stable
        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     this.tabState = state;
        //     if (
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.warehouseStatusService.isConnected()
        //     ) {
        //         this.onReconnect();
        //     }
        // });
        await this.subscribeToRealTimeEvents();
    }

    receiveRealTimeAlert(item: EquipmentAlarmNotification) {
        if (item && item.floorId && item.areaId && this.floorId === item.floorId && this.areaId === item.areaId) {
            if (
                (!this.visibleStates.some((element) => element === 'alert_low') && !this.visibleStates.some((element) => element === 'alert')) ||
                (!this.visibleStates.some((element) => element === 'alert_low') && item.priority.toString() === '3') ||
                (!this.visibleStates.some((element) => element === 'alert') && item.priority.toString() !== '3')
            ) {
                return;
            } else {
                const alert: Alert = {
                    alarmSourceTimeStamp: new Date(),
                    closedTimeStamp: new Date(),
                    equipmentId: item.equipmentId,
                    equipmentType: item.equipmentType,
                    description: item.variableName,
                    priority: item.priority,
                    floorId: item.floorId,
                    areaId: item.areaId,
                    zoneId: item.zoneId,
                    lineId: item.lineId,
                    state: item.state,
                    alarmServerTimeStamp: item.serverts,
                    sourceTimeStamp: item.sourceTimeStamp
                };

                if (
                    this.warehouseStatusService.isNewer(this.groupName, ALERT_STATE_CHANGED, alert, alert.sourceTimeStamp)
                ) {
                    this.alertsTableSmComponent.processRealTimeAlert(alert);
                }
            }
        } else if (localStorage.getItem('showWrongNotifications') === 'true') {
            console.warn('Notification received for wrong fqn', {
                contextFloor: this.floorId,
                contextArea: this.areaId,
                notificationFloor: item?.floorId,
                notificationArea: item?.areaId,
            });
        }
    }
    setPriorityComboItems() {
        if (this.visibleStates.some((item) => item === 'alert_low') && this.visibleStates.some((item) => item === 'alert')) {
            this.priority$ = ['All', '1', '2', '3'];
        } else if (this.visibleStates.some((item) => item === 'alert')) {
            this.priority$ = ['All', '1', '2'];
        } else if (this.visibleStates.some((item) => item === 'alert_low')) {
            this.priority$ = ['All', '3'];
        } else {
            this.priority$ = [];
        }
    }

    // checkVisibleStates(alerts) {
    //     if (!this.visibleStates.some((item) => item === 'alert_low') && !this.visibleStates.some((item) => item === 'alert')) {
    //         this.areaAlerts = alerts.filter((item) => item.priority !== '1' && item.priority !== '2' && item.priority !== '3');
    //     } else if (!this.visibleStates.some((item) => item === 'alert_low')) {
    //         this.areaAlerts = alerts.filter((item) => item.priority !== '3');
    //     } else if (!this.visibleStates.some((item) => item === 'alert')) {
    //         this.areaAlerts = alerts.filter((item) => item.priority !== '1' && item.priority !== '2');
    //     } else {
    //         this.areaAlerts = alerts;
    //     }
    // }

    checkVisibleStates(alert: Alert) {
        if (!this.visibleStates.some((item) => item === 'alert_low') && !this.visibleStates.some((item) => item === 'alert')) {
            return alert.priority !== '1' && alert.priority !== '2' && alert.priority !== '3';
        } else if (!this.visibleStates.some((item) => item === 'alert_low')) {
            return alert.priority !== '3';
        } else if (!this.visibleStates.some((item) => item === 'alert')) {
            return alert.priority !== '1' && alert.priority !== '2';
        }
        return true;
    }

    async ngOnInit() {
        this.changeView('alerts');
    }

    async ngOnDestroy() {
        super.ngOnDestroy();
        await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    navigateToZone(event, item) {
        if (item) {
            // tslint:disable-next-line: max-line-length
            this.router.navigate([`warehouse/${this.warehouse.warehouse}/floor/${item.floorId}/area/${item.areaId}/zone/${item.zoneId}`]);
        } else {
            const key = $(event.target).hasClass('button-icon-marker')
                ? $(event.target).data('icon-key')
                : $(event.target).data('key')
                    ? $(event.target).data('key')
                    : $(event.target).data('arrow');
            const keys = key.split('-');
            this.router.navigate([`warehouse/${this.warehouse.warehouse}/floor/${keys[0]}/area/${keys[1]}/zone/${keys[2]}`]);
        }
    }

    changeView(view) {
        // this.alertsView = 'alerts' === view;
        //this.analyticsView = 'analytics' === view;
        // this.variablesView = 'variables' === view;
        // if (this.alertsView) {
        //     this.alertsService
        //         .getAlertsByArea(this.floorId, this.areaId)
        //         .pipe(
        //             takeUntil(this.ngUnsubscribe),
        //             take(1),
        //             catchError((err) => {
        //                 console.log('Ha ocurrido un problema en el provider al recuperar el mapa del area');
        //                 return of(null);
        //             })
        //         )
        //         .subscribe((res) => {
        //             this.checkVisibleStates(res);
        //             this.setPriorityComboItems();
        //         });
        // }
        // if (this.analyticsView) {
        //     this.areasService
        //         .getAreaKpis(this.floorId, this.areaId)
        //         .pipe(takeUntil(this.ngUnsubscribe))
        //         .subscribe((res) => {
        //             this.areaKpis = !Array.isArray(res) ? [] : res;
        //         });
        // }
    }

    handleChangeVisibleStates(states: Array<MapState>) {
        const newAreaConfiguration = {
            id: 'area',
            states,
        };
        this.configurationFilterValues = [...this.configurationFilterValues, newAreaConfiguration];
        this.configurationFilterValues = _.uniqBy(this.configurationFilterValues, (item) => item.id);
        this.configuration.stateFilter = JSON.stringify(this.configurationFilterValues);
        this.changeVisibleStates(states);
        this.setPriorityComboItems();
        this.saveConfiguration();
    }

    saveConfiguration() {
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
    }

    async onReconnect() {
        // this.alertsService
        //     .getAlertsByArea(this.floorId, this.areaId)
        //     .pipe(takeUntil(this.ngUnsubscribe))
        //     .subscribe(async (alerts) => {
        //         this.checkVisibleStates(alerts);
        //         this.alertsTableSmComponent.setDatatableItems(this.areaAlerts);
        //         await this.subscribeToRealTimeEvents();
        //     });
        await this.subscribeToRealTimeEvents();
    }

    async subscribeToRealTimeEvents(): Promise<void> {
        try {
            await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
            await this.warehouseStatusService.startSubscription(this.groupName, this.supportedEvents);
            this.lineChange$ = await this.warehouseStatusService.listenNotifications(this.groupName, LINE_STATE_CHANGED);
            const alertObservable = await this.warehouseStatusService.listenNotifications(this.groupName, ALERT_STATE_CHANGED);
            alertObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.receiveRealTimeAlert.bind(this));

            this.alertsService
                .getAlertsByArea(this.floorId, this.areaId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(async (alerts) => {
                    const alertsToTable: Alert[] = [];
                    alerts.forEach((alert) => {
                        if (
                            this.checkVisibleStates(alert) &&
                            this.warehouseStatusService.isNewer(this.groupName, ALERT_STATE_CHANGED, alert, alert.sourceTimeStamp)
                        ) {
                            //this.alertsTableSmComponent.processRealTimeAlert(alert);
                            alertsToTable.push(alert);
                        }
                    });
                    this.areaAlerts = alertsToTable;
                });

            this.warehouseStatusService.onReconnecting?.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.onReconnect());
        } catch (error) {
            console.error(error);
        }
    }

    refreshAlertsTable(linesVisible) {
        this.linesVisible = linesVisible.map((line) => {
            const [floorId, areaId, zoneId, lineId] = line.split('-');
            return {
                floorId,
                areaId,
                zoneId,
                lineId,
            };
        });
        this.linesVisible = [...this.linesVisible];
    }
    newLineSelected($event) {
        this.lineToShow = JSON.stringify($event);
    }
    offcanvasToggle(visible: boolean) {
        this.activateZoom = !visible;
        if (!visible) this.lineToShow = '';
    }
}
