<offcanvas #offcanvas modalId="historyVariablesOffcavas" title="History variables filters" position="start" [width]="getScreenWidth()">
    <div class="col-12">
        <div class="row mt-3">
            <app-select #typeFilter key="type-search-history" label="Variable type" [item$]="type$" columns="6" [selected]="!!variableType"></app-select>
            <app-input
                #fqnFilter
                key="fqn-search-history"
                label="Floor-Area-Zone-Line-Equipment"
                placeholder="Use * as wildcard (0*, *AKL*...)"
                columns="6"
                [ngClass]="fqn ? 'filter-selected' : ''"
            ></app-input>
            <app-input #nameFilter key="name-search-history" label="Variable" columns="6" [ngClass]="variableName ? 'filter-selected' : ''"></app-input>
            <app-select-multilevel #fazFilter key="faz-search-history" label="Floor/Area/Zone" columns="6" [selected]="!!floorId"></app-select-multilevel>
            <app-select
                #valueFixed
                key="value-fixed-history"
                label="Value"
                [item$]="activeValues$"
                [hidden]="!['Warning', 'Alarm'].includes(variableType)"
                columns="6"
                [selected]="!!valueFixedVal"
                [ngClass]="valueFixedVal ? 'filter-selected' : ''"
            ></app-select>
            <app-select
                #valueOperators
                key="value-operators-history"
                label="Value operator"
                [item$]="operators$"
                [hidden]="['Warning', 'Alarm'].includes(variableType)"
                columns="7"
                [selected]="!!condition"
            ></app-select>
            <app-input
                #valueInput
                key="value-input-history"
                label="Value"
                [hidden]="['Warning', 'Alarm'].includes(variableType) || operatorVal === 'Between'"
                columns="6"
                [ngClass]="valueVal ? 'filter-selected' : ''"
            ></app-input>
            <app-input
                #valueMin
                key="value-min-history"
                label="Min"
                [hidden]="operatorVal !== 'Between' || ['Warning', 'Alarm'].includes(variableType)"
                columns="6"
                [ngClass]="valueMinVal ? 'filter-selected' : ''"
            ></app-input>
            <app-input
                #valueMax
                key="value-max-history"
                label="Max"
                [hidden]="operatorVal !== 'Between' || ['Warning', 'Alarm'].includes(variableType)"
                columns="6"
                [ngClass]="valueMaxVal ? 'filter-selected' : ''"
            ></app-input>
            <app-input #lineFilter key="line-search-history" label="Line" columns="6" [ngClass]="lineId ? 'filter-selected' : ''"></app-input>
            <app-input
                #equipmentName
                key="equipment-search-variables-history"
                label="Equipment"
                columns="6"
                [ngClass]="equipmentId ? 'filter-selected' : ''"
            ></app-input>
            <app-select
                #equipmentType
                key="equipment-type-search-history"
                label="Equipment Type"
                [item$]="equipmentTypes"
                searchable="true"
                columns="6"
                [selected]="!!equipmentTypeVal"
            ></app-select>
        </div>
    </div>
</offcanvas>

<div class="row search-nav mt-3">
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-lg-3 pt-1 mobile-margin-bottom">
                <h2>{{ header }}</h2>
            </div>
            <div class="col-12 offset-lg-4 col-lg-2 mobile-margin-bottom">
                <app-datepicker #dateFilter columns="3" key="date-search-history" label="Date" [ranges]="calendarRange"></app-datepicker>
            </div>
            <div class="col-12 col-lg-2 mobile-margin-bottom">
                <app-dropdown-checkbox
                    #dropdowncheckboxSelectColumns
                    label="Select columns"
                    columns="7"
                    [availableItems]="availableColumnsHistory"
                    (changeFilter)="changeVisibleColumns($event)"
                ></app-dropdown-checkbox>
            </div>
            <div class="col-12 col-lg-1 mobile-margin-bottom ms-auto">
                <app-button columns="1" offcanvas="historyVariablesOffcavas"><i class="icon-filter"></i>Filters </app-button>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3 mb-4">
    <div class="col-12">
        <div style="overflow-x: auto">
            <div style="min-width: 1024px">
                <ngx-datatable
                    #table
                    class="bootstrap"
                    [rows]="itemsVariable"
                    [limit]="pageSize"
                    [externalPaging]="true"
                    [externalSorting]="true"
                    [count]="numberOfItems"
                    [offset]="pageNumber"
                    [columnMode]="'flex'"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [messages]="messages"
                    [rowHeight]="'auto'"
                    [sorts]="[{ prop: 'date', dir: 'desc' }]"
                    (sort)="setSort($event)"
                    (page)="setPage($event)"
                >
                    <ngx-datatable-column name="sourceTimeStamp" [sortable]="true" [flexGrow]="0.8" *ngIf="sourceTimeStampVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Date</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>{{ value ? (value | date: 'dd MMM - H:mm') : '' }}</ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="floorid" [sortable]="true" [flexGrow]="1" *ngIf="flooridVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Floor</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                class="clickable"
                                [innerHTML]="value | fazlDecorator: [['F', identifiersPreferences.Floor], [], [], []]"
                                (click)="goToFloor(row)"
                            ></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="areaid" [sortable]="true" [flexGrow]="1" *ngIf="areaidVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Area</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                class="clickable"
                                [innerHTML]="value | fazlDecorator: [[], ['A', identifiersPreferences.Area], [], []]"
                                (click)="goToArea(row)"
                            ></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="zoneid" [sortable]="true" [flexGrow]="1.5" *ngIf="zoneidVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Zone</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                class="clickable"
                                [innerHTML]="value | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []]"
                                (click)="goToZone(row)"
                            ></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="lineid" [sortable]="true" [flexGrow]="1.5" *ngIf="lineidVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Line</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                class="clickable"
                                [innerHTML]="value | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]]"
                                (click)="goToLine(row)"
                            ></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="equipmentId" [sortable]="true" [flexGrow]="1.8" *ngIf="equipmentIdVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Equipment</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span [innerHTML]="value"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="equipmentType" [sortable]="true" [flexGrow]="0.5" *ngIf="equipmentTypeVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Type</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span class="iconType" [innerHTML]="value | equipmentType"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="variableName" [sortable]="true" [flexGrow]="2" *ngIf="variableNameVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Variable Name</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span class="force-one-line" [innerHTML]="value"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="variableType" [sortable]="true" [flexGrow]="0.5" *ngIf="variableTypeVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Type</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span class="iconType mt-2" [innerHTML]="row | variableTypeIcon"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="value" [sortable]="true" [flexGrow]="2" *ngIf="valueVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Value</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span [ngClass]="isActiveVariable(value) ? 'bold' : ''" [innerHTML]="value"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-footer>
                        <ng-template
                            ngx-datatable-footer-template
                            let-rowCount="rowCount"
                            let-pageSize="pageSize"
                            let-selectedCount="selectedCount"
                            let-curPage="curPage"
                            let-offset="offset"
                        >
                            <div class="col-4">
                                <p style="font-size: 0.8rem; margin-top: 16px">
                                    Seeing
                                    <select class="table-length-selector" style="margin: 0 5px" (change)="changePageSize($event)" [value]="pageSize">
                                        <option [value]="size" *ngFor="let size of pageSizes">{{ size }}</option>
                                    </select>
                                    {{ pageSize }} of {{ rowCount | number }} entries
                                    {{ rowCount === 500 ? '(Showing up to 500 items, refine the filter)' : '' }}
                                </p>
                                <i [hidden]="!isFilterSet" style="font-size: 0.8rem; position: relative; top: -15px">Data shown may have a 10 minute delay.</i>
                            </div>
                            <div class="col-5">
                                <datatable-pager
                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                    [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="curPage"
                                    [size]="pageSize"
                                    [count]="rowCount"
                                    [hidden]="!(rowCount / pageSize > 1)"
                                    (change)="table.onFooterPage($event)"
                                >
                                </datatable-pager>
                            </div>
                            <div class="col-3">
                                <button
                                    class="button-excel"
                                    [ngClass]="waitForReportGeneration ? 'disabled' : 'enabled'"
                                    tabindex="0"
                                    (click)="generateRemoteCSV($event)"
                                    [title]="waitForReportGeneration ? 'Report will be downoaded shortly' : 'Click to generate the report (max 400k rows)'"
                                >
                                    <i [ngClass]="waitForReportGeneration ? 'fa fa-spinner fa-spin' : 'icon-download'"></i
                                    >{{ waitForReportGeneration ? 'Generating' : 'Generate' }} Report
                                </button>
                                <button
                                    class="button-refresh"
                                    [ngClass]="waitForVariablesResponse ? 'disabled' : 'enabled'"
                                    tabindex="0"
                                    (click)="updateHistoricVariables()"
                                >
                                    <span><i class="fas fa-sync"></i>Update</span>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>
