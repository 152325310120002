import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { environment } from '@environments/environment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Alert, AlertSnapshotVariable } from './alert';
import { Alerts, AlertsCount } from './alerts';
import { mapNotification } from '@app/notifications/shared/mappers/notification.mapper';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { AlertsActiveStatus, EquipmentAlarmNotification } from '@app/notifications/shared/events/alert-status';
dayjs.extend(utc);

@Injectable()
export class AlertsService {
    baseUrl: string;
    warehouse: string;

    constructor(
        private http: HttpClient,
        private appLoadService: AppLoadService,
    ) { }

    getAlertsActiveCount(): Observable<AlertsCount> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `alerts/${this.warehouse}/active/count`;
                return this.http.get<AlertsCount>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            shareReplay(),
        );
    }

    // tslint:disable-next-line:max-line-length
    getAlertsActive(): Observable<EquipmentAlarmNotification[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = environment.mode === 'front' ? `alertsActive` : `alerts/${this.warehouse}/active`;
                return this.http.get<AlertsActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            map((notifications: AlertsActiveStatus[]) => {
                const mappedAlerts: EquipmentAlarmNotification[] = notifications.map((notification) => {
                    return {
                        floorId: notification.floorid,
                        areaId: notification.areaid,
                        zoneId: notification.zoneid,
                        lineId: notification.lineid,
                        equipmentId: notification.equipmentId,
                        equipmentType: notification.equipmentType,
                        state: notification.state,
                        variableName: notification.variableName,
                        priority: notification.priority,
                        source: notification.source,
                        sourceTimeStamp: notification.sourceTimeStamp.toString(),
                        serverts: notification.serverTimeStamp.toString()
                    }
                });
                return mappedAlerts;
            }),
            shareReplay(),
        );
    }

    getAlertsByFloor(idFloor): Observable<Alert[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'alerts' : `alerts/${this.warehouse}/floors/${idFloor}`;
                return this.http.get<AlertsActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            map((notifications) => {
                const mappedAlerts: Alert[] = notifications.map((notification) =>
                    mapNotification(WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED, notification),
                );
                return mappedAlerts;
            }),
            shareReplay(),
        );
    }

    getAlertsByCustom(customId): Observable<Alert[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'alerts' : `alerts/${this.warehouse}/custom-map/${customId}`;
                return this.http.get<AlertsActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            map((notifications) => {
                const mappedAlerts: Alert[] = notifications.map((notification) =>
                    mapNotification(WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED, notification),
                );
                return mappedAlerts;
            }),
            shareReplay(),
        );
    }

    getAlertsByArea(idFloor, idArea): Observable<Alert[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'alerts' : `alerts/${this.warehouse}/floors/${idFloor}/areas/${idArea}`;
                return this.http.get<AlertsActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            map((notifications) => {
                const mappedAlerts: Alert[] = notifications.map((notification) =>
                    mapNotification(WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED, notification),
                );
                return mappedAlerts;
            }),
            shareReplay(),
        );
    }

    getAlertsByZone(idFloor, idArea, idZone): Observable<Alert[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = environment.mode === 'front' ? 'alerts' : `alerts/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}`;
                return this.http.get<AlertsActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            map((notifications) => {
                const mappedAlerts: Alert[] = notifications.map((notification) =>
                    mapNotification(WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED, notification),
                );
                return mappedAlerts;
            }),
            shareReplay(),
        );
    }

    getAlertsByLine(idFloor, idArea, idZone, idLine): Observable<Alert[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint =
                    environment.mode === 'front' ? 'alerts' : `alerts/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}/lines/${idLine}`;
                return this.http.get<AlertsActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            map((notifications) => {
                const mappedAlerts: Alert[] = notifications.map((notification) =>
                    mapNotification(WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED, notification),
                );
                return mappedAlerts;
            }),
            shareReplay(),
        );
    }

    getAlertsHistoric({
        floorId = null,
        areaId = null,
        zoneId = null,
        lineId = null,
        page = 0,
        pageSize = 20,
        from = dayjs().subtract(1, 'days'),
        to = dayjs(),
        priority = null,
        description = null,
        equipmentId = null,
        equipmentType = null,
    }): Observable<Alerts> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                if (environment.mode !== 'front') {
                    const utcFrom = dayjs(from.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    const utcTo = dayjs(to.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    /* eslint-disable */
                    let historicQuery = !!lineId
                        ? `history/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}`
                        : !!zoneId
                            ? `history/floors/${floorId}/areas/${areaId}/zones/${zoneId}`
                            : !!areaId
                                ? `history/floors/${floorId}/areas/${areaId}`
                                : !!floorId
                                    ? `history/floors/${floorId}`
                                    : `history`;
                    /* eslint-enable */
                    historicQuery += `?pagesize=${pageSize}&pagenumber=${page}&from=${utcFrom}&to=${utcTo}`;
                    if (priority) {
                        historicQuery += `&priority=${priority}`;
                    }
                    if (description) {
                        historicQuery += `&description=${description}`;
                    }
                    if (equipmentId) {
                        historicQuery += `&equipmentId=${equipmentId}`;
                    }
                    if (equipmentType) {
                        historicQuery += `&equipmentTypeFilter=${equipmentType}`;
                    }
                    return this.http.get<Alerts>(`${this.baseUrl}/alerts/${this.warehouse}/${historicQuery}`);
                } else {
                    // tslint:disable-next-line:max-line-length
                    return this.http
                        .get<any>(`${this.baseUrl}/alertsHistory?_page=${page}&_limit=${pageSize}`, { observe: 'response' })
                        .pipe(map((resp) => new Alerts(Number(resp.headers.get('X-Total-Count')), page, resp.body)));
                }
            }),
            take(1),
            shareReplay(),
        );
    }

    getAlertDetails(alarm: Alert): Observable<Array<AlertSnapshotVariable>> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                const fqdn = `${this.warehouse}.${alarm.floorId}.${alarm.areaId}.${alarm.zoneId}.${alarm.lineId}.${alarm.equipmentId}.${alarm.equipmentType}.FAILURE.${alarm.description}`;
                const cleanFqdn = String(fqdn).replaceAll('<span class="highlight">', '').replaceAll('</span>', '');
                const alertDate = dayjs(alarm.alarmSourceTimeStamp.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `variables/${this.warehouse}/equipment/snapshot?fqdn=${cleanFqdn}&date=${alertDate}`;
                return this.http.get<Array<AlertSnapshotVariable>>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            shareReplay()
        );
    }

    getAlarmsOrigin(idFloor: string, idArea: string, idZone: string, variableName: string = '', depth: string = '1'): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const params = ['All', ''].includes(variableName) ? { depth: 1 } : { depth: depth, variablename: variableName }
                const v2Version = ['All', ''].includes(variableName) ? '' : '/v2'
                const endpoint = `warehouse/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}/alarm-origin${v2Version}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`, { params: params }); //{ params: { depth: 1 } }
            }),
        );
    }

    hasAlarmsOrigin(): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `warehouse/${this.warehouse}/alarm-origin`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
        );
    }

    alarmsOriginMonitoredErrors(): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `warehouse/${this.warehouse}/alarm-origin/monitoredErrors`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
        );
    }
}
