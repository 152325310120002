import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Structure, Floor } from '@home/shared/structure';
import { shareReplay, catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Kpis } from '@app/shared/models/kpis';
import { AppLoadService } from '@app/app-load.service';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import lineStatusMapper from '@app/notifications/shared/mappers/line-status.mapper';
import { VariableNotification, WarehouseVariablesStatusSupportedEvents } from '@app/notifications/shared/events/variable-status';
import { mapNotification } from '@app/notifications/shared/mappers/notification.mapper';
@Injectable()
export class CustomsService {
    baseUrl: string;
    warehouse: string;

    constructor(
        private http: HttpClient,
        private appLoadService: AppLoadService,
    ) { }

    getFloor(idFloor: string): Observable<Structure | Floor> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res: any) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'map' : `map/${this.warehouse}/definitions/floors/${idFloor}`;
                return this.http.get<Structure>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp: any) => {
                if (environment.mode !== 'front') {
                    return resp;
                } else {
                    const floor = resp.floors.filter((f) => {
                        return String(f.id) === idFloor;
                    });
                    return floor[0];
                }
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar la planta', err);
                return of(null);
            }),
            shareReplay(),
        );
    }

    getFloorKpis(idFloor: string): Observable<Array<Kpis>> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res: any) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'kpis' : `kpis/${this.warehouse}/floors/${idFloor}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar los KPI´s de la planta -> ${err}`);
                return of(null);
            }),
            shareReplay(),
        );
    }

    floorNotificationReceieved(notificacion: any): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res: any) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'state' : `state/${this.warehouse}/notifications/notificationReceived`;
                return this.http.post<any>(`${this.baseUrl}/${endpoint}`, notificacion);
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema al mandar la rececpción de una notificación de la planta -> ${err}`);
                return of(null);
            }),
            shareReplay(),
        );
    }

    getCustomStatus(mapId: string): Observable<Array<LineStatusNotification>> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'status' : `V2/state/${this.warehouse}/custom-map/${mapId}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            map((states = []) => states.map(lineStatusMapper)),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar el estado del mapa custom -> ${err}`);
                return of([]);
            }),
            shareReplay(),
        );
    }

    getCustomStatusVariables(mapId: string): Observable<Array<VariableNotification>> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'status' : `V2/state/${this.warehouse}/custom-map/${mapId}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}/variable-lines`);
            }),
            map((notifications) => {
                const mappedVariables: VariableNotification[] = notifications.map((notification) => {
                    return mapNotification(WarehouseVariablesStatusSupportedEvents.WAREHOUSE_STATUS_VARIABLE_CHANGED, notification)
                });
                return mappedVariables;
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar el estado de la linea de equipos -> ${err}`);
                return of([]);
            }),
            shareReplay(),
        );
    }
}
