import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppLoadService } from '@app/app-load.service';
import { isInViewport } from '@app/map/shared/svg-functions';
import { VariablesStatusClient } from '@app/notifications/shared/clients/variables-status.client';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import { VARIABLE_EMPTY_REQUEST_ID, VariableFilter, VariableFilters, VariableNotification, VariableValueType } from '@app/notifications/shared/events/variable-status';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { VariablesActiveStatusService } from '@app/notifications/shared/handlers/variablesActive-status-service';
import { getEventTS } from '@app/notifications/shared/mappers/notification.mapper';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { UserConfiguration } from '@app/shared/models/configurations';
// import { yieldToMain } from '@app/shared/performance/auwa-scheduler';
import { TextColorPipe } from '@app/shared/pipes/text-color.pipe';
import { VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { CustomsService } from '@customs/shared/customs.service';
import { IdentifiersPreferences, Structure } from '@home/shared/structure';
import { VariableColors, findVariableColor, findVariableToShow } from '@variables/shared/variable';
import { Observable, Subject, of } from 'rxjs';
import { bufferTime, switchMap, take, takeUntil } from 'rxjs/operators';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import * as Svg from '@svgdotjs/svg.js';
import '@svgdotjs/svg.panzoom.js';
import { v4 as uuid } from 'uuid';
import fastdom from 'fastdom';
import * as _ from 'lodash-es';

const MAX_NOTIFICATIONS = 50;
const ORDER_COLUMN = 'SourceTimeStamp';
const ORDER_TYPE = 'desc';
@Component({
    selector: 'app-custom-map',
    templateUrl: './custom-map.component.html',
    styleUrls: ['./custom-map.component.scss'],
    providers: [VariablesActiveStatusService, VariablesStatusClient, TextColorPipe],
})
export class CustomMapComponent extends MapContainerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() structure: Structure;
    @Input() warehouse: string;
    @Input() custom: any;
    @Input() visibleStates: Array<string>;
    @Input() lineChange$: Observable<LineStatusNotification>;
    @Input() tabState: VisibilityStates;
    @Input() wheelZoom?: boolean = true;
    @Input() mode: string;
    @Input() activateZoom?: boolean = true;
    @Output() newLineSelected = new EventEmitter();
    @Output() visibleLines = new EventEmitter();
    @ViewChild('svg', { read: ElementRef, static: true }) svg;
    @ViewChild('contextMenu', { static: true }) contextMenu: ElementRef;
    $svg: JQuery;
    identifiersPreferences: IdentifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    setViewBox: string;
    customSVG: any;
    currentZoom = 1;
    timeoutConst = null;
    zonesOverMap: Array<any> = [];
    viewExtraData;
    viewFullSize = false;
    visibleZones = [];
    currentRequestId: string;
    currentFilters: VariableFilter[] = [];
    customSubscribed = false;
    waitForResponse = false;
    waitForResponseTimeout = null;

    groupName: string;

    isVisibilityEnabled: boolean;
    metadataBlocks: any = {};
    shapesVariables: any = [];
    textVariables: string[];
    allVariablesToShow: string[];
    variableColorsConfiguration: VariableColors[] = [];
    linesCache: Map<string, JQuery<HTMLElement>> = new Map();
    firstMapLoad = true;

    keySelectedWithRightClick = '';
    rightClickX = 0;
    rightClickY = 0;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private appLoadService: AppLoadService,
        private variablesActiveStatusService: VariablesActiveStatusService,
        private variablesStatusClient: VariablesStatusClient,
        //private pageLifeCycleService: PageLifecycleService,
        private textColorPipe: TextColorPipe,
        private customService: CustomsService,
        private warehouseStatusService: WarehouseStatusService
    ) {
        super();
    }

    ngOnInit() {

        this.groupName = `${this.warehouse}-${this.custom.name}`;
        // ONLY CONNECT if the custom map has variables
        // this.variablesStatusClient.connectToHub().then(() => {
        //     console.warn('Connected to Variables status Hub');
        // });
        this.visibleZones = this.custom ? this.getVisibleZones() : [];
        this.setViewBox = this.custom.viewBox || '0 0 3200 1800';
        this.$svg = $(this.svg.nativeElement);
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
            this.viewExtraData = this.userConfiguration.viewExtraData;
        });
        this.isVisibilityEnabled =
            this.mode === 'home-customs'
                ? this.custom.variablesLines?.variablesVisibility?.Home
                : this.mode === 'customs'
                    ? this.custom.variablesLines?.variablesVisibility?.Custom
                    : false;
        this.metadataBlocks.metadata = [];
        if (this.isVisibilityEnabled) {
            this.custom.variablesLines?.metadata.forEach((data) => {
                const dataJsonFormat = JSON.parse(data);
                if (dataJsonFormat.variable && dataJsonFormat.variable !== '') this.metadataBlocks.metadata.push(dataJsonFormat);
            });
            this.textVariables = this.custom.variablesLines?.texts.map((t) => t.variable).filter((v) => v && v !== '');
            this.shapesVariables = this.custom.variablesLines?.shapes.map((s) => s.variable).filter((v) => v && v !== '');
            const metadataVariables: string[] = this.metadataBlocks.metadata.map((t) => t.variable).filter((v) => v && v !== '');
            this.allVariablesToShow = [...new Set<string>(metadataVariables.concat(this.textVariables).concat(this.shapesVariables))];
        }
    }

    ngAfterViewInit(): void {
        this.customSVG = Svg.adopt(this.svg.nativeElement);
        this.currentZoom = this.customSVG.zoom();

        if (this.wheelZoom) {
            this.customSVG.panZoom({ zoomMax: 10, zoomMin: this.currentZoom, zoomFactor: 0.5 });
        }
        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     if (
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.variablesActiveStatusService.isConnected()
        //     ) {
        //         this.handleReconnection();
        //     }
        // });
        if (this.isVisibilityEnabled && this.custom.variablesLines) {
            setTimeout(() => {
                this.loadCustomStateVariables()
                this.loadVariableRT();
            });
        }
        this.appLoadService.getGlobalConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.variableColorsConfiguration = res['variableColors'] ?? [];
        });
        $(() => {
            this.$svg?.find('path').tooltip({
                container: 'body',
                html: true,
                placement: 'top',
            });
        });
        this.refreshVisibleLines();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.visibleStates?.currentValue || changes.lineChange$?.currentValue) {
            if (
                !_.isEqual(changes.visibleStates?.previousValue, changes.visibleStates?.currentValue) ||
                (!_.isEqual(changes.lineChange$?.previousValue, changes.lineChange$?.currentValue))
            ) {
                console.warn('This should only be called when the states filters are changed.');
                await this.startRealtimeNotifications();
            }
        }
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy();
        this.$svg?.remove();
        this.$svg?.find('path').tooltip('dispose');
        this.$svg = null;

        this.currentRequestId = 'stop-processing-notifications';
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        if (this.variablesStatusClient.connection) {
            this.variablesStatusClient.disconnectFromHub();
        }

        this.ngUnsubscribe?.next(true);
        this.ngUnsubscribe?.complete();
        this.linesCache.clear();
        this.linesCache = null;
    }

    refreshVisibleLines() {
        const rectSVG = this.svg.nativeElement.getBoundingClientRect();

        const visibleLines = this.$svg
            ?.find(`[data-key]`)
            .get()
            .filter((svg: HTMLElement) => isInViewport(svg, rectSVG))
            .map((svg: HTMLElement) => {
                return svg.dataset?.['key'];
            });

        this.visibleLines.emit(visibleLines);
    }

    loadVariableRT() {
        if (!this.allVariablesToShow || this.allVariablesToShow.length === 0) return;

        if (!this.variablesStatusClient.connection) {
            this.variablesStatusClient.connectToHub().then(() => {
                console.warn(`Connected to Variables status Hub ${this.custom?.id}`);
            });
        }

        if (!this.customSubscribed) this.currentRequestId = 'HomeCustomMap---' + uuid();

        this.allVariablesToShow.forEach((variableToShow) => {
            let [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = variableToShow?.split('.');
            variableType = variableType === 'FAILURE' ? 'ALARM' : variableType;
            const firstFilter: VariableFilter = {
                warehouseId: this.warehouse,
                floorId,
                areaId,
                zoneId,
                lineId,
                equipmentId,
                //equipmentType, //in DT this property is *
                variableType,
                variableName,
            };
            this.currentFilters.push(firstFilter);
        });
        const filters: VariableFilters = {
            requestId: this.currentRequestId,
            filters: this.currentFilters,
            maxNotificationItems: MAX_NOTIFICATIONS,
            orderColumn: ORDER_COLUMN,
            orderType: ORDER_TYPE,
        };

        if (!this.customSubscribed) {
            this.customSubscribed = true;
            this.variablesActiveStatusService.subscribeToVariable(filters, this.receiveRealTimeVariables.bind(this), this.handleReconnection.bind(this));
        } else {
            this.variablesActiveStatusService.applyFilter(filters);
        }
        this.waitForResponseTimeout = setTimeout(() => {
            this.waitForResponse = false;
            this.waitForResponseTimeout = null;
        }, 3000);
    }

    receiveRealTimeVariables(notification: VariableNotification) {
        if (notification.requestId === this.currentRequestId || notification.requestId === VARIABLE_EMPTY_REQUEST_ID) {
            if (notification.sourceTimeStamp === undefined && ![undefined, null, 'ApiDefault'].includes(notification.source)) {
                console.error('SourceTimeStamp from backend is null/undefined.', notification);
            }

            const variableToShow = findVariableToShow(this.allVariablesToShow, notification);

            if (variableToShow) {
                const [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = variableToShow.split('.');

                //cause in DT u dont know the EquipmentType, is used an * for this value
                const key = `${areaId}.${zoneId}.${floorId}.${lineId}.${equipmentId}.*.${variableType}.${variableName}`.toUpperCase();

                //SET value tu text variableLines element
                this.custom.variablesLines?.texts
                    ?.filter((v) => v['selectedVariable'] === key)
                    .forEach((text) => (text['text'] = String(notification.variableValue)));
                //SET value tu metadataBlock text variableLines element
                this.metadataBlocks.metadata
                    .filter((b) => b['variable'] === key)
                    .forEach((block) => {
                        block.texts.filter((v) => v['variable'] === key).forEach((text) => (text['text'] = String(notification.variableValue)));
                    });

                const icon = this.$svg?.find(`path.shape[data-key="${key}"]`);
                const blockElement = this.$svg?.find(`g.shape.block > path.withVariable[data-key="${key}"]`);
                const blockTextElement = this.$svg?.find(`[data-key="${'text' + key}"]`);


                if ((blockElement?.length && blockTextElement?.length) || icon?.length) {
                    let pathTextFillColor = '#eeeeee';

                    const variableFound: VariableColors = findVariableColor(
                        areaId,
                        zoneId,
                        floorId,
                        lineId,
                        equipmentId,
                        equipmentType,
                        variableType,
                        variableName,
                        this.variableColorsConfiguration
                    );

                    if (variableFound) {
                        const variableValue = notification.variableValueType.toUpperCase() !== VariableValueType.Boolean.toUpperCase() ? notification.variableValue : notification.variableValue.toString().toUpperCase() === 'TRUE' ? 1 : notification.variableValue.toString().toUpperCase() === 'FALSE' ? 0 : notification.variableValue;
                        const defaultColor = variableFound.settings?.find((color) => +color.to === 0);
                        const foundedColor = variableFound.settings?.find((color) => +variableValue <= +color.to);
                        const finalColor = foundedColor ?? defaultColor;
                        if (finalColor) {
                            pathTextFillColor = finalColor.color;
                            if (icon?.length) {
                                if (finalColor.visible !== false) {
                                    icon.attr('style', 'fill:' + pathTextFillColor);
                                } else {
                                    icon.attr('style', 'display:none');
                                }
                            }
                            if (blockTextElement?.length) {
                                const foreColor = this.textColorPipe.transform(pathTextFillColor);
                                blockTextElement.attr('style', 'fill: ' + foreColor + '; stroke: ' + foreColor);
                            }
                        }
                        blockElement?.attr('style', 'fill:' + pathTextFillColor);
                    }
                }
            }
            this.waitForResponse = false;
            if (this.waitForResponseTimeout) {
                clearTimeout(this.waitForResponseTimeout);
                this.waitForResponseTimeout = null;
            }
        }
    }


    async handleReconnection(this) {
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        this.customSubscribed = false;
        this.currentFilters = [];
        //this.selectedVariablesFromDT.forEach((element) => this.loadVariableRT(element.selectedVariable));
        this.loadVariableRT();
    }

    getVisibleZones() {
        return this.custom.zones.map((zone) => zone.id);
    }

    checkZone(floor, area, zone) {
        const id = `${floor.id}-${area.id}-${zone.id}`;
        return this.visibleZones.includes(id);
    }

    checkMatrix(floor, area, zone) {
        const id = `${floor.id}-${area.id}-${zone.id}`;
        const zoneFounded = this.custom.zones.filter((z) => z.id === id);
        return zoneFounded.length > 0 ? `matrix(${zoneFounded[0].matrix})` : 'matrix(1, 0, 0, 1, 0, 0)';
    }

    checkRotation(floor, area, zone) {
        const id = `${floor.id}-${area.id}-${zone.id}`;
        const zoneF = this.custom.zones.filter((z) => z.id === id);
        return zoneF.length > 0 && zoneF[0].rotation ? { transform: `rotate(${zoneF[0].rotation})` } : { transform: 'rotate(0deg)' };
    }

    toogleFullScreen(): void {
        this.viewFullSize = !this.viewFullSize;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.viewFullSize ? $('html').addClass('no-scroll') : $('html').removeClass('no-scroll');
    }

    navigateToLine(event) {
        const key = $(event.target).data('key');
        const keys = key.split('-');
        this.router.navigate([`warehouse/${this.warehouse}/floor/${keys[0]}/area/${keys[1]}/zone/${keys[2]}/line/${keys[3]}`]);
    }

    emitLine(event, newLineObject) {
        this.activateZoom = false;
        if (newLineObject) {
            this.newLineSelected.emit(newLineObject);
        } else if (event) {
            const key = $(event.target).data('key');
            const keys = key.split('-');

            this.newLineSelected.emit({
                warehouseId: this.warehouse,
                floorId: keys[0],
                areaId: keys[1],
                zoneId: keys[2],
                lineId: keys[3],
            });
        }
    }

    async startRealtimeNotifications() {
        try {
            this.warehouseStatusService.clearNotifications(this.groupName, WarehouseStatusSupportedEvents.LINE_STATE_CHANGED)

            await this.cleanLineMapStates();
            this.lineChange$
                ?.pipe(
                    bufferTime(200),
                    switchMap((notifications) => of(this.$svg ? notifications : [])),
                    switchMap((notifications) =>
                        of(notifications.filter((notification) =>
                            this.visibleZones.includes(`${notification.floorId}-${notification.areaId}-${notification.zoneId}`),
                        )),
                    ),
                    switchMap((allNotificationsOfCustom: LineStatusNotification[]) => {
                        return of(allNotificationsOfCustom.filter((notification) => {
                            const notificationTS = getEventTS(WarehouseStatusSupportedEvents.LINE_STATE_CHANGED, notification);
                            return this.warehouseStatusService.isNewer(this.groupName, WarehouseStatusSupportedEvents.LINE_STATE_CHANGED, notification, notificationTS)
                            //return NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, WarehouseStatusSupportedEvents.LINE_STATE_CHANGED, notificationTS, notification);
                        }));
                    }),
                    switchMap((notifications) => of(notifications.map((notification) => this.getElementToUpdateState(notification)))),
                    takeUntil(this.ngUnsubscribe),
                )
                .subscribe({
                    next: this.drawLineState.bind(this),
                    error: console.error,
                });
            this.loadCustomState();
        } catch (error) {
            console.error(error);
        }
    }

    loadCustomState() {
        this.warehouseStatusService.clearNotifications(this.groupName, WarehouseStatusSupportedEvents.LINE_STATE_CHANGED)

        const initialLineMapStates$ = this.customService.getCustomStatus(this.custom.id);
        initialLineMapStates$
            .pipe(
                //switchMap((allNotifications) => of(allNotifications.filter((notification) => this.custom.name === notification.floorId))),
                switchMap((allNotificationsOfCustom: LineStatusNotification[]) => {
                    return allNotificationsOfCustom.filter((notification) => {
                        const notificationTS = getEventTS(WarehouseStatusSupportedEvents.LINE_STATE_CHANGED, notification);
                        return this.warehouseStatusService.isNewer(this.groupName, WarehouseStatusSupportedEvents.LINE_STATE_CHANGED, notification, notificationTS)
                        // return NotificationsMap.isNewerNotification(
                        //     'WarehouseStatusService',
                        //     this.groupName,
                        //     WarehouseStatusSupportedEvents.LINE_STATE_CHANGED,
                        //     notificationTS,
                        //     notification,
                        // );
                    });
                }),
                switchMap((newestNotification) => {
                    return of(this.getElementToUpdateState(newestNotification));
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
                next: (notificationWithElement) => {
                    this.drawLineState([notificationWithElement]);
                },
                error: console.error,
            });
    }

    loadCustomStateVariables() {
        const initialCustomMapStatesVariables$ = this.customService.getCustomStatusVariables(this.custom.id);

        initialCustomMapStatesVariables$
            .pipe(
                take(1),
                // CAN't filter here, becouse the variable may be from any Floor, Area, Zone, etc
                // switchMap((allNotifications) => of(allNotifications.filter((notification) => this.custom.id === notification.floorId))),
                // switchMap((notifications) =>
                //     of(notifications.filter((notification) => {
                //         return this.visibleZones.includes(`${notification.floorId}-${notification.areaId}-${notification.zoneId}`)
                //     }
                //     )),
                // ),
                switchMap((allNotificationsOfCustom: VariableNotification[]) => {
                    return of(allNotificationsOfCustom.filter((notification) => {
                        return this.variablesActiveStatusService.isNewerNotificationFromService(notification)
                    }));
                })
            )
            .subscribe({
                next: (notificationWithElement) => {
                    notificationWithElement.forEach(v => {
                        v.requestId = this.currentRequestId;
                        this.receiveRealTimeVariables(v)
                    });
                },
                error: console.error,
            });
    }

    async cleanLineMapStates() {
        if (!this.$svg) return;
        if (this.firstMapLoad) {
            this.firstMapLoad = false;
            return;
        }
        for (const zone of this.visibleZones || []) {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            const el = this.getOrAddLineCache(zone);
            el.removeClass().removeData('state');
        }
        // await yieldToMain();
    }

    getElementToUpdateState(notification) {
        const key = `${notification.floorId}-${notification.areaId}-${notification.zoneId}-${notification.lineId}`;
        if (this.$svg) {
            const el = this.getOrAddLineCache(key);
            return { notification, el };
        }
        return undefined;
    }

    drawLineState(items: Array<any>) {
        for (const item of items) {
            if (!item) continue;
            const { notification, el } = item;
            const lineState = notification.state.filter((s) => s === 'unknown' || this.visibleStates?.includes(s)).join(' ');
            fastdom.mutate(() => el.removeClass().addClass(lineState).data('state', lineState));
        }
    }

    resetZoom() {
        if (this.customSVG) {
            this.customSVG.zoom(this.currentZoom);
            this.$svg.attr('viewBox', this.setViewBox);
            this.refreshVisibleLines();
        }
    }

    getOrAddLineCache(key: string) {
        if (!this.linesCache?.has(key)) {
            const el = this.$svg.find(`[data-key="${key}"]`);
            this.linesCache.set(key, el);
        }
        return this.linesCache.get(key);
    }

    onRightClick(e) {
        this.$svg?.find('path').tooltip('hide');

        this.keySelectedWithRightClick = $(e.target).data('key');

        this.rightClickX = e.pageX;
        this.rightClickY = e.pageY;

        return false;
    }
    hideContextMenu() {
        this.keySelectedWithRightClick = '';
        this.rightClickX = 0;
        this.rightClickY = 0;
    }
}
