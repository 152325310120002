<div [hidden]="liteView">
    <div class="map-wrapper" [ngClass]="viewFullSize ? 'expand' : ''" [hidden]="type !== 'Lines'">
        <h4 *ngIf="!isCustomView">
            {{ floor.id | fazlDecorator: [['F', identifiersPreferences.Floor], [], [], []] }}
            <button class="float-end btn btn-light px-1" (click)="resetZoom()" title="Reposition map">
                <i class="fa fa-crosshairs" aria-hidden="true"></i>
            </button>
            <button class="float-end btn btn-light px-1 me-2" (click)="toogleFullScreen()" [title]="viewFullSize ? 'Compress floor' : 'Expand floor'">
                <i [ngClass]="viewFullSize ? 'fa fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'" style="cursor: pointer" aria-hidden="true"></i>
            </button>
            <a *ngIf="mode === 'floor'" class="float-end mt-2 me-2" [routerLink]="['/warehouse', warehouse]">View complete warehouse&nbsp;&nbsp;|</a>
        </h4>
        <svg
            #svg2
            auwaSvgZoom
            directiveCurrentZoom="{{ currentZoom }}"
            directiveViewBox="{{ floor.linesViewBox || '0 0 3200 1800' }}"
            directiveActivateZoom="{{ activateZoom }}"
            (svgZoomEventEmmiter)="hideContextMenu(); refreshVisibleLines()"
            [attr.viewBox]="floor.linesViewBox || '0 0 3200 1800'"
        >
            <svg:g class="freeBacksGroup" [ngClass]="{ 'zone-svg-opacity': markedAreaZone && markedAreaZone.split('-').length !== 0 }" *ngIf="viewExtraData">
                <ng-container *ngFor="let item of floor.freeBacksLines.shapes">
                    <svg:path
                        *ngIf="item && item.data && item !== 'null'"
                        class="shape"
                        [attr.data-element]="'shape'"
                        [attr.d]="item.data"
                        [attr.style]="item.style"
                    ></svg:path>
                </ng-container>
                <ng-container *ngFor="let item of floor.freeBacksLines.texts">
                    <svg:text class="shape" [attr.data-element]="'shape'" [attr.font-size]="item.size || '16'" [attr.x]="item.x" [attr.y]="item.y">
                        <tspan dy="20">{{ item.text }}</tspan>
                    </svg:text>
                </ng-container>
                <ng-container *ngFor="let item of floor.freeBacksLines.arrows">
                    <svg:polyline class="shape" [attr.data-element]="'shape'" [attr.points]="item" marker-end="url(#triangle)"></svg:polyline>
                </ng-container>
            </svg:g>
            <ng-container *ngFor="let area of floor.areas; trackBy: trackByAreas">
                <svg:g
                    class="zone-svg"
                    [ngClass]="{
                        'zone-svg-opacity': markedAreaZone && markedAreaZone.split('-').length === 2 && markedAreaZone !== floor.id + '-' + area.id,
                        selected: markedAreaZone && markedAreaZone.split('-').length === 2 && markedAreaZone === floor.id + '-' + area.id
                    }"
                    attr.data-key="{{ floor.id }}-{{ area.id }}"
                >
                    <ng-container *ngFor="let zone of area.zones; trackBy: trackByZones">
                        <svg:g
                            [ngClass]="{
                                'zone-svg-opacity':
                                    markedAreaZone && markedAreaZone.split('-').length === 3 && markedAreaZone !== floor.id + '-' + area.id + '-' + zone.id,
                                selected:
                                    markedAreaZone && markedAreaZone.split('-').length === 3 && markedAreaZone === floor.id + '-' + area.id + '-' + zone.id
                            }"
                            attr.data-key="{{ floor.id }}-{{ area.id }}-{{ zone.id }}"
                        >
                            <ng-container *ngFor="let line of zone.lines; trackBy: trackByLines">
                                <svg:g
                                    [ngClass]="{
                                        'zone-svg-opacity':
                                            markedAreaZone &&
                                            markedAreaZone.split('-').length === 4 &&
                                            markedAreaZone !== floor.id + '-' + area.id + '-' + zone.id + '-' + line.id,
                                        selected:
                                            markedAreaZone &&
                                            markedAreaZone.split('-').length === 4 &&
                                            markedAreaZone === floor.id + '-' + area.id + '-' + zone.id + '-' + line.id
                                    }"
                                >
                                    <svg:path
                                        *ngIf="line.data && line.data !== 'null'"
                                        attr.title="Area {{ area.id | fazlDecorator: [[], ['A', identifiersPreferences.Area], [], []] }}<br>Zone {{
                                            zone.id | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []]
                                        }}<br><strong>Line {{ line.id | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]] }}</strong>"
                                        [attr.data-element]="'line'"
                                        attr.data-key="{{ floor.id }}-{{ area.id }}-{{ zone.id }}-{{ line.id }}"
                                        [attr.d]="line.data"
                                        (click)="emitLine($event, undefined)"
                                        (contextmenu)="onRightClick($event)"
                                    ></svg:path>
                                </svg:g>
                            </ng-container>
                        </svg:g>
                    </ng-container>
                </svg:g>
            </ng-container>
            <svg:g class="freeFrontsGroup" [ngClass]="{ 'zone-svg-opacity': markedAreaZone && markedAreaZone.split('-').length !== 0 }" *ngIf="viewExtraData">
                <ng-container *ngFor="let item of floor.freeFrontsLines.shapes">
                    <svg:path
                        *ngIf="item && item.data && item.data !== 'null'"
                        class="shape"
                        [attr.data-element]="'shape'"
                        [attr.d]="item.data"
                        [attr.style]="item.style"
                    ></svg:path>
                </ng-container>
                <ng-container *ngFor="let item of floor.freeFrontsLines.texts">
                    <svg:text
                        filter="url(#outline)"
                        class="shape"
                        [attr.font-size]="item.size || '16'"
                        [attr.data-element]="'shape'"
                        [attr.x]="item.x"
                        [attr.y]="item.y"
                    >
                        <tspan dy="20">{{ item.text }}</tspan>
                    </svg:text>
                </ng-container>
                <ng-container *ngFor="let item of floor.freeFrontsLines.arrows">
                    <svg:polyline class="shape" [attr.data-element]="'shape'" [attr.points]="item" marker-end="url(#triangle)"></svg:polyline>
                </ng-container>
            </svg:g>
            <svg:g
                *ngIf="isVisibilityEnabled"
                class="variablesGroup"
                [ngClass]="{ 'zone-svg-opacity': markedAreaZone && markedAreaZone.split('-').length !== 0 }"
            >
                <ng-container *ngFor="let item of floor?.variablesLines?.texts">
                    <svg:text
                        class="shape"
                        [attr.data-element]="'shape'"
                        [attr.font-size]="item.size || '16'"
                        [attr.x]="item.x"
                        [attr.y]="item.y"
                        [attr.variable]="item.variable"
                        *ngIf="item"
                    >
                        <!--[attr.data-key]="item.variable ? item.variable : ''"-->
                        <tspan dy="20">{{ item.text }}</tspan>
                        <!--[attr.text-anchor]="item.variable ? 'middle' : 'left'" -->
                        <title>{{ item.variable }}</title>
                    </svg:text>
                </ng-container>
                <ng-container *ngFor="let item of floor?.variablesLines?.shapes">
                    <svg:path
                        class="shape"
                        [attr.data-element]="'shape'"
                        [attr.d]="item.data"
                        [attr.data-key]="item.variable"
                        [attr.variable]="item.variable"
                        *ngIf="item && item.data && item.data !== 'null'"
                    ></svg:path>
                    <title>{{ item.variable }}</title>
                </ng-container>
                <ng-container *ngFor="let block of metadataBlocks?.metadata">
                    <svg:g class="shape block" [attr.transform]="block?.transform" [attr.variable]="block?.variable" [attr.description]="block?.description">
                        <ng-container *ngFor="let item of block?.shapes">
                            <svg:path
                                *ngIf="item && item.data && item.data !== 'null'"
                                [attr.data-element]="'shape'"
                                [attr.data-key]="item.variable"
                                [ngClass]="{ withVariable: item.variable, highlightPath: item.highlightPath }"
                                [attr.d]="item.data"
                                [attr.variable]="item.variable"
                                [attr.description]="item.description"
                            >
                                <title>{{ block.variable }} - {{ block.description }}</title>
                            </svg:path>
                        </ng-container>
                        <ng-container *ngFor="let item of block?.texts">
                            <svg:text
                                class="shape"
                                [attr.data-element]="'shape'"
                                [attr.font-size]="item.size || '16'"
                                [attr.x]="item.x"
                                [attr.y]="item.y"
                                [attr.data-key]="item.variable ? 'text' + item.variable : ''"
                                [attr.variable]="item.variable"
                                [attr.description]="item.description"
                            >
                                <tspan [attr.text-anchor]="item.variable ? 'middle' : 'left'" dy="20">{{ item.text }}</tspan>
                                <title>{{ item.variable }}</title>
                            </svg:text>
                        </ng-container>
                    </svg:g>
                </ng-container>
                <ng-container *ngFor="let item of floor?.variablesLines?.texts">
                    <svg:text
                        class="shape"
                        [attr.data-element]="'shape'"
                        [attr.font-size]="item.size || '16'"
                        [attr.x]="item.x"
                        [attr.y]="item.y"
                        [attr.variable]="item.variable"
                    >
                        <!--[attr.data-key]="item.variable ? item.variable : ''"-->
                        <tspan dy="20">{{ item.text }}</tspan>
                        <!--[attr.text-anchor]="item.variable ? 'middle' : 'left'" -->
                        <title>{{ item.variable }}</title>
                    </svg:text>
                </ng-container>
                <ng-container *ngFor="let item of floor?.variablesLines?.shapes">
                    <svg:path
                        class="shape"
                        [attr.data-element]="'shape'"
                        [attr.d]="item.data"
                        [attr.data-key]="item.variable"
                        [attr.variable]="item.variable"
                        *ngIf="item && item.data && item.data !== 'null'"
                    >
                        <title>{{ item.variable }}</title>
                    </svg:path>
                </ng-container>
            </svg:g>
        </svg>
        <div class="elements-over-map" #buttonsOverMap>
            <div [id]="'accordion-' + floor.id" class="accordion" *ngIf="linesOverMap.length > 0">
                <div class="accordion-item">
                    <h2 class="accordion-header" [id]="'heading-' + floor.id">
                        <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse-' + floor.id"
                            aria-expanded="false"
                            [attr.aria-controls]="'collapse-' + floor.id"
                        >
                            <h6 class="linesOverMap-title my-2">
                                <span class="badge bg-dark me-2">{{ countLinesOverMap }}</span>
                                Zones without map in {{ floor.id | fazlDecorator: [['F', identifiersPreferences.Floor], [], [], []] }}
                            </h6>
                        </button>
                    </h2>
                    <div
                        [id]="'collapse-' + floor.id"
                        class="accordion-collapse collapse"
                        [attr.aria-labelledby]="'heading-' + floor.id"
                        [attr.data-bs-parent]="'#accordion-' + floor.id"
                    >
                        <div class="accordion-body">
                            <ng-template ngFor let-zone [ngForOf]="linesOverMap">
                                <div class="row" *ngIf="zone.lines.length > 0">
                                    <h5 class="svg-area-title">
                                        <strong>Zone {{ zone.id | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []] }}</strong>
                                    </h5>
                                </div>
                                <div class="row" *ngIf="zone.lines.length > 0" style="margin: 0">
                                    <div class="btn-zones col-6 col-md-4 col-lg-2" *ngFor="let line of zone.lines">
                                        <div
                                            role="button"
                                            attr.data-key="{{ floor.id }}-{{ zone.id }}-{{ line.id }}"
                                            data-element="line"
                                            class="btn btn-default"
                                            (click)="emitLine($event, undefined)"
                                        >
                                            {{ line.id | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]] }}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card" *ngIf="liteView">
    <div class="card-header" id="heading{{ floor.id }}">
        <a data-bs-toggle="collapse" attr.data-bs-target="#collapse{{ floor.id }}" aria-expanded="true" attr.aria-controls="collapse{{ floor.id }}">
            <p class="accordion-title">
                {{ floor.id | fazlDecorator: [['F', identifiersPreferences.Floor], [], [], []] }}
                <span class="badge">{{ floor.areas.length }}</span>
            </p>
        </a>
    </div>
    <div id="collapse{{ floor.id }}" class="collapse show" attr.aria-labelledby="heading{{ floor.id }}" data-parent="#accordion">
        <div class="card-body" #buttons>
            <div class="row" *ngFor="let area of floor.areas; trackBy: trackByAreasLite">
                <div class="col-12">
                    <p class="area-title">
                        <strong>Area {{ area.id | fazlDecorator: [[], ['A', identifiersPreferences.Area], [], []] }}</strong>
                        <a [routerLink]="['/warehouse', warehouse, 'floor', floor.id, 'area', area.id]" style="padding-left: 10px">View complete area</a>
                    </p>
                </div>
                <div class="col-12">
                    <div class="row" style="margin-left: -5px; margin-right: -5px">
                        <div [ngClass]="mode === 'floor' ? 'col-6 col-md-4 col-lg-3' : 'col-2'" class="btn-zones" *ngFor="let zone of area.zones">
                            <div
                                role="button"
                                attr.data-key="{{ floor.id }}-{{ area.id }}-{{ zone.id }}"
                                title="Area {{ area.id | fazlDecorator: [[], ['A', identifiersPreferences.Area], [], []] }} - Zone {{
                                    zone.id | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []]
                                }}"
                                data-element="zone"
                                class="btn btn-default"
                                (click)="navigateToZone($event)"
                            >
                                {{ zone.id | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-context-menu
    [keySelectedWithRightClick]="keySelectedWithRightClick"
    [warehouse]="warehouse"
    [contextId]="floor.id"
    [x]="rightClickX"
    [y]="rightClickY"
    [identifiersPreferences]="identifiersPreferences"
    (newLineSelectedFromContextMenu)="emitLine(undefined, $event)"
    hideLink="Floor"
></app-context-menu>
