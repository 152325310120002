import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppLoadService } from '@app/app-load.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationsService {
    constructor(
        private http: HttpClient,
        private appLoadService: AppLoadService,
    ) { }

    readConfiguration(url, user): Observable<any> {
        const data = this.http.get<any[]>(`${url}/api/configuration/${user}`);
        return data;
    }
    createConfiguration(url, configuration): Observable<any[]> {
        const data = this.http.post<any[]>(`${url}/api/configuration`, configuration);
        return data;
    }
    updateConfiguration(url, configuration): Observable<any[]> {
        const data = this.http.put<any[]>(`${url}/api/configuration`, configuration);
        return data;
    }
    deleteConfiguration(url, user): Observable<any[]> {
        const data = this.http.delete<any[]>(`${url}/api/configuration/${user}`);
        return data;
    }

    /*
    There are 2 levels of configurations stored:
        The user level is the configuration that allows the user to customize its own view or the filters applied to the view.
        As in some cases all users in the same warehouse must see the data in the same format, there is a configuration at warehouse level. 
    To differentiate this, the method saveConfiguration can receive as key the user email or the warehouseId. If the key contains an @ the configuration will be stored as user configuration whereas if @ is not present will be stored as warehouse configuration
    */
    saveConfiguration(hostName: string, userEmail: string, userName: string, configuration: any) {
        //TODO: this function may be merge with saveConfigurationPromise and only have one (the problem is inside nav-component - changeWarehouse)
        this.readConfiguration(hostName, userEmail)
            .pipe(take(1))
            .subscribe((data: any) => {
                if (data) {
                    data.data = JSON.stringify(configuration);
                    this.updateConfiguration(hostName, data)
                        .pipe(take(1))
                        .subscribe((res) => {
                            if (userEmail.includes('@')) {
                                this.appLoadService.setCurrentConfiguration(configuration);
                            } else {
                                this.appLoadService.setGlobalConfiguration(configuration);
                            }
                        });
                } else {
                    const newConfiguration = {
                        application: 'Auwa',
                        user: userEmail,
                        name: userName,
                        data: JSON.stringify(configuration),
                    };
                    this.createConfiguration(hostName, newConfiguration)
                        .pipe(take(1))
                        .subscribe((res) => {
                            if (userEmail.includes('@')) {
                                this.appLoadService.setCurrentConfiguration(configuration);
                            } else {
                                this.appLoadService.setGlobalConfiguration(configuration);
                            }
                        });
                }
            });
    }

    saveConfigurationPromise(hostName: string, userEmail: string, userName: string, configuration: any) {
        //TODO: this function may be merge with saveConfiguration and only have one (the problem is inside nav-component - changeWarehouse)
        return new Promise((resolve, reject) => {
            try {
                this.readConfiguration(hostName, userEmail)
                    .pipe(take(1))
                    .subscribe((data: any) => {
                        if (data) {
                            data.data = JSON.stringify(configuration);
                            this.updateConfiguration(hostName, data)
                                .pipe(take(1))
                                .subscribe((res) => {
                                    if (userEmail.includes('@')) {
                                        this.appLoadService.setCurrentConfiguration(configuration);
                                        resolve(true);
                                    } else {
                                        this.appLoadService.setGlobalConfiguration(configuration);
                                        resolve(true);
                                    }
                                });
                        } else {
                            const newConfiguration = {
                                application: 'Auwa',
                                user: userEmail,
                                name: userName,
                                data: JSON.stringify(configuration),
                            };
                            this.createConfiguration(hostName, newConfiguration)
                                .pipe(take(1))
                                .subscribe((res) => {
                                    if (userEmail.includes('@')) {
                                        this.appLoadService.setCurrentConfiguration(configuration);
                                        resolve(true);
                                    } else {
                                        this.appLoadService.setGlobalConfiguration(configuration);
                                        resolve(true);
                                    }
                                });
                        }
                    });
            } catch (error) {
                resolve(false);
            }
        })
    }
}
