import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppLoadService } from '@app/app-load.service';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { environment } from '@environments/environment';
import { UserConfiguration, WarehouseConfiguration } from '@app/shared/models/configurations';
import { VariableValueType } from '@app/notifications/shared/events/variable-status';
import { ModalConfirmationComponent } from '@ays/commons/lib/components/modal-confirmation/modal-confirmation.component';
import {
    ScannerTrendColors,
    ErrorTrendColors,
    VariableColors,
    ZoneColors,
    //BoxDetectedColors,
} from '@variables/shared/variable';
import { ColorsService } from '@app/shared/services/colors.service';
import { IdentifiersPreferences } from '@app/map/home/shared/structure';
import * as _ from 'lodash-es';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements AfterViewInit, OnDestroy {
    @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalConfirmationComponent;

    warehouse: Warehouse;
    userName: string;
    userEmail: string;

    //warehouses: Array<Warehouse> = [];
    userConfiguration: UserConfiguration;
    warehouseConfiguration: WarehouseConfiguration;

    liteView: boolean;
    darkMode: boolean;
    userConfigurationIdentifiersPreferences: IdentifiersPreferences = { Floor: null, Area: null, Zone: null, Line: null };

    warehouseConfigurationHeatMap: any;

    //#region COLORS
    warehouseConfigurationAlertsZonesColors: ZoneColors[] = [];
    alertsZonesColorsToDelete: string = ''

    warehouseConfigurationVariableColors: VariableColors[] = [];
    variableColorToDelete: { id: string, variable: string }
    variableNumericType = VariableValueType.Numeric.toLowerCase();

    warehouseConfigurationScannerTrendColors: ScannerTrendColors[] = [];
    scannerTrendColorsToDelete: string = ''

    // warehouseConfigurationBoxDetectedColors: BoxDetectedColors = { lightMode: '', darkMode: '' };
    // userConfigurationBoxDetectedColors: BoxDetectedColors = { lightMode: '', darkMode: '' };
    // colorBoxes: string = this.colorsService.getColor('default-status-boxes'); //'#8B572A';

    warehouseConfigurationErrorTrendColors: ErrorTrendColors[] = [];

    confirmationMessage = {
        messageId: 0,
        parameters: {},
        text: null,
    };

    dangerMode = true;
    //#endregion

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private appLoadService: AppLoadService,
        private configurationsService: ConfigurationsService,
        private authenticationService: AuthenticationService,
        private colorsService: ColorsService,
    ) {
        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
        this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.warehouse = res;
        });
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.liteView = this.userConfiguration?.liteMode ?? false;
            this.darkMode = this.userConfiguration?.darkMode ?? false;

            this.userConfigurationIdentifiersPreferences = this.userConfiguration.identifiersPreferences

            // this.userConfigurationBoxDetectedColors = this.userConfiguration?.boxDetectedColors ?? { lightMode: this.colorBoxes, darkMode: this.colorBoxes };
            // this.userConfigurationBoxDetectedColors.lightMode =
            //     this.userConfigurationBoxDetectedColors.lightMode !== '' ? this.userConfigurationBoxDetectedColors.lightMode : this.colorBoxes;
            // this.userConfigurationBoxDetectedColors.darkMode =
            //     this.userConfigurationBoxDetectedColors.darkMode !== '' ? this.userConfigurationBoxDetectedColors.darkMode : this.colorBoxes;
        });
        this.appLoadService.getGlobalConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            try {
                this.warehouseConfiguration = res;

                this.warehouseConfigurationHeatMap = this.warehouseConfiguration?.heatMap;

                this.warehouseConfigurationVariableColors = this.warehouseConfiguration?.variableColors?.map(v => {
                    return {
                        variable: v?.variable,
                        settings: v?.settings,
                        dataType: v?.dataType ? v?.dataType.toLowerCase() : this.variableNumericType
                    }
                }) ?? [];
                this.warehouseConfigurationAlertsZonesColors = this.warehouseConfiguration?.alertsZonesColors ?? [];
                this.warehouseConfigurationScannerTrendColors = this.warehouseConfiguration?.scannerTrendColors ?? [];
                this.warehouseConfigurationErrorTrendColors = this.warehouseConfiguration?.errorTrendColors ?? [];

                // this.warehouseConfigurationBoxDetectedColors = this.warehouseConfiguration?.boxDetectedColors ?? {
                //     lightMode: this.colorBoxes,
                //     darkMode: this.colorBoxes,
                // };

                // this.warehouseConfigurationBoxDetectedColors.lightMode =
                //     this.warehouseConfigurationBoxDetectedColors.lightMode !== '' ? this.warehouseConfigurationBoxDetectedColors.lightMode : this.colorBoxes;
                // this.warehouseConfigurationBoxDetectedColors.darkMode =
                //     this.warehouseConfigurationBoxDetectedColors.darkMode !== '' ? this.warehouseConfigurationBoxDetectedColors.darkMode : this.colorBoxes;
            } catch (error) {
                console.log(`%c GetGlobalConfiguration setting error => ${error}`, `background: ; color: red`);
            }
        })
    }

    ngAfterViewInit() {
        //#region COLORS
        $('#automationConfirmationModal > div > div > div.modal-footer').css('border-top', 'none');
        //#endregion
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    toggleViewMode(e) {
        if (!$(e.target).hasClass('active')) {
            this.userConfiguration.liteMode = !this.liteView;

            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
        }
    }

    toggleDarkMode(e) {
        if (!$(e.target).hasClass('active')) {
            this.userConfiguration.darkMode = !this.darkMode;

            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
        }
    }

    confirmationEmited(value) {
        if (value) {
            switch (this.confirmationMessage.messageId) {
                case 2: {
                    const deleteId = this.confirmationMessage?.parameters['id'];
                    this.variableColorToDelete = { id: deleteId, variable: '' }
                    break;
                }
                case 3: {
                    const deleteId = this.confirmationMessage?.parameters['id'];
                    const deleteVariable = this.confirmationMessage?.parameters['variable'];
                    this.variableColorToDelete = { id: deleteId, variable: deleteVariable }
                    break;
                }
                case 4: {
                    const deleteVariable = this.confirmationMessage?.parameters['variable'];
                    this.variableColorToDelete = { id: '', variable: deleteVariable }
                    break;
                }
                case 5: {
                    this.alertsZonesColorsToDelete = this.confirmationMessage?.parameters['zone'];
                    break;
                }
                case 6: {
                    this.scannerTrendColorsToDelete = this.confirmationMessage?.parameters['variable'];
                    break;
                }
                default: {
                }
            }
        }
        this.confirmationMessage = { messageId: 0, parameters: null, text: '' };
        this.confirmationModal.closeModal();
    }

    deleteConfirmation(messageId: number, id: string, variable: string, enviroment: string) {
        switch (messageId) {
            case 2: {
                this.confirmationMessage = {
                    messageId,
                    parameters: { id },
                    text: `Do you want to delete the <strong>${enviroment}</strong> enviroment?`,
                };
                break;
            }
            case 3: {
                this.confirmationMessage = {
                    messageId,
                    parameters: { id, variable },
                    text: `Do you want to delete the <strong>${enviroment}</strong> enviroment from the <strong>${variable
                        .split('.')
                        .pop()}</strong> variable?`,
                };
                break;
            }
            case 4: {
                this.confirmationMessage = {
                    messageId,
                    parameters: { variable },
                    text: `Do you want to delete the <strong>${variable.split('.').pop()}</strong> color configuration?`,
                };
                break;
            }
            case 5: {
                this.confirmationMessage = {
                    messageId,
                    parameters: { zone: variable },
                    text: `Do you want to delete the zone <strong>${variable}</strong> color configuration?`,
                };
                break;
            }
            case 6: {
                this.confirmationMessage = {
                    messageId,
                    parameters: { variable },
                    text: `Do you want to delete the scanner <strong>${variable}</strong> color configuration?`,
                };
                break;
            }
        }
        this.confirmationModal.openModal();
    }

    updateHeatMap(heatMap) {
        try {
            this.warehouseConfiguration.heatMap = heatMap?.heatMap;

            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.warehouse.warehouse, this.warehouse.warehouse, this.warehouseConfiguration);
        } catch (error) {
            console.log(`%c updateVariableColor setting error => ${error}`, `background: red; color: gold`);
        }
    }

    updateIdentifiers(identifiers: IdentifiersPreferences) {
        try {
            this.userConfiguration.identifiersPreferences = identifiers;

            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
        } catch (error) {
            console.log(`%c updateIdentifiers setting error => ${error}`, `background: red; color: gold`);
        }
    }

    //#region COLORS
    variableColorDeleteConfirmation(event) {
        this.deleteConfirmation(event?.messageId, event?.id, event?.variable, event?.enviroment)
    }
    updateVariableColor(variableColors) {
        try {
            this.warehouseConfiguration.variableColors = [...variableColors?.variableColors];

            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.warehouse.warehouse, this.warehouse.warehouse, this.warehouseConfiguration);
        } catch (error) {
            console.log(`%c updateVariableColor setting error => ${error}`, `background: red; color: gold`);
        }
    }
    //#endregion
    //#region ZoneColor
    alertsZonesDeleteConfirmation(event) {
        this.deleteConfirmation(event?.messageId, event?.id, event?.variable, event?.enviroment)
    }
    updateAlertsZonesColor(alertsZones) {
        try {
            this.warehouseConfiguration.alertsZonesColors = [...alertsZones?.alertsZones];

            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.warehouse.warehouse, this.warehouse.warehouse, this.warehouseConfiguration);
        } catch (error) {
            console.log(`%c updateAlertsZonesColor setting error => ${error}`, `background: red; color: gold`);
        }
    }
    //#endregion
    //#region ScannerColor
    scannerTrendDeleteConfirmation(event) {
        this.deleteConfirmation(event?.messageId, event?.id, event?.variable, event?.enviroment)
    }
    updateScannersTrendColor(scannerTrend) {
        try {
            this.warehouseConfiguration.scannerTrendColors = [...scannerTrend?.scannerTrend];

            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.warehouse.warehouse, this.warehouse.warehouse, this.warehouseConfiguration);
        } catch (error) {
            console.log(`%c updateScannersTrendColor setting error => ${error}`, `background: red; color: gold`);
        }
    }
    //#endregion
    //#region ErrorTrendColor
    updateErrorTrendColor(errorTrend) {
        try {
            this.warehouseConfiguration.errorTrendColors = [...errorTrend?.errorTrend];

            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.warehouse.warehouse, this.warehouse.warehouse, this.warehouseConfiguration);
        } catch (error) {
            console.log(`%c updateErrorTrendColor setting error => ${error}`, `background: red; color: gold`);
        }
    }
    //#endregion
    //#region boxDetectedColor
    // updateBoxDetectedColor(e) {
    //     try {
    //         if (!e.userConfiguration) {
    //             if (e.lightMode) {
    //                 this.warehouseConfigurationBoxDetectedColors.lightMode = e.newColor;
    //             } else {
    //                 this.warehouseConfigurationBoxDetectedColors.darkMode = e.newColor;
    //             }
    //             this.warehouseConfiguration.boxDetectedColors = this.warehouseConfigurationBoxDetectedColors;
    //             this.colorsService.setColor('status-boxes', e.newColor);

    //             this.configurationsService.saveConfiguration(this.warehouse.hostName, this.warehouse.warehouse, this.warehouse.warehouse, this.warehouseConfiguration);
    //         } else {
    //             if (e.lightMode) {
    //                 this.userConfigurationBoxDetectedColors.lightMode = e.newColor;
    //             } else {
    //                 this.userConfigurationBoxDetectedColors.darkMode = e.newColor;
    //             }
    //             this.userConfiguration.boxDetectedColors = this.userConfigurationBoxDetectedColors;
    //             this.colorsService.setColor('status-boxes', e.newColor);

    //             this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
    //         }
    //     } catch (error) {
    //         console.log(`%c UpdateBoxDetectedColor setting error => ${error}`, `background: red; color: gold`);
    //     }
    // }
    //#endregion
}