import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrow',
})
export class ArrowPipe implements PipeTransform {
    constructor() { }

    private acceptedArrows = ['hl', 'hr', 'vu', 'vd'];

    transform(value: string, option: string, fullSize: boolean = true): string { //option O: Orientation, X: xArrow, Y: yArrow
        if (option === 'O') {
            if (value) {
                const orientation = value.toLowerCase();
                if (this.acceptedArrows.includes(orientation)) {
                    const full = fullSize ? '-full' : ''
                    return `assets/img/arrows/${orientation}.svg#${orientation}${full}`;
                } else {
                    return ``;
                }
            } else {
                return ``;
            }
        }
        if (['X', 'Y'].includes(option) && isNaN(+value)) { return '0'; }
        return Math.max(+value, 0).toString();
    }
}
