/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, EventEmitter } from '@angular/core';
import { EquipmentAlarmNotification, AlertsActiveStatus, ALERTS_ACTIVE } from '@app/notifications/shared/events/alert-status';
import { MonitoringService } from '../../../shared/services/monitoring.service';
import { WarehouseStatusClient } from '../clients/warehouse-status.client';
import { tap, bufferTime, filter } from 'rxjs/operators';
import { MapStates } from '@app/shared/models/map-state';
import { WarehouseStatusSupportedEvents } from '../events/warehouse-status';
import { NotificationsMap } from '../notifications-map/notifications-map';
import * as dayjs from 'dayjs';

@Injectable()
export class AlertsActiveStatusService {
    alertsType = [MapStates.alert, 'maintenance_pending', 'maintenance_processing', 'maintenance_blocked', 'resolved'];
    onAlert: EventEmitter<EquipmentAlarmNotification> = new EventEmitter<EquipmentAlarmNotification>();
    onNewAlert: EventEmitter<EquipmentAlarmNotification> = new EventEmitter<EquipmentAlarmNotification>();
    alertSubscription: any;
    alertSubscriptionTracking: any;
    alertTSMap = new WeakMap();
    onReconnect: () => Promise<void>;
    handleReconnectionReference: () => Promise<void>;

    private readonly equipmentsCallback = (alertsActiveStatus: AlertsActiveStatus[]) => {
        alertsActiveStatus.forEach((alertActiveStatus) => {
            if (/*!this.onAlert.isStopped &&*/ this.alertsType.includes(alertActiveStatus.state)) {
                try {
                    this.onAlert.emit({
                        floorId: alertActiveStatus.floorid,
                        areaId: alertActiveStatus.areaid,
                        zoneId: alertActiveStatus.zoneid,
                        lineId: alertActiveStatus.lineid,
                        equipmentId: alertActiveStatus.equipmentId,
                        equipmentType: alertActiveStatus.equipmentType,
                        state: alertActiveStatus.state,
                        variableName: alertActiveStatus.variableName,
                        priority: alertActiveStatus.priority,
                        source: alertActiveStatus.source,
                        sourceTimeStamp: alertActiveStatus.sourceTimeStamp.toString(),
                        serverts: alertActiveStatus.serverTimeStamp.toString(),
                    });
                } catch (error) {
                    console.log(`%c Alert active Emit error => ${error}`, `background: ; color: red`);
                }
            }
        });
    };

    constructor(
        private warehouseStatusClient: WarehouseStatusClient,
        private monitoringService: MonitoringService,
    ) { }

    public async subscribeToAlert(
        warehouse: string,
        context: any,
        delegateFunc: (notification: EquipmentAlarmNotification) => void,
        onReconnect: () => Promise<void>,
    ) {
        try {
            const eventName = WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED;
            NotificationsMap.clearNotificationState(this.constructor.name, ALERTS_ACTIVE, eventName);
            this.alertSubscription = this.onAlert.subscribe({
                next: (event: EquipmentAlarmNotification) => {
                    const notificationTS = event.serverts;

                    if (NotificationsMap.isNewerNotification(this.constructor.name, ALERTS_ACTIVE, eventName, notificationTS, event)) {
                        delegateFunc(event);
                        this.onNewAlert.emit(event);
                    }
                },
            });
            this.alertSubscriptionTracking = this.onNewAlert
                .pipe(
                    filter((event: EquipmentAlarmNotification) => event.source === 'EventHub'),

                    tap((event: EquipmentAlarmNotification) =>
                        this.alertTSMap.set({ fqn: `${warehouse}-${event.equipmentId}`, ts: event.sourceTimeStamp, state: event.state }, new Date().getTime()),
                    ),
                    bufferTime(5000),
                )
                .subscribe({
                    next: (events: EquipmentAlarmNotification[]) => {
                        if (localStorage.getItem('AlertLatencyMetricEnabled') != null) {
                            try {
                                events.forEach((event) => {
                                    const sourcets = dayjs(event.sourceTimeStamp).toDate();

                                    const alertTS =
                                        this.alertTSMap.get({ fqn: `${warehouse}-${event.equipmentId}`, ts: event.sourceTimeStamp, state: event.state }) ||
                                        new Date().getTime();
                                    const timeDiff = (alertTS - sourcets.getTime()) / 1000;
                                    this.monitoringService.logMetric('Auwa.Web.SignalR.AlertLatency', timeDiff, null, null, null, {
                                        Equipment: event.equipmentId,
                                        Warehouse: warehouse,
                                    });
                                });
                            } catch {
                                console.error('Error when ActiveAlerts socket');
                            }
                        }
                    },
                });
            NotificationsMap.clearNotificationState(this.constructor.name, ALERTS_ACTIVE, eventName);
            this.onReconnect = onReconnect;
            this.handleReconnectionReference = this.handleReconnection.bind(this);
            await this.warehouseStatusClient.subscribe(eventName, warehouse, this.equipmentsCallback, this.handleReconnectionReference);
        } catch (error) {
            console.log(`%c Subscribe to Alert error => ${error}`, `background: ; color: red`);
        }
    }

    async handleReconnection() {
        const eventName = WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED;
        NotificationsMap.clearNotificationState(this.constructor.name, ALERTS_ACTIVE, eventName);
        await this.onReconnect();
    }

    public async unsubscribeFromAlert(warehouse: string) {
        try {
            const eventName = WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED;
            NotificationsMap.clearNotificationState(this.constructor.name, ALERTS_ACTIVE, eventName);
            this.alertSubscription?.unsubscribe();
            this.alertSubscription = null;
            this.alertSubscriptionTracking?.unsubscribe();
            this.alertSubscriptionTracking = null;
            await this.warehouseStatusClient.unsubscribe(eventName, warehouse);
        } catch (error) {
            console.log(`%c Error unsubscribeFromAlert => ${error}`, `background: ; color: red`);
        }
    }

    isNewerNotificationFromService(notification: EquipmentAlarmNotification): boolean {
        const eventName = WarehouseStatusSupportedEvents.ALERT_STATE_CHANGED;
        const notificationTS = notification.serverts;
        return NotificationsMap.isNewerNotification(this.constructor.name, ALERTS_ACTIVE, eventName, notificationTS, notification)
    }

    isConnected() {
        return this.warehouseStatusClient.connection?.hubConnection.state === 'Connected';
    }
}
