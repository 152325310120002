<div class="row mt-3 mb-3 justify-content-center">
    <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
        <div class="row mt-4 mb-5">
            <div class="col-12 col-md-6 col-lg-7">
                <h5 class="float-start text-start ms-5 me-3 mt-2">ALERTS/WARNINGS TABLE - ZONE COLORS</h5>
            </div>
            <div class="col-6 offset-3 col-md-6 col-lg-3 offset-md-0">
                <app-search
                    #alertsZonesFilter
                    key="txtFindAlertsZonesConfiguration"
                    placeholder="Filter Zones"
                    class=""
                    icon="fas fa-search"
                    (searchValueDeleted)="alertsZonesFilterValue = ''"
                >
                </app-search>
            </div>
            <div class="container">
                <div class="row ms-md-5 mt-2 mb-2">
                    <div class="col-12 ms-5 mt-0">
                        <table class="table-compact table-hover w-75 mt-1 mb-5">
                            <thead>
                                <tr>
                                    <th class="w-50">Zone</th>
                                    <th class="text-center w-25">Color</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let zoneColor of alertsZonesColors; index as i; trackBy: trackByAlertZone"
                                    [hidden]="alertsZonesFilterValue !== '' && !zoneColor.zone.includes(alertsZonesFilterValue)"
                                    style="cursor: auto"
                                >
                                    <td>
                                        <span class="force-one-line">
                                            {{ zoneColor.zone }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <shared-color-picker
                                            class="level-2 badge me-1 w-50 py-2 my-1"
                                            [colorRange]="zoneColor.color"
                                            [fromDataRange]="''"
                                            [toDataRange]="''"
                                            [symbol]="''"
                                            [middle]="zoneColor.color"
                                            okButtonText="SELECT"
                                            (colorSelected)="saveAlertsZonesColors($event, zoneColor.zone)"
                                            [ngStyle]="{ 'background-color': zoneColor.color, color: zoneColor.color | textColor }"
                                        ></shared-color-picker>
                                    </td>
                                    <td class="text-center">
                                        <button
                                            class="btn btn-sm btn-danger my-1"
                                            title="Delete Zone Color"
                                            (click)="deleteConfirmation(5, '', zoneColor.zone, '')"
                                        >
                                            <i class="fas fa-times-circle"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <app-select-multilevel
                                            columns="3"
                                            #settingsFAZ
                                            key="faz-search-settings"
                                            label="Select Zone"
                                            [withAllValue]="false"
                                            [withTopLevelsDisabled]="true"
                                        ></app-select-multilevel>
                                    </td>
                                    <td class="text-center">
                                        <shared-color-picker
                                            class="level-2 badge me-1 w-50 py-2"
                                            [colorRange]="newColorSelectedZone"
                                            [fromDataRange]="''"
                                            [toDataRange]="''"
                                            [symbol]="''"
                                            [middle]="newColorSelectedZone"
                                            okButtonText="SELECT"
                                            (colorSelected)="selectedNewColorZone($event)"
                                            [ngStyle]="{ 'background-color': newColorSelectedZone, color: newColorSelectedZone | textColor }"
                                        ></shared-color-picker>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-primary my-1" (click)="addNewAlertsZonesColors()">
                                            <i class="fas fa-check" title="Add Zone Color"></i>
                                        </button>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
