import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppLoadService } from '@app/app-load.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { Alert } from '@alerts/shared/alert';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { AlertsService } from '@alerts/shared/alerts.service';
import { MapGlobalState, MapState, initialStates } from '@app/shared/models/map-state';
import { AlertsTableSmComponent } from '@app/shared/components/alerts-table-sm/alerts-table-sm.component';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { EquipmentAlarmNotification } from '@app/notifications/shared/events/alert-status';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import * as _ from 'lodash-es';

const { ALERT_STATE_CHANGED, LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;

@Component({
    templateUrl: './zones.component.html',
    styleUrls: ['./zones.component.scss'],
})
export class ZonesComponent extends MapContainerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('alertsTable') alertsTableSmComponent: AlertsTableSmComponent;
    floorId = '0';
    areaId = '0';
    zoneId = '0';
    groupName: string;
    configurationFilterValues: Array<MapGlobalState>;
    //liteView: boolean;
    warehouse: Warehouse;
    alertsView = true;
    //analyticsView: boolean;
    //variablesView: boolean;
    priority$ = ['All', '1', '2', '3'];
    map: any;
    zoneKpis: any;
    zoneAlerts: Array<Alert>;
    equipmentTypes: Array<string> = [];
    linesVisible: Array<{
        floorId;
        areaId;
        zoneId;
        lineId;
    }> = [];
    line: any;
    isFullscreen = false;
    showFilters = false;
    configuration: any;
    userName: string;
    userEmail: string;
    supportedEvents: Array<string> = [ALERT_STATE_CHANGED, LINE_STATE_CHANGED];
    lineChange$: Observable<LineStatusNotification>;
    mapRender = false;
    tabState: VisibilityStates = VisibilityStates.active;

    lineToShow = '';
    activateZoom = true;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private appLoadService: AppLoadService,
        private configurationsService: ConfigurationsService,
        private route: ActivatedRoute,
        private warehouseStatusService: WarehouseStatusService,
        private alertsService: AlertsService,
        private authenticationService: AuthenticationService,
        //private pageLifeCycleService: PageLifecycleService
    ) {
        super();
        this.configuration = this.route.snapshot.data.zone.configuration;
        this.map = this.route.snapshot.data.zone.map;
        // tslint:disable-next-line:max-line-length
        this.warehouse = this.route.snapshot.data.zone.warehouse;
        this.zoneAlerts = _.cloneDeep(this.route.snapshot.data.zone.alerts);
        this.equipmentTypes = ['All', ...(this.route.snapshot.data.zone.equipmentTypes || []).sort()];
        this.line = this.route.snapshot.paramMap.get('line');

        if (this.map === undefined || this.map === null) {
            this.map = { id: 0, lines: [] };
            this.router.navigate(['warehouse', this.warehouse.warehouse]);
        }

        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
            if (params.floorId && params.areaId && params.zoneId) {
                this.floorId = params.floorId;
                this.areaId = params.areaId;
                this.zoneId = params.zoneId;
                this.groupName = `${this.warehouse.warehouse}-${this.floorId}-${this.areaId}-${this.zoneId}`;
            }
            //check if the Floor, Area and Zone are part of the map Object
            if (!this.map.id || this.map.id === 0) {
                this.router.navigate(['warehouse', this.warehouse.warehouse]);
            } else {
                if (this.map.areas?.filter((area) => area.id === this.areaId).length === 0) {
                    this.router.navigate(['warehouse', this.warehouse.warehouse]);
                } else {
                    if (
                        this.map.areas
                            ?.filter((area) => area.id === this.areaId)
                            .filter((zone) => zone.zones.filter((zoneOfZones) => zoneOfZones.id === this.zoneId).length).length === 0
                    ) {
                        this.router.navigate(['warehouse', this.warehouse.warehouse]);
                    } else {
                        this.mapRender = true;
                    }
                }
            }
        });

        if (this.configuration.stateFilter) {
            this.configurationFilterValues = JSON.parse(this.configuration.stateFilter);
            const valueInZone = this.configurationFilterValues.filter((item) => item.id === 'zone')[0]?.states;
            this.availableStates = valueInZone ?? initialStates;
        } else {
            this.availableStates = initialStates;
        }
        this.changeVisibleStates(this.availableStates);

        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
    }

    async ngAfterViewInit(): Promise<void> {
        this.stateService.isFullscreen.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isFullscreen) => {
            this.isFullscreen = isFullscreen;
        });
        this.stateService.showFilters.pipe(takeUntil(this.ngUnsubscribe)).subscribe((showFilters) => {
            this.showFilters = showFilters;
        });
        // TODO: This was commented to avoid causing performance issues in Redis and will be restored when Redis will be stable
        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     this.tabState = state;
        //     if (
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.warehouseStatusService.isConnected()
        //     ) {
        //         this.onReconnect();
        //     }
        // });
        await this.subscribeToRealTimeEvents();
    }

    receiveRealTimeAlert(item: EquipmentAlarmNotification) {
        const { floorId, areaId, zoneId } = item;
        const contextId = `${this.floorId}-${this.areaId}-${this.zoneId}`;
        if (contextId === `${floorId}-${areaId}-${zoneId}`) {
            if (
                (!this.visibleStates.some((element) => element === 'alert_low') && !this.visibleStates.some((element) => element === 'alert')) ||
                (!this.visibleStates.some((element) => element === 'alert_low') && item.priority.toString() === '3') ||
                (!this.visibleStates.some((element) => element === 'alert') && item.priority.toString() !== '3')
            ) {
                return;
            } else {
                const alert: Alert = {
                    alarmSourceTimeStamp: new Date(),
                    closedTimeStamp: new Date(),
                    equipmentId: item.equipmentId,
                    equipmentType: item.equipmentType,
                    description: item.variableName,
                    priority: item.priority,
                    floorId: floorId,
                    areaId: areaId,
                    zoneId: zoneId,
                    lineId: item.lineId,
                    state: item.state,
                    alarmServerTimeStamp: item.serverts,
                    sourceTimeStamp: item.sourceTimeStamp
                };

                if (
                    //NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, ALERT_STATE_CHANGED, alert.sourceTimeStamp, alert)
                    this.warehouseStatusService.isNewer(this.groupName, ALERT_STATE_CHANGED, alert, alert.sourceTimeStamp)
                ) {
                    this.alertsTableSmComponent.processRealTimeAlert(alert);
                }
            }
        } else if (localStorage.getItem('showWrongNotifications') === 'true') {
            console.warn(
                'Notification received for wrong fqn',
                {
                    contextFloor: this.floorId,
                    contextArea: this.areaId,
                    contextZone: this.zoneId,
                    notificationFloor: floorId,
                    notificationArea: areaId,
                    notificationZone: zoneId,
                },
                item,
            );
        }
    }

    ngOnInit() {
        this.changeView('alerts');
    }

    async ngOnDestroy() {
        super.ngOnDestroy();
        await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
        this.ngUnsubscribe?.next(true);
        this.ngUnsubscribe?.complete();
    }

    navigateToLine(event, item) {
        if (item) {
            // tslint:disable-next-line: max-line-length
            this.router.navigate([`warehouse/${this.warehouse.warehouse}/floor/${item.floorId}/area/${item.areaId}/zone/${item.zoneId}/line/${item.lineId}`]);
        } else {
            const key = $(event.target).hasClass('button-icon-marker')
                ? $(event.target).data('icon-key')
                : $(event.target).data('key')
                    ? $(event.target).data('key')
                    : $(event.target).data('arrow');
            const keys = key.split('-');
            this.router.navigate([`warehouse/${this.warehouse.warehouse}/floor/${keys[0]}/area/${keys[1]}/zone/${keys[2]}/line/${keys[3]}`]);
        }
    }

    setPriorityComboItems() {
        if (this.visibleStates.some((item) => item === 'alert_low') && this.visibleStates.some((item) => item === 'alert')) {
            this.priority$ = ['All', '1', '2', '3'];
        } else if (this.visibleStates.some((item) => item === 'alert')) {
            this.priority$ = ['All', '1', '2'];
        } else if (this.visibleStates.some((item) => item === 'alert_low')) {
            this.priority$ = ['All', '3'];
        } else {
            this.priority$ = [];
        }
    }

    // checkVisibleStates(alerts) {
    //     if (!this.visibleStates.some((item) => item === 'alert_low') && !this.visibleStates.some((item) => item === 'alert')) {
    //         this.zoneAlerts = alerts.filter((item) => item.priority !== '1' && item.priority !== '2' && item.priority !== '3');
    //     } else if (!this.visibleStates.some((item) => item === 'alert_low')) {
    //         this.zoneAlerts = alerts.filter((item) => item.priority !== '3');
    //     } else if (!this.visibleStates.some((item) => item === 'alert')) {
    //         this.zoneAlerts = alerts.filter((item) => item.priority !== '1' && item.priority !== '2');
    //     } else {
    //         this.zoneAlerts = alerts;
    //     }
    // }

    checkVisibleStates(alert) {
        if (!this.visibleStates.some((item) => item === 'alert_low') && !this.visibleStates.some((item) => item === 'alert')) {
            return alert.priority !== '1' && alert.priority !== '2' && alert.priority !== '3';
        } else if (!this.visibleStates.some((item) => item === 'alert_low')) {
            return alert.priority !== '3';
        } else if (!this.visibleStates.some((item) => item === 'alert')) {
            return alert.priority !== '1' && alert.priority !== '2';
        }
        return true;
    }

    changeView(view) {
        // this.alertsView = 'alerts' === view;
        //this.analyticsView = 'analytics' === view;
        // this.variablesView = 'variables' === view;
        // if (this.alertsView) {
        //     this.alertsService
        //         .getAlertsByZone(this.floorId, this.areaId, this.zoneId)
        //         .pipe(
        //             takeUntil(this.ngUnsubscribe),
        //             take(1),
        //             catchError((err) => {
        //                 console.log('Ha ocurrido un problema en el provider al recuperar las alertas de la zona');
        //                 return of(null);
        //             })
        //         )
        //         .subscribe((res) => {
        //             this.checkVisibleStates(res);
        //             this.setPriorityComboItems();
        //         });
        // }
        // if (this.analyticsView) {
        //     this.zonesService
        //         .getZoneKpis(this.floorId, this.areaId, this.zoneId)
        //         .pipe(takeUntil(this.ngUnsubscribe))
        //         .subscribe((res) => {
        //             this.zoneKpis = !Array.isArray(res) ? [] : res;
        //         });
        // }
    }

    handleChangeVisibleStates(states: Array<MapState>) {
        const newZoneConfiguration = {
            id: 'zone',
            states,
        };
        this.configurationFilterValues = [...this.configurationFilterValues, newZoneConfiguration];
        this.configurationFilterValues = _.uniqBy(this.configurationFilterValues, (item) => item.id);
        this.configuration.stateFilter = JSON.stringify(this.configurationFilterValues);
        this.changeVisibleStates(states);
        this.setPriorityComboItems();
        this.saveConfiguration();
    }

    saveConfiguration() {
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
    }

    async onReconnect() {
        // this.alertsService
        //     .getAlertsByZone(this.floorId, this.areaId, this.zoneId)
        //     .pipe(takeUntil(this.ngUnsubscribe))
        //     .subscribe(async (alerts) => {
        //         this.checkVisibleStates(alerts);
        //         this.alertsTableSmComponent.setDatatableItems(this.zoneAlerts);
        // await this.subscribeToRealTimeEvents();
        // });
        await this.subscribeToRealTimeEvents();
    }

    async subscribeToRealTimeEvents() {
        try {
            await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
            await this.warehouseStatusService.startSubscription(this.groupName, this.supportedEvents);
            this.lineChange$ = await this.warehouseStatusService.listenNotifications(this.groupName, LINE_STATE_CHANGED);
            const alertObservable = await this.warehouseStatusService.listenNotifications(this.groupName, ALERT_STATE_CHANGED);
            alertObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.receiveRealTimeAlert.bind(this));

            this.alertsService
                .getAlertsByZone(this.floorId, this.areaId, this.zoneId)
                .pipe(
                    take(1),
                    catchError((err) => {
                        console.log('Ha ocurrido un problema en el provider al recuperar las alertas de la zona (Floor)', err);
                        return of([]);
                    }),
                )
                .subscribe((alerts) => {
                    const alertsToTable: Alert[] = [];
                    alerts.forEach((alert) => {
                        if (
                            this.checkVisibleStates(alert) ||
                            //NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, ALERT_STATE_CHANGED, alert.sourceTimeStamp, alert)
                            this.warehouseStatusService.isNewer(this.groupName, ALERT_STATE_CHANGED, alert, alert.sourceTimeStamp)
                        ) {
                            this.alertsTableSmComponent.processRealTimeAlert(alert);
                            alertsToTable.push(alert);
                        }
                    });
                    this.zoneAlerts = alertsToTable;
                    this.setPriorityComboItems();
                });
            this.warehouseStatusService.onReconnecting?.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.onReconnect());
        } catch (error) {
            console.error(error);
        }
    }

    refreshAlertsTable(linesVisible) {
        this.linesVisible = linesVisible.map((line) => {
            const [floorId, areaId, zoneId, lineId] = line.split('-');
            return {
                floorId,
                areaId,
                zoneId,
                lineId,
            };
        });
        this.linesVisible = [...this.linesVisible];
    }

    newLineSelected($event) {
        this.lineToShow = JSON.stringify($event);
    }
    offcanvasToggle(visible: boolean) {
        this.activateZoom = !visible;
        if (!visible) this.lineToShow = '';
    }
}
