import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthorizedComponent } from './core/components/unauthorized/unauthorized.component';
import { HomeCustomsComponent } from './map/home/components/customs/home-customs.component';
import { CanActivateRoute } from './core/shared/authentication/authentication.guard';
import { HomeLinesComponent } from './map/home/components/lines/home-lines.component';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { CustomsComponent } from '@customs/components/customs.component';
import { FloorsComponent } from '@floors/components/floors.component';
import { CustomsResolver } from '@customs/shared/customs.resolver';
import { AreasComponent } from '@areas/components/areas.component';
import { ZonesComponent } from '@zones/components/zones.component';
import { LinesComponent } from '@lines/components/lines.component';
import { LandingComponent } from './landing/landing.component';
import { SettingsComponent } from './shared/components/settings/settings.component';
import { FloorsResolver } from '@floors/shared/floors.resolver';
import { environment } from '../environments/environment.prod';
import { LinesResolver } from '@lines/shared/lines.resolver';
import { ZonesResolver } from '@zones/shared/zones.resolver';
import { AreasResolver } from '@areas/shared/areas.resolver';
import { CustomComponent } from '@custom/custom.component';
import { HomeResolver } from '@home/shared/home.resolver';
import { CustomResolver } from '@custom/custom.resolver';
import { HomeComponent } from '@home/home.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    {
        path: 'landing',
        component: LandingComponent,
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
    },
    {
        path: 'warehouse/:id',
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
        children: [
            {
                path: '',
                canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                component: HomeComponent,
                resolve: { home: HomeResolver },
                children: [
                    {
                        path: 'lines',
                        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                        component: HomeLinesComponent,
                        title: 'Auwa: Home Lines',
                    },
                    {
                        path: 'customs',
                        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                        component: HomeCustomsComponent,
                        title: 'Auwa: Home Customs',
                    },
                ],
            },
            {
                path: 'customs/:customName',
                component: CustomsComponent,
                resolve: { customs: CustomsResolver },
                canActivate: environment.mode === 'front' ? [] : [CanActivateRoute],
                title: 'Auwa: Custom',
            },
            {
                path: 'dashboard',
                component: CustomComponent,
                resolve: { home: CustomResolver },
                canActivate: environment.mode === 'front' ? [] : [CanActivateRoute],
            },

            {
                path: 'floor/:floorId',
                children: [
                    {
                        path: '',
                        component: FloorsComponent,
                        resolve: { floor: FloorsResolver },
                        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                        title: 'Auwa: Floor',
                    },
                    {
                        path: 'area/:areaId',
                        children: [
                            {
                                path: '',
                                component: AreasComponent,
                                resolve: { area: AreasResolver },
                                canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                                title: 'Auwa: Area',
                            },
                            {
                                path: 'zone/:zoneId',
                                children: [
                                    {
                                        path: '',
                                        component: ZonesComponent,
                                        resolve: { zone: ZonesResolver },
                                        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                                        title: 'Auwa: Zone',
                                    },
                                    {
                                        path: 'line/:lineId',
                                        children: [
                                            {
                                                path: '',
                                                component: LinesComponent,
                                                resolve: { line: LinesResolver },
                                                canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                                                title: 'Auwa: Line',
                                            },
                                        ],
                                        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                                    },
                                ],
                            },
                        ],
                        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                    },
                ],
                canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
            },
        ],
    },
    // lazy loading - goes here to avoid dependency loops
    {
        path: 'warehouse/:id/alerts',
        loadChildren: () => import('./alerts/alerts.module').then((m) => m.AlertsModule),
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
    },
    {
        path: 'warehouse/:id/warnings',
        loadChildren: () => import('./warnings/warnings.module').then((m) => m.WarningsModule),
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
    },
    {
        path: 'warehouse/:id/connections',
        loadChildren: () => import('./connections/connections.module').then((m) => m.ConnectionsModule),
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
    },
    {
        path: 'warehouse/:id/reports',
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
    },
    {
        path: 'warehouse/:id/variables',
        loadChildren: () => import('./variables/variables.module').then((m) => m.VariablesModule),
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
    },
    {
        path: 'warehouse/:id/scanners',
        loadChildren: () => import('./scanners/scanners.module').then((m) => m.ScannersModule),
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
    },
    {
        path: 'warehouse/:id/analytics',
        loadChildren: () => import('./powerbi/powerbi.module').then((m) => m.PowerBiModule),
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
    },
    {
        path: 'warehouse/:id/settings',
        component: SettingsComponent,
        canActivate: environment.mode === 'front' ? [] : [CanActivateRoute],
    },
    {
        path: 'warehouse/:id/userManual',
        loadChildren: () => import('./user-manual/user-manual.module').then((m) => m.UserManualModule),
        canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
    },
    // generic
    { path: '404', component: NotFoundComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', initialNavigation: 'disabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
