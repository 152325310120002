import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { SearchComponent } from '@ays/commons/lib/components/forms/search/search.component';
import { SelectComponent } from '@ays/commons/lib/components/forms/select/select.component';
import { ScannerTrendColors } from '@variables/shared/variable';
import { Subject, takeUntil } from 'rxjs';

const FIRST_SCANNER_DEFAULT_COLOR = '#ffffe5';
const SECOND_SCANNER_DEFAULT_COLOR = '#fff2e5';
const THIRD_SCANNER_DEFAULT_COLOR = '#ffe5e5';

const MIN_DEFAULT_RANGE = 20;
const MAX_DEFAULT_RANGE = 80;

const INITIAL_ENVIROMENT = 'DEFAULT';

@Component({
    selector: 'settings-scanners-trend',
    templateUrl: './settings-scanners-trend.component.html',
    styleUrls: ['./settings-scanners-trend.component.scss'],
})
export class SettingsScannersTrendComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input({ required: true }) scannerTrendColors: ScannerTrendColors[] = [];
    @Input({ required: false }) scannerTrendColorsToDelete: string;
    @Output() newScannerTrend = new EventEmitter();
    @Output() scannerTrendDeleteConfirmation = new EventEmitter();
    @ViewChild('scannersSelector') scannersSelector: SelectComponent;
    @ViewChild('scannerTrendFilter', { static: false }) scannerTrendFilter: SearchComponent;
    $scannersSelector: JQuery;
    selectedScanner: string;
    scanner$: string[];
    scannerTrendFilterValue = '';

    scannerRangeColorsTemporal: {
        firstColorRange: string;
        secondColorRange: string;
        thirdColorRange: string;
        firstRange: number;
        secondRange: number;
    };
    initialEnviroment: string = INITIAL_ENVIROMENT;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private equipmentService: EquipmentsService,) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.scannerTrendColorsToDelete?.currentValue) {
            this.deleteScannerColor(changes.scannerTrendColorsToDelete?.currentValue);
        }
    }

    ngOnInit(): void {
        this.initializeScannerRangeColors()

        this.equipmentService
            .getEquipmentNames('SCANNER')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((scanners) => {
                this.scanner$ = scanners?.sort((a, b) => (a > b ? 1 : -1));
            });
    }

    ngAfterViewInit() {
        this.$scannersSelector = this.scannersSelector.$select;
        this.$scannersSelector.off('change').on('change', () => {
            this.selectedScanner = String(this.$scannersSelector.find('option:selected').val());
        });

        const that = this;
        this.scannerTrendFilter.$input.off('keyup').on('keyup', function (e) {
            that.scannerTrendFilterValue = String($(this).val()).toUpperCase();
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }


    addNewScanner() {
        if (!this.selectedScanner) return;

        const elementAlreadyExists = this.scannerTrendColors.find((element) => element.scanner === this.selectedScanner);
        if (!elementAlreadyExists) {
            const newScanner: ScannerTrendColors = {
                scanner: `${this.selectedScanner}`,
                ...this.scannerRangeColorsTemporal,
            };
            this.scannerTrendColors.push(newScanner);
            this.scannerTrendColors.sort();

            this.updateScannerTrendColors()

            this.$scannersSelector.val('').trigger('change');
        }
    }

    deleteScannerColor(scanner: string) {
        this.scannerTrendColors = this.scannerTrendColors.filter((element) => element.scanner !== scanner);

        this.updateScannerTrendColors()
    }

    initializeScannerRangeColors() {
        this.scannerRangeColorsTemporal = {
            firstColorRange: FIRST_SCANNER_DEFAULT_COLOR,
            secondColorRange: SECOND_SCANNER_DEFAULT_COLOR,
            thirdColorRange: THIRD_SCANNER_DEFAULT_COLOR,
            firstRange: MIN_DEFAULT_RANGE,
            secondRange: MAX_DEFAULT_RANGE,
        };

        if (!this.scannerTrendColors.find((element) => element.scanner === INITIAL_ENVIROMENT)) {
            const defaultScanner: ScannerTrendColors = {
                scanner: INITIAL_ENVIROMENT,
                ...this.scannerRangeColorsTemporal,
            };
            this.scannerTrendColors.push(defaultScanner);

            this.updateScannerTrendColors()
        }
    }

    saveColorPickerScanner(event, scanner: string = '') {
        if (scanner === '') {
            this.scannerRangeColorsTemporal[event[1]] = event[2];
        } else {
            const scannerToUpdate = this.scannerTrendColors.find((element) => element.scanner === scanner);
            scannerToUpdate[event[1]] = event[2];

            this.updateScannerTrendColors()
        }
    }

    calculateNewRangesScanner(event, scanner: string = '') {
        if (scanner === '') {
            this.scannerRangeColorsTemporal['firstRange'] = event[1].minVal || MIN_DEFAULT_RANGE;
            this.scannerRangeColorsTemporal['secondRange'] = event[1].maxVal || MAX_DEFAULT_RANGE;
        } else {
            const scannerToUpdate = this.scannerTrendColors.find((element) => element.scanner === scanner);
            scannerToUpdate['firstRange'] = event[1].minVal || scannerToUpdate['firstRange'];
            scannerToUpdate['secondRange'] = event[1].maxVal || scannerToUpdate['secondRange'];

            this.updateScannerTrendColors()
        }
    }

    updateScannerTrendColors() {
        this.newScannerTrend.emit({
            scannerTrend: [...this.scannerTrendColors]
        });
    }

    deleteConfirmation(messageId: number, id: string, variable: string, enviroment: string) {
        this.scannerTrendDeleteConfirmation.emit({
            messageId, id, variable, enviroment
        });
    }

    trackByScanner(index: number, element: any) {
        return element?.scanner;
    }

}
