<h4>User Manual</h4>
<hr />
<div class="row">
    <div class="col-3 col-md-2 bg-ter">
        <!--Menu
         <ul>
            <li *ngFor="let item of menu">
                <a (click)="markdowmFileShown = item.markdownFile" >{{item.caption}}</a>
              </li>
        </ul> -->
        <!-- <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">
            <li class="dropdown" *ngFor="let item of menu; let i = index">
                <a href="#" class="nav-link dropdown-toggle text-truncate" id="dropdown{{i}}" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fs-5"></i><span class="ms-1 d-none d-sm-inline">{{item.caption}}</span>
                </a>
                <ul class="dropdown-menu text-small shadow" attr.aria-labelledby="dropdown{{i}}">
                    <li *ngFor="let child of item.childs">
                        <a (click)="markdowmFileShown = child.markdownFile" >{{child.caption}}</a>
                    </li>
                </ul>
            </li>
        </ul> -->
        <!-- <ul class="list-unstyled ps-0" id="menu">
            <li class="mb-1" *ngFor="let item of menu; let i = index">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" attr.data-bs-target="#account-collapse{{i}}" aria-expanded="false">
                    {{item.caption}}
                </button>
                <div class="collapse ms-3" id="account-collapse{{i}}">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li *ngFor="let child of item.childs">
                        <a (click)="markdowmFileShown = child.markdownFile" class="link-dark rounded">{{child.caption}}</a>
                    </li>
                  </ul>
                </div>
              </li>
        </ul> -->
        <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item" *ngFor="let item of menu; let i = index">
                <h2 class="accordion-header" id="flush-headingOne{{ i }}">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        attr.data-bs-target="#flush-collapseOne{{ i }}"
                        aria-expanded="false"
                        attr.aria-controls="flush-collapseOne{{ i }}"
                    >
                        {{ item.caption }}
                    </button>
                </h2>
                <div
                    id="flush-collapseOne{{ i }}"
                    class="accordion-collapse collapse"
                    attr.aria-labelledby="flush-headingOne{{ i }}"
                    attr.data-bs-parent="#accordionFlushExample{{ i }}"
                >
                    <div class="accordion-body">
                        <li *ngFor="let child of item.childs">
                            <a (click)="markdowmFileShown = child.markdownFile" class="link-dark rounded">{{ child.caption }}</a>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-9 col-md-10">
        <!-- <div class="row mt-2">
            <div class="col-12">
                <markdown [data]="'# AUWA 2.0 - Web  '"></markdown>
            </div>
        </div>
        <hr> -->
        <!-- <div class="row mt-2">
            <div class="col-12">
                <markdown [src]="'assets/user-manual/demo.md'" (load)="onLoad($event)" (error)="onError($event)"></markdown>
            </div>
        </div> -->
        <markdown [src]="markdowmFileShown" (load)="onLoad($event)" (error)="onError($event)"></markdown>
    </div>
</div>
