import { NgModule } from '@angular/core';
import { PriorityPipe } from '@shared/pipes/priority.pipe';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { TimeAgoPipe } from '@app/shared/pipes/time-ago.pipe';
import { PerformancePipe } from '@app/shared/pipes/performance.pipe';
import { SingleBarPipe } from '@app/shared/pipes/single-bar.pipe';
import { MinutesPipe } from '@app/shared/pipes/minutes.pipe';
import { FqdnNamePipe } from '@app/shared/pipes/fqdn-name.pipe';
import { EquipmentTypePipe } from './equipment-type.pipe';
import { FazlDecoratorPipe } from './fazl-decorator.pipe';
import { FqnPipe } from './fqn.pipe';
import { VariableTypeIconPipe } from './variable-icon.pipe';
import { DownloadResourceIconPipe } from './download-resource-icon.pipe';
import { DisplayFilterPipe } from './display-filter.pipe';
import { VariableValueTypePipe } from './variable-value-type.pipe';
import { ArchiveTypePipe } from './archive-type.pipe';
import { VariableDescriptionPipe } from './variable-description.pipe';
import { TextColorPipe } from './text-color.pipe';
import { ArrowPipe } from './arrows.pipe';

@NgModule({
    declarations: [
        MinutesPipe,
        SingleBarPipe,
        PerformancePipe,
        TimeAgoPipe,
        PriorityPipe,
        FazlDecoratorPipe,
        FqnPipe,
        FqdnNamePipe,
        TruncatePipe,
        EquipmentTypePipe,
        VariableTypeIconPipe,
        DisplayFilterPipe,
        DownloadResourceIconPipe,
        ArchiveTypePipe,
        VariableValueTypePipe,
        VariableDescriptionPipe,
        TextColorPipe,
        ArrowPipe
    ],
    exports: [
        MinutesPipe,
        SingleBarPipe,
        PerformancePipe,
        TimeAgoPipe,
        PriorityPipe,
        FazlDecoratorPipe,
        FqdnNamePipe,
        FqnPipe,
        TruncatePipe,
        EquipmentTypePipe,
        VariableTypeIconPipe,
        DisplayFilterPipe,
        DownloadResourceIconPipe,
        ArchiveTypePipe,
        VariableValueTypePipe,
        VariableDescriptionPipe,
        TextColorPipe,
        ArrowPipe
    ],
})
export class PipesModule { }
