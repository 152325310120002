<div class="collapse" id="filterMenu" [ngClass]="{ show: !isFullscreen || showFilters }">
    <div class="row search-nav mt-3 pb-2">
        <div class="col-12 col-xl-5 col-lg-4">
            <app-breadcrumbs [columns]="9" [breadcrumbs]="[floorId, areaId]"></app-breadcrumbs>
        </div>
        <div class="col-12 col-xl-3 col-lg-2">
            <div class="row justify-content-end">
                <div class="col-12 col-lg-6 col-xl-4">
                    <app-dropdown-checkbox
                        label="States"
                        [icon]="filterIcon"
                        [iconTitle]="filterTitle"
                        columns="12"
                        [availableItems]="availableStates"
                        (changeFilter)="handleChangeVisibleStates($event)"
                    ></app-dropdown-checkbox>
                </div>
            </div>
        </div>
        <div class="d-none col-12 d-lg-block col-lg-6 col-xl-4">
            <div class="btn-group btn-group-auwa float-end" role="group">
                <button type="button" class="btn btn-auwa" [ngClass]="{ active: alertsView }">Alerts</button>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-xl-8 col-lg-6 col-12 svg-wrapper">
        <div class="map-wrapper">
            <app-areas-map
                *ngIf="mapRender"
                [map]="map"
                [visibleStates]="visibleStates"
                [lineChange$]="lineChange$"
                [tabState]="tabState"
                (clickZone)="navigateToZone($event, null)"
                (visibleLines)="refreshAlertsTable($event)"
                [activateZoom]="activateZoom"
                (newLineSelected)="newLineSelected($event)"
            >
            </app-areas-map>
        </div>
    </div>
    <div class="col-12 col-lg-6 col-xl-4 px-lg-0">
        <div class="col-12 d-lg-none mt-2">
            <div class="btn-group btn-group-auwa float-end" role="group">
                <button type="button" class="btn btn-auwa" [ngClass]="{ active: alertsView }">Alerts</button>
            </div>
        </div>
        <div [ngClass]="{ 'hide-me': !alertsView }">
            <shared-alerts-table-sm
                #alertsTable
                [items]="areaAlerts"
                [filterItems]="linesVisible"
                [group]="floorId + '_' + areaId"
                [priority$]="priority$"
                fqdnMode="area"
            ></shared-alerts-table-sm>
        </div>
    </div>
</div>
<app-home-lines-minified-container [lineToShowOff]="lineToShow" [visibleStatesOff]="visibleStates" (offcanvasToggle)="offcanvasToggle($event)">
</app-home-lines-minified-container>
