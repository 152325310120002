import { Directive, ElementRef, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { defaultZoomPercent, svgClickPosition } from '@app/map/shared/svg-functions';
import { isBoolean } from 'lodash-es';
import '@svgdotjs/svg.panzoom.js';
import * as Svg from '@svgdotjs/svg.js';

@Directive({
    selector: '[auwaSvgZoom]',
})
export class SvgZoomDirective {
    @Input() directiveCurrentZoom: number = 0.2;
    @Input() directiveViewBox: any;
    @Input() directiveActivateZoom: boolean | string = true; //initial idea was to use boolean, but this input maybe is string (it depends how u set it in the svg attribute)
    @Input() directiveReduceZoom: number = 0;
    @Output() svgZoomEventEmmiter = new EventEmitter();
    drag: boolean = false;
    zoomed: boolean = false;

    constructor(private elem: ElementRef) { }

    @HostListener('mousedown', ['$event'])
    mouseDown(event: MouseEvent) {
        this.drag = false;
    }

    @HostListener('mousemove', ['$event'])
    mouseMove(event: MouseEvent) {
        this.drag = true;
    }

    @HostListener('zoom', ['$event'])
    zoom() {
        setTimeout(() => {
            this.svgZoomEventEmmiter.emit();
        }, 10);
    }

    @HostListener('click', ['$event'])
    zoomClick(event: MouseEvent) {
        if (isBoolean(this.directiveActivateZoom && this.directiveActivateZoom) || this.directiveActivateZoom.toString() === 'true') {
            const zoomPercent = defaultZoomPercent - +this.directiveReduceZoom;
            let svgNativeAdopt: any = Svg.adopt(this.elem.nativeElement);
            const actualZoom = +this.directiveCurrentZoom;
            if (!this.zoomed) {
                const position = svgClickPosition(event, this.elem.nativeElement);
                const newZoom = actualZoom + actualZoom * zoomPercent;
                if (!this.drag) {
                    svgNativeAdopt.zoom(newZoom, { x: position.x, y: position.y });
                    this.zoomed = true;
                }
            } else {
                if (!this.drag) {
                    if (svgNativeAdopt) {
                        svgNativeAdopt.zoom(this.directiveCurrentZoom);
                        $(this.elem.nativeElement).attr('viewBox', this.directiveViewBox);
                    }
                    this.zoomed = false;
                }
            }
        }
        this.svgZoomEventEmmiter.emit();
    }
}
