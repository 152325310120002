<offcanvas #offcanvas modalId="activeVariablesOffcavas" title="Realtime variables filters" position="start" [width]="getScreenWidth()">
    <div class="col-12">
        <div class="row mt-3">
            <app-select #variablesType key="type-search" label="Variable type" [item$]="type$" columns="6" [selected]="!!typeVal"></app-select>
            <app-input
                #fqnFilter
                key="fqn-search"
                label="Floor-Area-Zone-Line-Equipment"
                placeholder="Use * as wildcard (0*, *AKL*...)"
                columns="6"
                [ngClass]="fqnVal ? 'filter-selected' : ''"
            ></app-input>
            <app-input #variablesName key="name-search" label="Variable" columns="6" [ngClass]="nameVal ? 'filter-selected' : ''"></app-input>
            <app-select
                #valueFixed
                key="value-fixed"
                label="Value"
                [item$]="activeValues$"
                [hidden]="!['Warning', 'Alarm'].includes(typeVal)"
                columns="6"
                [selected]="!!valueFixedVal"
            ></app-select>
            <app-select
                #valueOperators
                key="value-operators"
                label="Value operator"
                [item$]="operators$"
                [hidden]="['Warning', 'Alarm'].includes(typeVal)"
                columns="6"
                [selected]="!!condition"
            ></app-select>
            <app-input
                #valueInput
                key="value-input"
                label="Value"
                [hidden]="['Warning', 'Alarm'].includes(typeVal) || operatorVal === 'Between'"
                columns="6"
                [ngClass]="valueVal ? 'filter-selected' : ''"
            ></app-input>
            <app-input
                #valueMin
                key="value-min-test"
                label="Min"
                [hidden]="operatorVal !== 'Between' || ['Warning', 'Alarm'].includes(typeVal)"
                columns="6"
                [ngClass]="valueMinVal ? 'filter-selected' : ''"
            ></app-input>
            <app-input
                #valueMax
                key="value-max"
                label="Max"
                [hidden]="operatorVal !== 'Between' || ['Warning', 'Alarm'].includes(typeVal)"
                columns="6"
                [ngClass]="valueMaxVal ? 'filter-selected' : ''"
            ></app-input>
            <app-select-multilevel #fazFilter key="faz-search" label="Floor/Area/Zone" columns="6" [selected]="!!floorVal"></app-select-multilevel>
            <app-input #line key="line-search" label="Line" columns="6" [ngClass]="lineVal ? 'filter-selected' : ''"></app-input>
            <app-input
                #equipmentName
                key="equipment-search-variable-active"
                label="Equipment"
                columns="6"
                [ngClass]="eqNameVal ? 'filter-selected' : ''"
            ></app-input>
            <app-select
                #equipmentType
                key="equipment-type-search"
                label="Equipment Type"
                [item$]="equipmentTypes"
                searchable="true"
                columns="6"
                [selected]="!!eqTypeVal && eqTypeVal !== 'All'"
            ></app-select>
        </div>
        <div class="row mt-1">
            <div class="col-12 col-md-6 col-lg-6">
                <button type="button" class="btn w-100 btn-auwa pe-2 ps-2" (click)="addFilterAndApply()"><i class="fa fa-plus"></i>Add Filter</button>
            </div>
        </div>
    </div>
</offcanvas>

<div class="row search-nav mt-3">
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-lg-3 pt-1 mobile-margin-bottom">
                <h2>{{ header }}</h2>
            </div>
            <div class="col-12 offset-lg-6 col-lg-2 mobile-margin-bottom">
                <app-dropdown-checkbox
                    #dropdowncheckboxSelectColumns
                    label="Select columns"
                    columns="7"
                    [availableItems]="availableColumnsActive"
                    (changeFilter)="changeVisibleColumns($event)"
                ></app-dropdown-checkbox>
            </div>
            <div class="col-12 col-lg-1 mobile-margin-bottom ms-auto">
                <app-button columns="1" offcanvas="activeVariablesOffcavas"><i class="icon-filter"></i>Filters </app-button>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-12">
        <div [hidden]="currentFilters.length > 0" class="mt-3">
            <div style="display: table; height: 300px; width: 100%">
                <div style="display: table-cell; vertical-align: middle">
                    <p class="mt-3 text-center font-weight-bold" style="display: block; text-align: center">
                        <span>
                            <i class="fa fa-times-circle"></i>
                            A filter must be set to view data
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12">
            <ul class="list-group w-100">
                <ng-template ngFor let-filter [ngForOf]="currentFilters">
                    <li class="list-group-item" style="font-size: 12px; font-weight: bold; border-radius: 0">
                        <span class="pe-2"> {{ filter | displayFilter }} </span>
                        <i class="fa fa-times float-right mr-1 clickable" title="Remove filter" aria-hidden="true" (click)="removeFilter(filter)"></i>
                    </li>
                </ng-template>
            </ul>
        </div>
        <div style="overflow-x: auto">
            <div style="min-width: 1280px">
                <ngx-datatable
                    #table
                    [hidden]="currentFilters.length < 1"
                    class="bootstrap"
                    [rows]="itemsVariable"
                    [limit]="pageSize"
                    [columnMode]="'flex'"
                    [messages]="messages"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [rowHeight]="'auto'"
                    [sorts]="[{ prop: orderColumn, dir: orderType }]"
                    (sort)="setSort($event)"
                    [rowClass]="checkErrors"
                    [selectionType]="'single'"
                    [selected]="selectedRow"
                >
                    <ngx-datatable-column name="variableSourceTimestamp" [sortable]="true" [flexGrow]="0.8" *ngIf="variableSourceTimestampVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Date</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>{{ value ? (value | date: 'dd MMM - H:mm') : '' }}</ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="floor" [sortable]="true" [flexGrow]="0.6" *ngIf="floorVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Floor</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                class="clickable"
                                [innerHTML]="value | fazlDecorator: [['F', identifiersPreferences.Floor], [], [], []]"
                                (click)="goToFloor(row)"
                            ></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="area" [sortable]="true" [flexGrow]="1" *ngIf="areaVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Area</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                class="clickable"
                                [innerHTML]="value | fazlDecorator: [[], ['A', identifiersPreferences.Area], [], []]"
                                (click)="goToArea(row)"
                            ></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="zone" [sortable]="true" [flexGrow]="1.5" *ngIf="zoneVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Zone</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                class="clickable"
                                [innerHTML]="value | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []]"
                                (click)="goToZone(row)"
                            ></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="line" [sortable]="true" [flexGrow]="1.5" *ngIf="lineVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Line</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                class="clickable"
                                [innerHTML]="value | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]]"
                                (click)="goToLine(row)"
                            ></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="equipmentId" [sortable]="true" [flexGrow]="2" *ngIf="equipmentIdVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Equipment</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span [innerHTML]="row.equipmentType | equipmentType: true" style="font-size: 16px" class="mt-2 me-2"></span>
                            <span [innerHTML]="value" class="ms-1"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="variableName" [sortable]="true" [flexGrow]="2" *ngIf="variableNameVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Variable Name</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span class="force-one-line" [innerHTML]="value"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="variableType" [sortable]="true" [flexGrow]="0.5" *ngIf="variableTypeVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Type</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span
                                class="iconType mt-2"
                                [innerHTML]="row | variableTypeIcon"
                                [title]="row.variableType.charAt(0).toUpperCase() + row.variableType.slice(1).toLowerCase()"
                            >
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="opcErrorType" [sortable]="true" [flexGrow]="1" *ngIf="opcErrorTypeVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">OPC Error</span>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <span class="force-one-line" [innerHTML]="value"></span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="variableValue" [sortable]="true" [flexGrow]="2" [cellClass]="isEditableRow" *ngIf="variableValueVisible">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">Value</span>
                        </ng-template>
                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                            <span *ngIf="!row.isOpcError || (row.isOpcError && row.opcErrorType !== 'Missing')">
                                <shared-variable-input
                                    [fqn]="row.fqn"
                                    [variable]="row"
                                    [variableState]="getState(row.fqn)"
                                    [refreshable]="true"
                                    (editValue)="editValue($event)"
                                    (updateValue)="updateValue($event)"
                                    (undoEditValue)="undoEditValue($event)"
                                    (saveInput)="saveValue($event)"
                                    (focusLost)="focusLost($event)"
                                    (refreshValue)="refreshValue($event)"
                                ></shared-variable-input>
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-footer>
                        <ng-template
                            ngx-datatable-footer-template
                            let-rowCount="rowCount"
                            let-pageSize="pageSize"
                            let-selectedCount="selectedCount"
                            let-curPage="curPage"
                            let-offset="offset"
                        >
                            <div class="col-4">
                                <p style="font-size: 0.8rem; margin-top: 16px">
                                    Seeing
                                    <select class="table-length-selector" style="margin: 0 5px" (change)="changePageSize($event)" [value]="pageSize">
                                        <option [value]="size" *ngFor="let size of pageSizes">{{ size }}</option>
                                    </select>
                                    {{ pageSize }} of {{ rowCount | number }} entries
                                    {{ rowCount === 500 ? '(Showing up to 500 items, refine the filter)' : '' }}
                                </p>
                            </div>
                            <div class="col-5">
                                <datatable-pager
                                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'"
                                    [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="curPage"
                                    [size]="pageSize"
                                    [count]="rowCount"
                                    [hidden]="!(rowCount / pageSize > 1)"
                                    (change)="table.onFooterPage($event)"
                                >
                                </datatable-pager>
                            </div>
                            <div class="col-3 ms-auto">
                                <button
                                    class="button-excel"
                                    *ngIf="!getRealTimeVariablesLength()"
                                    tabindex="0"
                                    [title]="'Variables'"
                                    (click)="generateLocalCSV()"
                                >
                                    <i class="icon-download ps-3"></i>Download
                                </button>
                                <button
                                    class="button-pause"
                                    [disabled]="realTimeStatus === UPDATE_REALTIME"
                                    tabindex="0"
                                    (click)="pauseRealTimeNotifications()"
                                >
                                    <span>
                                        <i class="fa fa-pause" [hidden]="realTimeStatus !== RUNNING"></i>
                                        <i class="fa fa-play" [hidden]="realTimeStatus !== PAUSED"></i>
                                        <i class="fa fa-sync-alt" [hidden]="realTimeStatus !== UPDATE_REALTIME"></i>
                                        {{ realTimeStatus === RUNNING ? 'Pause notifications' : realTimeStatus === PAUSED ? 'Start notifications' : '' }}
                                    </span>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>
