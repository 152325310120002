<div class="row search-nav mt-3">
    <div class="col-12 col-xl-5 col-lg-4">
        <app-breadcrumbs columns="9" [breadcrumbs]="[]" [customName]="customName"></app-breadcrumbs>
    </div>
    <div class="col-12 col-xl-3 col-lg-2">
        <div class="row justify-content-end">
            <div class="col-12 col-lg-6 col-xl-4">
                <app-dropdown-checkbox
                    label="States"
                    [icon]="filterIcon"
                    [iconTitle]="filterTitle"
                    columns="12"
                    [availableItems]="availableStates"
                    (changeFilter)="handleChangeVisibleStates($event)"
                    extraClasses="ps-lg-0"
                ></app-dropdown-checkbox>
            </div>
        </div>
    </div>
    <div class="d-none col-12 d-lg-block col-lg-6 col-xl-4">
        <div class="btn-group btn-group-auwa float-end" role="group">
            <button type="button" class="btn btn-auwa" [ngClass]="{ active: alertsTab }" (click)="changeTab()">Alerts</button>
            <!-- <button type="button" class="btn btn-auwa" [ngClass]="{ active: !alertsTab }" (click)="changeTab()">Variables</button> -->
        </div>
    </div>
</div>

<div class="row mt-2">
    <!-- <div [ngClass]="liteView ? 'col-xl-8 col-lg-6 col-12' : 'col-xl-8 col-lg-6 col-12 svg-wrapper'"> -->
    <div [ngClass]="'col-xl-8 col-lg-6 col-12 svg-wrapper'">
        <!-- <div [ngClass]="{ accordion: liteView }" *ngIf="custom"> -->
        <div *ngIf="custom">
            <app-custom-map
                [warehouse]="warehouse.warehouse"
                [structure]="structure"
                [activateZoom]="activateZoom"
                [custom]="custom"
                [visibleStates]="visibleStates"
                [lineChange$]="lineChange$"
                [tabState]="tabState"
                [mode]="mode"
                (visibleLines)="refreshAlertsTable($event)"
                (newLineSelected)="newLineSelected($event)"
            >
            </app-custom-map>
        </div>
    </div>

    <div class="col-12 col-lg-6 col-xl-4 px-lg-0">
        <div class="col-12 d-lg-none mt-2">
            <div class="btn-group btn-group-auwa float-end" role="group">
                <button type="button" class="btn btn-auwa" [ngClass]="{ active: alertsTab }" (click)="changeTab()">Alerts</button>
            </div>
        </div>
        <div [ngClass]="{ 'hide-me': !alertsTab }">
            <shared-alerts-table-sm #alertsTable [items]="customsAlerts" [filterItems]="linesVisible" fqdnMode="line"></shared-alerts-table-sm>
        </div>
    </div>
</div>
<app-home-lines-minified-container [lineToShowOff]="lineToShow" [visibleStatesOff]="visibleStates" (offcanvasToggle)="offcanvasToggle($event)">
</app-home-lines-minified-container>
