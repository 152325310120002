import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AppLoadService } from '@app/app-load.service';
import { Structure } from '@app/map/home/shared/structure';
import { HomeService } from '@app/map/home/shared/home.service';
import { LinesService } from '@app/map/lines/shared/lines.service';

@Injectable()
export class CustomResolver {
    constructor(
        private homeService: HomeService,
        private linesService: LinesService,
        private appLoadService: AppLoadService,
    ) {}

    getStructure(): Observable<Structure> {
        return this.homeService.getStructure().pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema al recuperar la estructura');
                return of(null);
            }),
        );
    }

    getIcons(): Observable<any> {
        return this.linesService.getIcons().pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema en el provider al recuperar los iconos -> ' + err);
                return of(null);
            }),
        );
    }

    resolve(): Observable<any> {
        return forkJoin([
            this.getStructure(),
            this.getIcons(),
            this.appLoadService.getCurrentWarehouse.pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar el almacen actual', err);
                    return of(null);
                }),
            ),
        ]).pipe(
            map((x) => {
                return {
                    structure: x[0],
                    icons: x[1],
                    warehouse: x[2],
                };
            }),
        );
    }
}
