/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, EventEmitter } from '@angular/core';
import { ActiveAlertsClient } from '../clients/active-alerts.client';
import { Subscription } from 'rxjs';
import { NotificationsMap } from '../notifications-map/notifications-map';

export interface AlertsCountStatus {
    newCount: number;
    sourcets: Date;
    serverts: Date;
    timestamp: string;
    warehouseId: string;
}

const ALERTS_COUNT = 'AlertsCount';

@Injectable()
export class AlertsCountStatusService {
    onAlertsCount: EventEmitter<AlertsCountStatus> = new EventEmitter<AlertsCountStatus>();
    alertsCountSubscription: Subscription;
    alertsCountSubscriptionId: string;
    endPoint: string;
    warehouse: string;
    onReconnectAlertCount: () => Promise<void>;
    handleReconnectionAlertCountReference: () => Promise<void>;

    private readonly alertsCountCallback = (alertsCountStatus: AlertsCountStatus) => {
        if (!this.onAlertsCount.isStopped) {
            this.onAlertsCount.emit(alertsCountStatus);
        }
    };

    constructor(
        private activeAlertsClient: ActiveAlertsClient,
    ) { }

    public async subscribeToAlertCount(
        warehouse: string,
        context: any,
        delegateFunc: (context: any, alertsCount: number) => void,
        onReconnect: () => Promise<void>,
    ) {
        const eventName = ActiveAlertsClient.SupportedEvents.ACTIVE_ALERTS_COUNT_CHANGED;
        // tslint:disable-next-line:max-line-length
        this.alertsCountSubscription = this.onAlertsCount.subscribe({
            next: (event: AlertsCountStatus) => {
                const notificationTS = event.timestamp;
                // tslint:disable-next-line: max-line-length
                if (NotificationsMap.isNewerNotification(this.constructor.name, ALERTS_COUNT, eventName, notificationTS, event)) {
                    delegateFunc(context, event.newCount);
                }
            },
        });
        NotificationsMap.clearNotificationState(this.constructor.name, ALERTS_COUNT, eventName);
        this.onReconnectAlertCount = onReconnect;
        this.handleReconnectionAlertCountReference = this.handleReconnectionAlertCount.bind(this);
        await this.activeAlertsClient.subscribe(eventName, warehouse, this.alertsCountCallback, this.handleReconnectionAlertCountReference);
    }

    async handleReconnectionAlertCount() {
        const eventName = ActiveAlertsClient.SupportedEvents.ACTIVE_ALERTS_COUNT_CHANGED;
        NotificationsMap.clearNotificationState(this.constructor.name, ALERTS_COUNT, eventName);
        await this.onReconnectAlertCount?.();
    }

    public async unsubscribeFromAlertCount(warehouse: string) {
        this.alertsCountSubscription?.unsubscribe();
        const eventName = ActiveAlertsClient.SupportedEvents.ACTIVE_ALERTS_COUNT_CHANGED;
        await this.activeAlertsClient.unsubscribe(eventName, warehouse);
    }

    isConnected() {
        return this.activeAlertsClient.connection?.hubConnection.state === 'Connected';
    }
}
