import alertsActiveStatusMapper from './alertsActive-status.mapper';
import equipmentStatusMapper from './equipment-status.mapper';
import lineStatusMapper from './line-status.mapper';
import variablesStatusMapper from './variable-status.mapper';
import { WarehouseStatusSupportedEvents } from '../events/warehouse-status';
import { WarehouseVariablesStatusSupportedEvents } from '../events/variable-status';

const { ALERT_STATE_CHANGED, EQUIPMENT_STATE_CHANGED, LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;

export const mapNotification = (eventName: string, event: any) => {
    /* eslint-disable prettier/prettier */
    return eventName === ALERT_STATE_CHANGED
        ? alertsActiveStatusMapper(event)
        : eventName === LINE_STATE_CHANGED
          ? lineStatusMapper(event)
          : eventName === EQUIPMENT_STATE_CHANGED
            ? equipmentStatusMapper(event)
            : eventName === WarehouseVariablesStatusSupportedEvents.WAREHOUSE_STATUS_VARIABLE_CHANGED
              ? variablesStatusMapper(event)
              : event;
    /* eslint-enable prettier/prettier */
};

export const getEventTS = (eventName: string, event: any) => {
    /* eslint-disable prettier/prettier */
    return eventName === ALERT_STATE_CHANGED
        ? event.serverts
        : eventName === EQUIPMENT_STATE_CHANGED
          ? event.serverts
          : eventName === LINE_STATE_CHANGED
            ? event.serverts
            : event.serverts;
    /* eslint-enable prettier/prettier */
};
