/* eslint-disable @typescript-eslint/naming-convention */
import { Component, AfterViewInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Location } from '@angular/common';
import { VariableHistory, VariableType, isActiveVariable, VariablesViewState, VARIABLES_MODE } from '@variables/shared/variable';
import { VariablesHistory } from '@variables/shared/variables';
import { InputComponent } from '@ays/lib/components/forms/input/input.component';
import { SelectComponent } from '@ays/lib/components/forms/select/select.component';
import { SelectMultilevelComponent } from '@app/shared/components/forms/select-multilevel/select-multilevel.component';
import { VariablesService } from '@variables/shared/variables.service';
import { NavigationService } from '@app/core/shared/navigation/navigation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableComponentDirective } from '@ays';
import { Warehouse } from '@app/core/shared/warehouse';
import { UserConfiguration } from '@app/shared/models/configurations';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { AppLoadService } from '@app/app-load.service';
import { VariableFilter } from '@app/notifications/shared/events/variable-status';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { ArchivesService } from '@app/core/shared/archives/archives.service';
import { calendar15DaysRange } from '@app/shared/models/calendar-ranges';
import { DatepickerComponent } from '@ays/commons/lib/components/forms/datepicker/datepicker.component';
import { IdentifiersPreferences } from '@app/map/home/shared/structure';
import * as _ from 'lodash-es';
import * as dayjs from 'dayjs';

const ALL = 'All';
const { ALARM, COMMAND, STATUS, WARNING } = VariableType;
const EQUALS = 'Equals';
const NOT_EQUALS = 'NotEquals';
const GREATER = 'Greater';
const GREATER_OR_EQUALS = 'GreaterOrEquals';
const LESSER = 'Lesser';
const LESSER_OR_EQUALS = 'LesserOrEquals';
const BETWEEN = 'Between';
const ACTIVE = 'Active';
const INACTIVE = 'Inactive';

const HISTORY_VARIABLES = 'History Variable Status';

@Component({
    selector: 'app-variables-history',
    templateUrl: './variables-history.component.html',
    styleUrls: ['./variables-history.component.scss'],
})
export class VariablesHistoryComponent extends TableComponentDirective<VariableHistory> implements AfterViewInit, OnDestroy {
    @ViewChild('dateFilter') dateFilter: DatepickerComponent;
    @ViewChild('typeFilter') typeFilter: SelectComponent;
    @ViewChild('nameFilter') nameFilter: InputComponent;
    @ViewChild('fqnFilter') fqnFilter: InputComponent;
    @ViewChild('equipmentName') equipmentName: InputComponent;
    @ViewChild('equipmentType') equipmentTypeSelect: SelectComponent;
    @ViewChild('lineFilter') lineFilter: InputComponent;
    @ViewChild('fazFilter') fazFilter: SelectMultilevelComponent;
    @ViewChild('valueOperators') valueOperators: SelectComponent;
    @ViewChild('valueInput') valueInput: InputComponent;
    @ViewChild('valueMax') valueMax: InputComponent;
    @ViewChild('valueMin') valueMin: InputComponent;
    @ViewChild('valueFixed') valueFixed: SelectComponent;

    @Input() warehouse: Warehouse;
    @Input() equipmentTypes: string[];

    $dateFilter: JQuery;
    $typeFilter: JQuery;
    $nameFilter: JQuery;
    $fqnFilter: JQuery;
    $equipmentName: JQuery;
    $equipmentType: JQuery;
    $lineFilter: JQuery;
    $fazFilter: JQuery;
    $valueOperators: JQuery;
    $valueInput: JQuery;
    $valueMax: JQuery;
    $valueMin: JQuery;
    $valueFixed: JQuery;
    identifiersPreferences: IdentifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    checkApplyFilters = _.debounce(this.applyFilters, 300, { leading: false });
    variables: VariablesHistory;
    waitForVariablesResponse = false;
    waitForReportGeneration = false;
    type$: Array<string> = [ALL, ALARM, COMMAND, STATUS, WARNING];
    pageSizes: Array<number> = [10, 25, 50];
    pageSize = 10;
    reportsImmutable: VariableHistory[];
    numberOfItems: number;
    pageNumber: number;
    from: any;
    to: any;
    floorId = '';
    areaId = '';
    zoneId: string;
    lineId: string;
    fqn: string;
    variableName: string;
    equipmentId: string;
    equipmentTypeVal: string;
    faz: string;
    variableType: VariableType = WARNING;
    currentFilters: VariableFilter[] = [];
    isFilterSet = false;
    operators$: Array<string> = [EQUALS, NOT_EQUALS, GREATER, GREATER_OR_EQUALS, LESSER, LESSER_OR_EQUALS, BETWEEN];
    operatorVal = EQUALS;
    activeValues$ = [ALL, INACTIVE, ACTIVE];
    condition: any = undefined;
    valueVal = '';
    valueMinVal = '';
    valueMaxVal = '';
    valueFixedVal = '';
    orderColumn = 'SourceTimeStamp';
    orderType = 'desc';
    userName: string;
    userEmail: string;
    isActiveVariable = isActiveVariable;

    sourceTimeStampVisible = true;
    flooridVisible = true;
    areaidVisible = true;
    zoneidVisible = true;
    lineidVisible = true;
    equipmentIdVisible = true;
    equipmentTypeVisible = true;
    variableNameVisible = true;
    variableTypeVisible = true;
    valueVisible = true;

    queryParams: VariablesViewState;
    calendarParams = {
        start: dayjs().subtract(1, 'days').format('D/M/YY HH:mm'),
        end: dayjs().format('D/M/YY HH:mm'),
    };
    calendarRange = calendar15DaysRange;
    maxRangeDays = 15;
    availableColumnsHistory = [
        { position: 0, id: 'sourceTimeStamp', label: 'Date', visible: true },
        { position: 1, id: 'floorid', label: 'Floor', visible: true },
        { position: 2, id: 'areaid', label: 'Area', visible: true },
        { position: 3, id: 'zoneid', label: 'Zone', visible: true },
        { position: 4, id: 'lineid', label: 'Line', visible: true },
        { position: 5, id: 'equipmentId', label: 'Equipment', visible: true },
        { position: 6, id: 'equipmentType', label: 'Type', visible: true },
        { position: 7, id: 'variableName', label: 'Variable Name', visible: true },
        { position: 8, id: 'variableType', label: 'Type', visible: true },
        { position: 9, id: 'value', label: 'Value', visible: true },
    ];

    header = HISTORY_VARIABLES;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private variablesService: VariablesService,
        private route: ActivatedRoute,
        private navigationService: NavigationService,
        private location: Location,
        private router: Router,
        private configurationsService: ConfigurationsService,
        private authenticationService: AuthenticationService,
        private appLoadService: AppLoadService,
        private archiveService: ArchivesService,
    ) {
        super();

        this.warehouse = this.route.snapshot.data.variables.warehouse;
        this.equipmentTypes = ['All', ...(this.route.snapshot.data.variables.equipmentTypes || []).sort()];

        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };

            this.availableColumnsHistory = this.userConfiguration?.columnsVisible?.variableAvailableColumnsHistory
                ? JSON.parse(this.userConfiguration?.columnsVisible?.variableAvailableColumnsHistory)
                : this.availableColumnsHistory;

            this.visibleColumns();
        });
    }

    getScreenWidth(): string {
        if (screen.availWidth > 768) {
            return '35%';
        } else if (screen.availWidth < 576) {
            return '85%';
        }
    }

    handleUrlParams(params: Params): void {
        //if (params.mode === VARIABLES_MODE.HISTORY) {
        // get filters from query params, configuration or empty in that order
        const filters: VariableFilter[] = params.f
            ? JSON.parse(params.f)
            : this.userConfiguration?.historyVariablesFilter
                ? JSON.parse(this.userConfiguration.historyVariablesFilter)
                : [];
        const from = params.from ? params.from : this.userConfiguration?.historyVariablesFrom ? this.userConfiguration.historyVariablesFrom : null;
        const to = params.to ? params.to : this.userConfiguration?.historyVariablesFrom ? this.userConfiguration.historyVariablesTo : null;
        // set filter in the url if not present
        if (!params.f) {
            this.queryParams = {
                mode: VARIABLES_MODE.HISTORY,
                f: JSON.stringify(filters.map(this.removeEmpty)),
                from,
                to,
            };
            const url = this.router
                .createUrlTree([], {
                    relativeTo: this.route,
                    queryParams: { ..._.omitBy(this.queryParams, (v) => !v || v === '') },
                })
                .toString();
            this.location.go(url);
        }
        const currentFilters: VariableFilter[] = filters.map(this.removeEmpty);
        const currentFrom = from ? dayjs(from, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss') : null;
        const currentTo = to ? dayjs(to, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD HH:mm:ss') : null;
        if (JSON.stringify(currentFilters) !== JSON.stringify(this.currentFilters) || currentFrom !== this.from || currentTo !== this.to) {
            if (!from) {
                this.from = dayjs().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
                this.to = dayjs().format('YYYY-MM-DD HH:mm:ss');
            } else {
                this.from = currentFrom;
                this.to = currentTo;
            }
            this.currentFilters = [...currentFilters];

            this.saveFilterConfiguration();

            const filter = this.currentFilters.length > 0 ? this.currentFilters[0] : ({} as VariableFilter);

            this.fqn = !!filter.fqn?.match(/^(\d+)\*/) ? `F${filter.fqn}` : filter.fqn;
            this.lineId = filter.lineId?.trim();
            this.equipmentId = filter.equipmentId?.trim();
            this.equipmentTypeVal = filter.equipmentType === ALL ? null : filter.equipmentType;
            this.variableName = filter.variableName;
            this.variableType = filter.variableType === ALL ? null : (filter.variableType as VariableType);

            const [floorId, areaId, zoneId] = filter.faz?.split('-') || [];
            this.floorId = floorId;
            this.areaId = areaId;
            this.zoneId = zoneId;

            this.setInputValue(filter.fqn, this.$fqnFilter);
            this.setInputValue(filter.lineId, this.$lineFilter);
            this.setInputValue(filter.equipmentId, this.$equipmentName);
            this.setInputValue(filter.variableName, this.$nameFilter);

            const operator = Object.keys(filter.condition || { EQUALS })[0];
            this.condition = filter.condition;
            const { Value, ValueMin, ValueMax } = this.condition?.[operator] || {};
            this.valueFixedVal = null;
            this.valueMinVal = null;
            this.valueMaxVal = null;
            this.valueVal = null;
            this.operatorVal = operator;
            if ([ALARM, WARNING].includes(filter.variableType as VariableType)) {
                const fixedValue = Value?.toString() === 'true' ? ACTIVE : Value?.toString() === 'false' ? INACTIVE : null;
                this.$valueFixed.val(fixedValue || ALL).trigger('change');
            } else if (operator === BETWEEN) {
                this.setInputValue(ValueMin, this.$valueMin);
                this.valueMinVal = ValueMin;
                this.setInputValue(ValueMax, this.$valueMax);
                this.valueMaxVal = ValueMax;
            } else if (![ALARM, WARNING].includes(filter.variableType as VariableType) && Value) {
                this.setInputValue(Value, this.$valueInput);
                this.valueVal = Value;
            }
            // const variableType = String(filter.variableType) as VariableType;
            // const fixedValueCondition = { [EQUALS]: { Value: this.valueFixedVal } };
            // const betweenCondition    = { [ BETWEEN ]: { MinValue: this.valueMinVal, MaxValue: this.valueMaxVal } };
            // const otherCondition      = { [this.operatorVal]: { Value: this.valueVal } };
            // this.condition = [ ALARM, WARNING ].includes(variableType) && this.valueFixedVal !== '' ? fixedValueCondition :
            //                  this.operatorVal === BETWEEN                                           ? betweenCondition :
            //                  ![ ALARM, WARNING ].includes(variableType) && this.valueVal !== ''     ? otherCondition
            //                                                                                         : undefined;
            this.$valueOperators.val(operator).trigger('change');
            this.$typeFilter.val(filter.variableType || ALL).trigger('change');
            setTimeout(() => {
                this.fazFilter.val(filter.faz, true);
            }, 500);
            this.$equipmentType.val(filter.equipmentType || ALL).trigger('change');

            this.isFilterSet = !!(this.fqn || this.lineId || this.equipmentId || this.equipmentTypeVal || this.variableName || this.variableType);
            if (this.isFilterSet) {
                this.doRequest(this.pageNumber, this.pageSize);
            } else {
                this.setDatatableItems([]);
            }
        } /*else {
            this.doRequest(this.pageNumber, this.pageSize);
        }*/

        this.dateFilter.calendar.setDate(
            [dayjs(currentFrom, 'YYYY/MM/DD HH:mm:ss').toISOString(), dayjs(currentTo, 'YYYY/MM/DD HH:mm:ss').toISOString()],
            true,
        );
        //}
    }

    setInputValue(value: string, input: JQuery): void {
        if (value) {
            input.val(value || '').trigger('blur');
        }
    }

    visibleColumns() {
        if (this.availableColumnsHistory) {
            this.sourceTimeStampVisible = this.availableColumnsHistory.find((x) => x.id === 'sourceTimeStamp').visible;
            this.flooridVisible = this.availableColumnsHistory.find((x) => x.id === 'floorid').visible;
            this.areaidVisible = this.availableColumnsHistory.find((x) => x.id === 'areaid').visible;
            this.zoneidVisible = this.availableColumnsHistory.find((x) => x.id === 'zoneid').visible;
            this.lineidVisible = this.availableColumnsHistory.find((x) => x.id === 'lineid').visible;
            this.equipmentIdVisible = this.availableColumnsHistory.find((x) => x.id === 'equipmentId').visible;
            this.equipmentTypeVisible = this.availableColumnsHistory.find((x) => x.id === 'equipmentType').visible;
            this.variableNameVisible = this.availableColumnsHistory.find((x) => x.id === 'variableName').visible;
            this.variableTypeVisible = this.availableColumnsHistory.find((x) => x.id === 'variableType').visible;
            this.valueVisible = this.availableColumnsHistory.find((x) => x.id === 'value').visible;
        }
    }

    ngAfterViewInit(): void {
        this.$fqnFilter = this.fqnFilter.$input;
        this.$fqnFilter.attr('maxlength', 25);
        this.$fazFilter = this.fazFilter.$selectMultilevel;
        this.$typeFilter = this.typeFilter.$select;
        this.$nameFilter = this.nameFilter.$input;
        this.$nameFilter.attr('maxlength', 40);
        this.$lineFilter = this.lineFilter.$input;
        this.$lineFilter.attr('maxlength', 40);
        this.$equipmentName = this.equipmentName.$input;
        this.$equipmentName.attr('maxlength', 40);
        this.$equipmentType = this.equipmentTypeSelect.$select;
        this.$valueOperators = this.valueOperators.$select;
        this.$valueInput = this.valueInput.$input;
        this.$valueInput.attr('maxlength', 40);
        this.$valueMin = this.valueMin.$input;
        this.$valueMin.attr('maxlength', 25);
        this.$valueMax = this.valueMax.$input;
        this.$valueMax.attr('maxlength', 25);
        this.$valueFixed = this.valueFixed.$select;

        this.dateFilter.calendar.setDate([dayjs().subtract(1, 'days').format('D/M/YY HH:mm'), dayjs().format('D/M/YY HH:mm')], true);
        this.dateFilter.calendar.config.onClose.push((selectedDates, dateStr, instance) => {
            setTimeout(() => {
                this.dateFilter.calendar.setDate([this.calendarParams.start, this.calendarParams.end], true);
            });
        });
        const minDate = dayjs().subtract(this.maxRangeDays, 'days').format('D/M/YY HH:mm');
        this.dateFilter.calendar.config.minDate = minDate;
        let mode;
        $(this.dateFilter.calendar.calendarContainer)
            .find('.flatpickr-confirm')
            .on('click', (e) => {
                const selectedDates = this.dateFilter.calendar.selectedDates;
                if (selectedDates.length > 1 && selectedDates[0].toISOString() !== selectedDates[1].toISOString()) {
                    this.calendarParams.start = dayjs(selectedDates[0]).format('DD/MM/YY H:mm');
                    this.calendarParams.end = dayjs(selectedDates[1]).format('DD/MM/YY H:mm');
                    const from = dayjs(selectedDates[0]).format('YYYY-MM-DD HH:mm:ss');
                    const to = dayjs(selectedDates[1]).format('YYYY-MM-DD HH:mm:ss');
                    mode = VARIABLES_MODE.HISTORY;

                    this.dateFilter.calendar.setDate([dayjs(from, 'YYYY/MM/DD HH:mm:ss').toISOString(), dayjs(to, 'YYYY/MM/DD HH:mm:ss').toISOString()], true);
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: {
                            ..._.omitBy(
                                {
                                    ...this.queryParams,
                                    mode,
                                    from,
                                    to,
                                },
                                (v) => !v || v === '',
                            ),
                        },
                    });
                }
            });

        this.enableFilterTriggers();
        setTimeout(() => {
            // take url params
            this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
                this.handleUrlParams(params);
            });
        });
    }

    ngOnDestroy() {
        this.disableFiltersTrigger();
        this.$fazFilter?.off();
        this.$fqnFilter?.off();
        this.dateFilter.$datepicker.off();
        this.$lineFilter?.off();
        this.$equipmentName?.off();
        this.$equipmentType?.off();
        this.$nameFilter?.off();
        this.$typeFilter?.off();
        this.$valueOperators?.off();
        this.$valueInput?.off();
        this.$valueMin?.off();
        this.$valueMax?.off();
        this.$valueFixed?.off();
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    saveFilterConfiguration() {
        this.userConfiguration.historyVariablesFilter = JSON.stringify(this.currentFilters.map(this.removeEmpty));
        this.userConfiguration.historyVariablesFrom = this.from;
        this.userConfiguration.historyVariablesTo = this.to;
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
    }

    disableFiltersTrigger(): void {
        this.$equipmentType.off('change');
        this.$lineFilter.off('keyup');
        this.$fqnFilter.off('keyup');
        this.$nameFilter.off('keyup');
        this.$equipmentName.off('keyup');
        this.$lineFilter.off('keyup');
        this.$typeFilter.off('change');
        this.$fazFilter.off('change');
    }

    enableFilterTriggers(): void {
        const doRequestOnPressEnter = async (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                await this.checkApplyFilters();
            }
        };

        this.$equipmentType.val(ALL).trigger('change');
        this.$equipmentType.on('change', () => {
            const equipmentType = String(this.$equipmentType.val() || '').trim();
            this.equipmentTypeVal = equipmentType === ALL ? '' : equipmentType;
            this.checkApplyFilters();
        });
        this.$lineFilter.off('keyup').on('keyup', doRequestOnPressEnter);
        this.$fqnFilter.off('keyup').on('keyup', doRequestOnPressEnter);
        this.$nameFilter.off('keyup').on('keyup', doRequestOnPressEnter);
        this.$equipmentName.off('keyup').on('keyup', doRequestOnPressEnter);
        this.$lineFilter.off('keyup').on('keyup', doRequestOnPressEnter);
        this.$valueOperators.val(EQUALS).trigger('change');
        this.$valueOperators.on('change', (e) => {
            this.operatorVal = String(this.$valueOperators.val());
        });
        this.$valueInput.off('keyup').on('keyup', doRequestOnPressEnter);
        this.$valueMin.off('keyup').on('keyup', doRequestOnPressEnter);
        this.$valueMax.off('keyup').on('keyup', doRequestOnPressEnter);
        this.$valueFixed.val(ACTIVE).trigger('change');
        this.$valueFixed.on('change', () => {
            const valueFixed = String(this.$valueFixed.val());
            this.valueFixedVal = valueFixed === ALL ? null : valueFixed;
            this.checkApplyFilters();
        });
        this.$valueFixed.on('change', doRequestOnPressEnter);
        this.$typeFilter.val(WARNING).trigger('change');
        this.$typeFilter.on('change', () => {
            const variableType = String(this.$typeFilter.val());
            this.variableType = variableType === ALL ? null : (variableType as VariableType);
            this.checkApplyFilters();
        });
        this.$fazFilter.on('change', () => {
            const [floorId, areaId, zoneId] = this.$fazFilter.val()?.toString().split('-') || [];
            this.floorId = floorId || '';
            this.areaId = areaId || '';
            this.zoneId = zoneId || '';
            this.checkApplyFilters();
        });
    }

    applyFilters() {
        const fqnVal = String(this.$fqnFilter.val()).trim();
        const fqn = fqnVal && !!fqnVal.match(/^(\d+)\*/) ? `F${fqnVal}` : fqnVal;
        const faz = this.zoneId
            ? `${this.floorId}-${this.areaId}-${this.zoneId}`
            : this.areaId
                ? `${this.floorId}-${this.areaId}`
                : this.floorId
                    ? `${this.floorId}`
                    : null;
        const lineId = String(this.$lineFilter.val()).trim();
        const equipmentId = String(this.$equipmentName.val()).trim();
        const equipmentTypeVal = String(this.equipmentTypeVal).trim();
        const equipmentType = equipmentTypeVal === ALL ? null : equipmentTypeVal;
        const variableName = String(this.$nameFilter.val()).trim();
        const typeVal = String(this.variableType || '').trim();
        const variableType = typeVal === ALL ? null : (typeVal as VariableType);
        const fixedValue = String(this.$valueFixed.val());
        const valueFixed = fixedValue === ALL ? '' : fixedValue === ACTIVE ? 'true' : 'false';
        const value = String(this.$valueInput.val()).trim();
        const valueMin = String(this.$valueMin.val()).trim();
        const valueMax = String(this.$valueMax.val()).trim();
        let operator = String(this.$valueOperators.val() || '');
        if (value && !operator) {
            operator = EQUALS;
            this.$valueOperators.val(operator).trigger('change');
        }
        const fixedValueCondition = { [EQUALS]: { Value: valueFixed } };
        const betweenCondition = valueMin && valueMax ? { [BETWEEN]: { MinValue: valueMin, MaxValue: valueMax } } : null;
        const otherCondition = { [this.operatorVal]: { Value: value } };
        const condition =
            [ALARM, WARNING].includes(variableType) && valueFixed !== ''
                ? fixedValueCondition
                : operator === BETWEEN
                    ? betweenCondition
                    : ![ALARM, WARNING].includes(variableType) && value !== ''
                        ? otherCondition
                        : undefined;

        this.queryParams = {
            ...this.queryParams,
            f: JSON.stringify([
                this.removeEmpty({
                    fqn,
                    faz,
                    lineId,
                    variableName,
                    variableType,
                    equipmentId,
                    equipmentType,
                    condition,
                }),
            ]),
        };

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                ..._.omitBy(
                    {
                        mode: VARIABLES_MODE.HISTORY,
                        from: this.from,
                        to: this.to,
                        f: JSON.stringify([
                            this.removeEmpty({
                                fqn,
                                faz,
                                lineId,
                                variableName,
                                variableType,
                                equipmentId,
                                equipmentType,
                                condition,
                            }),
                        ]),
                    },
                    (v) => !v || v === '',
                ),
            },
        });
    }

    doRequest(pageNumber = 0, pageSize = 10) {
        this.enableLoader();
        this.disableComponents();
        return new Promise((res) => {
            this.variablesService
                .getVariablesHistory({
                    from: this.from,
                    to: this.to,
                    page: String(pageNumber) || null,
                    pageSize: String(pageSize) || null,
                    fqnPattern: this.fqn || null,
                    floorid: this.floorId || null,
                    areaid: this.areaId || null,
                    zoneid: this.zoneId || null,
                    lineid: this.lineId || null,
                    equipmentId: this.equipmentId || null,
                    equipmentType: this.equipmentTypeVal || null,
                    variable: this.variableName || null,
                    variableType: this.variableType || null,
                    condition: this.condition || null,
                    orderColumn: this.orderColumn,
                    orderType: this.orderType,
                })
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((variables) => {
                    this.applyRequest(variables);
                    res('resolved');
                });
        });
    }

    applyRequest(variables) {
        this.variables = variables;
        this.reportsImmutable = this.variables.items;
        this.numberOfItems = this.variables.numberOfItems;
        this.pageNumber = this.variables.pageNumber;
        this.setDatatableItems(this.reportsImmutable);
        this.disableLoader();
    }

    disableComponents() {
        this.$fazFilter.prop('disabled', false);
        this.$fqnFilter.prop('disabled', false);
        this.$equipmentName.prop('disabled', false);
        this.$equipmentType.prop('disabled', false);
        this.$lineFilter.prop('disabled', false);
        this.$nameFilter.prop('disabled', false);
        this.$typeFilter.prop('disabled', false);
    }

    setPage(pageInfo) {
        this.pageNumber = pageInfo.offset;
        this.pageSize = pageInfo.pageSize;
        this.doRequest(this.pageNumber, this.pageSize);
    }

    setSort(pageInfo) {
        this.enableLoader();
        this.orderColumn = pageInfo.sorts[0].prop;
        this.orderType = pageInfo.sorts[0].dir;
        this.doRequest();
    }

    changePageSize(event) {
        super.changePageSize(event);
        this.pageSize = +event.target.value;
        this.doRequest(this.pageNumber, this.pageSize);
    }

    async updateHistoricVariables() {
        if (!this.waitForVariablesResponse) {
            this.waitForVariablesResponse = true;
            await this.checkApplyFilters();
            this.waitForVariablesResponse = false;
        }
    }

    generateRemoteCSV(e) {
        if (!this.waitForReportGeneration) {
            this.enableReport();
            this.waitForReportGeneration = true;
            this.archiveService
                .generateVariablesArchive({
                    from: this.from,
                    to: this.to,
                    fqnPattern: this.fqn || null,
                    floorid: this.floorId || null,
                    areaid: this.areaId || null,
                    zoneid: this.zoneId || null,
                    lineid: this.lineId || null,
                    equipmentId: this.equipmentId || null,
                    equipmentType: this.equipmentTypeVal || null,
                    variable: this.variableName || null,
                    variableType: this.variableType || null,
                    condition: this.condition || null,
                    orderColumn: this.orderColumn,
                    orderType: this.orderType,
                })
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe();
            this.waitForReportGeneration = false;
            setTimeout(() => {
                this.disableReport();
            }, 3000);
        }
    }

    goToFloor(data) {
        this.navigationService.goToFloor(this.warehouse.warehouse, data);
    }
    goToArea(data) {
        this.navigationService.goToFloorWithSelected(this.warehouse.warehouse, data, 'AREA');
        //this.navigationService.goToArea(this.warehouse.warehouse, data);
    }
    goToZone(data) {
        this.navigationService.goToFloorWithSelected(this.warehouse.warehouse, data, 'ZONE');
        //this.navigationService.goToZone(this.warehouse.warehouse, data);
    }
    goToLine(data) {
        this.navigationService.goToLine(this.warehouse.warehouse, data);
    }

    removeEmpty(obj) {
        return Object.entries(obj)
            .filter(([k, v]) => (v instanceof Array ? v.length > 0 : v !== null && v !== ''))
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    }

    changeVisibleColumns(columns) {
        this.availableColumnsHistory = this.header === HISTORY_VARIABLES ? columns : this.availableColumnsHistory;

        this.userConfiguration = {
            ...this.userConfiguration,
            columnsVisible: {
                ...this.userConfiguration.columnsVisible,
                variableAvailableColumnsHistory: JSON.stringify(this.availableColumnsHistory),
            },
        };
        this.saveConfiguration();
    }

    saveConfiguration() {
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
    }
}
