<div class="collapse" id="filterMenu" [ngClass]="{ show: !isFullscreen || showFilters }">
    <div class="row search-nav mt-3">
        <div class="col-12 col-xl-5 col-lg-4">
            <app-breadcrumbs columns="9" [breadcrumbs]="[floorMap.id]"></app-breadcrumbs>
        </div>
        <div class="col-12 col-xl-3 col-lg-2">
            <div class="row justify-content-end">
                <div class="col-12 col-lg-6 col-xl-4">
                    <app-button
                        *ngIf="toSelectAreaZoneBuffer"
                        columns="12"
                        (clicked)="toSelectAreaZone = toSelectAreaZone === '' ? toSelectAreaZoneBuffer : ''"
                        [title]="toSelectAreaZone === '' ? 'Highlight ' + toSelectAreaZoneType : 'Remove Highlight ' + toSelectAreaZoneType"
                        key="btnSelectAreaZone"
                    >
                        <i [ngClass]="toSelectAreaZone === '' ? 'fas fa-highlighter' : 'fas fa-eraser'"></i>
                    </app-button>
                </div>
                <div class="col-12 col-lg-6 col-xl-4">
                    <app-dropdown-checkbox
                        label="States"
                        [icon]="filterIcon"
                        [iconTitle]="filterTitle"
                        columns="12"
                        extraClasses="ps-lg-0"
                        [availableItems]="availableStates"
                        (changeFilter)="handleChangeVisibleStates($event)"
                    ></app-dropdown-checkbox>
                </div>
            </div>
        </div>
        <div class="d-none col-12 d-lg-block col-lg-6 col-xl-4">
            <div class="btn-group btn-group-auwa float-end" role="group">
                <button type="button" class="btn btn-auwa" [ngClass]="{ active: alertsView }">Alerts</button>
            </div>
        </div>
    </div>
</div>

<div class="row mt-2">
    <div [ngClass]="liteView ? 'col-xl-8 col-lg-6 col-12' : 'col-xl-8 col-lg-6 col-12 svg-wrapper'">
        <div [ngClass]="{ accordion: liteView }">
            <app-floors-map
                *ngIf="mapRender"
                [type]="currentMode"
                [warehouse]="warehouse.warehouse"
                [floor]="floorMap"
                [visibleStates]="visibleStates"
                [currentMode]="currentMode"
                [lineChange$]="lineChange$"
                [tabState]="tabState"
                (clickArea)="navigateToArea($event)"
                (visibleLines)="refreshAlertsTable($event)"
                mode="floor"
                [markedAreaZone]="toSelectAreaZone"
                [activateZoom]="activateZoom"
                (newLineSelected)="newLineSelected($event)"
            ></app-floors-map>
        </div>
    </div>

    <div class="col-12 col-xl-4 col-lg-6 px-lg-0">
        <div class="col-12 d-lg-none mt-2">
            <div class="btn-group btn-group-auwa float-end" role="group">
                <button type="button" class="btn btn-auwa" [ngClass]="{ active: alertsView }">Alerts</button>
            </div>
        </div>
        <div [ngClass]="{ 'hide-me': !alertsView }">
            <shared-alerts-table-sm
                #alertsTable
                [items]="floorAlerts"
                [filterItems]="linesVisible"
                [group]="floorMap.id"
                [priority$]="priority$"
                fqdnMode="floor"
            ></shared-alerts-table-sm>
        </div>
    </div>
</div>
<app-home-lines-minified-container [lineToShowOff]="lineToShow" [visibleStatesOff]="visibleStates" (offcanvasToggle)="offcanvasToggle($event)">
</app-home-lines-minified-container>
