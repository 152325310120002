import { VARIABLE_EMPTY_REQUEST_ID, VariableActiveStatus, VariableNotification } from '../events/variable-status';

const variablesStatusMapper = (variableStatusNotification: VariableActiveStatus): VariableNotification => {
    return {
        requestId: variableStatusNotification?.requestId || VARIABLE_EMPTY_REQUEST_ID,
        floorId: variableStatusNotification.floorid,
        areaId: variableStatusNotification.areaid,
        zoneId: variableStatusNotification.zoneid,
        lineId: variableStatusNotification.lineid,
        equipmentId: variableStatusNotification.equipmentId,
        equipmentType: variableStatusNotification.equipmentType,
        fqn: variableStatusNotification.fqn,
        source: variableStatusNotification.source,
        timestamp: variableStatusNotification.timestamp,
        sourceTimeStamp: variableStatusNotification.sourceTimeStamp,
        serverTimeStamp: variableStatusNotification.serverTimeStamp,
        variableName: variableStatusNotification.variableName,
        variableType: variableStatusNotification.variableType.replace(/FAILURE/gi, 'ALARM'),
        variableValue: variableStatusNotification.value,
        variableValueType: variableStatusNotification.valueType,
        isOpcError: variableStatusNotification.isOpcError,
        opcErrorType: variableStatusNotification.opcErrorType,
    };
};

export default variablesStatusMapper;
