import { Directive, Input, ElementRef, Inject, HostListener } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[auwaOpenNewTab]',
})
export class OpenLinkInNewTabDirective {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('routerLink') link: string[];

    constructor(
        private el: ElementRef,
        @Inject(Window) private win: Window,
    ) {}

    @HostListener('mousedown', ['$event'])
    onLinkClicked(event) {
        if (event.ctrlKey) {
            this.win.open(this.link.join('/') || 'main/default');
        }
    }
}
