/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationsMap } from '../notifications-map/notifications-map';
import { ServiceStatusClient } from '../clients/service-status.client';

export interface WarehouseMapStatus {
    WarehouseId: string;
    TimeStamp: string;
    version?: string;
}

const CANONICAL_MAP = 'CanonicalMap';

@Injectable()
export class ServiceStatusService {
    onWarehouseMap: EventEmitter<WarehouseMapStatus> = new EventEmitter<WarehouseMapStatus>();
    warehouseMapSubscription: Subscription;
    warehouseMapSubscriptionId: string;
    endPoint: string;
    warehouse: string;
    onReconnectWarehouseMapChanged: () => Promise<void>;
    handleReconnectionWarehouseMapChangedReference: () => Promise<void>;

    private readonly warehouseMapChangedCallback = (warehouseMapStatus: WarehouseMapStatus) => {
        this.onWarehouseMap.emit(warehouseMapStatus);
    };

    constructor(
        private serviceStatusClient: ServiceStatusClient,
    ) { }

    public async subscribeToWarehouseMapChanged(
        warehouse: string,
        context: any,
        delegateFunc: (context: any, warehouseMap: any) => void,
        onReconnect: () => Promise<void>,
    ) {
        try {

            const eventName = ServiceStatusClient.SupportedEvents.CANONICAL_MAP_CHANGED;
            // tslint:disable-next-line:max-line-length
            this.warehouseMapSubscription = this.onWarehouseMap.subscribe({
                next: (event: WarehouseMapStatus) => {
                    const storedVersion = NotificationsMap.getLastNotification(this.constructor.name, CANONICAL_MAP, eventName, event);
                    const candidateVersion = event.TimeStamp;
                    const key = `${this.warehouse}.${eventName}`;
                    if (!candidateVersion || !storedVersion || candidateVersion.localeCompare(storedVersion.timestamp) >= 0) {
                        delegateFunc(context, event);
                        if (candidateVersion) {
                            NotificationsMap.setLastNotification(this.constructor.name, CANONICAL_MAP, eventName, key, {
                                timestamp: event.TimeStamp,
                                notification: event,
                            });
                        }
                    } else {
                        // tslint:disable-next-line: max-line-length
                        const { timestamp: currentVersion } = storedVersion;
                        console.log(
                            `%cSkip notification ${key}
    current:   ${currentVersion}
    candidate: ${candidateVersion}`,
                            'color: orange;',
                        );
                    }
                },
            });
            NotificationsMap.clearNotificationState(this.constructor.name, CANONICAL_MAP, eventName);
            this.onReconnectWarehouseMapChanged = onReconnect;
            this.handleReconnectionWarehouseMapChangedReference = this.handleReconnectionWarehouseMapChanged.bind(this);
            await this.serviceStatusClient.subscribe(eventName, warehouse, this.warehouseMapChangedCallback, this.handleReconnectionWarehouseMapChangedReference);
        } catch (error) {
            console.log(`%c error ServiceStatus => ${error}`, `background: ; color: red`);
        }
    }

    async handleReconnectionWarehouseMapChanged() {
        const eventName = ServiceStatusClient.SupportedEvents.CANONICAL_MAP_CHANGED;
        NotificationsMap.clearNotificationState(this.constructor.name, CANONICAL_MAP, eventName);
        await this.onReconnectWarehouseMapChanged?.();
    }

    public async unsubscribeFromWarehouseMapChanged(warehouse: string) {
        this.warehouseMapSubscription?.unsubscribe();
        const eventName = ServiceStatusClient.SupportedEvents.CANONICAL_MAP_CHANGED;
        await this.serviceStatusClient.unsubscribe(eventName, warehouse);
    }

    isConnected() {
        return this.serviceStatusClient.connection?.hubConnection.state === 'Connected';
    }
}
