import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertsTableSmComponent } from '@app/shared/components/alerts-table-sm/alerts-table-sm.component';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { environment } from '@environments/environment';
import { AppLoadService } from '@app/app-load.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { Structure } from '@home/shared/structure';
import { Alert } from '@alerts/shared/alert';
import { VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { AlertsService } from '@alerts/shared/alerts.service';
import { EquipmentAlarmNotification } from '@app/notifications/shared/events/alert-status';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { MapGlobalState, MapState, initialStates } from '@app/shared/models/map-state';
import * as _ from 'lodash-es';

const { ALERT_STATE_CHANGED, LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;

@Component({
    templateUrl: './customs.component.html',
    styleUrls: ['./customs.component.scss'],
})
export class CustomsComponent extends MapContainerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('alertsTable') alertsTableSmComponent: AlertsTableSmComponent;
    liteView: boolean;
    warehouse: Warehouse;
    minDate: any;
    maxDate: any;
    structure: Structure;
    customId: string;
    customName: string;
    custom: any;
    floorKpis: any;
    equipmentTypes: Array<string> = [];
    configuration: any;
    userName: string;
    userEmail: string;
    configurationFilterValues: Array<MapGlobalState>;
    alertsTab: boolean;
    customsAlerts: Array<Alert>;
    linesVisible: Array<{
        floorId;
        areaId;
        zoneId;
        lineId;
    }> = [];
    groupName: string;
    mode = 'customs';
    lineChange$: Observable<LineStatusNotification>;
    supportedEvents = [ALERT_STATE_CHANGED, LINE_STATE_CHANGED];
    tabState: VisibilityStates = VisibilityStates.active;

    lineToShow = '';
    activateZoom = true;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private appLoadService: AppLoadService,
        private configurationsService: ConfigurationsService,
        private route: ActivatedRoute,
        private alertsService: AlertsService,
        private warehouseStatusService: WarehouseStatusService,
        private authenticationService: AuthenticationService,
        //private pageLifeCycleService: PageLifecycleService
    ) {
        super();
        this.customName = this.router.url
            .split('/customs/')[1]
            .replaceAll('%20', ' ')
            .replaceAll('%5C', '\\')
            .replaceAll('%28', '(')
            .replaceAll('%29', ')')
            .replaceAll('%2F', '/');
        this.configuration = this.route.snapshot.data.customs.configuration;
        this.equipmentTypes = ['All', ...this.route.snapshot.data.customs.equipmentTypes];
        this.warehouse = this.route.snapshot.data.customs.warehouse;
        this.structure = this.route.snapshot.data.customs.structure;
        // this.customsAlerts = this.route.snapshot.data.customs.alerts
        if (this.configuration.stateFilter) {
            this.configurationFilterValues = JSON.parse(this.configuration.stateFilter);
            const valueInCustoms = this.configurationFilterValues.filter((item) => item.id === 'customs')[0]?.states;
            this.availableStates = valueInCustoms ?? initialStates;
        } else {
            this.availableStates = initialStates;
        }
        this.changeVisibleStates(this.availableStates);
        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
        this.custom = this.structure.custom.filter((custom) => custom.name === this.customName)[0];
        this.customId = this.custom.id;
        this.groupName = `${this.warehouse.warehouse}-CUSTOMS-${this.customName}`;
    }

    ngOnInit() {
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.liteView = res.liteMode;
        });
        this.alertsTab = true;
    }

    async ngAfterViewInit(): Promise<void> {
        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     this.tabState = state;
        //     if (
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.warehouseStatusService.isConnected()
        //     ) {
        //         this.onReconnect();
        //     }
        // });
        this.onReconnect();
    }

    async ngOnDestroy() {
        super.ngOnDestroy();
        await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
        this.ngUnsubscribe?.next(true);
        this.ngUnsubscribe?.complete();
    }

    handleChangeVisibleStates(states: Array<MapState>) {
        const newCustomsConfiguration = {
            id: 'customs',
            states,
        };
        this.configurationFilterValues = [...this.configurationFilterValues, newCustomsConfiguration];
        this.configurationFilterValues = _.uniqBy(this.configurationFilterValues, (item) => item.id);
        this.configuration.stateFilter = JSON.stringify(this.configurationFilterValues);
        this.changeVisibleStates(states);
        this.saveConfiguration();
    }
    saveConfiguration() {
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
    }

    processAlertNotification(item: EquipmentAlarmNotification) {
        const alert: Alert = {
            alarmSourceTimeStamp: new Date(),
            closedTimeStamp: new Date(),
            equipmentId: item.equipmentId,
            equipmentType: item.equipmentType,
            description: item.variableName,
            priority: item.priority,
            floorId: item.floorId,
            areaId: item.areaId,
            zoneId: item.zoneId,
            lineId: item.lineId,
            state: item.state,
            alarmServerTimeStamp: item.serverts,
            sourceTimeStamp: item.sourceTimeStamp
        };
        if (
            //NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, ALERT_STATE_CHANGED, alert.sourceTimeStamp, alert)
            this.warehouseStatusService.isNewer(this.groupName, ALERT_STATE_CHANGED, alert, alert.sourceTimeStamp)
        ) {
            this.alertsTableSmComponent.processRealTimeAlert(alert);
        }
    }

    async onReconnect() {
        this.alertsService
            .getAlertsByCustom(this.customId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((alerts) => {
                const alertsToTable: Alert[] = [];
                alerts.forEach((alert) => {
                    if (
                        this.warehouseStatusService.isNewer(this.groupName, ALERT_STATE_CHANGED, alert, alert.sourceTimeStamp)
                    ) {
                        //this.alertsTableSmComponent.processRealTimeAlert(alert);
                        alertsToTable.push(alert);
                    }
                });
                this.customsAlerts = alertsToTable;
            });
        await this.subscribeToRealTimeEvents();
    }

    async subscribeToRealTimeEvents(): Promise<void> {
        try {
            await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
            await this.warehouseStatusService.startSubscription(this.groupName, this.supportedEvents);

            const alertObservable = await this.warehouseStatusService.listenNotifications(this.groupName, ALERT_STATE_CHANGED);
            alertObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.processAlertNotification.bind(this));

            this.lineChange$ = await this.warehouseStatusService.listenNotifications(this.groupName, LINE_STATE_CHANGED);

            this.warehouseStatusService.onReconnecting?.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.onReconnect());
        } catch (error) {
            console.error(error);
        }
    }

    changeTab() {
        //this.alertsTab = !this.alertsTab;
    }

    refreshAlertsTable(linesVisible) {
        this.linesVisible = linesVisible.map((line) => {
            const [floorId, areaId, zoneId, lineId] = line.split('-');
            return {
                floorId,
                areaId,
                zoneId,
                lineId,
            };
        });
        //this.linesVisible = [...this.linesVisible];
    }

    newLineSelected($event) {
        this.lineToShow = JSON.stringify($event);
    }

    offcanvasToggle(visible: boolean) {
        this.activateZoom = !visible;
        if (!visible) this.lineToShow = '';
    }
}
