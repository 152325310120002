<div class="map-wrapper" [ngClass]="viewFullSize ? 'expand' : ''">
    <h4 *ngIf="!wheelZoom">
        <span class="custom-link" [routerLink]="['/warehouse', warehouse, 'customs', custom.name]">{{ custom.name }}</span>
        <button class="float-end btn btn-light px-1 me-2" (click)="toogleFullScreen()" [title]="viewFullSize ? 'Compress floor' : 'Expand floor'">
            <i [ngClass]="viewFullSize ? 'fa fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'" style="cursor: pointer" aria-hidden="true"></i>
        </button>
    </h4>
    <h4 *ngIf="wheelZoom">
        {{ custom.name }}
        <button class="float-end btn btn-light px-1" (click)="resetZoom()" title="Reposition map">
            <i class="fa fa-crosshairs" aria-hidden="true"></i>
        </button>
        <button class="float-end btn btn-light px-1 me-2" (click)="toogleFullScreen()" [title]="viewFullSize ? 'Compress floor' : 'Expand floor'">
            <i [ngClass]="viewFullSize ? 'fa fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'" style="cursor: pointer" aria-hidden="true"></i>
        </button>
        <a class="float-end mt-2 me-2" [routerLink]="['/warehouse', warehouse]">View complete warehouse&nbsp;&nbsp;|</a>
    </h4>
    <div class="svg-wrapper">
        <div class="map-content">
            <svg
                #svg
                auwaSvgZoom
                directiveCurrentZoom="{{ currentZoom }}"
                directiveViewBox="{{ setViewBox }}"
                directiveActivateZoom="{{ activateZoom }}"
                (svgZoomEventEmmiter)="hideContextMenu(); refreshVisibleLines()"
                [attr.viewBox]="setViewBox"
            >
                <svg:g class="freeBacksGroup" *ngIf="viewExtraData && custom.freeBacks">
                    <ng-container *ngFor="let item of custom.freeBacks.shapes">
                        <svg:path
                            *ngIf="item && item !== 'null'"
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.d]="item.data"
                            [attr.style]="item.style"
                        ></svg:path>
                    </ng-container>
                    <ng-container *ngFor="let item of custom.freeBacks.texts">
                        <svg:text class="shape" [attr.data-element]="'shape'" [attr.font-size]="item.size || '16'" [attr.x]="item.x" [attr.y]="item.y">
                            <tspan dy="20">{{ item.text }}</tspan>
                        </svg:text>
                    </ng-container>
                    <ng-container *ngFor="let item of custom.freeBacks.arrows">
                        <svg:polyline class="shape" [attr.data-element]="'shape'" [attr.points]="item" marker-end="url(#triangle)"></svg:polyline>
                    </ng-container>
                </svg:g>
                <ng-container *ngFor="let floor of structure.floors">
                    <svg:g>
                        <ng-container *ngFor="let area of floor.areas">
                            <svg:g class="zone-svg">
                                <ng-container *ngFor="let zone of area.zones">
                                    <ng-container *ngIf="checkZone(floor, area, zone)">
                                        <svg:g [attr.transform]="checkMatrix(floor, area, zone)">
                                            <svg:g class="rotation-group" [ngStyle]="checkRotation(floor, area, zone)">
                                                <ng-container *ngFor="let line of zone.lines">
                                                    <svg:path
                                                        *ngIf="line && line.data && line.data !== 'null'"
                                                        attr.title="Floor {{
                                                            floor.id | fazlDecorator: [['F', identifiersPreferences.Floor], [], [], []]
                                                        }}<br>Area {{ area.id | fazlDecorator: [[], ['A', identifiersPreferences.Area], [], []] }}<br>Zone {{
                                                            zone.id | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []]
                                                        }}<br><strong>Line {{
                                                            line.id | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]]
                                                        }}</strong>"
                                                        [ngClass]="{ danger: line.equipments.length === 0 }"
                                                        [attr.data-element]="'line'"
                                                        attr.data-key="{{ floor.id }}-{{ area.id }}-{{ zone.id }}-{{ line.id }}"
                                                        [attr.d]="line.data"
                                                        (click)="emitLine($event, undefined)"
                                                        (contextmenu)="onRightClick($event)"
                                                    >
                                                        <!-- <title>{{ area.id }}-{{ zone.id }}-{{ line.id }}</title> -->
                                                    </svg:path>
                                                </ng-container>
                                            </svg:g>
                                        </svg:g>
                                    </ng-container>
                                </ng-container>
                            </svg:g>
                        </ng-container>
                    </svg:g>
                </ng-container>
                <svg:g class="freeFrontsGroup" *ngIf="viewExtraData && custom.freeFronts">
                    <ng-container *ngFor="let item of custom.freeFronts.shapes">
                        <svg:path
                            *ngIf="item && item !== 'null'"
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.d]="item.data"
                            [attr.style]="item.style"
                        ></svg:path>
                    </ng-container>
                    <ng-container *ngFor="let item of custom.freeFronts.texts">
                        <svg:text
                            filter="url(#outline)"
                            class="shape"
                            [attr.font-size]="item.size || '16'"
                            [attr.data-element]="'shape'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                        >
                            <tspan dy="20">{{ item.text }}</tspan>
                        </svg:text>
                    </ng-container>
                    <ng-container *ngFor="let item of custom.freeFronts.arrows">
                        <svg:polyline class="shape" [attr.data-element]="'shape'" [attr.points]="item" marker-end="url(#triangle)"></svg:polyline>
                    </ng-container>
                </svg:g>
                <svg:g *ngIf="isVisibilityEnabled" class="variablesGroup">
                    <ng-container *ngFor="let item of custom?.variablesLines?.texts">
                        <svg:text
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.font-size]="item.size || '16'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                            [attr.selectedVariable]="item.selectedVariable"
                            *ngIf="item"
                        >
                            <tspan dy="20">{{ item.text }}</tspan>
                        </svg:text>
                    </ng-container>
                    <ng-container *ngFor="let item of custom?.variablesLines?.shapes">
                        <svg:path
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.d]="item.data"
                            [attr.data-key]="item.variable"
                            [attr.variable]="item.variable"
                            *ngIf="item && item.data && item.data !== 'null'"
                        ></svg:path>
                        <title>{{ item.variable }}</title>
                    </ng-container>
                    <ng-container *ngFor="let block of metadataBlocks?.metadata">
                        <svg:g
                            class="shape block"
                            [attr.transform]="block?.transform"
                            [attr.variable]="block?.variable"
                            [attr.description]="block?.description"
                        >
                            <ng-container *ngFor="let item of block?.shapes">
                                <svg:path
                                    *ngIf="item && item.data !== 'null'"
                                    [attr.data-element]="'shape'"
                                    [attr.data-key]="item.variable"
                                    [ngClass]="{ withVariable: item.variable, highlightPath: item.highlightPath }"
                                    [attr.d]="item.data"
                                    [attr.variable]="item.variable"
                                    [attr.description]="item.description"
                                >
                                    <title>{{ block.variable }} - {{ block.description }}</title>
                                </svg:path>
                            </ng-container>
                            <ng-container *ngFor="let item of block?.texts">
                                <svg:text
                                    class="shape"
                                    [attr.data-element]="'shape'"
                                    [attr.font-size]="item.size || '16'"
                                    [attr.x]="item.x"
                                    [attr.y]="item.y"
                                    [attr.data-key]="item.variable ? 'text' + item.variable : ''"
                                    [attr.variable]="item.variable"
                                    [attr.description]="item.description"
                                >
                                    <tspan [attr.text-anchor]="item.variable ? 'middle' : 'left'" dy="20">{{ item.text }}</tspan>
                                    <title>{{ item.variable }}</title>
                                </svg:text>
                            </ng-container>
                        </svg:g>
                    </ng-container>
                    <ng-container *ngFor="let item of custom.variablesLines.texts">
                        <svg:text
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.font-size]="item.size || '16'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                            [attr.selectedVariable]="item.selectedVariable"
                        >
                            <tspan dy="20">{{ item.text }}</tspan>
                        </svg:text>
                    </ng-container>
                    <ng-container *ngFor="let item of custom?.variablesLines?.shapes">
                        <svg:path
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.d]="item.data"
                            [attr.data-key]="item.variable"
                            [attr.variable]="item.variable"
                            *ngIf="item && item !== 'null'"
                        >
                            <title>{{ item.variable }}</title>
                        </svg:path>
                    </ng-container>
                </svg:g>
            </svg>
        </div>
    </div>
</div>
<app-context-menu
    [keySelectedWithRightClick]="keySelectedWithRightClick"
    [warehouse]="warehouse"
    [contextId]="custom?.name"
    [x]="rightClickX"
    [y]="rightClickY"
    [identifiersPreferences]="identifiersPreferences"
    (newLineSelectedFromContextMenu)="emitLine(undefined, $event)"
></app-context-menu>
