import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { LinesService } from '@app/map/lines/shared/lines.service';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { VariablesActiveStatusService } from '@app/notifications/shared/handlers/variablesActive-status-service';
import { VariablesService } from '@variables/shared/variables.service';
import { VariableFilter, VariableFilters, VariableNotification } from '@app/notifications/shared/events/variable-status';
import { VARIABLES_MODE, VariablesViewState, VariableHistory, VariableDashboardType, setFqnPreferences, setIdentifiers } from '@variables/shared/variable';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppLoadService } from '@app/app-load.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { UserConfiguration } from '@app/shared/models/configurations';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
//import { pageLifeCycleService } from '@app/shared/services/page-lifecycle.service';
import { SelectComponent } from '@ays/commons/lib/components/forms/select/select.component';
import { VariablesStatusClient } from '@app/notifications/shared/clients/variables-status.client';
import { IdentifiersPreferences } from '@app/map/home/shared/structure';
import { v4 as uuid } from 'uuid';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as _ from 'lodash-es';
dayjs.extend(customParseFormat);

export interface VariableDashboardValue {
    timestamp: Date;
    value: any;
    variableSource: VariableDashboardType;
}
export interface VariableDashboardItem {
    fqn: string;
    name: string;
    variableIcon?: VariableHistory;
    values: VariableDashboardValue[];
}

const MAX_NOTIFICATIONS = 50;
const ORDER_COLUMN = 'SourceTimeStamp';
const ORDER_TYPE = 'desc';

const VARIABLES_DASHBOARD = 'Variables Dashboard';

@Component({
    selector: 'app-variables-dashboard',
    templateUrl: './variables-dashboard.component.html',
    styleUrls: ['./variables-dashboard.component.scss'],
    providers: [VariablesActiveStatusService, VariablesStatusClient],
})
export class VariablesDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('linesSelector') linesSelector: SelectComponent;
    @ViewChild('equipmentsSelector') equipmentsSelector: SelectComponent;
    @ViewChild('variableSelector') variableSelector: SelectComponent;

    isDashboardItemsEmpty = true;

    warehouse: Warehouse;
    selectedLine: string;
    selectedEquipment: string;
    selectedVariable: string;

    $linesSelector: JQuery;
    $equipmentsSelector: JQuery;
    $variableSelector: JQuery;

    identifiersPreferences: IdentifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    line$: Array<string> = [];
    lineEquipments$: Array<string> = [];
    variable$: Array<any> = [];
    currentRequestId: string;
    currentFilters: VariableFilter[] = [];
    dashboardVariablesFilter: Array<string> = [];
    subscribed = false;
    userConfiguration: UserConfiguration;
    userName: string;
    userEmail: string;
    warehouseId: string;
    isFirstLoadDashboardData = false;
    isLoadingDataTable = false;
    isRepeatedItem = false;
    promisesCounter = 0;
    dashboardItems: Array<VariableDashboardItem> = [];

    header = VARIABLES_DASHBOARD;

    tableColours = `<ul>
        <li><b>Real Time values</b>: A light gray background indicates those rows which relate to this data type. First real time value pops up at the first row of the table. Subsequent values move downwards, replacing previous historic values.</li>
        <li><b>Historic values</b>: A darken background colour and italic text are intended for these historic values. The values from second row to the last one are colouring this way once data have been firstly fetched.</li>
    </ul>`;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private appLoadService: AppLoadService,
        private configurationsService: ConfigurationsService,
        private variablesService: VariablesService,
        private equipmentsService: EquipmentsService,
        private linesService: LinesService,
        private variablesActiveStatusService: VariablesActiveStatusService,
        private authenticationService: AuthenticationService,
        //private pageLifeCycleService: PageLifecycleService,
        private variablesStatusClient: VariablesStatusClient,
    ) {
        this.warehouse = this.route.snapshot.data.variables.warehouse;
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
        });
    }

    ngOnInit() {
        this.variablesStatusClient.connectToHub().then(() => {
            console.warn('Connected to VariablesDashboard Hub');
        });

        const value = this.authenticationService.getUser();
        this.userEmail = value.userName || '';
        this.userName = value.name || '';

        this.selectedLine = '';
        this.selectedEquipment = '';
        this.selectedVariable = '';
        this.dashboardItems = [];
        this.linesService
            .getLinesNames()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((lines) => {
                this.line$ = this.setFAZLPreferences(lines);
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            // take url params
            this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => this.handleUrlParams(params));
        });
        this.$linesSelector = this.linesSelector.$select;
        this.$linesSelector.off('change').on('change', () => {
            this.selectedLine = String(this.$linesSelector.find('option:selected').val());
            this.listEquipments(this.selectedLine);
        });
        this.$equipmentsSelector = this.equipmentsSelector.$select;
        this.$equipmentsSelector.off('change').on('change', () => {
            this.selectedEquipment = String(this.$equipmentsSelector.find('option:selected').val());
            this.listVariables(this.selectedLine, this.selectedEquipment);
        });
        this.$variableSelector = this.variableSelector.$select;
        this.$variableSelector.off('change').on('change', () => {
            this.selectedVariable = String(this.$variableSelector.find('option:selected').val());
            if (this.selectedVariable) {
                this.isRepeatedItem = false;
                //checking if there is a repeated item
                this.dashboardVariablesFilter.forEach((item) => {
                    const [
                        warehouseDashboardFilter,
                        floorDashboardFilter,
                        areaDashboardFilter,
                        zoneDashboardFilter,
                        lineDashboardFilter,
                        equipmentIdDashboardFilter,
                        equipmentTypeDashboardFilter,
                        variableDashboardFilter,
                    ] = item.split('-');
                    const [floorId, areaId, zoneId, lineId] = this.selectedLine.split('-');
                    const [equipmentId, equipmentType] = this.selectedEquipment.split('-');
                    const updatedWarehouse = setIdentifiers(floorId, areaId, zoneId, lineId);
                    if (
                        updatedWarehouse[0] === floorDashboardFilter &&
                        updatedWarehouse[1] === areaDashboardFilter &&
                        updatedWarehouse[2] === zoneDashboardFilter &&
                        updatedWarehouse[3] === lineDashboardFilter &&
                        equipmentIdDashboardFilter === equipmentId &&
                        equipmentTypeDashboardFilter === equipmentType &&
                        variableDashboardFilter === this.selectedVariable
                    ) {
                        this.resettingCombo();
                        this.isRepeatedItem = true;
                    }
                });
                if (!this.isRepeatedItem) {
                    let [floorId, areaId, zoneId, lineId] = this.selectedLine.split('-') || [];
                    [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
                    const [equipmentType, equipmentId] = this.selectedEquipment.split('-') || [];
                    const fqn = `${this.warehouseId}-${floorId}-${areaId}-${zoneId}-${lineId}-${equipmentId}-${equipmentType}-${this.selectedVariable}`;

                    this.loadVariableRT(fqn);
                    this.loadVariableHistory(this.selectedLine, this.selectedEquipment, this.selectedVariable).then();
                }
            }
        });

        $('.dashboard-table-doc-popover').popover({ trigger: 'focus' });

        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     if (
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.variablesActiveStatusService.isConnected()
        //     ) {
        //         this.handleReconnection();
        //     }
        // });
    }

    async ngOnDestroy(): Promise<void> {
        this.currentRequestId = 'stop-processing-notifications';
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        this.variablesStatusClient.disconnectFromHub();

        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    setFAZLPreferences(items: string[]): string[] {
        const result = [];
        let item = '';
        items.map((e) => {
            const id = e.split('-');
            this.warehouseId = id.shift();
            item = setFqnPreferences(
                id[0],
                this.identifiersPreferences.Floor,
                id[1],
                this.identifiersPreferences.Area,
                id[2],
                this.identifiersPreferences.Zone,
                id[3],
                this.identifiersPreferences.Line,
            );
            result.push(item);
            result.sort();
        });
        return result;
    }

    handleUrlParams(params: any) {
        //if (params.mode === VARIABLES_MODE.DASHBOARD) {
        // get filters from query params, configuration or empty in that order
        let filters: string[] = params.f
            ? JSON.parse(params.f)
            : this.userConfiguration?.dashboardVariablesFilter
                ? this.userConfiguration.dashboardVariablesFilter.map((item) => item)
                : [];
        // TODO: filters will return a list of fqn, handle them to load the tables
        // check if filters are valid for this warehouse
        const isFilterValid = filters.reduce((acc, filter) => {
            const [warehouse, , , , , , ,] = filter.split('-');
            if (acc && warehouse === this.warehouse.warehouse) return true;
        }, true);
        if (!isFilterValid) {
            filters = [];
        }
        // set filter in the url if not present
        if (!isFilterValid || !params.f) {
            const queryParams = {
                mode: VARIABLES_MODE.DASHBOARD,
                f: JSON.stringify(filters),
            };
            const url = this.router
                .createUrlTree([], {
                    relativeTo: this.route,
                    queryParams: { ..._.omitBy(queryParams, (v) => !v || v === '') },
                })
                .toString();
            this.location.go(url);
        }
        // TODO: filters will return a list of fqn, handle them to load the tables
        if (this.dashboardVariablesFilter.length === 0) {
            filters.forEach((item) => {
                // eslint-disable-next-line prefer-const
                let [warehouseId, floorId, areaId, zoneId, lineId, equipmentType, equipmentId, selectedVariable] = item.split('-');
                this.warehouseId = warehouseId;
                [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
                const selectedLine = `${floorId}-${areaId}-${zoneId}-${lineId}`;
                const selectedEquipment = `${equipmentType}-${equipmentId}`;
                this.selectedEquipment = selectedEquipment;
                this.selectedVariable = selectedVariable;
                const fqn = `${this.warehouseId}-${floorId}-${areaId}-${zoneId}-${lineId}-${equipmentId}-${equipmentType}-${selectedVariable}`;
                this.loadVariableRT(fqn);
                this.loadVariableHistory(selectedLine, selectedEquipment, selectedVariable).then();
            });
            this.dashboardVariablesFilter = [...filters];
        }
        //}
    }

    // ngOnChanges(changes: SimpleChanges) {
    //     if (changes.selectedLine?.currentValue?.length) {
    //         this.listEquipments(this.selectedLine);
    //         // TODO: enable equipment dropdown when data is loaded
    //     }
    //     if (changes.selectedEquipment?.currentValue?.length) {
    //         this.listVariables(this.selectedLine, this.selectedEquipment);
    //         // TODO: enable variables dropdown when data is loaded
    //     }
    //     if (changes.selectedVariable?.currentValue) {}
    // }

    listEquipments(selectedLine: string) {
        let [floorId, areaId, zoneId, lineId] = selectedLine.split('-') || [];
        if (floorId && areaId && zoneId && lineId) {
            [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
            this.equipmentsService
                .getEquipmentNamesByLine(floorId, areaId, zoneId, lineId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    this.lineEquipments$ = res;
                });
        } else {
            this.lineEquipments$ = [];
        }
    }

    listVariables(selectedLine: string, selectedEquipment: string) {
        let [floorId, areaId, zoneId, lineId] = selectedLine.split('-') || [];
        if (floorId && areaId && zoneId && lineId) {
            [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
        }
        const [equipmentSystem, equipmentType] = selectedEquipment.split('-') || [];
        if (equipmentSystem && equipmentType) {
            this.equipmentsService
                .getVariablesFromEquipment(floorId, areaId, zoneId, lineId, equipmentSystem, equipmentType)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((variables) => {
                    this.variable$ = variables?.map((v) => {
                        return { value: v.name, name: v.name };
                    });
                });
        } else {
            this.variable$ = [];
        }
    }

    loadVariableHistory(selectedLine: string, selectedEquipment: string, selectedVariable: string): Promise<string> {
        return new Promise((res) => {
            const from = dayjs().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss');
            const to = dayjs().format('YYYY-MM-DD HH:mm:ss');
            this.promisesCounter++;
            let [floorId, areaId, zoneId, lineId] = selectedLine.split('-') || [];
            [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
            const [equipmentId, equipmentType] = selectedEquipment.split('-') || [];
            const fqn = `${this.warehouseId}-${floorId}-${areaId}-${zoneId}-${lineId}-${equipmentId}-${equipmentType}-${selectedVariable}`;
            this.isLoadingDataTable = true;
            this.variablesService
                .getVariablesHistory({
                    from,
                    to,
                    floorid: floorId,
                    areaid: areaId,
                    zoneid: zoneId,
                    lineid: lineId,
                    equipmentId,
                    equipmentType,
                    variable: selectedVariable,
                })
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((variables) => {
                    const defaultValue = { value: '', timestamp: '', variableSource: VariableDashboardType.historic };
                    const values = [
                        ...variables.items.map(({ value, sourceTimeStamp }) => ({
                            value,
                            timestamp: sourceTimeStamp,
                            variableSource: VariableDashboardType.historic,
                        })),
                        ...new Array(10).fill(defaultValue),
                    ].slice(0, 10);
                    const newDashboardItem = {
                        fqn: `${setFqnPreferences(
                            floorId,
                            this.identifiersPreferences.Floor,
                            areaId,
                            this.identifiersPreferences.Area,
                            zoneId,
                            this.identifiersPreferences.Zone,
                            lineId,
                            this.identifiersPreferences.Line,
                        )}-${equipmentId}-${equipmentType}-${selectedVariable}`,
                        name: `${equipmentId}-${equipmentType}-${selectedVariable}`,
                        variableIcon: variables.items[0],
                        values,
                    };
                    this.dashboardItems = [...(this.dashboardItems || []), newDashboardItem];
                    if (this.isFirstLoadDashboardData && this.dashboardItems.length === this.promisesCounter) {
                        this.isFirstLoadDashboardData = false;
                        this.isLoadingDataTable = false;
                    }
                    if (!this.isFirstLoadDashboardData) {
                        this.isLoadingDataTable = false;
                        this.isDashboardItemsEmpty = false;
                        this.resettingCombo();
                    }
                    if (this.dashboardVariablesFilter.indexOf(fqn) === -1) {
                        this.dashboardVariablesFilter.push(fqn);
                        this.userConfiguration.dashboardVariablesFilter = [...this.dashboardVariablesFilter];
                        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
                        this.setUrlParams({
                            mode: VARIABLES_MODE.DASHBOARD,
                            f: JSON.stringify(this.dashboardVariablesFilter),
                        });
                    }

                    res(fqn);
                });
        });
    }

    async handleReconnection(this) {
        // TODO: reconnection should empty dashboardItems.values to reload them?
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        this.subscribed = false;
        this.currentFilters = [];

        this.dashboardItems.forEach((item) => {
            const [floorIdTemp, areaIdTemp, zoneIdTemp, lineIdTemp, equipmentId, equipmentType, variableName] = item.fqn.split('-');
            const [floorId, areaId, zoneId, lineId] = setIdentifiers(floorIdTemp, areaIdTemp, zoneIdTemp, lineIdTemp);
            const fqn = `${this.warehouseId}-${floorId}-${areaId}-${zoneId}-${lineId}-${equipmentType}-${equipmentId}-${variableName}`;
            this.loadVariableRT(fqn);
            this.isLoadingDataTable = false;
        });
    }

    loadVariableRT(fqn: string) {
        //eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.dashboardItems.length ? (this.isLoadingDataTable = true) : (this.isFirstLoadDashboardData = true);
        this.isDashboardItemsEmpty = false;

        if (!this.subscribed) {
            this.currentRequestId = 'VariablesDashboard---' + uuid();
        }
        // eslint-disable-next-line prefer-const
        let [warehouseId, floorId, areaId, zoneId, lineId, equipmentType, equipmentId, variableName] = fqn?.split('-');
        [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);

        const firstFilter: VariableFilter = {
            warehouseId,
            floorId,
            areaId,
            zoneId,
            lineId,
            equipmentId,
            equipmentType,
            variableName,
        };
        this.currentFilters.push(firstFilter);
        const filters: VariableFilters = {
            requestId: this.currentRequestId,
            filters: this.currentFilters,
            maxNotificationItems: MAX_NOTIFICATIONS,
            orderColumn: ORDER_COLUMN,
            orderType: ORDER_TYPE,
        };
        if (!this.subscribed) {
            this.subscribed = true;
            this.variablesActiveStatusService.subscribeToVariable(filters, this.receiveRealTimeVariables.bind(this), this.handleReconnection.bind(this));
        } else {
            this.variablesActiveStatusService.applyFilter(filters);
        }
    }

    receiveRealTimeVariables(notification: VariableNotification) {
        if (notification.requestId === this.currentRequestId) {
            if (notification.sourceTimeStamp === undefined && ![undefined, null, 'ApiDefault'].includes(notification.source)) {
                console.error('SourceTimeStamp from backend is null/undefined.', notification);
            }
            const ts = dayjs(notification.sourceTimeStamp).toDate();
            this.dashboardItems.forEach((item) => {
                const [floorIdTemp, areaIdTemp, zoneIdTemp, lineIdTemp, equipmentId, equipmentType, variableName] = item.fqn.split('-');
                const [floorId, areaId, zoneId, lineId] = setIdentifiers(floorIdTemp, areaIdTemp, zoneIdTemp, lineIdTemp);
                if (
                    notification.floorId === floorId &&
                    notification.areaId === areaId &&
                    notification.zoneId === zoneId &&
                    notification.lineId === lineId &&
                    notification.equipmentType === equipmentType &&
                    notification.equipmentId === equipmentId &&
                    notification.variableName === variableName
                ) {
                    // check if there is already an entry with the same value for the same date
                    let existingEntry = false;
                    item.values.forEach((row) => {
                        if (
                            row &&
                            dayjs(ts).isSame(dayjs(row.timestamp)) &&
                            notification.variableValue.toString().toUpperCase() === row.value.toString().toUpperCase()
                        ) {
                            existingEntry = true;
                        }
                    });
                    // if not, add it as new value
                    if (!existingEntry) {
                        if (item?.values[0]?.value !== String(notification.variableValue))
                            //only add if the value is different to first value
                            item.values = [
                                {
                                    value: String(notification.variableValue),
                                    timestamp: ts,
                                    variableSource: VariableDashboardType.realTime,
                                } as VariableDashboardValue,
                                ...item.values,
                            ].slice(0, 10);

                        item.variableIcon = {
                            warehouseId: '',
                            floorid: floorId,
                            areaid: areaId,
                            zoneid: zoneId,
                            lineid: lineId,
                            equipmentId,
                            equipmentType,
                            fqn: item.fqn,
                            source: '',
                            sourceTimeStamp: ts.toString(),
                            timestamp: ts.toString(),
                            value: String(notification.variableValue),
                            valueDataType: notification.variableValueType,
                            variableName: notification.variableName,
                            variableType: notification.variableType,
                        };
                    }
                }
            });
        }
    }

    async removeView(itemName) {
        //TODO: Implementing logic with new variables name
        const [equipmentId, equipmentType, variable] = itemName.split('-');
        this.dashboardItems = this.dashboardItems.filter((item) => item.name !== itemName);
        this.dashboardVariablesFilter = this.dashboardVariablesFilter.filter((item) => {
            const [, , , , , equipmentIdDashboardFilter, equipmentTypeDashboardFilter, variableDashboardFilter] = item.split('-');
            if (equipmentId !== equipmentIdDashboardFilter || equipmentType !== equipmentTypeDashboardFilter || variable !== variableDashboardFilter) {
                return item;
            }
        });
        if (this.dashboardItems.length === 0) {
            this.isDashboardItemsEmpty = true;
            await this.variablesActiveStatusService.unsubscribeFromVariable();
        } else {
            this.handleReconnection();
        }
        this.promisesCounter = this.dashboardItems.length;
        this.userConfiguration.dashboardVariablesFilter = [...this.dashboardVariablesFilter];

        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);

        this.setUrlParams({
            mode: VARIABLES_MODE.DASHBOARD,
            f: JSON.stringify(this.dashboardVariablesFilter),
        });
    }

    setUrlParams(viewState: VariablesViewState) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { ..._.omitBy(viewState, (v) => !v || v === '') },
        });
    }

    resettingCombo() {
        this.$linesSelector.val('').trigger('change');
        this.$equipmentsSelector.val('').trigger('change');
        this.$variableSelector.val('').trigger('change');
        this.selectedLine = '';
        this.selectedEquipment = '';
        this.selectedVariable = '';
    }
}
