/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { WarehouseStatusClient } from '../clients/warehouse-status.client';
import { ReplaySubject, Subject } from 'rxjs';
import { getEventTS, mapNotification } from '../mappers/notification.mapper';
import { WorkerMessageTypes } from '../web-workers/worker-message-types';
import { HubConnectionState } from '@microsoft/signalr';
// import { yieldToMain } from '@app/shared/performance/auwa-scheduler';
import { NotificationsMap } from '../notifications-map/notifications-map';

interface NotificationSubjects {
    [groupName: string]: {
        [eventName: string]: ReplaySubject<any>;
    };
}

@Injectable()
export class WarehouseStatusService {
    subjects: NotificationSubjects = {};
    onReconnecting: Subject<void>;
    worker: Worker;
    handleReconnectionReference: () => Promise<void>;

    constructor(
        private warehouseStatusClient: WarehouseStatusClient
    ) { }

    public async startSubscription(groupName: string, supportedEvents: Array<string>): Promise<void> {
        try {
            this.initializeWorker();
            if (!this.subjects[groupName]) {
                supportedEvents.forEach((eventName) => {
                    this.clearNotificationsMap(groupName, eventName);
                    this.subjects = {
                        ...this.subjects,
                        [groupName]: {
                            ...(this.subjects[groupName] || {}),
                            [eventName]: new ReplaySubject(Infinity, 10000), //(1, 10000) (100, 10000) (1, 3000) (Infinity, 10000) (Infinity, 5000)
                        },
                    };
                });
                this.onReconnecting = new Subject<void>();
                this.handleReconnectionReference = this.handleReconnection.bind(this, groupName, supportedEvents);
                await this.warehouseStatusClient.subscribe$(groupName, supportedEvents, this.processNotification.bind(this), this.handleReconnectionReference);
            }
        } catch (error) {
            console.error(error);
        }
    }

    initializeWorker() {
        try {
            if (!this.worker && typeof Worker !== 'undefined') {
                console.warn('Create warehouse-status.worker');
                this.worker = new Worker(new URL('../web-workers/warehouse-status.worker', import.meta.url));
                this.worker.onmessage = async ({ data }) => {
                    if (data.messageType === WorkerMessageTypes.NOTIFY) {
                        await this.workerNofity({ groupName: data.groupName, eventName: data.eventName, notificationGroup: data.notificationGroup });
                    }
                };
            }
        } catch (error) {
            console.error(error);
            this.worker?.terminate();
            this.worker = null;
        }
    }

    clearNotificationsMap(groupName: string, eventName: string) {
        if (this.worker) {
            const constructorName = this.constructor.name;
            this.worker.postMessage({ messageType: WorkerMessageTypes.CLEAR_MAP, constructorName, groupName, eventName });
        } else {
            this.clearNotifications(groupName, eventName);
        }
    }

    processNotification(groupName: string, eventName: string, notificationGroup: any[]) {
        if (this.worker) {
            this.worker.postMessage({
                messageType: WorkerMessageTypes.FILTER_NOTIFICATIONS,
                constructorName: this.constructor.name,
                groupName,
                eventName,
                notificationGroup,
            });
        } else {
            try {
                const replaySubject = this.subjects?.[groupName]?.[eventName];
                notificationGroup
                    .map((notification) => mapNotification(eventName, notification))
                    .filter((notification: any) => this.isNewer(groupName, eventName, notification))
                    .forEach((notification) => replaySubject?.next(notification));
            } catch (error) {
                console.error(error);
            }
        }
    }

    async workerNofity(data: { groupName: string; eventName: string; notificationGroup: any[] }) {
        const { groupName, eventName, notificationGroup } = data;
        const replaySubject = this.subjects?.[groupName]?.[eventName];
        for (const notification of notificationGroup) {
            if (notification.state !== 'Auwa.Core.Model.Variables.AlarmVariable') {
                replaySubject?.next(notification);
            }
        }
        // await yieldToMain();
    }

    async listenNotifications(groupName: string, eventName: string, retry: number = 0): Promise<ReplaySubject<any>> {
        this.clearNotifications(groupName, eventName);
        return new Promise((resolve, reject) => {
            try {
                if (this.subjects[groupName] && this.subjects[groupName][eventName]) {
                    return resolve(this.subjects[groupName][eventName]);
                } else {
                    setTimeout(() => resolve(this.listenNotifications(groupName, eventName, retry + 1)), 250);
                }
            } catch (error) {
                console.error(error);
            }
        });
    }

    isNewer(groupName: string, eventName: string, event: any, timestampToCompare: any = null): boolean {
        const notificationTS = timestampToCompare ?? getEventTS(eventName, event);
        return NotificationsMap.isNewerNotification(this.constructor.name, groupName, eventName, notificationTS, event);
    }

    clearNotifications(groupName, eventName) {
        NotificationsMap.clearNotificationState(this.constructor.name, groupName, eventName);
    }

    async handleReconnection(groupName: string, subscribedEvents: Array<string>) {
        subscribedEvents.forEach((eventName) => {
            this.clearNotifications(groupName, eventName);
        });
        this.onReconnecting?.next();
    }

    public async endSubscription(groupName: string, supportedEvents: Array<string>) {
        try {
            supportedEvents.forEach((eventName) => {
                if (this.subjects && this.subjects[groupName] && this.subjects[groupName][eventName]) {
                    this.subjects[groupName][eventName].complete();
                    this.subjects[groupName][eventName] = null;
                    delete this.subjects[groupName][eventName];
                }
            });
            if (this.subjects[groupName] && Object.keys(this.subjects[groupName]).length === 0) {
                this.subjects[groupName] = null;
                delete this.subjects[groupName];
            }
            this.onReconnecting?.complete();
            this.onReconnecting = null;
            await this.warehouseStatusClient.unsubscribe$(groupName, supportedEvents);
        } catch (error) {
            console.error(error);
        }
    }

    isConnected() {
        return this.warehouseStatusClient.connection?.hubConnection.state === HubConnectionState.Connected;
    }
}
