import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { AppLoadService } from '@app/app-load.service';
import { EquipmentsService } from '@app/map/equipments/equipments.service';

@Injectable()
export class VariablesResolver {
    constructor(
        private equipmentService: EquipmentsService,
        private appLoadService: AppLoadService,
    ) {}

    getEquipmentTypes(): Observable<string[]> {
        return this.equipmentService.getEquipmentTypes();
    }

    resolve(): Observable<any> {
        return forkJoin([
            this.appLoadService.getCurrentWarehouse.pipe(
                take(1),
                catchError((err) => {
                    console.error('Ha ocurrido un problema al recuperar el almacen actual', err);
                    return of(null);
                }),
            ),
            this.getEquipmentTypes().pipe(
                take(1),
                catchError((err) => {
                    console.error('Ha ocurrido un problema al recuperar el almacen actual', err);
                    return of(null);
                }),
            ),
        ]).pipe(
            map((x) => {
                return {
                    warehouse: x[0],
                    equipmentTypes: x[1],
                };
            }),
        );
    }
}
