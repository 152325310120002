import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { LinesService } from '@lines/shared/lines.service';
import { Line } from '@app/map/home/shared/structure';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { AppLoadService } from '@app/app-load.service';
import * as _ from 'lodash-es';

@Injectable()
export class LinesResolver {
    constructor(
        private linesService: LinesService,
        private equipmentsService: EquipmentsService,
        private appLoadService: AppLoadService,
    ) {}

    getIcons(): Observable<Line> {
        return this.linesService.getIcons().pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema en el provider al recuperar los iconos -> ' + err);
                return of(null);
            }),
        );
    }

    getLineMap(route): Observable<Line> {
        return this.linesService.getLine(route.params.floorId, route.params.areaId, route.params.zoneId, route.params.lineId).pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema en el provider al recuperar el mapa de la linea de equipos -> ' + err);
                return of(null);
            }),
        );
    }

    getEquipmentTypes(): Observable<string[]> {
        return this.equipmentsService.getEquipmentTypes().pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema al recuperar los tipos de equipos');
                return of(null);
            }),
        );
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return forkJoin([
            this.getLineMap(route),
            this.getEquipmentTypes(),
            this.appLoadService.getCurrentWarehouse.pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar el almacen actual', err);
                    return of(null);
                }),
            ),
            this.getIcons(),
            this.appLoadService.getCurrentConfiguration.pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar la configuracion', err);
                    return of(null);
                }),
            ),
        ]).pipe(
            map((x) => {
                return {
                    map: x[0],
                    equiopmentTypes: x[1],
                    warehouse: x[2],
                    icons: x[3],
                    configuration: x[4],
                };
            }),
        );
    }
}
