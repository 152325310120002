export const ALERTS_ACTIVE = 'ActiveAlerts';
export interface EquipmentAlarmNotification {
    floorId?: string;
    areaId?: string;
    zoneId?: string;
    lineId?: string;
    equipmentId: string;
    equipmentType: string;
    state: string;
    variableName?: string;
    priority?: string;
    source: string;
    sourceTimeStamp: string;
    serverts: string;
}

export interface AlertsActiveStatus {
    warehouseId: string;
    floorid: string;
    areaid: string;
    zoneid: string;
    lineid: string;
    equipmentId: string;
    equipmentType: string;
    state: string;
    variableName: string;
    priority: string;
    source: string;
    sourceTimeStamp: Date;
    serverTimeStamp: Date;
}

// export interface RawAlertsActiveStatus {
//     warehouseId: string;
//     floorid: string;
//     areaid: string;
//     zoneid: string;
//     lineid: string;
//     equipmentId: string;
//     equipmentType: string;
//     state: Array<string>;
//     variableName: string;
//     priority: string;
//     source: string;
//     sourceTimeStamp: string;
//     serverTimeStamp: string;
// }
