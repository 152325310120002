<div class="row mt-3 mb-3 justify-content-center">
    <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
        <h5 class="text-start ms-5 mt-4">HEAT MAP</h5>
        <div class="mt-3 mb-3">
            <shared-config-color-picker
                [dimension]="'Running'"
                [firstRange]="runningConfiguration.ranges[0]"
                [secondRange]="runningConfiguration.ranges[1]"
                [firstColorRange]="runningConfiguration.colors[0]"
                [secondColorRange]="runningConfiguration.colors[1]"
                [thirdColorRange]="runningConfiguration.colors[2]"
                (colorSelected)="saveColorPicker($event)"
                (applyRanges)="calculateNewRanges($event)"
            ></shared-config-color-picker>
        </div>
        <div class="mt-3 mb-3">
            <shared-config-color-picker
                [dimension]="'Stopped'"
                [firstRange]="stoppedConfiguration.ranges[0]"
                [secondRange]="stoppedConfiguration.ranges[1]"
                [firstColorRange]="stoppedConfiguration.colors[0]"
                [secondColorRange]="stoppedConfiguration.colors[1]"
                [thirdColorRange]="stoppedConfiguration.colors[2]"
                (colorSelected)="saveColorPicker($event)"
                (applyRanges)="calculateNewRanges($event)"
            ></shared-config-color-picker>
        </div>
        <div class="mt-3 mb-3">
            <shared-config-color-picker
                [dimension]="'On Error'"
                [firstRange]="onErrorConfiguration.ranges[0]"
                [secondRange]="onErrorConfiguration.ranges[1]"
                [firstColorRange]="onErrorConfiguration.colors[0]"
                [secondColorRange]="onErrorConfiguration.colors[1]"
                [thirdColorRange]="onErrorConfiguration.colors[2]"
                (colorSelected)="saveColorPicker($event)"
                (applyRanges)="calculateNewRanges($event)"
            ></shared-config-color-picker>
        </div>
        <div class="mt-3 mb-3">
            <shared-config-color-picker
                [dimension]="'Errors'"
                [firstRange]="errorsConfiguration.ranges[0]"
                [errorsMiddleRange]="errorsConfiguration.ranges[1]"
                [secondRange]="errorsConfiguration.ranges[2]"
                [firstColorRange]="errorsConfiguration.colors[0]"
                [secondColorRange]="errorsConfiguration.colors[1]"
                [thirdColorRange]="errorsConfiguration.colors[2]"
                [firstName]="errorsConfiguration.names[0]"
                [secondName]="errorsConfiguration.names[1]"
                [thirdName]="errorsConfiguration.names[2]"
                (colorSelected)="saveColorPicker($event)"
                (applyRanges)="calculateNewRanges($event)"
                (updatedErrorsFromValue)="saveErrorsFromValue($event)"
                (updatedErrorsNameValue)="saveErrorsNameValue($event)"
            ></shared-config-color-picker>
        </div>
        <div class="mt-3 mb-3">
            <shared-config-color-picker
                [dimension]="'Availability'"
                [firstRange]="availabilityConfiguration.ranges[0]"
                [secondRange]="availabilityConfiguration.ranges[1]"
                [firstColorRange]="availabilityConfiguration.colors[0]"
                [secondColorRange]="availabilityConfiguration.colors[1]"
                [thirdColorRange]="availabilityConfiguration.colors[2]"
                (colorSelected)="saveColorPicker($event)"
                (applyRanges)="calculateNewRanges($event)"
            ></shared-config-color-picker>
        </div>
        <div class="mt-3">
            <shared-config-color-picker
                [dimension]="'LWDO'"
                [firstRange]="LWDOConfiguration.ranges[0]"
                [secondRange]="LWDOConfiguration.ranges[1]"
                [firstColorRange]="LWDOConfiguration.colors[0]"
                [secondColorRange]="LWDOConfiguration.colors[1]"
                [thirdColorRange]="LWDOConfiguration.colors[2]"
                (colorSelected)="saveColorPicker($event)"
                (applyRanges)="calculateNewRanges($event)"
            ></shared-config-color-picker>
        </div>
    </div>
</div>
