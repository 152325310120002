import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { shareReplay, catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Kpis } from '@app/shared/models/kpis';
import { Structure } from '@app/map/home/shared/structure';
import { AppLoadService } from '@app/app-load.service';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import lineStatusMapper from '@app/notifications/shared/mappers/line-status.mapper';
import { EquipmentStatusNotification } from '@app/notifications/shared/events/equipment-status';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import equipmentStatusMapper from '@app/notifications/shared/mappers/equipment-status.mapper';
import { VariableNotification, WarehouseVariablesStatusSupportedEvents } from '@app/notifications/shared/events/variable-status';
import { mapNotification } from '@app/notifications/shared/mappers/notification.mapper';

const { LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;

@Injectable()
export class LinesService {
    baseUrl: string;
    warehouse: string;

    constructor(
        private http: HttpClient,
        private appLoadService: AppLoadService,
    ) { }

    getLinesNames(): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res: any) => {
                if (res.warehouse && res.displayName) {
                    this.baseUrl = `${res.hostName}/api`.toLowerCase();
                    this.warehouse = res.warehouse;
                    const endpoint = environment.mode === 'front' ? 'map' : `map/${this.warehouse}/lines`;
                    return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
                }
            }),
            map((resp: any) => {
                if (environment.mode !== 'front') {
                    return resp;
                } else {
                    const lineNames: Array<string> = [];
                    resp.floors.forEach((floor) => {
                        floor.areas.forEach((area) => {
                            area.zones.forEach((zone) => {
                                zone.lines.forEach((line) => {
                                    lineNames.push(`${this.warehouse}-${floor.id}-${area.id}-${zone.id}-${line.id}`);
                                });
                            });
                        });
                    });
                    return lineNames;
                }
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar los nombres de lineas de equipos', err);
                return of(null);
            }),
            shareReplay(1, 1000),
        );
    }

    getLine(idFloor: string, idArea: string, idZone: string, idLine: string): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res: any) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint =
                    environment.mode === 'front'
                        ? 'map'
                        : `map/${this.warehouse}/definitions/floors/${idFloor}/areas/${idArea}/zones/${idZone}/lines/${idLine}`;
                return this.http.get<Structure>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp) => {
                if (environment.mode !== 'front') {
                    return resp;
                } else {
                    const floor = resp.floors.filter((f) => {
                        return String(f.id) === idFloor;
                    });
                    const area = floor[0].areas.filter((a) => {
                        return String(a.id) === idArea;
                    });
                    const zone = area[0].zones.filter((z) => {
                        return String(z.id) === idZone;
                    });
                    const line = zone[0].lines.filter((l) => {
                        return String(l.id) === idLine;
                    });
                    return line[0];
                }
            }),
            catchError((err) => {
                console.log(`Ha ocurrido un problema en el service al recuperar la linea de equipos -> ${err}`);
                return of(null);
            }),
            shareReplay(),
        );
    }

    getLineStatus(floorId: string, areaId?: string, zoneId?: string, lineId?: string): Observable<Array<LineStatusNotification | EquipmentStatusNotification>> {
        const mapper = !lineId ? lineStatusMapper : equipmentStatusMapper;
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                /* eslint-disable prettier/prettier */
                const endpoint =
                    environment.mode === 'front'
                        ? 'status'
                        : !!lineId
                            ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}`
                            : !!zoneId
                                ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}`
                                : !!areaId
                                    ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}`
                                    : `V2/state/${this.warehouse}/floors/${floorId}`;
                /* eslint-enable prettier/prettier */
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            map((states = []) => states.map(mapper)),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar el estado de la linea de equipos -> ${err}`);
                return of([]);
            }),
            shareReplay(),
        );
    }

    getLineStatusVariableLines(floorId: string, areaId?: string, zoneId?: string, lineId?: string): Observable<VariableNotification[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                /* eslint-disable prettier/prettier */
                const endpoint =
                    environment.mode === 'front'
                        ? 'status'
                        : !!lineId
                            ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}`
                            : !!zoneId
                                ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}`
                                : !!areaId
                                    ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}`
                                    : `V2/state/${this.warehouse}/floors/${floorId}`;
                /* eslint-enable prettier/prettier */
                return this.http.get<any>(`${this.baseUrl}/${endpoint}/variable-lines`);
            }),
            map((notifications) => {
                const mappedVariables: VariableNotification[] = notifications.map((notification) => {
                    notification.variableName = notification.variableName.split('.').pop().toString();
                    return mapNotification(WarehouseVariablesStatusSupportedEvents.WAREHOUSE_STATUS_VARIABLE_CHANGED, notification)
                });
                return mappedVariables;
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar el estado de la linea de equipos -> ${err}`);
                return of([]);
            }),
            shareReplay(),
        );
    }

    getLineKpis(idFloor: string, idArea: string, idZone: string, idLine: string): Observable<Kpis> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint =
                    environment.mode === 'front'
                        ? 'kpis'
                        : idLine && idLine !== ''
                            ? `kpis/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}/lines/${idLine}`
                            : idZone && idZone !== ''
                                ? `kpis/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}`
                                : idArea && idArea !== ''
                                    ? `kpis/${this.warehouse}/floors/${idFloor}/areas/${idArea}`
                                    : idFloor && idFloor !== ''
                                        ? `kpis/${this.warehouse}/floors/${idFloor}`
                                        : 'kpis';
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            // map((resp) => {
            //     return resp;
            // }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar los KPI's de la linea de equipos -> ${err}`);
                return of(null);
            }),
            shareReplay(),
        );
    }

    lineNotificationReceieved(notificacion: any): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = environment.mode === 'front' ? 'state' : `state/${this.warehouse}/notifications/notificationReceived`;
                return this.http.post<any>(`${this.baseUrl}/${endpoint}`, notificacion);
            }),
            map((resp) => {
                return resp;
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema al mandar la rececpción de una notificación de la linea -> ${err}`);
                return of(null);
            }),
            shareReplay(),
        );
    }

    getIcons(): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                if (res.warehouse && res.displayName) {
                    this.baseUrl = `${res.hostName}/api`.toLowerCase();
                    this.warehouse = res.warehouse;
                    const endpoint = environment.mode === 'front' ? 'icons' : `designtool/typologies/${this.warehouse}/icons`;
                    return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
                }
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar los nombres de lineas de equipos', err);
                return of(null);
            }),
            shareReplay(1, 1000),
        );
    }
}
