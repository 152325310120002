<div class="row datatables-header-filter first code-filter" [ngClass]="{ 'hide-me': globalFilter }">
    <div class="arrow"></div>
    <app-input #codeSearch key="code-search" label="Code" columns="12"></app-input>
</div>
<div class="row datatables-header-filter equipment-filter" [ngClass]="{ 'hide-me': globalFilter }">
    <div class="arrow"></div>
    <app-input #equipmentSearch key="equipment-search-alert-table-sm" label="Equipment" columns="12"></app-input>
</div>
<div class="row datatables-header-filter description-filter" [ngClass]="{ 'hide-me': globalFilter }">
    <div class="arrow"></div>
    <app-input #descriptionSearch key="description-search" label="Description" columns="12"></app-input>
</div>
<div class="row datatables-header-filter priority-filter" [ngClass]="{ 'hide-me': globalFilter }">
    <div class="arrow"></div>
    <app-select #prioritySearch key="priority-search" label="Type" [item$]="priority$" columns="12"></app-select>
</div>

<div class="row">
    <div class="col-12 position-relative" style="overflow-x: hidden">
        <shared-alerts-snapshot #tableAlertSnapshot [alertSnapshot]="alertSnapshot"></shared-alerts-snapshot>
        <ngx-datatable
            #table
            class="bootstrap alerts-table-shared"
            [rows]="itemsVariable"
            [limit]="15"
            [columnMode]="'flex'"
            [scrollbarV]="true"
            [virtualization]="false"
            [headerHeight]="50"
            [messages]="messages"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [rowClass]="getRowClass"
            [sorts]="[
                { prop: 'isCritical', dir: 'desc' },
                { prop: 'state', dir: 'asc' },
                { prop: 'alarmSourceTimeStamp', dir: 'desc' }
            ]"
            [selectionType]="'single'"
            [selected]="selectedRow"
        >
            <ngx-datatable-column name="alarmSourceTimeStamp" [sortable]="true" [flexGrow]="1">
                <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                    <span (click)="sort()">Date</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span class="force-one-line">{{ value | date: 'd/M H:mm' }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="equipmentId" [sortable]="true" [flexGrow]="5">
                <ng-template ngx-datatable-header-template let-sort="sortFn">
                    <i class="fas fa-filter" (click)="showFilter($event, 'equipment-filter')" [ngClass]="{ 'hide-me': globalFilter }"></i>
                    <span [ngClass]="{ 'ps-3': !globalFilter }" (click)="sort()">Equipment</span>
                    <span class="filter-applyed equipment-filter-resume" [ngClass]="{ 'hide-me': globalFilter }"></span>
                    <span class="ps-2 pe-3">|</span>
                    <div class="position-relative" style="display: inline; top: 0">
                        <i
                            class="fas fa-filter"
                            (click)="showFilter($event, 'description-filter')"
                            [ngClass]="{ 'hide-me': globalFilter }"
                            style="top: -4px; left: -9px"
                        ></i>
                        <span [ngClass]="{ 'ps-3': !globalFilter }">Description</span>
                        <span class="filter-applyed description-filter-resume" [ngClass]="{ 'hide-me': globalFilter }" style="bottom: -12px; left: 15px"></span>
                    </div>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span
                        class="force-one-line navigate"
                        [innerHTML]="row | fqdnName: fqdnMode"
                        [title]="getRawTitle(row | fqdnName: '' : true)"
                        (click)="navigateToLine($event, row)"
                    ></span>
                    <span class="force-one-line">
                        <span [innerHTML]="row.description | variableDescriptionPipe: 'FAILURE'"></span>
                        &nbsp;
                        <i class="fa fa-info-circle" title="Display alarm details" (click)="displayAlarmDetails(row)" [hidden]="globalFilter"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="description" [sortable]="true" [flexGrow]="4" *ngIf="desktopMode">
                <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                    <span (click)="sort()">{{ column.name }}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span class="force-one-line" [innerHTML]="value"></span>
                </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column
                name="priority"
                [sortable]="true"
                [flexGrow]="0.6"
                headerClass="text-center empty"
                cellClass="text-center"
                *ngIf="desktopMode"
            >
                <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                    <i class="fas fa-filter" (click)="showFilter($event, 'priority-filter')" [ngClass]="{ 'hide-me': globalFilter }"></i>
                    <span [ngClass]="{ 'ps-3': !globalFilter }" (click)="sort()">PR</span>
                    <span class="filter-applyed priority-filter-resume" [ngClass]="{ 'hide-me': globalFilter }"></span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span [innerHTML]="value"></span>
                </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="state" [sortable]="true" [flexGrow]="0" *ngIf="desktopMode">
                <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                    <span (click)="sort()"><i class="icon-accept header"></i></span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span class="state-wrapper" [ngSwitch]="value">
                        <i class="icon-alert_blocked" [title]="value" *ngSwitchCase="'maintenance_pending'"></i>
                        <i class="icon-alert_blocked" [title]="value" *ngSwitchCase="'maintenance_blocked'"></i>
                        <i class="icon-alert_working" [title]="value" *ngSwitchCase="'maintenance_processing'"></i>
                        <i class="icon-alert_confirmation" [title]="value" *ngSwitchCase="'maintenance_finished'"></i>
                        <i class="icon-accept" [title]="value" *ngSwitchCase="'resolved'"></i>
                        <i class="icon-alert_active" [title]="value" *ngSwitchDefault></i>
                    </span>
                </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-footer>
                <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-curPage="curPage"
                    let-offset="offset"
                >
                    <div class="d-none d-lg-inline col-lg-3 pe-0">
                        <strong>{{ rowCount | number }}</strong> alerts
                    </div>
                    <div class="col-12 col-lg-6 px-0">
                        <datatable-pager
                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                            [pagerRightArrowIcon]="'datatable-icon-right'"
                            [pagerPreviousIcon]="'datatable-icon-prev'"
                            [pagerNextIcon]="'datatable-icon-skip'"
                            [page]="curPage"
                            [size]="pageSize"
                            [count]="rowCount"
                            [hidden]="!(rowCount / pageSize > 1)"
                            (change)="table.onFooterPage($event)"
                        >
                        </datatable-pager>
                    </div>
                    <div class="d-none d-lg-inline col-lg-3 pe-0">
                        <button class="button-excel" tabindex="0" [title]="group + '_Alerts'" (click)="generateCSV($event, [])">
                            <i class="icon-download"></i>Download
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div>
