<div class="row mt-4 mb-3 justify-content-center">
    <div class="col-12 col-md-8 col-lg-6">
        <h5>IDENTIFIERS</h5>
        <div class="row mt-3">
            <app-checkbox
                [key]="'Floor'"
                #floorCheck
                [label]="'Floor'"
                columns="3"
                extraClasses="ps-0 pe-0"
                (clicked)="handleCheckBoxes('Floor')"
            ></app-checkbox>
            <app-checkbox [key]="'Area'" #areaCheck [label]="'Area'" columns="3" extraClasses="ps-0" (clicked)="handleCheckBoxes('Area')"></app-checkbox>
        </div>
        <div class="row mt-3">
            <app-checkbox [key]="'Zone'" #zoneCheck [label]="'Zone'" columns="3" extraClasses="ps-0 pe-0" (clicked)="handleCheckBoxes('Zone')"></app-checkbox>
            <app-checkbox [key]="'Line'" #lineCheck [label]="'Line'" columns="3" extraClasses="ps-0" (clicked)="handleCheckBoxes('Line')"></app-checkbox>
        </div>
        <div class="mt-3"><strong>Preview</strong></div>
        <div>
            <span [ngClass]="{ 'coloring-text': isColoringTextFloor }">{{ floorIdentifierValue | fazlDecorator: [['F', identifiers.Floor], [], [], []] }}</span>
            -
            <span [ngClass]="{ 'coloring-text': isColoringTextArea }">{{ areaIdentifierValue | fazlDecorator: [[], ['A', identifiers.Area], [], []] }}</span>
            -
            <span [ngClass]="{ 'coloring-text': isColoringTextZone }">{{ zoneIdentifierValue | fazlDecorator: [[], [], ['Z', identifiers.Zone], []] }}</span>
            -
            <span [ngClass]="{ 'coloring-text': isColoringTextLine }">{{ lineIdentifierValue | fazlDecorator: [[], [], [], ['EQL', identifiers.Line]] }}</span>
        </div>
    </div>
</div>
