import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';

const FIRST_DEFAULT_COLOR = '#2da45d';
const SECOND_DEFAULT_COLOR = '#fe840e';
const THIRD_DEFAULT_COLOR = '#ff2800';

const MIN_DEFAULT_RANGE = 20;
const ERRORS_MIDDLE_DEFAULT_RANGE = 60;
const MAX_DEFAULT_RANGE = 80;

const FIRST_DEFAULT_ERRORS_NAME = 'Range 1';
const SECOND_DEFAULT_ERRORS_NAME = 'Range 2';
const THIRD_DEFAULT_ERRORS_NAME = 'Range 3';

@Component({
    selector: 'settings-heatmap',
    templateUrl: './settings-heatmap.component.html',
    styleUrls: ['./settings-heatmap.component.scss'],
})
export class SettingsHeatMapComponent implements OnChanges {
    @Input({ required: true }) currentHeatMap;
    @Output() newHeatMap = new EventEmitter();

    runningConfiguration = { ranges: [], colors: [] };
    stoppedConfiguration = { ranges: [], colors: [] };
    onErrorConfiguration = { ranges: [], colors: [] };
    errorsConfiguration = { ranges: [], colors: [], names: [] };
    availabilityConfiguration = { ranges: [], colors: [] };
    LWDOConfiguration = { ranges: [], colors: [] };

    currentDimension: string;
    firstRange = MIN_DEFAULT_RANGE;
    errorsMiddleRange = ERRORS_MIDDLE_DEFAULT_RANGE;
    secondRange = MAX_DEFAULT_RANGE;
    firstErrorsName = FIRST_DEFAULT_ERRORS_NAME;
    secondErrorsName = SECOND_DEFAULT_ERRORS_NAME;
    thirdErrorsName = THIRD_DEFAULT_ERRORS_NAME;
    firstColorRange = FIRST_DEFAULT_COLOR;
    secondColorRange = SECOND_DEFAULT_COLOR;
    thirdColorRange = THIRD_DEFAULT_COLOR;

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentHeatMap?.currentValue) {
            const current = changes.currentHeatMap?.currentValue

            this.runningConfiguration.ranges = current?.Running?.ranges || [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
            this.runningConfiguration.colors = current?.Running?.colors || [
                FIRST_DEFAULT_COLOR,
                SECOND_DEFAULT_COLOR,
                THIRD_DEFAULT_COLOR,
            ];
            this.stoppedConfiguration.ranges = current?.Stopped?.ranges || [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
            this.stoppedConfiguration.colors = current?.Stopped?.colors || [
                THIRD_DEFAULT_COLOR,
                SECOND_DEFAULT_COLOR,
                FIRST_DEFAULT_COLOR,
            ];
            this.onErrorConfiguration.ranges =
                current && current['On error']?.ranges
                    ? current['On error'].ranges
                    : [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
            this.onErrorConfiguration.colors =
                current && current['On error']?.colors
                    ? current['On error'].colors
                    : [THIRD_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, FIRST_DEFAULT_COLOR];
            this.errorsConfiguration.ranges = current?.Errors?.ranges || [
                MIN_DEFAULT_RANGE,
                ERRORS_MIDDLE_DEFAULT_RANGE,
                MAX_DEFAULT_RANGE,
            ];
            this.errorsConfiguration.colors = current?.Errors?.colors || [
                FIRST_DEFAULT_COLOR,
                SECOND_DEFAULT_COLOR,
                THIRD_DEFAULT_COLOR,
            ];
            this.errorsConfiguration.names = current?.Errors?.names || [
                FIRST_DEFAULT_ERRORS_NAME,
                SECOND_DEFAULT_ERRORS_NAME,
                THIRD_DEFAULT_ERRORS_NAME,
            ];

            this.availabilityConfiguration.ranges =
                current && current['No error']?.ranges
                    ? current['No error'].ranges
                    : [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
            this.availabilityConfiguration.colors =
                current && current['No error']?.colors
                    ? current['No error'].colors
                    : [FIRST_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, THIRD_DEFAULT_COLOR];
            this.LWDOConfiguration.ranges =
                current && current['LWDO']?.ranges
                    ? current['LWDO'].ranges
                    : [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE];
            this.LWDOConfiguration.colors =
                current && current['LWDO']?.colors
                    ? current['LWDO'].colors
                    : [FIRST_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, THIRD_DEFAULT_COLOR];
        }
    }

    calculateNewRanges(event) {
        if (event[1].minVal && event[1].maxVal) {
            if (Number.isInteger(event.minVal)) this.firstRange = event.minVal;
            if (Number.isInteger(event.maxVal)) this.secondRange = event.maxVal;
            this.currentDimension = event[0];
            this.firstRange = event[1].minVal;
            this.secondRange = event[1].maxVal;

            this.currentHeatMap = {
                ...this.currentHeatMap,
                [this.currentDimension]: {
                    ranges: [this.firstRange, this.secondRange],
                    colors: [
                        ...(this.currentHeatMap[this.currentDimension]?.colors || [
                            this.firstColorRange,
                            this.secondColorRange,
                            this.thirdColorRange,
                        ]),
                    ],
                },
            }
            this.updateHeatMap()
        }
    }

    saveColorPicker(event) {
        this.currentDimension = event[0];
        switch (event[1]) {
            case 'firstColorRange':
                this.firstColorRange = event[2];
                [, this.secondColorRange, this.thirdColorRange] = this.checkCurrentColors(this.currentDimension);
                break;
            case 'secondColorRange':
                this.secondColorRange = event[2];
                [this.firstColorRange, , this.thirdColorRange] = this.checkCurrentColors(this.currentDimension);
                break;
            case 'thirdColorRange':
                this.thirdColorRange = event[2];
                [this.firstColorRange, this.secondColorRange, ,] = this.checkCurrentColors(this.currentDimension);
                break;
        }
        if (this.currentDimension !== 'Errors') {
            this.currentHeatMap = {
                ...this.currentHeatMap,
                [this.currentDimension]: {
                    ranges: [...(this.currentHeatMap[this.currentDimension]?.ranges || [MIN_DEFAULT_RANGE, MAX_DEFAULT_RANGE])],
                    colors: [this.firstColorRange, this.secondColorRange, this.thirdColorRange],
                },
            }
        } else {
            if (this.currentHeatMap.Errors?.colors) {
                this.currentHeatMap.Errors.colors[0] = this.firstColorRange;
                this.currentHeatMap.Errors.colors[1] = this.secondColorRange;
                this.currentHeatMap.Errors.colors[2] = this.thirdColorRange;
            } else {
                this.currentHeatMap = {
                    ...this.currentHeatMap,
                    Errors: {
                        ...this.currentHeatMap.Errors,
                        colors: [this.firstColorRange, this.secondColorRange, this.thirdColorRange],
                    },
                }
            }
        }
        this.updateHeatMap()
    }

    saveErrorsFromValue({ rangeId, newValue }) {
        switch (rangeId) {
            case '0':
                this.firstRange = +newValue;
                this.errorsConfiguration.ranges[0] = this.firstRange;
                if (this.currentHeatMap.Errors?.ranges) {
                    this.currentHeatMap.Errors.ranges[0] = this.firstRange;
                }
                break;
            case '1':
                this.errorsMiddleRange = +newValue;
                this.errorsConfiguration.ranges[1] = this.errorsMiddleRange;
                if (this.currentHeatMap.Errors?.ranges) {
                    this.currentHeatMap.Errors.ranges[1] = this.errorsMiddleRange;
                }
                break;
            case '2':
                this.secondRange = +newValue;
                this.errorsConfiguration.ranges[2] = this.secondRange;
                if (this.currentHeatMap.Errors?.ranges) {
                    this.currentHeatMap.Errors.ranges[2] = this.secondRange;
                }
        }
        if (!this.currentHeatMap.Errors?.ranges) {
            this.currentHeatMap = {
                ...this.currentHeatMap,
                Errors: {
                    ...this.currentHeatMap.Errors,
                    ranges: [this.firstRange, this.errorsMiddleRange, this.secondRange],
                },
            }
        }
        this.updateHeatMap()
    }

    saveErrorsNameValue({ rangeId, newValue }) {
        switch (rangeId) {
            case '0':
                this.firstErrorsName = newValue;
                this.errorsConfiguration.names[0] = this.firstErrorsName;
                if (this.currentHeatMap.Errors?.names) {
                    this.currentHeatMap.Errors.names[0] = this.firstErrorsName;
                }
                break;
            case '1':
                this.secondErrorsName = newValue;
                this.errorsConfiguration.names[1] = this.secondErrorsName;
                if (this.currentHeatMap.Errors?.names) {
                    this.currentHeatMap.Errors.names[1] = this.secondErrorsName;
                }
                break;
            case '2':
                this.thirdErrorsName = newValue;
                this.errorsConfiguration.names[2] = this.thirdErrorsName;
                if (this.currentHeatMap.Errors?.names) {
                    this.currentHeatMap.Errors.names[2] = this.thirdErrorsName;
                }
        }
        if (!this.currentHeatMap.Errors?.names) {
            this.currentHeatMap = {
                ...this.currentHeatMap,
                Errors: {
                    ...this.currentHeatMap.Errors,
                    names: [this.firstErrorsName, this.secondErrorsName, this.thirdErrorsName],
                },
            }
        }
        this.updateHeatMap()
    }

    checkCurrentColors(currentDimension: string): string[] {
        if (this.currentHeatMap[currentDimension]?.colors) {
            return this.currentHeatMap[currentDimension].colors;
        } else {
            return [FIRST_DEFAULT_COLOR, SECOND_DEFAULT_COLOR, THIRD_DEFAULT_COLOR];
        }
    }


    updateHeatMap() {
        this.newHeatMap.emit({
            heatMap: this.currentHeatMap
        });
    }
}
