import { Component, OnInit, OnChanges, OnDestroy, Input, Output, ViewChild, ElementRef, EventEmitter, SimpleChanges, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EquipmentStatusNotification } from '@app/notifications/shared/events/equipment-status';
import { MapStates } from '@app/shared/models/map-state';
import { AppLoadService } from '@app/app-load.service';
import { Line } from '@app/map/home/shared/structure';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { Level, Metadata, VARIABLE_EMPTY_REQUEST_ID, VariableFilter, VariableFilters, VariableNotification, WILD_CARD_CHARACTER } from '@app/notifications/shared/events/variable-status';
import { VariableColors, isActiveVariable, isRunVariable } from '@variables/shared/variable';
import { isInViewport, minViewBoxSizeToHaveZoom, createPathUrl, createEquipmentUrl/*, createArrow*/ } from '@app/map/shared/svg-functions';
import { ImageSecurePipe } from '@app/shared/pipes/image-secure.pipe';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { StateService } from '@app/core/shared/state/state.service';
import { VariablesActiveStatusService } from '@app/notifications/shared/handlers/variablesActive-status-service';
import { VariablesStatusClient } from '@app/notifications/shared/clients/variables-status.client';
import { TextColorPipe } from '@app/shared/pipes/text-color.pipe';
import { getEventTS } from '@app/notifications/shared/mappers/notification.mapper';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { LinesService } from '@app/map/lines/shared/lines.service';
import { VariablesService } from '@variables/shared/variables.service';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { v4 as uuid } from 'uuid';
import * as Svg from '@svgdotjs/svg.js';
import * as _ from 'lodash-es';
import '@svgdotjs/svg.panzoom.js';

const { EQUIPMENT_STATE_CHANGED } = WarehouseStatusSupportedEvents;

const {
    unknown: UNKNOWN,
    alert: ALERT,
    alert_low: ALERT_LOW,
    box: BOX,
    critical: CRITICAL,
    disconnected: DISCONNECTED,
    run: RUN,
    warning: WARNING,
    without_permission: LWDO,
} = MapStates;

@Component({
    selector: 'app-lines-map',
    templateUrl: './lines-map.component.html',
    styleUrls: ['./lines-map.component.scss'],
    providers: [VariablesActiveStatusService, VariablesStatusClient, TextColorPipe],
})
export class LinesMapComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() line: Line;
    @Input() markedEquipment: any;
    @Input() icons: any;
    @Input() alternativeIds?: any;
    @Input() warehouse: string;
    @Input() visibleStates: Array<string>;
    @Input() equipmentChange$: Observable<EquipmentStatusNotification>;
    @Input() tabState: VisibilityStates;
    @Input() openVariableModal: boolean = false;
    @Output() filterTableAlarms = new EventEmitter();
    @Output() visibleEquipment = new EventEmitter();
    @ViewChild('svg', { read: ElementRef, static: true }) svg;
    @ViewChild('svg2', { read: ElementRef, static: true }) svg2;
    @ViewChild('buttons', { read: ElementRef, static: false }) buttons;
    $svg: JQuery;
    $svg2: JQuery;
    $buttons: JQuery;
    alertsType = [ALERT, 'maintenance_pending', 'maintenance_processing', 'maintenance_blocked', 'maintenance_finished', 'resolved'];
    warehouseId = '';
    floorId = '0';
    areaId = '0';
    zoneId = '0';
    lineId = '0';
    setViewBox: string;
    liteView: boolean;
    iconNames = [];
    iconsContent = [];
    timer = null;
    delay = 300;
    prevent = false;
    equipmentSelected: string = null;
    lineSVG: any;
    currentZoom = 1;
    reduceZoom = 0;
    viewZoom = false;
    viewFullSize = false;
    firstRender = true;
    equipmentFromUrl: string = null;

    groupName: string;

    isLoadingLineView = false;
    setViewBoxSVG2 = '0 0 600 600';
    backgroundImage: string | SafeUrl = '';
    metadata: Metadata;
    currentRequestId: string;
    //equipmentVariables: Array<any> = [];
    subscribed = false;
    viewLineMap = true; //or photo (last level picture)
    haveLinePhoto = false;
    linesDataModalOpen = false;
    viewLineIconsText = false;

    variableColorsConfiguration: VariableColors[] = [];

    openLineDataModal: () => void;
    closeLineDataModal: () => void;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private appLoadService: AppLoadService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private imageSecurePipe: ImageSecurePipe,
        private equipmentsService: EquipmentsService,
        //private pageLifeCycleService: PageLifecycleService,
        private variablesActiveStatusService: VariablesActiveStatusService,
        private stateService: StateService,
        private variablesStatusClient: VariablesStatusClient,
        private lineService: LinesService,
        private textColorPipe: TextColorPipe,
        private variablesService: VariablesService,
        private warehouseStatusService: WarehouseStatusService
    ) {
        const pattern = /^\/warehouse\/.{1,}\/floor\/.{1,}\/area\/.{1,}\/zone\/.{1,}\/line\/.{1,};equipment=.{1,}$/;
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (pattern.test(event.url)) {
                    this.equipmentFromUrl = event.url.split(';equipment=')[1];
                    $(() => this.setEquipmentFromUrl());
                }
            }
        });
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.visibleStates?.currentValue || changes.equipmentChange$?.currentValue) {
            this.highlightMarkedEquipment();
            await this.startRealtimeNotifications();
            if (this.$svg2 && this.viewLineMap && changes.visibleStates?.currentValue) {
                this.loadVariableRT();
            }
        }
    }

    ngOnInit(): void {
        // ONLY CONNECT if has and want to view variables
        // this.variablesStatusClient.connectToHub().then(() => {
        //     console.warn('Connected to LineMap Hub');
        // });

        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
            if (params.floorId && params.areaId && params.zoneId) {
                this.warehouseId = params.id;
                this.floorId = params.floorId;
                this.areaId = params.areaId;
                this.zoneId = params.zoneId;
                this.lineId = params.lineId;
            } else if (this.alternativeIds) {
                this.warehouseId = this.alternativeIds.id || this.warehouseId;
                this.floorId = this.alternativeIds.floorId;
                this.areaId = this.alternativeIds.areaId;
                this.zoneId = this.alternativeIds.zoneId;
                this.lineId = this.alternativeIds.lineId;
            }

            this.groupName = `${this.warehouseId}-${this.floorId}-${this.areaId}-${this.zoneId}-${this.lineId}`;
        });

        this.$svg = $(this.svg.nativeElement);
        //this.$buttons = $(this.buttons.nativeElement);
        this.iconNames = this.icons.map((icon) => icon.name);
        this.iconsContent = this.icons.map((icon) => {
            return { name: icon.name, data: icon.content };
        });
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.liteView = this.line.viewBox ? res.liteMode : true;
        });
        this.setViewBox = this.line.viewBox || '';
        $(() => {
            $('.my_rect').tooltip({
                container: 'body',
                placement: 'top',
            });
        });
    }

    ngAfterViewInit() {
        if (this.buttons) this.$buttons = $(this.buttons.nativeElement);

        const [left, top, right, bottom] = this.setViewBox?.split(' ');
        this.viewZoom = Math.max(minViewBoxSizeToHaveZoom, +right || 0, +bottom || 0) !== minViewBoxSizeToHaveZoom;
        if (this.viewZoom) {
            this.lineSVG = Svg.adopt(this.svg.nativeElement);
            // Added setTimeout to allow the svg to be fully loaded
            // Error: Impossible to get absolute width and height. Please provide an absolute width and height attribute on the zooming element
            setTimeout(() => (this.currentZoom = this.lineSVG.zoom()));

            this.reduceZoom = +bottom >= 32 ? -1 : +bottom >= 25 ? 0 : +bottom >= 20 ? 1 : +bottom >= 15 ? 2 : 3;

            this.lineSVG.panZoom({ zoomMax: 100, zoomMin: this.currentZoom, zoomFactor: 0.5 });
        }

        this.openLineDataModal = () => {
            this.linesDataModalOpen = true;
        }

        this.closeLineDataModal = () => {
            this.linesDataModalOpen = false;
            if (this.viewLineMap) {
                this.handleReconnection();
            }
        }

        const myOffcanvasEquipments = document.getElementById('offcanvasEquipments');
        myOffcanvasEquipments.addEventListener('show.bs.offcanvas', this.openLineDataModal);
        myOffcanvasEquipments.addEventListener('hide.bs.offcanvas', this.closeLineDataModal);

        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     if (
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.variablesActiveStatusService.isConnected() &&
        //         this.viewLineMap
        //     ) {
        //         this.handleReconnection();
        //     }
        // });

        this.appLoadService.getGlobalConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.variableColorsConfiguration = res['variableColors'] ?? [];
        });

        this.showLineView();

        if (this.openVariableModal) {
            setTimeout(() => {
                if (!this.equipmentFromUrl) this.equipmentFromUrl = this.markedEquipment;
                if (this.equipmentFromUrl) this.setEquipmentFromUrl();
            }, 500);
        }
    }

    highlightMarkedEquipment() {
        setTimeout(() => {
            if (this.markedEquipment && this.$svg && !this.liteView) {
                const el = this.$svg.find(`rect[data-key*="${this.markedEquipment}-"]`);
                el.addClass('markedEquipment');
                if (this.firstRender) {
                    el.tooltip({
                        placement: 'top',
                        trigger: 'hover',
                    });
                    el.tooltip('show');
                    setTimeout(() => el.tooltip('hide'), 5000);
                    this.firstRender = false;
                }
            } else if (this.markedEquipment && this.liteView) {
                const equipment = document.querySelector(`div[data-key*="${this.markedEquipment}-"]`);
                equipment.parentElement.classList.add('liteMarkedEquipment');
            }
        }, 100);
    }
    setEquipmentFromUrl() {
        if (this.equipmentFromUrl && this.line) {
            const equipment = this.line.equipments.filter((eq) => eq.id === this.equipmentFromUrl)[0];
            setTimeout(() => {
                this.appLoadService.setEquipmentVariables({ equipment: equipment.id, type: equipment.type });
            }, 1000);
        }
    }

    async ngOnDestroy(): Promise<void> {
        this.subscribed = false;

        this.currentRequestId = 'stop-processing-notifications';
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        if (this.variablesStatusClient.connection) {
            this.variablesStatusClient.disconnectFromHub();
        }

        const myOffcanvasEquipments = document.getElementById('offcanvasEquipments');
        myOffcanvasEquipments.removeEventListener('show.bs.offcanvas', this.openLineDataModal);
        myOffcanvasEquipments.removeEventListener('hide.bs.offcanvas', this.closeLineDataModal);

        this.ngUnsubscribe?.next(true);
        this.ngUnsubscribe?.complete();
        this.$svg?.remove();
        this.$svg2?.remove();
        $('.my_rect').tooltip('dispose');
        this.$svg = null;
        this.$svg2 = null;
        this.$buttons = null;
        this.iconNames = null;
        this.iconsContent = null;
    }

    async startRealtimeNotifications() {
        try {
            if (!this.equipmentChange$) return;
            this.warehouseStatusService.clearNotifications(this.groupName, EQUIPMENT_STATE_CHANGED,);

            //NotificationsMap.clearNotificationState('WarehouseStatusService', this.groupName, EQUIPMENT_STATE_CHANGED);
            this.cleanLineMapStates();
            this.equipmentChange$.pipe(
                takeUntil(this.ngUnsubscribe)
            ).subscribe({
                next: (notification) => {
                    const notificationTS = getEventTS(EQUIPMENT_STATE_CHANGED, notification);
                    if (
                        //NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, EQUIPMENT_STATE_CHANGED, notificationTS, notification)
                        this.warehouseStatusService.isNewer(this.groupName, EQUIPMENT_STATE_CHANGED, notification, notificationTS)
                    ) {
                        this.changeEquipmentStatus(notification)
                    }
                }

            });

            this.lineService
                .getLineStatus(this.floorId, this.areaId, this.zoneId, this.lineId)
                .pipe(take(1))
                .subscribe((lineStates: Array<EquipmentStatusNotification>) => {
                    lineStates.forEach((notification) => {
                        const notificationTS = getEventTS(EQUIPMENT_STATE_CHANGED, notification);
                        if (
                            //NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, EQUIPMENT_STATE_CHANGED, notificationTS, notification)
                            this.warehouseStatusService.isNewer(this.groupName, EQUIPMENT_STATE_CHANGED, notification, notificationTS)
                        ) {
                            this.changeEquipmentStatus(notification);
                        }
                    });
                });
        } catch (error) {
            console.error(error);
        }
    }

    toogleFullScreen(): void {
        this.viewFullSize = !this.viewFullSize;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.viewFullSize ? $('html').addClass('no-scroll') : $('html').removeClass('no-scroll');
    }

    createPathUrl(device) {
        return createPathUrl(device);
    }

    createEquipmentUrl(device) {
        return createEquipmentUrl(device, this.iconNames);
    }

    // createArrow(data) {
    //     return createArrow(data);
    // }

    changeEquipmentStatus(equipment: EquipmentStatusNotification) {
        if (this.floorId === equipment.floorId && this.areaId === equipment.areaId && this.zoneId === equipment.zoneId && this.lineId === equipment.lineId) {
            if (this.tabState === VisibilityStates.active) {
                requestAnimationFrame(() => this.setColorEquipment(equipment, this.visibleStates));
            } else {
                this.setColorEquipment(equipment, this.visibleStates);
            }
        } else if (localStorage.getItem('showWrongNotifications') === 'true') {
            console.warn('Notification received for wrong fqn', {
                contextFloor: this.floorId,
                contextArea: this.areaId,
                contextZone: this.zoneId,
                contextLine: this.line.id,
                notificationFloor: equipment.floorId,
                notificationArea: equipment.areaId,
                notificationZone: equipment.zoneId,
                notificationLine: equipment.lineId,
            });
        }
    }

    cleanLineMapStates() {
        if (!this.$svg) return;
        this.line.equipments.forEach((equipment) => {
            const type = equipment.type.toLowerCase();
            const equipmentKey = `${equipment.id}-${type}`;
            const el = this.$svg.find(`[data-key="${equipmentKey}"]`);
            el.removeClass(
                [
                    ALERT,
                    ALERT_LOW,
                    BOX,
                    CRITICAL,
                    DISCONNECTED,
                    RUN,
                    WARNING,
                    LWDO,
                ].join(' '),
            ).removeData('state');
            if (this.$buttons) {
                const button = this.$buttons.find(`[data-key="${equipmentKey}"]`);
                button
                    .removeClass(
                        [
                            ALERT,
                            ALERT_LOW,
                            BOX,
                            CRITICAL,
                            DISCONNECTED,
                            RUN,
                            WARNING,
                            LWDO,
                        ].join(' '),
                    )
                    .removeData('state');
            }
        });
    }

    setColorEquipment(equipment: EquipmentStatusNotification, visibleStates: Array<string>) {
        if (!this.$svg) return;
        const type = equipment.equipmentType.toLowerCase();
        const equipmentKey = `${equipment.equipmentId}-${type}`;
        const state = equipment.state.filter((s) => s === UNKNOWN || this.visibleStates?.includes(s)).join(' ');
        const stateList = state.split(' ');

        // Get the svg/buttons which state will be changed
        const equipmentBox = this.getEquipmentElements()?.find(`[data-key="${equipmentKey}"]`);
        const lineEquipments = this.isSvg() ? this.$svg?.find('.my_piece') : this.$buttons.find('[data-key]');
        const equipmentBoxWrapper = this.isSvg() ? this.$svg?.find(`[data-key="${equipmentKey}-wrapper"]`) : null;

        // Set current state of the equipment so it can be checked by other equipments in the line
        equipmentBox.data('state', state);

        // Get the state of the other equipments in the line
        const elementsByState = this.countElementsByState(this.visibleStates);

        // Clear the state applied to the equipment
        equipmentBox.removeClass();

        /* 
        The rules applied to the equipment are:
            - If the equipment state has disconnected, all equipments in the line must be shown as disconnected
            - If the equipment state has alert, alert_low or critical, only the equipment must be shown with its alert level
              plus other states such as box or warning
            - If the equipment state has lwdo, all the equipments in the line must be shown as lwdo plus other states such as box or warning
            - If the equipment state has run, all the equipments in the line must be shown as run plus other states such as box or warning
            - If the equipment state has warning, only the equipment must be shown with the secondary state warning
            - If the equipment state has box, only the equipment wrapper must be shown with box dots
            - If there isn't any state that matches this rules, the equipment must be shown as unknown by default

        The states are categorized in the following order:
            - Main equipment states: alert, alert_low, critical
            - Main line states: lwdo, run
            - Secondary equipment states: box, warning
        */
        //    debugger
        if (stateList.includes(DISCONNECTED) || elementsByState[DISCONNECTED] > 0) {
            // There is a PLC_NOT_COMM alert in the line, the equipment must be shown as disconnected
            equipmentBox.addClass(`${this.getEquipmentBaseClass()} ${DISCONNECTED}`);

        } else if ([ALERT, ALERT_LOW, CRITICAL].some((s) => stateList.includes(s))) {
            // The equipment has an alert. As a main state at equipment level, the state shown must override next states
            equipmentBox.addClass(`${this.getEquipmentBaseClass()} ${state}`);

        } else if (elementsByState[LWDO] > 0 || elementsByState[RUN] > 0) {
            // As line state, the 
            const lineState = elementsByState[LWDO] > 0 ? LWDO : RUN;
            equipmentBox.addClass(`${this.getEquipmentBaseClass()} ${lineState} ${state}`);
            this.setLineEquipmentsState(lineEquipments, equipmentKey, this.getEquipmentBaseClass(), lineState);

        } else {
            // Default status is unknown
            equipmentBox.addClass(`${this.getEquipmentBaseClass()} ${UNKNOWN} ${state}`);
            this.setLineEquipmentsState(lineEquipments, equipmentKey, this.getEquipmentBaseClass(), UNKNOWN);
        }

        // Box state must be applied separately from the other states
        // as affects only to the equipment wrapper for SVGs
        if (!!equipmentBoxWrapper) {
            if (state.includes(BOX)) {
                equipmentBoxWrapper.removeClass().addClass(`my_rect ${BOX}`);
            } else if (!state.includes(BOX)){
                equipmentBoxWrapper.removeClass(`${BOX}`);
            }
        }

        // Add tooltip showing equipmentId, equipmentType and state
        const equipmentTooltip = `${equipment.equipmentId} <small class="tooltip-equipment-type">${equipment.equipmentType}</small>`;
        const tooltipContent = equipment.variableName ? `${equipmentTooltip}<br><i>${equipment.variableName}</i>` : equipmentTooltip;
        equipmentBoxWrapper?.find(`[data-key="${equipmentKey}-wrapper"]`).attr('data-original-title', tooltipContent);
    }

    private countElementsByState(visibleStates: Array<string>) {
        let elementStates = {};
        const elements = this.getEquipmentElements();
        elements?.find('[data-state]').each(function (index) {
            $(this).data('state').split(' ').forEach((state) => {
                if (visibleStates.includes(state)) {
                    elementStates = {
                        ...elementStates,
                        [state]: (elementStates[state] || 0) + 1,
                    };
                }
            });
        });
        return elementStates;
    }

    isSvg() {
        return !this.$buttons;
    }
    getEquipmentElements() {
        return this.isSvg() ? this.$svg : this.$buttons;
    }

    getEquipmentBaseClass() {
        return this.isSvg() ? 'my_piece' : 'btn btn-default';
    }

    setLineEquipmentsState(
        lineEquipments: JQuery<HTMLElement>,
        currentEquipmentKey: string,
        baseClass: string, // Svg or button class
        lineState: string, // Must be lwdo or run
    ) {
        lineEquipments.each(function (index) {
            const equipmentKey = $(this).data('key');
            const equipmentState = $(this).data('state').split(' ').filter((s) => s !== lineState).join(' ');
            if (currentEquipmentKey !== equipmentKey && !(equipmentState.includes(ALERT) || equipmentState.includes(ALERT_LOW) || equipmentState.includes(CRITICAL))) {
                $(this).removeClass().addClass(`${baseClass} ${lineState} ${equipmentState}`);
            }
        });
    }

    filterAlarms(event, equipment, type) {
        if (event) {
            event.stopPropagation();
        }
        this.timer = setTimeout(() => {
            if (!this.prevent && this.$svg) {
                if (event) {
                    this.$svg?.find('.my_piece').addClass('disabled');
                    const key = $(event.target).data('key').replace('-wrapper', '');
                    this.$svg?.find('.my_piece').filter(`[data-key=${key}]`).removeClass('disabled');
                } else {
                    this.$svg?.find('.my_piece').removeClass('disabled');
                }
                this.filterTableAlarms.emit({ equipment, type });
            }
            this.prevent = false;
        }, this.delay);
    }

    displayVariables(event, equipment, type) {
        if (!this.$svg) return;
        if (event && this.equipmentSelected !== `${equipment}-${type}`) {
            event.stopPropagation();
            clearTimeout(this.timer);
            this.prevent = true;
            this.equipmentSelected = `${equipment}-${type}`;
            this.$svg?.find('.my_piece').addClass('disabled');
            const key = $(event.target).data('key').replace('-wrapper', '');
            this.$svg?.find('.my_piece').filter(`[data-key=${key}]`).removeClass('disabled');
            this.appLoadService.setEquipmentVariables({ equipment, type });
        } else {
            this.$svg.find('.my_piece').removeClass('disabled');
            this.equipmentSelected = null;
            this.appLoadService.setEquipmentVariables({ equipment: null, type: null });
        }
    }

    displayVariablesFromLineView(event, equipment) {
        if (!this.$svg2) return;
        const eq = equipment.split('.')[0];
        if (event && this.equipmentSelected !== `${eq}`) {
            event.stopPropagation();
            this.prevent = true;
            this.equipmentSelected = `${eq}`;
            this.appLoadService.setEquipmentVariables({ equipment: eq });
        } else {
            this.equipmentSelected = null;
            this.appLoadService.setEquipmentVariables({ equipment: null, type: null });
        }
    }

    sanitizeIcon(icon) {
        return this.sanitizer.bypassSecurityTrustHtml(icon);
    }

    showLineView() {
        this.isLoadingLineView = true;
        this.viewLineMap = false;
        this.haveLinePhoto = false;

        this.backgroundImage = '';
        this.metadata = null;

        this.$svg2 = $(this.svg2.nativeElement);

        const level = Level.Line;
        //console.warn('Metada photo is disabled, restore for dev/pre/pro environment');
        this.equipmentsService
            .getEquipmentPhotoAndMetadata(this.lineId, level)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (res) => {
                    if (res && res['metadata']) {
                        this.appLoadService.getCurrentWarehouse.subscribe((current) => {
                            const baseUrl = `${current.hostName}/api`.toLowerCase();
                            const completeUrl = `${baseUrl}/designtool/ar-images/${level}/${this.warehouse}/typologies/${this.lineId
                                }/image?${new Date().getTime()}`;
                            this.imageSecurePipe.transform(completeUrl, false).subscribe((blobImage) => {
                                this.backgroundImage = blobImage;
                            });
                        });
                        this.metadata = JSON.parse(res['metadata']);

                        this.viewLineMap = true;
                        this.haveLinePhoto = true;

                        this.loadLineStateVariables();
                        this.loadVariableRT();
                    }
                    this.isLoadingLineView = false;
                },
                error: (err) => {
                    console.warn(`Error subscribing Photo and Metadata ${err}`);
                    this.isLoadingLineView = false;
                },
            });
    }

    loadVariableRT() {
        // ONLY CONNECT if has and want to view variables
        if (!this.variablesStatusClient.connection) {
            this.variablesStatusClient.connectToHub().then(() => {
                console.warn(`Connected to Variables status Hub ${this.line?.id}`);
            });
        }

        if (!this.subscribed) this.currentRequestId = 'LineMap---' + uuid();

        const filterArr: Array<VariableFilter> = [];

        const filter: VariableFilter = {
            warehouseId: this.warehouseId,
            floorId: this.floorId,
            areaId: this.areaId,
            zoneId: this.zoneId,
            lineId: this.lineId,
        };
        filterArr.push(filter);

        const filters: VariableFilters = {
            requestId: this.currentRequestId,
            filters: filterArr,
            maxNotificationItems: 500, //filterArr.length,
            orderColumn: 'variableSourceTimestamp',
            orderType: 'desc',
        };

        //TEST commented both lines becouse inside listenNotification (from the parent lines.component), makes the clear
        // NotificationsMap.clearNotificationState('WarehouseStatusService', this.groupName, EQUIPMENT_STATE_CHANGED);
        this.cleanLineMapStates();
        if (!this.subscribed) {
            this.variablesActiveStatusService.subscribeToVariable(filters, this.receiveRealTimeVariables.bind(this), this.handleReconnection.bind(this));
        } else {
            this.variablesActiveStatusService.applyFilter(filters);
        }
    }

    receiveRealTimeVariables(item: VariableNotification) {
        this.subscribed = true;
        if (this.$svg2 && this.viewLineMap) {
            if (item.requestId === this.currentRequestId || item.requestId === VARIABLE_EMPTY_REQUEST_ID) {
                const variableType = item.variableType.toUpperCase() === 'ALARM' ? 'FAILURE' : item.variableType;
                const equipmentId = item.equipmentId;
                const key = `${equipmentId}.${variableType}.${item.variableName}`.toUpperCase();

                this.metadata?.variables?.texts?.filter((v) => v['variable'] === key).forEach((text) => {
                    text['text'] = item.variableValue
                }
                );
                const textElement = this.$svg2?.find(`[data-key="${'text' + key}"]`);

                if (textElement.length) {
                    this.metadata?.variables?.blocks
                        .filter((b) => b['variable'] === key)
                        .forEach((block) => {
                            block.texts.filter((v) => v['variable'] === key).forEach((text) => (text['text'] = item.variableValue));
                        });
                }

                const el = this.$svg2.find(`[data-key="${key}"]`);

                if (el.length) {
                    /* eslint-disable prettier/prettier */
                    const state = !isActiveVariable(item.variableValue)
                        ? 'unknown'
                        : isRunVariable(item)
                            ? 'run'
                            : item.variableType.toUpperCase() === 'WARNING'
                                ? 'warning'
                                : variableType.toUpperCase() === 'FAILURE'
                                    ? 'alarm'
                                    : variableType.toUpperCase() === 'STATUS'
                                        ? 'status'
                                        : 'unknown';
                    /* eslint-enabled prettier/prettier */
                    //TODO: add BOX_DETECTED
                    el.removeClass().addClass(state).data('state', state);

                    //if the variable didnt have a previus class/color, look for it inside WarehouseConfiguration VariableColorSettings
                    if ((state === 'unknown' || state === 'status') && textElement.length) {
                        let pathTextFillColor = '#eeeeee';
                        const keyWithEquipmentType = `${equipmentId}.${item.equipmentType}.${variableType}.${item.variableName}`.toUpperCase();
                        const keyWithWildCards = `${WILD_CARD_CHARACTER}.${WILD_CARD_CHARACTER}.${WILD_CARD_CHARACTER}.${item.variableName}`.toUpperCase();

                        //first find the whole fqn, then with wildcards
                        let variableFound: VariableColors = this.variableColorsConfiguration.find((setting) => setting.variable.endsWith(keyWithEquipmentType));
                        if (!variableFound) {
                            variableFound = this.variableColorsConfiguration.find((setting) => setting.variable.endsWith(keyWithWildCards));
                        }

                        if (variableFound) {
                            const defaultColor = variableFound.settings?.find((color) => +color.to === 0);
                            const foundedColor = variableFound.settings?.find((color) => +item.variableValue <= +color.to);
                            const finalColor = foundedColor ?? defaultColor;
                            if (finalColor) {
                                pathTextFillColor = finalColor.color;

                                const foreColor = this.textColorPipe.transform(pathTextFillColor);
                                textElement.attr('style', 'fill: ' + foreColor + '; stroke: ' + foreColor);
                                //textElement.attr('style', 'fill: ' + foreColor);
                            }
                        }
                        el.attr('style', 'fill:' + pathTextFillColor); // + ';stroke:' + aaa2
                    }
                }
            }
        }
    }

    async handleReconnection() {
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        this.subscribed = false;
        this.loadVariableRT();
    }

    async toogleMap(): Promise<void> {
        this.viewLineMap = !this.viewLineMap;
        if (this.viewLineMap) {
            this.loadLineStateVariables();
            this.loadVariableRT();
            this.stateService.forceReconnectWS();
        } else {
            if (this.subscribed && !this.linesDataModalOpen) {
                await this.variablesActiveStatusService.unsubscribeFromVariable();
                this.subscribed = false;
            }
        }
    }

    refreshVisibleLines() {
        const rectSVG = this.svg.nativeElement.getBoundingClientRect();

        let visibleEquipment = this.$svg
            ?.find(`[data-key]`)
            .get()
            .filter((svg: HTMLElement) => isInViewport(svg, rectSVG))
            .map((svg: HTMLElement) => {
                return svg.dataset?.['key'];
            });

        visibleEquipment = [...new Set(visibleEquipment)];
        this.visibleEquipment.emit(visibleEquipment);
    }

    resetZoom() {
        if (this.lineSVG) {
            this.lineSVG.zoom(this.currentZoom);
            this.$svg.attr('viewBox', this.setViewBox);

            this.refreshVisibleLines();
        }
    }

    private countStateItems(state: string, visibleStates: Array<string>) {
        let itemsCount = 0;
        if (this.$buttons) {
            itemsCount = this.$buttons?.find('[data-state]').filter(function (index) {
                return visibleStates?.includes(state) && $(this).data('state')?.includes(state);
            }).length;
        } else if (this.$svg) {
            itemsCount = this.$svg?.find('[data-state]').filter(function (index) {
                return visibleStates?.includes(state) && $(this).data('state')?.includes(state);
            }).length;
        }
        return itemsCount || 0;
    }

    loadLineStateVariables() {
        const initialVariablesLine$ = this.variablesService.getVariablesFromLine(this.floorId, this.areaId, this.zoneId, this.lineId);

        initialVariablesLine$
            .pipe(
                take(1),
                switchMap((allNotifications) => of(allNotifications.filter((notification) => (this.floorId === notification.floorId && this.areaId === notification.areaId && this.zoneId === notification.zoneId && this.lineId === notification.lineId)))),
                switchMap((allNotificationsOfLine: VariableNotification[]) => {
                    return of(allNotificationsOfLine.filter((notification) => {
                        return this.variablesActiveStatusService.isNewerNotificationFromService(notification)
                    }));
                })
            )
            .subscribe({
                next: (notificationWithElement) => {
                    notificationWithElement.forEach(v => {
                        this.receiveRealTimeVariables(v)
                    }
                    );
                },
                error: console.error,
            });
    }
}
