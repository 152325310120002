import { Alert } from '@alerts/shared/alert';
import { AlertsActiveStatus } from '../events/alert-status';

const alertsActiveStatusMapper = (alertActiveStatus: AlertsActiveStatus): Alert => {
    return {
        floorId: alertActiveStatus.floorid, //
        areaId: alertActiveStatus.areaid, //
        zoneId: alertActiveStatus.zoneid, //
        lineId: alertActiveStatus.lineid, //
        equipmentId: alertActiveStatus.equipmentId, //
        equipmentType: alertActiveStatus.equipmentType, //
        state: alertActiveStatus.state, //
        variableName: alertActiveStatus.variableName,
        description: alertActiveStatus.variableName, //
        priority: alertActiveStatus.priority, //
        source: alertActiveStatus.source,
        sourceTimeStamp: alertActiveStatus.sourceTimeStamp.toString(),
        serverts: alertActiveStatus.serverTimeStamp.toString(),
        alarmSourceTimeStamp: alertActiveStatus.sourceTimeStamp,
        closedTimeStamp: new Date(),
        alarmServerTimeStamp: alertActiveStatus.serverTimeStamp.toString(),
        isCritical: String(alertActiveStatus.equipmentType).toUpperCase() === 'EMERGENCY',
        realDescription: alertActiveStatus.variableName, //used to enable app-search in alerts active
    };
};

export default alertsActiveStatusMapper;
