import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { VariablesStatusClient } from '@app/notifications/shared/clients/variables-status.client';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import { VARIABLE_EMPTY_REQUEST_ID, VariableFilter, VariableFilters, VariableNotification, VariableValueType } from '@app/notifications/shared/events/variable-status';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { VariablesActiveStatusService } from '@app/notifications/shared/handlers/variablesActive-status-service';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { UserConfiguration } from '@app/shared/models/configurations';
// import { yieldToMain } from '@app/shared/performance/auwa-scheduler';
import { TextColorPipe } from '@app/shared/pipes/text-color.pipe';
import { VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { environment } from '@environments/environment';
import { Floor, IdentifiersPreferences } from '@home/shared/structure';
import { VariableColors, findVariableColor, findVariableToShow } from '@variables/shared/variable';
import { Observable, Subject, of } from 'rxjs';
import { bufferTime, switchMap, take, takeUntil } from 'rxjs/operators';
import { getEventTS } from '@app/notifications/shared/mappers/notification.mapper';
import { LinesService } from '@app/map/lines/shared/lines.service';
// import { Chart } from 'chart.js';
import * as Svg from '@svgdotjs/svg.js';
import 'chartjs-adapter-dayjs-3';
import fastdom from 'fastdom';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash-es';

const { LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;
const MAX_NOTIFICATIONS = 50;
const ORDER_COLUMN = 'SourceTimeStamp';
const ORDER_TYPE = 'desc';

@Component({
    selector: 'app-home-lines-map',
    templateUrl: './home-lines-map.component.html',
    styleUrls: ['./home-lines-map.component.scss'],
    providers: [VariablesActiveStatusService, VariablesStatusClient, TextColorPipe],
})
export class HomeLinesMapComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() warehouse: string;
    @Input() floor: Floor;
    @Input() visibleStates: Array<string>;
    @Input() wheelZoom?: boolean = true;
    @Input() activateZoom?: boolean = true;
    @Output() newLineSelected = new EventEmitter();
    @ViewChild('svg2', { read: ElementRef, static: true }) svg2;
    @ViewChild('contextMenu', { static: true }) contextMenu: ElementRef;
    @ViewChild('buttonsOverMap', { read: ElementRef, static: false }) buttonsOverMap;
    $svg2: JQuery;
    $buttonsOverMap: JQuery;
    identifiersPreferences: IdentifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    currentWarehouse: any;
    userName: string;
    userEmail: string;
    currentRequestId: string;
    currentFilters: VariableFilter[] = [];
    subscribed = false;
    waitForResponse = false;
    waitForResponseTimeout = null;
    setViewBox: string;
    floorSVG: any;
    floorStates: any;
    // avaliabilityChart: Chart;
    // performanceChart: Chart;
    timeoutConst = null;
    linesOverMap: Array<any> = [];
    countLinesOverMap = 0;
    lineSVG: any;
    currentZoom = 1;
    viewExtraData;
    viewFullSize = false;

    isVisibilityEnabled = false;
    metadataBlocks: any = {};
    shapesVariables: any = [];
    textVariables: string[];
    allVariablesToShow: string[];
    variableColorsConfiguration: VariableColors[] = [];
    groupName: string;
    supportedEvents = [LINE_STATE_CHANGED];

    keySelectedWithRightClick = '';
    rightClickX = 0;
    rightClickY = 0;

    lineChange$: Observable<LineStatusNotification>;
    linesCache: Map<string, JQuery<HTMLElement>> = new Map<string, JQuery<HTMLElement>>();
    firstMapLoad = true;
    tabState: VisibilityStates;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private appLoadService: AppLoadService,
        private variablesActiveStatusService: VariablesActiveStatusService,
        private variablesStatusClient: VariablesStatusClient,
        private warehouseStatusService: WarehouseStatusService,
        private authenticationService: AuthenticationService,
        private configurationsService: ConfigurationsService,
        //private pageLifeCycleService: PageLifecycleService,
        private textColorPipe: TextColorPipe,
        private lineService: LinesService,
    ) { }

    ngOnInit() {
        // ONLY CONNECT if the floor has variables
        // this.variablesStatusClient.connectToHub().then(() => {
        //     console.warn('Connected to Variables status Hub');
        // });
        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => (this.currentWarehouse = res));
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
            this.viewExtraData = this.userConfiguration.viewExtraData;
            this.viewFullSize = this.userConfiguration.viewFullSize === this.floor?.id;
            if (this.viewFullSize && !$('html').hasClass('no-scroll')) {
                $('html').addClass('no-scroll');
            }
        });

        this.$svg2 = $(this.svg2.nativeElement);
        this.setViewBox = this.floor.zonesViewBox || '0 0 3200 1800';
        this.floor.areas.forEach((area) => {
            area.zones.forEach((zone) => {
                const zoneOverMap = {
                    id: `${area.id}-${zone.id}`,
                    lines: zone.lines.filter((line) => !line.data || line.data === 'null'),
                };
                if (zoneOverMap.lines.length > 0) {
                    this.linesOverMap.push(zoneOverMap);
                    this.countLinesOverMap += zoneOverMap.lines.length;
                }
            });
        });
        this.groupName = `${this.warehouse}-${this.floor.id}`;
        this.isVisibilityEnabled = this.floor.variablesLines?.variablesVisibility?.Home;
        this.metadataBlocks.metadata = [];
        if (this.isVisibilityEnabled) {
            this.floor?.variablesLines?.metadata?.forEach((data) => {
                const dataJsonFormat = JSON.parse(data);
                if (dataJsonFormat.variable && dataJsonFormat.variable !== '') this.metadataBlocks.metadata.push(dataJsonFormat);
            });
            this.textVariables = this.floor?.variablesLines?.texts?.map((t) => t.variable).filter((v) => v && v !== '');
            this.shapesVariables = this.floor?.variablesLines?.shapes.map((s) => s.variable).filter((v) => v && v !== '');
            const metadataVariables: string[] = this.metadataBlocks?.metadata?.map((t) => t.variable).filter((v) => v && v !== '');
            this.allVariablesToShow = [...new Set<string>(metadataVariables.concat(this.textVariables).concat(this.shapesVariables))];
        }
    }

    ngAfterViewInit(): void {
        //const that = this;
        this.$buttonsOverMap = $(this.buttonsOverMap.nativeElement);

        $('path').tooltip({
            container: 'body',
            html: true,
            placement: 'top',
        });
        this.lineSVG = Svg.adopt(this.svg2.nativeElement);
        this.currentZoom = this.lineSVG.zoom();
        if (this.wheelZoom) {
            this.lineSVG.panZoom({ zoomMax: 10, zoomMin: this.currentZoom, zoomFactor: 0.5 });
        }
        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     this.tabState = state;
        //     if (prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state)) {
        //         if (this.warehouseStatusService.isConnected()) {
        //             this.startRealtimeNotifications();
        //         }
        //         if (this.variablesActiveStatusService.isConnected()) {
        //             this.handleReconnection();
        //         }
        //     }
        //         if (this.variablesActiveStatusService.isConnected()) {
        //             this.handleReconnection();
        //         }
        // });
        if (this.isVisibilityEnabled && this.floor.variablesLines) {
            setTimeout(() => {
                this.loadFloorStateVariables();
                this.loadVariableRT();
            });
        }
        this.appLoadService.getGlobalConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.variableColorsConfiguration = res['variableColors'] ?? [];
        });
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.visibleStates?.currentValue) {
            if (!_.isEqual(changes.visibleStates?.previousValue, changes.visibleStates?.currentValue)) {
                console.warn('This should only be called when the states filters are changed.');
                this.startRealtimeNotifications();
            }
        }
    }

    async ngOnDestroy(): Promise<void> {
        this.ngUnsubscribe?.next(true);
        this.ngUnsubscribe?.complete();
        this.$svg2.remove();
        $('path').tooltip('dispose');

        this.currentRequestId = 'stop-processing-notifications';
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        if (this.variablesStatusClient.connection) {
            this.variablesStatusClient.disconnectFromHub();
        }

        await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
        this.$buttonsOverMap = null;
        this.$svg2 = null;
        this.linesCache.clear();
        this.linesCache = null;
    }

    toogleFullScreen(): void {
        this.viewFullSize = !this.viewFullSize;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.viewFullSize ? $('html').addClass('no-scroll') : $('html').removeClass('no-scroll');
        this.userConfiguration.viewFullSize = this.viewFullSize ? this.floor.id : null;
        this.configurationsService.saveConfiguration(this.currentWarehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
    }

    loadVariableRT() {
        if (!this.allVariablesToShow || this.allVariablesToShow.length === 0) return;

        // ONLY CONNECT if the floor has variables
        if (!this.variablesStatusClient.connection) {
            this.variablesStatusClient.connectToHub().then(() => {
                console.warn(`Connected to Variables status Hub ${this.floor?.id}`);
            });
        }


        if (!this.subscribed) this.currentRequestId = 'HomeLineMap---' + uuid();

        this.allVariablesToShow.forEach((variableToShow) => {
            let [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = variableToShow?.split('.');
            variableType = variableType === 'FAILURE' ? 'ALARM' : variableType;
            const firstFilter: VariableFilter = {
                warehouseId: this.warehouse,
                floorId,
                areaId,
                zoneId,
                lineId,
                equipmentId,
                //equipmentType, //in DT this property is *
                variableType,
                variableName,
            };
            this.currentFilters.push(firstFilter);
        });
        const filters: VariableFilters = {
            requestId: this.currentRequestId,
            filters: this.currentFilters,
            maxNotificationItems: MAX_NOTIFICATIONS,
            orderColumn: ORDER_COLUMN,
            orderType: ORDER_TYPE,
        };

        if (!this.subscribed) {
            this.subscribed = true;
            this.variablesActiveStatusService.subscribeToVariable(filters, this.receiveRealTimeVariables.bind(this), this.handleReconnection.bind(this));
        } else {
            this.variablesActiveStatusService.applyFilter(filters);
        }
        this.waitForResponseTimeout = setTimeout(() => {
            this.waitForResponse = false;
            this.waitForResponseTimeout = null;
        }, 3000);
    }

    receiveRealTimeVariables(notification: VariableNotification) {
        if (notification.requestId === this.currentRequestId || notification.requestId === VARIABLE_EMPTY_REQUEST_ID) {
            if (notification.sourceTimeStamp === undefined && ![undefined, null, 'ApiDefault'].includes(notification.source)) {
                console.error('SourceTimeStamp from backend is null/undefined.', notification);
            }

            const variableToShow = findVariableToShow(this.allVariablesToShow, notification);

            //for (let item of this.allVariablesToShow) {
            if (variableToShow) {
                const [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = variableToShow.split('.');

                //cause in DT u dont know the EquipmentType, is used an * for this value
                const key = `${areaId}.${zoneId}.${floorId}.${lineId}.${equipmentId}.*.${variableType}.${variableName}`.toUpperCase();

                this.floor.variablesLines?.texts
                    ?.filter((v) => v['selectedVariable'] === key)
                    .forEach((text) => (text['text'] = String(notification.variableValue)));
                this.metadataBlocks.metadata
                    .filter((b) => b['variable'] === key)
                    .forEach((block) => {
                        block.texts.filter((v) => v['variable'] === key).forEach((text) => (text['text'] = String(notification.variableValue)));
                    });

                const icon = this.$svg2?.find(`path.shape[data-key="${key}"]`);
                const blockElement = this.$svg2?.find(`g.shape.block > path.withVariable[data-key="${key}"]`);
                const blockTextElement = this.$svg2?.find(`[data-key="${'text' + key}"]`);

                if ((blockElement?.length && blockTextElement?.length) || icon?.length) {
                    let pathTextFillColor = '#eeeeee';

                    const variableFound: VariableColors = findVariableColor(
                        areaId,
                        zoneId,
                        floorId,
                        lineId,
                        equipmentId,
                        equipmentType,
                        variableType,
                        variableName,
                        this.variableColorsConfiguration
                    );

                    if (variableFound) {
                        const variableValue = notification.variableValueType.toUpperCase() !== VariableValueType.Boolean.toUpperCase() ? notification.variableValue : notification.variableValue.toString().toUpperCase() === 'TRUE' ? 1 : notification.variableValue.toString().toUpperCase() === 'FALSE' ? 0 : notification.variableValue;
                        const defaultColor = variableFound.settings?.find((color) => +color.to === 0);
                        const foundedColor = variableFound.settings?.find((color) => +variableValue <= +color.to);
                        const finalColor = foundedColor ?? defaultColor;
                        if (finalColor) {
                            pathTextFillColor = finalColor.color;
                            if (icon?.length) {
                                if (finalColor.visible !== false) {
                                    icon.attr('style', 'fill:' + pathTextFillColor);
                                } else {
                                    icon.attr('style', 'display:none');
                                }
                            }
                            if (blockTextElement?.length) {
                                const foreColor = this.textColorPipe.transform(pathTextFillColor);
                                blockTextElement.attr('style', 'fill: ' + foreColor + '; stroke: ' + foreColor);
                            }
                        }
                        blockElement?.attr('style', 'fill:' + pathTextFillColor);
                    }
                }
            }
            this.waitForResponse = false;
            if (this.waitForResponseTimeout) {
                clearTimeout(this.waitForResponseTimeout);
                this.waitForResponseTimeout = null;
            }
        }
    }


    async handleReconnection(this) {
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        this.subscribed = false;
        this.currentFilters = [];
        this.loadVariableRT();
    }

    emitLine(event, newLineObject) {
        this.activateZoom = false;
        if (newLineObject) {
            this.newLineSelected.emit(newLineObject);
        } else if (event) {
            const key = $(event.target).data('key');
            const keys = key.split('-');

            this.newLineSelected.emit({
                warehouseId: this.warehouse,
                floorId: keys[0],
                areaId: keys[1],
                zoneId: keys[2],
                lineId: keys[3],
            });
        }
    }

    async startRealtimeNotifications() {
        try {
            await this.warehouseStatusService.endSubscription(this.groupName, this.supportedEvents);
            await this.warehouseStatusService.startSubscription(this.groupName, this.supportedEvents);
            await this.cleanLineMapStates();
            this.lineChange$ = await this.warehouseStatusService.listenNotifications(this.groupName, LINE_STATE_CHANGED);
            this.lineChange$
                .pipe(
                    bufferTime(200),
                    switchMap((notifications) => of(this.$svg2 || this.$buttonsOverMap ? notifications : [])),
                    switchMap((notifications) => of(notifications.filter((notification) => this.floor.id === notification.floorId))),
                    switchMap((allNotificationsOfFloor: LineStatusNotification[]) => {
                        return of(allNotificationsOfFloor.filter((notification) => {
                            const notificationTS = getEventTS(LINE_STATE_CHANGED, notification);
                            return this.warehouseStatusService.isNewer(this.groupName, LINE_STATE_CHANGED, notification, notificationTS)
                            //return NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, LINE_STATE_CHANGED, notificationTS, notification);
                        }));
                    }),
                    switchMap((notifications) => of(notifications.map((notification) => this.getElementToUpdateState(notification)))),
                    takeUntil(this.ngUnsubscribe),
                )
                .subscribe({
                    next: this.drawLineState.bind(this),
                    error: console.error,
                });
            await this.loadFloorState();
            this.warehouseStatusService.onReconnecting?.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.startRealtimeNotifications());
        } catch (error) {
            console.error(error);
        }
    }

    async loadFloorState() {
        const initialLineMapStates$ = this.lineService.getLineStatus(this.floor.id); //.pipe(take(1));
        //*start original previos Mauros Change */
        // const lineStates = await lastValueFrom(initialLineMapStates$);
        // const newNotifications = lineStates.filter((notification) => {
        //     const notificationTS = getEventTS(LINE_STATE_CHANGED, notification);
        //     const groupName = `${this.warehouse}-${this.floor.id}`;
        //     // eslint-disable-next-line prettier/prettier
        //     return NotificationsMap.isNewerNotification('WarehouseStatusService', groupName, LINE_STATE_CHANGED, notificationTS, notification);
        // });
        // this.drawLineState(newNotifications);
        //*end original previos Mauros Change */

        initialLineMapStates$
            .pipe(
                take(1),
                switchMap((allNotifications) => of(allNotifications.filter((notification) => this.floor.id === notification.floorId))),
                switchMap((allNotificationsOfFloor: LineStatusNotification[]) => {
                    return allNotificationsOfFloor.filter((notification) => {
                        const notificationTS = getEventTS(LINE_STATE_CHANGED, notification);
                        return this.warehouseStatusService.isNewer(this.groupName, LINE_STATE_CHANGED, notification, notificationTS)
                        //return NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, LINE_STATE_CHANGED, notificationTS, notification);
                    });
                }),
                switchMap((newestNotification) => {
                    return of(this.getElementToUpdateState(newestNotification));
                }),
            )
            .subscribe({
                next: (notificationWithElement) => {
                    this.drawLineState([notificationWithElement]); // true)
                },
                error: console.error,
            });
    }

    loadFloorStateVariables() {
        const initialLineMapStatesVariables$ = this.lineService.getLineStatusVariableLines(this.floor.id);

        initialLineMapStatesVariables$
            .pipe(
                take(1),
                switchMap((allNotifications) => of(allNotifications.filter((notification) => this.floor.id === notification.floorId))),
                switchMap((allNotificationsOfFloor: VariableNotification[]) => {
                    return of(allNotificationsOfFloor.filter((notification) => {
                        return this.variablesActiveStatusService.isNewerNotificationFromService(notification)
                    }));
                })
            )
            .subscribe({
                next: (notificationWithElement) => {
                    notificationWithElement.forEach(v => {
                        this.receiveRealTimeVariables(v)
                    }
                    );
                },
                error: console.error,
            });
    }

    async cleanLineMapStates() {
        if (!this.$svg2) return;
        if (this.firstMapLoad) {
            this.firstMapLoad = false;
            return;
        }
        for (const area of this.floor.areas || []) {
            for (const zone of area.zones || []) {
                for (const line of zone.lines || []) {
                    const key = `${this.floor.id}-${area.id}-${zone.id}-${line.id}`;
                    const el = this.getOrAddLineCache(key);
                    if (el) el.removeClass().removeData('state');

                    if (this.$buttonsOverMap) {
                        const button = this.$buttonsOverMap.find(`[data-key="${key}"]`);
                        button.removeClass().removeData('state');
                    }
                }
                // await yieldToMain();
            }
        }
    }

    getElementToUpdateState(notification) {
        const key = `${notification.floorId}-${notification.areaId}-${notification.zoneId}-${notification.lineId}`;
        if (this.$svg2) {
            const el = this.getOrAddLineCache(key);
            let button;
            if (this.$buttonsOverMap) {
                button = this.$buttonsOverMap.find(`[data-key="${key}"]`);
            }
            return { notification, el, button };
        } /*else if (this.$buttons) {
            let button;
            if (this.$buttonsOverMap) {
                button = this.$buttonsOverMap.find(`[data-key="${key}"]`);
            }
            return { notification, el, button };
        } /*else if (this.$buttons) {
            const button = this.$buttons.find(`[data-key="${key}"]`);
            return { notification, button };
        }*/
        return undefined;
    }

    drawLineState(items: Array<any>) {
        for (const item of items) {
            if (!item) continue;
            const { notification, el, button } = item;
            const state = notification.state.filter((s) => s === 'unknown' || this.visibleStates?.includes(s)).join(' ');
            const newClass = el.length > 0 ? state : `btn btn-default ${state}`;
            const element = el.length > 0 ? el : button;

            fastdom.mutate(() => element.removeClass().addClass(newClass).data('state', state));
        }
    }

    resetZoom() {
        if (this.lineSVG) {
            this.lineSVG.zoom(this.currentZoom);
            this.$svg2.attr('viewBox', this.floor.linesViewBox || '0 0 3200 1800');
        }
    }

    closeExpand() {
        if (this.viewFullSize) {
            this.toogleFullScreen();
        }
    }

    onRightClick(e) {
        this.$svg2?.find('path').tooltip('hide');

        this.keySelectedWithRightClick = $(e.target).data('key');

        this.rightClickX = e.pageX;
        this.rightClickY = e.pageY;

        return false;
    }

    hideContextMenu() {
        this.keySelectedWithRightClick = '';
        this.rightClickX = 0;
        this.rightClickY = 0;
    }

    getOrAddLineCache(key: string): JQuery<HTMLElement> {
        if (!this.linesCache?.has(key)) {
            const el = this.$svg2.find(`[data-key="${key}"]`);
            this.linesCache.set(key, el);
        }
        return this.linesCache.get(key);
    }
}
