import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { LineParameterComponent } from './lines-data-modal/components/line-parameter/line-parameter.component';
import { AlertsTableSmComponent } from '@app/shared/components/alerts-table-sm/alerts-table-sm.component';
import { LinesDataModalComponent } from '@shared/components/lines-data-modal/lines-data-modal.component';
import { SelectMultilevelComponent } from './forms/select-multilevel/select-multilevel.component';
import { SelectWarehouseComponent } from './forms/select-warehouse/select-warehouse.component';
import { LoadingSpinnerComponent } from './forms/loading-spinner/loading-spinner.component';
import { DropdownCheckboxComponent } from './dropdown-checkbox/dropdown-checkbox.component';
import { AlarmOriginFlowComponent } from './alarm-origin-flow/alarm-origin-flow.component';
import { SelectVirtualComponent } from './forms/select-virtual/select-virtual.component';
import { AnalyticsPanelComponent } from './analytics-panel/analytics-panel.component';
import { VariablesTableComponent } from './variables-table/variables-table.component';
import { LinesMapIconsComponent } from './map/line/icons/lines-map-icons.component';
import { VariableInputComponent } from './variable-input/variable-input.component';
import { SvgPatternsComponent } from './svg-patterns/svg-patterns.component';
import { ConfigColorPickerComponent } from './config-color-picker/config-color-picker.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CustomMapComponent } from '../../map/custom/custom-map.component';
import { FloorsMapComponent } from './map/floor/floors-map.component';
import { AreasMapComponent } from './map/area/areas-map.component';
import { ZonesMapComponent } from './map/zone/zones-map.component';
import { LinesMapComponent } from './map/line/lines-map.component';
import { SettingsComponent } from './settings/settings.component';
import { AlertSnapshotComponent } from './alerts-snapshot/alerts-snapshot.component';
import { RangeSliderComponent } from './forms/range-slider/range-slider.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { InputComponent } from './forms/input/input.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { LinesService } from '@lines/shared/lines.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from '../pipes/pipes.module';
import { AysCommonsModule } from '@ays';
import { ImageSecurePipe } from '../pipes/image-secure.pipe';
import { VariableStatusComponent } from './variable-status/variable-status.component';
import { LinesMapMinifiedComponent } from './map/line-minified/lines-map-minified.component';
import { DirectivesModule } from '../directives/directives.module';
import { HomeLinesMinifiedContainerComponent } from '@app/map/home/components/lines/home-lines-minified-container.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
//import { SettingsBoxDetectedComponent } from './settings/settings-boxdetected/settings-boxdetected.component';
import { SettingsErrorsTrendComponent } from './settings/settings-errors-trend/settings-errors-trend.component';
import { SettingsScannersTrendComponent } from './settings/settings-scanners-trend/settings-scanners-trend.component';
import { SettingsAlertsZonesComponent } from './settings/settings-alerts-zones/settings-alerts-zones.component';
import { SettingsVariableColorComponent } from './settings/settings-variable-color/settings-variable-color.component';
import { SettingsHeatMapComponent } from './settings/settings-heatmap/settings-heatmap.component';
import { SettingsIdentifiersComponent } from './settings/settings-identifiers/settings-identifiers.component';

@NgModule({
        imports: [
                NgSelectModule,
                DirectivesModule,
                ScrollingModule,
                AysCommonsModule,
                CommonModule,
                ColorPickerModule,
                RouterModule,
                FormsModule,
                PipesModule,
                NgxDatatableModule,
        ],
        declarations: [
                InputComponent,
                CustomMapComponent,
                ColorPickerComponent,
                SelectVirtualComponent,
                DropdownCheckboxComponent,
                SvgPatternsComponent,
                AlarmOriginFlowComponent,
                FloorsMapComponent,
                AreasMapComponent,
                ZonesMapComponent,
                LinesMapComponent,
                ConfigColorPickerComponent,
                LinesMapIconsComponent,
                AlertsTableSmComponent,
                AnalyticsPanelComponent,
                LineParameterComponent,
                LinesDataModalComponent,
                SelectWarehouseComponent,
                SelectMultilevelComponent,
                FloorsMapComponent,
                BreadcrumbsComponent,
                LoadingSpinnerComponent,
                LineParameterComponent,
                VariableInputComponent,
                VariablesTableComponent,
                AlertSnapshotComponent,
                RangeSliderComponent,
                SettingsComponent,
                VariableStatusComponent,
                LinesMapMinifiedComponent,
                HomeLinesMinifiedContainerComponent,
                ContextMenuComponent,
                //SettingsBoxDetectedComponent,
                SettingsErrorsTrendComponent,
                SettingsScannersTrendComponent,
                SettingsAlertsZonesComponent,
                SettingsVariableColorComponent,
                SettingsHeatMapComponent,
                SettingsIdentifiersComponent,
        ],
        exports: [
                InputComponent,
                CustomMapComponent,
                ColorPickerComponent,
                SelectVirtualComponent,
                DropdownCheckboxComponent,
                SvgPatternsComponent,
                AlarmOriginFlowComponent,
                FloorsMapComponent,
                AreasMapComponent,
                ZonesMapComponent,
                LinesMapComponent,
                LinesMapIconsComponent,
                ConfigColorPickerComponent,
                AlertsTableSmComponent,
                AnalyticsPanelComponent,
                LineParameterComponent,
                LinesDataModalComponent,
                SelectWarehouseComponent,
                SelectMultilevelComponent,
                FloorsMapComponent,
                BreadcrumbsComponent,
                LoadingSpinnerComponent,
                VariableInputComponent,
                VariablesTableComponent,
                VariableStatusComponent,
                AlertSnapshotComponent,
                RangeSliderComponent,
                PipesModule,
                SettingsComponent,
                LinesMapMinifiedComponent,
                HomeLinesMinifiedContainerComponent,
                ContextMenuComponent,
                //SettingsBoxDetectedComponent,
                SettingsErrorsTrendComponent,
                SettingsScannersTrendComponent,
                SettingsAlertsZonesComponent,
                SettingsVariableColorComponent,
                SettingsHeatMapComponent,
                SettingsIdentifiersComponent,
        ],
        providers: [LinesService, EquipmentsService, ImageSecurePipe],
})
export class SharedComponentsModule { }
