/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, EventEmitter } from '@angular/core';
import { ReportStatusClient } from '../clients/report-status.client';
import { NotificationsMap } from '../notifications-map/notifications-map';

const REPORT_COUNT = 'ReportCount';

@Injectable()
export class ReportCountStatusService {
    onReportCount: EventEmitter<number> = new EventEmitter<number>();
    reportCountSubscription: any;
    reportCountSubscriptionId: string;
    endPoint: string;
    warehouse: string;
    onReconnect: () => Promise<void>;
    handleReconnectionReference: () => Promise<void>;

    private readonly reportCountCallback = (warehouseId: string, userId: string, reportCount: number) => {
        if (!this.onReportCount.isStopped) {
            this.onReportCount.emit(reportCount);
        }
    };

    constructor(
        private reportStatusClient: ReportStatusClient,
    ) { }

    public async subscribeToReportCount(
        warehouse: string,
        userEmail: string,
        context: any,
        delegateFunc: (context: any, alertsCount: number) => void,
        onReconnect: () => Promise<void>,
    ) {
        const eventName = ReportStatusClient.SupportedEvents.REPORT_COUNT_CHANGED;
        this.reportCountSubscription = this.onReportCount.subscribe({
            next: (event: number) => {
                delegateFunc(context, event);
            },
        });
        NotificationsMap.clearNotificationState(this.constructor.name, REPORT_COUNT, eventName);
        this.onReconnect = onReconnect;
        this.handleReconnectionReference = this.handleReconnection.bind(this);
        await this.reportStatusClient.subscribe(eventName, `${warehouse}-${userEmail}`, this.reportCountCallback, this.handleReconnectionReference);
    }

    async handleReconnection() {
        const eventName = ReportStatusClient.SupportedEvents.REPORT_COUNT_CHANGED;
        NotificationsMap.clearNotificationState(this.constructor.name, REPORT_COUNT, eventName);
        await this.onReconnect();
    }

    public async unsubscribeFromReportCount(warehouse: string, userEmail: string) {
        const eventName = ReportStatusClient.SupportedEvents.REPORT_COUNT_CHANGED;
        this.reportCountSubscription?.unsubscribe();
        await this.reportStatusClient.unsubscribe(eventName, `${warehouse}-${userEmail}`);
    }

    isConnected() {
        return this.reportStatusClient.connection?.hubConnection.state === 'Connected';
    }
}
