import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { SearchComponent } from '@ays/commons/lib/components/forms/search/search.component';
import { ZoneColors } from '@variables/shared/variable';
import { SelectMultilevelComponent } from '../../forms/select-multilevel/select-multilevel.component';

const INITIAL_ENVIROMENT = 'DEFAULT';
const INITIAL_COLOR_SELECTED = '#eeeeee';

@Component({
    selector: 'settings-alerts-zones',
    templateUrl: './settings-alerts-zones.component.html',
    styleUrls: ['./settings-alerts-zones.component.scss'],
})
export class SettingsAlertsZonesComponent implements AfterViewInit, OnChanges {
    @Input({ required: true }) alertsZonesColors: ZoneColors[] = [];
    @Input({ required: false }) alertsZonesColorsToDelete: string;
    @Output() newAlertZone = new EventEmitter();
    @Output() alertsZonesDeleteConfirmation = new EventEmitter();
    @ViewChild('settingsFAZ') settingsFAZ: SelectMultilevelComponent;
    @ViewChild('alertsZonesFilter', { static: false }) alertsZonesFilter: SearchComponent;
    $settingsFAZ: JQuery;
    selectedZone: string;
    newColorSelectedZone: string = INITIAL_COLOR_SELECTED;
    alertsZonesFilterValue = '';

    initialEnviroment: string = INITIAL_ENVIROMENT;

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.alertsZonesColorsToDelete?.currentValue) {
            this.deleteAlertsZonesColors(changes.alertsZonesColorsToDelete?.currentValue);
        }
    }

    ngAfterViewInit() {
        this.$settingsFAZ = this.settingsFAZ.$selectMultilevel;

        this.$settingsFAZ.on('change', () => {
            this.selectedZone = this.$settingsFAZ.val().toString();
        });

        const that = this;
        this.alertsZonesFilter.$input.off('keyup').on('keyup', function (e) {
            that.alertsZonesFilterValue = String($(this).val()).toUpperCase();
        });
    }

    selectedNewColorZone(color) {
        this.newColorSelectedZone = color;
    }
    addNewAlertsZonesColors() {
        if (!this.selectedZone || this.selectedZone === 'Select zone') {
            return;
        }
        const elementAlreadyExists = this.alertsZonesColors.find((element) => element.zone === this.selectedZone);
        if (!elementAlreadyExists) {
            const newZone = {
                zone: this.selectedZone,
                color: this.newColorSelectedZone,
            };
            this.alertsZonesColors.push(newZone);
            this.alertsZonesColors.sort();

            this.updateAlertsZonesColors()
        }
        this.selectedZone = ''
        this.settingsFAZ.val('', true);
        this.newColorSelectedZone = INITIAL_COLOR_SELECTED;
    }

    saveAlertsZonesColors(newColor: string, zone: string) {
        const zoneToUpdate = this.alertsZonesColors.find((element) => element.zone === zone);
        zoneToUpdate.color = newColor;

        this.updateAlertsZonesColors()
    }

    deleteAlertsZonesColors(zone: string) {
        this.alertsZonesColors = this.alertsZonesColors.filter((element) => element.zone !== zone);

        this.updateAlertsZonesColors()
    }

    updateAlertsZonesColors() {
        this.newAlertZone.emit({
            alertsZones: [...this.alertsZonesColors]
        });
    }

    deleteConfirmation(messageId: number, id: string, variable: string, enviroment: string) {
        this.alertsZonesDeleteConfirmation.emit({
            messageId, id, variable, enviroment
        });
    }

    trackByAlertZone(index: number, element: any) {
        return element?.zone;
    }

}
