import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MapContainerComponent } from '@app/shared/components/map-container/map-container.component';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { SelectComponent } from '@ays/lib/components/forms/select/select.component';
import { MapGlobalState, MapState } from '@app/shared/models/map-state';
import { environment } from '@environments/environment';
import { Warehouse } from '@app/core/shared/warehouse';
import { AppLoadService } from '@app/app-load.service';
import { Structure } from '@home/shared/structure';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash-es';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends MapContainerComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() drawLineState = new EventEmitter();
    @ViewChild('homeMapMode') homeMapMode: SelectComponent;
    $homeMapMode: JQuery;
    warehouse: Warehouse;
    structure: Structure;
    mode$ = ['Lines']; //['Zones', 'Lines'];
    comboMode = 'Lines'; //'Zones';
    configuration: any;
    userName: string;
    userEmail: string;
    configurationFilterValues: Array<MapGlobalState>;
    isFullscreen = false;
    showFilters = false;
    showFilterDropdown = false;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private router: Router,
        private appLoadService: AppLoadService,
        private route: ActivatedRoute,
        private configurationsService: ConfigurationsService,
        private authenticationService: AuthenticationService,
    ) {
        super();
        this.configuration = this.route.snapshot.data.home.configuration;
        this.warehouse = this.route.snapshot.data.home.warehouse;
        this.structure = this.route.snapshot.data.home.structure;
        if (this.structure.custom.length > 0) {
            this.mode$.push('Customs');
        }
        this.stateService.setVersionMap(this.structure.version);
        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
        this.configurationFilterValues = this.configuration?.stateFilter ? JSON.parse(this.configuration.stateFilter) : [];
        this.changeVisibleStates(this.availableStates);
    }

    ngOnInit() {
        setTimeout(() => {
            const initialMode = this.router.url;
            this.checkHomeMode(initialMode);
            this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    const mode = event.url;
                    this.checkHomeMode(mode);
                }
            });
        }, 0);
    }

    ngAfterViewInit(): void {
        this.$homeMapMode = this.homeMapMode.$select;
        this.$homeMapMode.off('change.mode').on('change.mode', () => {
            const mode = String(this.$homeMapMode.find('option:selected').val());

            this.appLoadService.getCurrentConfiguration.pipe(take(1)).subscribe((config) => {
                this.configuration = config;
                this.configuration.homeMode = mode;
                this.saveConfiguration(mode);
            });
        });
        this.stateService.isFullscreen.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isFullscreen) => {
            this.isFullscreen = isFullscreen;
        });
        this.stateService.showFilters.pipe(takeUntil(this.ngUnsubscribe)).subscribe((showFilters) => {
            this.showFilters = showFilters;
        });
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.showFilterDropdown = !res.viewFullSize;
        });
    }
    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy();
        this.$homeMapMode.off();
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    checkHomeMode(mode) {
        if (mode === `/warehouse/${this.warehouse.warehouse}`) {
            const defaultMode =
                this.configuration?.homeMode && this.mode$.includes(this.configuration?.homeMode) ? this.configuration.homeMode.toLowerCase() : 'lines'; //'zones'
            this.router.navigate([`warehouse/${this.warehouse.warehouse}/${defaultMode}`]);
            // } else if (mode === `/warehouse/${this.warehouse.warehouse}/zones`) {
            //     this.comboMode = 'Zones';
        } else if (mode === `/warehouse/${this.warehouse.warehouse}/lines`) {
            this.comboMode = 'Lines';
        } else if (mode === `/warehouse/${this.warehouse.warehouse}/customs`) {
            this.comboMode = 'Customs';
        }
    }

    handleChangeVisibleStates(states: Array<MapState>) {
        if (this.comboMode === 'Lines') {
            const newFloorConfiguration = {
                id: 'floor',
                states,
            };
            this.configurationFilterValues = [newFloorConfiguration, ...this.configurationFilterValues];
        } else if (this.comboMode === 'Customs') {
            const newCustomsConfiguration = {
                id: 'customs',
                states,
            };
            this.configurationFilterValues = [newCustomsConfiguration, ...this.configurationFilterValues];
        }
        this.configurationFilterValues = _.uniqBy(this.configurationFilterValues, (item) => item.id);
        this.configuration.stateFilter = JSON.stringify(this.configurationFilterValues);
        this.changeVisibleStates(states);
        this.saveConfiguration();
    }

    saveConfiguration(mode = null) {
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
        if (mode) {
            setTimeout(() => {
                this.router.navigate([`warehouse/${this.warehouse.warehouse}/${mode.toLowerCase()}`]);
            }, 0);
        }
    }
}
