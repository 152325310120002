import { Component } from '@angular/core';

@Component({
    selector: 'user-manual',
    templateUrl: './user-manual.component.html',
    styleUrls: ['./user-manual.component.scss'],
})
export class UserManualComponent {
    markdowmFileShown = 'assets/user-manual/home.md';
    menu = [
        { caption: 'Auwa', childs: [{ caption: 'Home (Landing page)', markdownFile: 'assets/user-manual/home.md' }] },
        {
            caption: 'States',
            childs: [
                { caption: 'Mapas', markdownFile: 'assets/user-manual/mapas.md' },
                { caption: 'Alerts', markdownFile: 'assets/user-manual/alerts.md' },
                { caption: 'Variables', markdownFile: 'assets/user-manual/variables.md' },
            ],
        },
    ];

    onError(e) {
        //console.log(`%c Error => ${e}`, `background: red; color: white`);
    }
    onLoad(e) {
        //console.log(`%c Load => ${e}`, `background: blue; color: white`);
    }
}
