<div class="row mt-3 mb-3 justify-content-center">
    <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
        <div class="row mt-4 mb-5">
            <div class="col-7 d-flex">
                <h5 class="text-start ms-5 me-3 mt-2">VARIABLE'S COLORS AND VISIBILITY</h5>
                <button
                    class="btn btn-auwa px-4 ms-auto"
                    title="Add new Variable Color Setting"
                    data-bs-toggle="tooltip"
                    (click)="onClickAddNewVariableColor()"
                >
                    Add
                </button>
            </div>
            <div class="col-4 ms-auto me-5">
                <app-search
                    #variableFilter
                    key="txtFindVariableColorConfiguration"
                    placeholder="Filter Variables"
                    class=""
                    icon="fas fa-search"
                    (searchValueDeleted)="variableFilterValue = ''"
                >
                </app-search>
            </div>
        </div>
        <div class="container">
            <div
                class="row ms-5 mt-2 mb-2"
                *ngFor="let variableColor of variableColors; index as i; trackBy: trackByVariable"
                [hidden]="variableFilterValue !== '' && !variableColor.variable.includes(variableFilterValue)"
            >
                <div class="col-12 fw-bold force-one-line">
                    {{ variableColor.variable.split('.').pop() }}
                    <button
                        class="float-end btn btn-sm btn-danger me-5 d-inline-block"
                        (click)="deleteConfirmation(4, '', variableColor.variable, '')"
                        title="Delete Variable Color Setting"
                        data-bs-toggle="tooltip"
                    >
                        <i class="fas fa-times-circle"></i>
                    </button>
                    <a
                        class="float-end btn btn-sm btn-light fw-bold me-5 py-1 d-inline-block"
                        data-bs-toggle="collapse"
                        href="#collapseTable{{ i }}"
                        role="button"
                        aria-expanded="false"
                        attr.aria-controls="collapseTable{{ i }}"
                        title="View/Edit Variable Color Setting"
                    >
                        {{ variableColor.settings?.length }} ITEMS
                    </a>
                    <div style="overflow-x: auto">
                        <p class="small fw-normal">
                            {{ variableColor.variable.split('.').slice(0, -1).join('.') }}
                        </p>
                    </div>
                </div>
                <div class="col-12 ms-5 mt-0 collapse" [id]="'collapseTable' + i">
                    <table class="table-compact table-hover w-75 mt-1 mb-5">
                        <thead>
                            <tr>
                                <th class="w-25">Environment</th>
                                <th class="w-25 text-end">{{ variableColor?.dataType === variableNumericType ? 'To' : 'Value' }}</th>
                                <th class="w-25 text-center">Color</th>
                                <th class="w-25 text-center">Visibility (icons only)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let setting of variableColor.settings; index as i; trackBy: trackById">
                                <td>
                                    <div
                                        style="overflow-x: auto"
                                        [ngStyle]="{ cursor: variableColor?.dataType !== variableNumericType || i === 0 ? 'auto' : 'pointer' }"
                                    >
                                        <span
                                            (dblclick)="editingValue(setting, txtNewEnviroment, 'enviroment')"
                                            [hidden]="editingVariableColorEnviroment === setting.id"
                                        >
                                            {{ setting.enviroment }}
                                            <span *ngIf="variableColor?.dataType !== variableNumericType">
                                                {{ setting.to === '1' ? ' - TRUE' : ' - FALSE' }}
                                            </span>
                                        </span>
                                    </div>
                                    <div [hidden]="editingVariableColorEnviroment !== setting.id">
                                        <app-input
                                            #txtNewEnviroment
                                            key="txtNewEnviroment{{ setting.id }}"
                                            label="Environment"
                                            extraClasses="w-100"
                                        ></app-input>
                                        <button
                                            class="btn btn-sm btn-editor float-end ms-1"
                                            (click)="saveValue(setting.id, variableColor.variable, txtNewEnviroment, 'enviroment')"
                                            title="SAVE"
                                        >
                                            <i class="fa fa-save" aria-hidden="true"></i>
                                        </button>
                                        <button class="btn btn-sm btn-editor float-end ms-1" (click)="editingVariableColorEnviroment = ''" title="CANCEL">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </td>
                                <td class="text-end">
                                    <span
                                        (dblclick)="editingValue(setting, txtNewTo, 'to')"
                                        [hidden]="editingVariableColorTo === setting.id"
                                        [ngStyle]="{ cursor: variableColor?.dataType !== variableNumericType ? 'auto' : 'pointer' }"
                                    >
                                        {{ setting.to }}
                                    </span>
                                    <div [hidden]="editingVariableColorTo !== setting.id">
                                        <app-input
                                            #txtNewTo
                                            key="txtNewTo{{ setting.id }}"
                                            label="To"
                                            type="number"
                                            withoutArrows="true"
                                            extraClasses="w-100 float-end text-end"
                                        ></app-input>

                                        <button
                                            class="btn btn-sm btn-editor float-end ms-1"
                                            (click)="saveValue(setting.id, variableColor.variable, txtNewTo, 'to')"
                                            title="SAVE"
                                        >
                                            <i class="fa fa-save" aria-hidden="true"></i>
                                        </button>
                                        <button class="btn btn-sm btn-editor float-end ms-1" (click)="editingVariableColorTo = ''" title="CANCEL">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <shared-color-picker
                                        class="level-2 badge me-1 w-50 py-2 my-1"
                                        [colorRange]="setting.color"
                                        [fromDataRange]="''"
                                        [toDataRange]="''"
                                        [symbol]="''"
                                        [middle]="setting.color"
                                        okButtonText="SELECT"
                                        (colorSelected)="updateVariableSettingColor($event, setting.id, variableColor.variable)"
                                        [ngStyle]="{ 'background-color': setting.color, color: setting.color | textColor }"
                                    ></shared-color-picker>
                                </td>
                                <td class="text-center">
                                    <app-checkbox
                                        [key]="setting.id"
                                        columns="12"
                                        [checked]="setting.visible ?? true"
                                        (clicked)="
                                            clickChkSettingVariablesVisibility(
                                                variableColor.variable,
                                                setting.id,
                                                setting.visible === undefined ? true : setting.visible
                                            )
                                        "
                                    ></app-checkbox>
                                </td>
                                <td class="text-center">
                                    <button
                                        class="btn btn-sm btn-danger my-1"
                                        (click)="deleteConfirmation(3, setting.id, variableColor.variable, setting.enviroment)"
                                        [hidden]="setting.enviroment === initialEnviroment"
                                    >
                                        <i class="fas fa-times-circle"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="variableColor?.dataType === variableNumericType">
                                <td>
                                    <app-input
                                        #txtVariableSettingColorEnviromentWithVariable
                                        [key]="'txtVariableSettingColorEnviroment' + variableColor.variable"
                                        label="Environment"
                                    ></app-input>
                                </td>
                                <td>
                                    <app-input
                                        #txtVariableSettingColorToWithVariable
                                        [key]="'txtVariableSettingColorTo' + variableColor.variable"
                                        label="To"
                                        type="number"
                                        withoutArrows="true"
                                        extraClasses="w-75 float-end"
                                    ></app-input>
                                </td>
                                <td class="text-center">
                                    <shared-color-picker
                                        class="level-2 badge me-1 w-50 py-2"
                                        [colorRange]="newColorSelected"
                                        [fromDataRange]="''"
                                        [toDataRange]="''"
                                        [symbol]="''"
                                        [middle]="newColorSelected"
                                        okButtonText="SELECT"
                                        (colorSelected)="selectedNewColor($event)"
                                        [ngStyle]="{ 'background-color': newColorSelected, color: newColorSelected | textColor }"
                                    ></shared-color-picker>
                                </td>
                                <td class="text-center">
                                    <app-checkbox
                                        #txtVariableSettingVisibilityWithVariable
                                        [key]="'txtVariableSettingVisibility' + variableColor.variable"
                                        columns="12"
                                        checked="true"
                                    ></app-checkbox>
                                </td>
                                <td class="text-center">
                                    <button
                                        class="btn btn-sm btn-primary my-1"
                                        (click)="
                                            addNewEnviroment(
                                                variableColor.variable,
                                                txtVariableSettingColorEnviromentWithVariable,
                                                txtVariableSettingColorToWithVariable,
                                                txtVariableSettingVisibilityWithVariable
                                            )
                                        "
                                    >
                                        <i class="fas fa-check" title="Add environment color/visibility"></i>
                                    </button>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal-centered #newVariableColorModal key="newVariableColorModal" title="Adding new Variable Color" [hideFooter]="true" extraSize="xl">
    <div class="row">
        <div class="col-12 col-md-10 offset-md-1">
            <app-select
                #linesSelector
                [item$]="line$"
                key="data-lines-dashboard-search"
                label="Equipment-line"
                columns="3"
                placeholder="Please select an Equipment-line"
                searchable="true"
                dropdownParent="#newVariableColorModal"
            ></app-select>
        </div>
        <div class="col-12 col-md-5 offset-md-1">
            <app-select
                #equipmentsSelector
                [item$]="lineEquipments$"
                key="data-equipments-dashboard-search"
                label="Equipment"
                columns="3"
                placeholder="Please select an Equipment"
                searchable="true"
                dropdownParent="#newVariableColorModal"
                [disabled]="!selectedLine"
            ></app-select>
        </div>
        <div class="col-12 col-md-5">
            <app-select
                #variableSelector
                [item$]="variable$"
                key="variable-dashboard-search"
                label="Variable"
                columns="3"
                placeholder="Please select a Variable"
                searchable="true"
                dropdownParent="#newVariableColorModal"
                [disabled]="!selectedLine || !selectedEquipment"
            ></app-select>
            <span class="small" *ngIf="selectedLine && selectedEquipment && selectedVariable">Value Type: {{ selectedVariableDataType }}</span>
        </div>
    </div>
    <div [hidden]="!selectedLine || !selectedEquipment || !selectedVariable" class="mb-1 container ms-5">
        <table class="table-compact table-hover w-75 mt-3 mb-5 ms-5" [hidden]="!newVariableSettingsColor || newVariableSettingsColor?.length === 0">
            <thead>
                <tr>
                    <th class="w-25">Environment</th>
                    <th class="w-25 text-end">{{ selectedVariableDataType === variableNumericType ? 'To' : 'Value' }}</th>
                    <th class="w-25 text-center">Color</th>
                    <th class="w-25 text-center">Visibility (icons only)</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let setting of newVariableSettingsColor; index as i; trackBy: trackById">
                    <td [ngStyle]="{ cursor: selectedVariableDataType !== variableNumericType || i === 0 ? 'auto' : 'pointer' }">
                        {{ setting.enviroment }}
                        <span *ngIf="selectedVariableDataType !== variableNumericType">
                            {{ setting.to === '1' ? ' - TRUE' : ' - FALSE' }}
                        </span>
                    </td>
                    <td class="text-end" [ngStyle]="{ cursor: selectedVariableDataType !== variableNumericType || i === 0 ? 'auto' : 'pointer' }">
                        {{ setting.to }}
                    </td>
                    <td class="text-center">
                        <shared-color-picker
                            class="level-2 badge me-1 w-50 py-2 my-1"
                            [colorRange]="setting.color"
                            [fromDataRange]="''"
                            [toDataRange]="''"
                            [symbol]="''"
                            [middle]="setting.color"
                            okButtonText="SELECT"
                            (colorSelected)="updateVariableSettingColor($event, setting.id, '')"
                            [ngStyle]="{ 'background-color': setting.color, color: setting.color | textColor }"
                        ></shared-color-picker>
                    </td>
                    <td class="text-center">
                        <app-checkbox
                            #txtNewSettingVisibilityListed
                            [key]="setting.id"
                            columns="12"
                            [checked]="setting.visible"
                            (clicked)="clickChkSettingBooleanTypeVisibility(txtNewSettingVisibilityListed, setting.id)"
                        ></app-checkbox>
                    </td>
                    <td class="text-center">
                        <button
                            class="btn btn-sm btn-danger my-1"
                            (click)="deleteConfirmation(2, setting.id, '', setting.enviroment)"
                            [hidden]="setting.enviroment === initialEnviroment"
                        >
                            <i class="fas fa-times-circle"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="selectedVariableDataType === variableNumericType">
                    <td>
                        <app-input #txtVariableSettingColorEnviroment key="txtVariableSettingColorEnviroment" label="Enviroment"></app-input>
                    </td>
                    <td>
                        <app-input
                            #txtVariableSettingColorTo
                            key="txtVariableSettingColorTo"
                            label="To"
                            type="number"
                            withoutArrows="true"
                            extraClasses="w-75 float-end"
                        ></app-input>
                    </td>
                    <td class="text-center">
                        <shared-color-picker
                            class="level-2 badge me-1 w-50 py-2"
                            [colorRange]="newColorSelected"
                            [fromDataRange]="''"
                            [toDataRange]="''"
                            [symbol]="''"
                            [middle]="newColorSelected"
                            okButtonText="SELECT"
                            (colorSelected)="selectedNewColor($event)"
                            [ngStyle]="{ 'background-color': newColorSelected, color: newColorSelected | textColor }"
                        ></shared-color-picker>
                    </td>
                    <td class="text-center">
                        <app-checkbox #txtNewSettingVisibility key="txtNewSettingVisibility" columns="12" checked="true"></app-checkbox>
                    </td>
                    <td class="text-center">
                        <button
                            class="btn btn-sm btn-primary my-1"
                            (click)="addNewEnviroment('', txtVariableSettingColorEnviroment, txtVariableSettingColorTo, txtNewSettingVisibility)"
                        >
                            <i class="fas fa-check"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row mt-2 p-0">
        <div class="col-12 text-center" *ngIf="variableColorAlreadyExists !== ''">
            <span class="text-danger">{{ variableColorAlreadyExists }}</span>
        </div>
        <div class="col-12 mt-2 pt-0 mb-3 pb-3">
            <button type="button" class="btn btn-auwa float-end me-5" (click)="newVariableColorModal.closeModal()">Close</button>
            <button
                class="btn btn-auwa float-end me-5 px-4"
                (click)="saveNewVariableSettingColor()"
                [hidden]="!newVariableSettingsColor || newVariableSettingsColor?.length === 0"
            >
                Save
            </button>
            <app-checkbox
                #applyForAllVariablesCheck
                class="float-end me-2 mt-2"
                key="chkApplyForAllVariables"
                label="Apply this color Settings for all Variables with the same name."
                columns="8"
                checked="true"
                (clicked)="clickChkApplyForAll()"
                [hidden]="!newVariableSettingsColor || newVariableSettingsColor?.length === 0"
            ></app-checkbox>
        </div>
    </div>
</app-modal-centered>
<app-modal-confirmation #confirmationModal key="variableColorConfirmationModal" (responseChanged)="confirmationEmited($event)" [dangerMode]="dangerMode">
    <span [innerHTML]="confirmationMessage.text"></span>
</app-modal-confirmation>
