import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { OffcanvasComponent } from '@ays/commons/lib/components/offcanvas/offcanvas.component';

@Component({
    selector: 'app-home-lines-minified-container',
    templateUrl: './home-lines-minified-container.component.html',
    styleUrls: ['./home-lines-minified-container.component.scss'],
})
export class HomeLinesMinifiedContainerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input() lineToShowOff: string = '';
    @Input() visibleStatesOff: Array<string> = [];
    @Output() offcanvasToggle = new EventEmitter();
    @ViewChild('offcanvasMapaMinified', { static: false }) offcanvasMapaMinified: OffcanvasComponent;
    showOffCanvas: () => void;
    hideOffCanvas: () => void;

    lineToShowOffTemp = { warehouseId: '', floorId: '', areaId: '', zoneId: '', lineId: '' };

    constructor() { }

    ngOnInit() { }

    async ngAfterViewInit(): Promise<void> {
        this.showOffCanvas = () => {
            this.offcanvasToggle.emit(true);
        };
        this.hideOffCanvas = () => {
            this.offcanvasToggle.emit(false);
        };
        const myOffcanvasEquipments = document.getElementById('offcanvasMapaMinifiedDOM');
        myOffcanvasEquipments.addEventListener('show.bs.offcanvas', this.showOffCanvas);
        myOffcanvasEquipments.addEventListener('hide.bs.offcanvas', this.hideOffCanvas);


    }

    hideOffcanvas(event) {
        if (!event) {
            this.offcanvasMapaMinified.hideDetails();
        }
    }

    async ngOnDestroy() {
        const myOffcanvasEquipments = document.getElementById('offcanvasMapaMinifiedDOM');
        myOffcanvasEquipments.removeEventListener('show.bs.offcanvas', this.showOffCanvas);
        myOffcanvasEquipments.removeEventListener('hide.bs.offcanvas', this.hideOffCanvas);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.lineToShowOff?.currentValue) {
            this.lineToShowOffTemp = JSON.parse(changes.lineToShowOff?.currentValue);

            if (this.lineToShowOffTemp && this.lineToShowOffTemp?.lineId !== '') {
                this.offcanvasMapaMinified.showDetails();
            }
        }
    }
}
