import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ErrorTrendColors } from '@variables/shared/variable';

const FIRST_ERROR_DEFAULT_COLOR = '#ffffe5';
const SECOND_ERROR_DEFAULT_COLOR = '#fff2e5';
const THIRD_ERROR_DEFAULT_COLOR = '#ffe5e5';

const MIN_DEFAULT_RANGE = 20;
const MAX_DEFAULT_RANGE = 80;

const INITIAL_ENVIROMENT = 'DEFAULT';

@Component({
    selector: 'settings-errors-trend',
    templateUrl: './settings-errors-trend.component.html',
    styleUrls: ['./settings-errors-trend.component.scss'],
})
export class SettingsErrorsTrendComponent implements OnInit {
    @Input({ required: true }) errorTrendColors: ErrorTrendColors[] = [];
    @Output() newErrorTrend = new EventEmitter();

    errorRangeColorsTemporal: {
        firstColorRange: string;
        secondColorRange: string;
        thirdColorRange: string;
        firstRange: number;
        secondRange: number;
    };

    constructor() { }

    ngOnInit(): void {
        this.initializeErrorRangeColors()
    }

    initializeErrorRangeColors() {
        this.errorRangeColorsTemporal = {
            firstColorRange: FIRST_ERROR_DEFAULT_COLOR,
            secondColorRange: SECOND_ERROR_DEFAULT_COLOR,
            thirdColorRange: THIRD_ERROR_DEFAULT_COLOR,
            firstRange: MIN_DEFAULT_RANGE,
            secondRange: MAX_DEFAULT_RANGE,
        };

        if (!this.errorTrendColors.find((element) => element.fqn === INITIAL_ENVIROMENT)) {
            const defaultError: ErrorTrendColors = {
                fqn: INITIAL_ENVIROMENT,
                ...this.errorRangeColorsTemporal,
            };
            this.errorTrendColors.push(defaultError);
            this.updateErrorTrendColors()
        }
    }

    saveColorPickerErrors(event, fqn: string = '') {
        if (fqn === '') {
            this.errorRangeColorsTemporal[event[1]] = event[2];
        } else {
            const errorToUpdate = this.errorTrendColors.find((element) => element.fqn === fqn);
            errorToUpdate[event[1]] = event[2];
            this.updateErrorTrendColors()
        }
    }

    calculateNewRangesErrors(event, fqn: string = '') {
        if (fqn === '') {
            this.errorRangeColorsTemporal['firstRange'] = event[1].minVal || MIN_DEFAULT_RANGE;
            this.errorRangeColorsTemporal['secondRange'] = event[1].maxVal || MAX_DEFAULT_RANGE;
        } else {
            const errorToUpdate = this.errorTrendColors.find((element) => element.fqn === fqn);
            errorToUpdate['firstRange'] = event[1].minVal || errorToUpdate['firstRange'];
            errorToUpdate['secondRange'] = event[1].maxVal || errorToUpdate['secondRange'];
            this.updateErrorTrendColors()
        }
    }

    updateErrorTrendColors() {
        this.newErrorTrend.emit({
            errorTrend: [...this.errorTrendColors]
        });
    }
}
