<div *ngIf="structure">
    <div class="col-12">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-12 svg-wrapper home" *ngFor="let floor of structure.floors">
                <app-home-lines-map
                    (newLineSelected)="newLineSelected($event)"
                    [activateZoom]="activateZoom"
                    [warehouse]="warehouse.warehouse"
                    [floor]="floor"
                    [visibleStates]="visibleStates"
                    [wheelZoom]="false"
                >
                </app-home-lines-map>
            </div>
        </div>
    </div>
</div>
<app-home-lines-minified-container [lineToShowOff]="lineToShow" [visibleStatesOff]="visibleStates" (offcanvasToggle)="offcanvasToggle($event)">
</app-home-lines-minified-container>
