export interface Ranges {
    num: string;
    dimension: string;
    label: string;
}
export const calendar15DaysRangeWithoutMinutes: Array<Ranges> = [
    { num: '1', dimension: 'hours', label: 'Last hour' },
    { num: '6', dimension: 'hours', label: 'Last 6 hours' },
    { num: '12', dimension: 'hours', label: 'Last 12 hours' },
    { num: '24', dimension: 'hours', label: 'Last 24 hours' },
    { num: '2', dimension: 'days', label: 'Last 2 days' },
    { num: '7', dimension: 'days', label: 'Last 7 days' },
    { num: '15', dimension: 'days', label: 'Last 15 days' },
];
export const calendar15DaysRange: Array<Ranges> = [
    { num: '15', dimension: 'minutes', label: 'Last 15 minutes' },
    { num: '30', dimension: 'minutes', label: 'Last 30 minutes' },
    ...calendar15DaysRangeWithoutMinutes,
];
