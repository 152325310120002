import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '@environments/environment';
import { StateService } from '../state/state.service';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import * as _ from 'lodash-es';

export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@Injectable()
export class SettingsService {
    globalSettings: any;
    authenticationSettings: any;
    instancesSettings: any;
    msalSettings: any;
    signalRSettings: any;
    applicationInsightsSettings: any;
    msalConfig: PublicClientApplication;
    msalInterceptorConfig: MsalInterceptorConfiguration;
    msalGuardConfig: MsalGuardConfiguration;

    constructor(
        private location: Location,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private stateService: StateService,
    ) { }

    isEmpty = (str: any) => typeof str === 'undefined' || !str || 0 === str.length;
    getBaseUrl = (): string => `${window.location.protocol}//${window.location.host}`;

    async initializeService() {
        if (environment.mode === 'front') {
            return Promise.resolve();
        }
        try {
            const request = await fetch('settings.json');
            const settingsJson = await request.json()
            this.authenticationSettings = settingsJson.authentication;
            this.instancesSettings = settingsJson.instances;
            this.msalSettings = settingsJson.msal;
            this.signalRSettings = settingsJson.signalR;
        } catch (e) {
            return Promise.reject();
        }

        const baseUrl = this.authenticationSettings.defaultApiBaseUrl;
        const otherUrls = Object.values(this.instancesSettings);
        this.stateService.setApiUri(baseUrl);
        const protectedResourceMap: Map<string, string[]> = new Map();
        if (environment.mode !== 'front') {
            [baseUrl, ...otherUrls].forEach((instanceUrl) => {
                protectedResourceMap.set(`${instanceUrl}/`, [this.msalSettings.apiId + '/user_impersonation']);
            });
            protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
        } else {
            protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
        }
        this.msalConfig = new PublicClientApplication({
            auth: {
                authority: 'https://login.microsoftonline.com/' + this.msalSettings.tenantId,
                clientId: this.msalSettings.clientId,
                navigateToLoginRequestUrl: true,
                redirectUri: this.getBaseUrl(),
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: isIE,
                claimsBasedCachingEnabled: true,
            },
            system: {
                loggerOptions: {
                    loggerCallback: () => { },
                    piiLoggingEnabled: false,
                },
            },
        });
        this.msalInterceptorConfig = {
            interactionType: InteractionType.Redirect,
            protectedResourceMap,
        };
        this.msalGuardConfig = {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read', this.msalSettings.apiId],
            },
            loginFailedRoute: './login-failed',
        };
    }

    getMsalConfig(): PublicClientApplication {
        return this.msalConfig;
    }
    getMsalInterceptorConfig(): MsalInterceptorConfiguration {
        return this.msalInterceptorConfig;
    }
    getmsalGuardConfig(): MsalGuardConfiguration {
        return this.msalGuardConfig;
    }

    getBackendConfiguration() {
        return this.http.get<any>(`${this.authenticationSettings.defaultApiBaseUrl}/settings`).pipe(take(1));
    }

    filterWarehouses(resp: { warehouses: any[] }) {
        let userGroups = [];
        this.authenticationService.getGroups.pipe(take(1)).subscribe((res) => (userGroups = res));
        if (userGroups.includes('auwa-super-admin')) {
            return resp.warehouses;
        } else {
            const filteredWarehouses = resp.warehouses.filter((wh: { warehouseId: any }) => {
                if (userGroups.includes(`auwa-user-${wh.warehouseId}`)) {
                    return wh;
                }
            });
            return filteredWarehouses;
        }
    }
}
