<!-- All variables but Reset ones -->
<span
    [hidden]="componentType === 'Switch' || ((variableType || '').toLowerCase() === COMMAND.toLowerCase() && variableName.toUpperCase().includes('RESET'))"
    [title]="(variableType || '').toLowerCase() === COMMAND.toLowerCase() ? 'Double click to edit' : ''"
    (dblclick)="editInputValue(variable)"
    [style.height]="'100%'"
    [style.display]="variable.isOpcError || (variable.isOpcError && variable.opcErrorType === 'Missing') ? '' : 'block'"
>
    <span title="Error message" [hidden]="!variableState?.error" class="error-message">
        {{ variableState?.errorMessage }}
    </span>
    <span
        [hidden]="variableState?.inputState?.includes(EDITING) || variableState?.error"
        class="value"
        [ngClass]="isActiveVariable(variableValue) ? 'fw-bold' : ''"
        [innerHTML]="![null, undefined].includes(variableState?.value) ? variableState?.value : (variableValue | variableValueTypePipe: variableValueType)"
    >
    </span>
    <input
        #variableInput
        [id]="'variableInput' + fqn + variableInput"
        class="col-md-6"
        [hidden]="componentType === 'Switch' || !variableState?.inputState?.includes(EDITING) || variableState?.error"
        (keyup)="updateInputValue($event)"
        (keyup.esc)="updateInputValue($event)"
        (keydown.enter)="saveInputValue()"
        (focusout)="inputFocusLost()"
        type="text"
        [value]="![null, undefined].includes(variableState?.value) ? variableState?.value : variableValue"
    />
    <i class="fa fa-spinner spinner float-end" style="font-size: 16px" [hidden]="!variableState?.inputState?.includes(SAVING)"></i>
    <i
        class="fa fa-check-circle refreshed float-end me-1"
        style="display: block"
        title="Refreshed"
        [hidden]="!variableState?.inputState?.includes(REFRESHED)"
    ></i>
    <i
        class="fa fa-sync-alt float-end me-1"
        title="Refresh"
        (click)="refreshInputValue()"
        [ngClass]="variableState?.inputState?.includes(REFRESHING) ? 'spinner' : ''"
        [hidden]="
            !refreshable ||
            variableState?.inputState?.includes(EDITING) ||
            variableState?.inputState?.includes(DIRTY) ||
            variableState?.inputState?.includes(REFRESHED) ||
            variableState?.inputState?.includes(SAVING) ||
            variableState?.error
        "
    ></i>
    <i
        class="fa fa-pen float-end me-1"
        title="Updated"
        [hidden]="!variableState?.inputState?.includes(UPDATED) || variableState?.inputState?.includes(SAVING)"
        [ngClass]="variableState?.inputState?.includes(UPDATED) || !variableState?.inputState?.includes(SAVING) ? 'updated ' : ''"
    ></i>
    <i
        class="fa fa-save float-end me-1"
        title="Save"
        (click)="saveInputValue()"
        [hidden]="variableState?.inputState?.includes(SAVING) || !variableState?.inputState?.includes(DIRTY) || variableState?.error"
    ></i>
    <i
        class="fa fa-times float-end me-1"
        title="Discard"
        (click)="undoInputChanges()"
        [hidden]="variableState?.inputState?.includes(SAVING) || !variableState?.inputState?.includes(DIRTY) || variableState?.error"
    ></i>
</span>
<!-- Reset Variable Button -->
<span
    [hidden]="!((variableType || '').toLowerCase() === COMMAND.toLowerCase() && variableName.toUpperCase().includes('RESET'))"
    title="Reset counters"
    style="display: block; height: 100%"
>
    <i class="fa fa-spinner spinner float-start" style="font-size: 16px" [hidden]="!variableState?.inputState?.includes(SAVING)"></i>
    <span title="Error message" [hidden]="!variableState?.error" class="error-message">
        {{ variableState?.errorMessage }}
    </span>
    <i
        class="fa fa-power-off float-end me-1"
        [ngClass]="isResetting ? 'reset-btn-circle reset-btn-pulse text-success' : 'text-danger'"
        title="Reset counters"
        (click)="resetVariable('1')"
    ></i>
</span>
<!-- Switch Variable Button -->
<span [hidden]="componentType !== 'Switch'" title="Switch value" class="negative-margins">
    <app-toggle-simple
        [key]="fqn"
        [columns]="1"
        [checked]="isActiveVariable(variableValue)"
        trueText="ON"
        falseText="OFF"
        circleColor="#ffffff"
        (clicked)="switchValue(isActiveVariable(variableValue))"
        class="float-end me-4"
    >
    </app-toggle-simple>
    <span title="Error message" [hidden]="!variableState?.error" class="error-message w-100">
        {{ variableState?.errorMessage }}
    </span>
</span>
