import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { SearchComponent } from '@ays/commons/lib/components/forms/search/search.component';
import { setFqnPreferences, setIdentifiers, VariableColors, VariableColorSettings } from '@variables/shared/variable';
import { SelectMultilevelComponent } from '../../forms/select-multilevel/select-multilevel.component';
import { takeUntil } from 'rxjs/operators';
import { LinesService } from '@app/map/lines/shared/lines.service';
import { Subject } from 'rxjs';
import { IdentifiersPreferences } from '@app/map/home/shared/structure';
import { VariableValueType, WILD_CARD_CHARACTER } from '@app/notifications/shared/events/variable-status';
import { v4 as uuid } from 'uuid';
import { SelectComponent } from '@ays/commons/lib/components/forms/select/select.component';
import { CheckboxComponent } from '@ays/commons/lib/components/forms/checkbox/checkbox.component';
import { ModalCenteredComponent } from '@ays/commons/lib/components/modal-centered/modal-centered.component';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { ModalConfirmationComponent } from '@ays/commons/lib/components/modal-confirmation/modal-confirmation.component';

const INITIAL_ENVIROMENT = 'DEFAULT';
const INITIAL_COLOR_SELECTED = '#eeeeee';

@Component({
    selector: 'settings-variable-color',
    templateUrl: './settings-variable-color.component.html',
    styleUrls: ['./settings-variable-color.component.scss'],
})
export class SettingsVariableColorComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
    @Input({ required: true }) variableColors: VariableColors[] = [];
    @Input({ required: true }) identifiersPreferences: IdentifiersPreferences;
    @Input({ required: false }) variableColorToDelete: { id: string, variable: string };
    @Output() newVariableColor = new EventEmitter();
    @Output() variableColorDeleteConfirmation = new EventEmitter();
    @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalConfirmationComponent;
    @ViewChild('newVariableColorModal', { static: false }) newVariableColorModal: ModalCenteredComponent;
    @ViewChild('applyForAllVariablesCheck', { static: false }) applyForAllVariablesCheck: CheckboxComponent;
    @ViewChild('variableFilter', { static: false }) variableFilter: SearchComponent;
    @ViewChild('linesSelector') linesSelector: SelectComponent;
    @ViewChild('equipmentsSelector') equipmentsSelector: SelectComponent;
    @ViewChild('variableSelector') variableSelector: SelectComponent;
    $linesSelector: JQuery;
    $equipmentsSelector: JQuery;
    lineEquipments$: Array<string> = [];
    variable$: Array<any> = [];
    $variableSelector: JQuery;
    line$: Array<string> = [];
    editingVariableColorTo = '';
    editingVariableColorEnviroment = '';
    variableColorAlreadyExists = '';
    newColorSelected: string = INITIAL_COLOR_SELECTED;
    newVariableSettingsColor: VariableColorSettings[] = [];
    selectedVariableDataType: string;
    selectedVariable: string;
    selectedVariableCompleteFQN: string;
    selectedLine: string;
    selectedEquipment: string;
    initialEnviroment: string = INITIAL_ENVIROMENT;
    variableFilterValue = '';
    variableNumericType = VariableValueType.Numeric.toLowerCase();
    confirmationApplyToAllDone = false;
    dangerMode = false;
    confirmationMessage = {
        messageId: 0,
        parameters: {},
        text: null,
    };
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(private linesService: LinesService, private equipmentsService: EquipmentsService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.variableColorToDelete?.currentValue) {
            this.deleteVariableSettingColor(changes.variableColorToDelete?.currentValue);
        }
    }

    ngOnInit(): void {
        this.linesService
            .getLinesNames()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((lines) => {
                this.line$ = this.setFAZLPreferences(lines);
            });
    }

    ngAfterViewInit() {
        // const visibilityCheckboxes = document.querySelectorAll('table div.form-check');
        // visibilityCheckboxes.forEach(checkbox => checkbox.setAttribute('style', 'padding-left:0px'));

        this.$linesSelector = this.linesSelector.$select;
        this.$linesSelector.off('change').on('change', () => {
            this.selectedLine = String(this.$linesSelector.find('option:selected').val());
            this.listEquipments(this.selectedLine);
        });
        this.$equipmentsSelector = this.equipmentsSelector.$select;
        this.$equipmentsSelector.off('change').on('change', () => {
            this.selectedEquipment = String(this.$equipmentsSelector.find('option:selected').val());
            this.listVariables(this.selectedLine, this.selectedEquipment);
        });
        this.$variableSelector = this.variableSelector.$select;
        this.$variableSelector.off('change').on('change', () => {
            this.selectedVariable = String(this.$variableSelector.find('option:selected').val());

            this.selectedVariableDataType = this.variable$.find(v => v.value === this.selectedVariable)?.dataType;

            this.configureSelectedVariableCompleteFQN();

            const newVariable: VariableColorSettings = {
                id: uuid(),
                enviroment: INITIAL_ENVIROMENT,
                to: '0',
                color: INITIAL_COLOR_SELECTED,
                visible: true,
            };
            this.newVariableSettingsColor = [];
            this.newVariableSettingsColor.push(newVariable);

            if (this.selectedVariableDataType === VariableValueType.Boolean.toLowerCase()) {
                const newVariable: VariableColorSettings = {
                    id: uuid(),
                    enviroment: INITIAL_ENVIROMENT,
                    to: '1',
                    color: INITIAL_COLOR_SELECTED,
                    visible: true,
                };
                this.newVariableSettingsColor.push(newVariable);
            }
        });

        const that = this;
        this.variableFilter.$input.off('keyup').on('keyup', function (e) {
            that.variableFilterValue = String($(this).val()).toUpperCase();
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    colorSelected(color, variable, id /*from,to*/) {
        const variableToSetColor = this.variableColors?.find((v) => v.variable === variable).settings?.find((s) => s.id === id);
        if (variableToSetColor) {
            variableToSetColor.color = color;
        }
    }

    selectedNewColor(color) {
        this.newColorSelected = color;
    }

    setFAZLPreferences(items: string[]): string[] {
        const result = [];
        let item = '';
        items.map((e) => {
            const id = e.split('-');
            //const warehouseId = id.shift();
            item = setFqnPreferences(
                id[1],
                this.identifiersPreferences.Floor,
                id[2],
                this.identifiersPreferences.Area,
                id[3],
                this.identifiersPreferences.Zone,
                id[4],
                this.identifiersPreferences.Line,
            );
            result.push(item);
            result.sort();
        });
        return result;
    }

    listEquipments(selectedLine: string) {
        this.selectedVariableCompleteFQN = '';
        let [floorId, areaId, zoneId, lineId] = selectedLine.split('-') || [];
        if (floorId && areaId && zoneId && lineId) {
            [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
            //this.selectedVariableCompleteFQN = [areaId, zoneId, floorId, lineId].join('.');
            this.equipmentsService
                .getEquipmentNamesByLine(floorId, areaId, zoneId, lineId)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    this.lineEquipments$ = res;
                });
        } else {
            this.lineEquipments$ = [];
        }
    }

    listVariables(selectedLine: string, selectedEquipment: string) {
        let [floorId, areaId, zoneId, lineId] = selectedLine.split('-') || [];
        [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
        const [equipmentSystem, equipmentType] = selectedEquipment.split('-') || [];
        if (equipmentSystem && equipmentType) {
            this.equipmentsService
                .getVariablesFromEquipment(floorId, areaId, zoneId, lineId, equipmentSystem, equipmentType)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((variables) => {
                    /* only NUMERIC and BOOLEAN datatypes */
                    this.variable$ = variables
                        ?.filter((numOrBoolVariable) => (numOrBoolVariable.datatype.toLowerCase() === VariableValueType.Numeric.toLowerCase() || numOrBoolVariable.datatype.toLowerCase() === VariableValueType.Boolean.toLowerCase()))
                        ?.map((v) => {
                            return { value: [v.name, v.type].join('-').toUpperCase(), name: v.name, dataType: v.datatype };
                        });
                });
        } else {
            this.variable$ = [];
        }
    }

    updateVariableSettingColor(color, id, variable) {
        if (variable === '') {
            const variableToSetColor = this.newVariableSettingsColor?.find((s) => s.id === id);
            if (variableToSetColor) {
                variableToSetColor.color = color;
            }
        } else {
            const variableToSetColorAndUpdate = this.variableColors
                .find((v) => v.variable === variable)
                ?.settings?.find((s) => s.id === id);
            if (variableToSetColorAndUpdate) {
                variableToSetColorAndUpdate.color = color;

                this.updateVariableColors()
            }
        }
    }

    deleteVariableSettingColor(variableColorToDelete: { id: string, variable: string }) {
        const { id, variable } = variableColorToDelete
        /* only id when delete a new color setting */
        /* only variable when delete the entire color setting for a variable */
        /* id and variable when delete an enviroment of color setting */
        if (id !== '' && variable === '') {
            const index = this.newVariableSettingsColor.findIndex((v) => v.id === id);
            if (index !== -1) this.newVariableSettingsColor.splice(index, 1);
        }
        if (id === '' && variable !== '') {
            this.variableColors = this.variableColors.filter((v) => v.variable !== variable);

            this.updateVariableColors()
        }
        if (id !== '' && variable !== '') {
            const variableToUpdate = this.variableColors.find((v) => v.variable === variable);
            if (variableToUpdate) {
                const index = variableToUpdate.settings.findIndex((s) => s.id === id);
                if (index !== -1) variableToUpdate.settings.splice(index, 1);

                this.variableColors.find((v) => v.variable === variable).settings = variableToUpdate.settings;

                this.updateVariableColors()
            }
        }
    }

    addNewEnviroment(variable, txtEnviroment, txtTo, txtVisibility) {
        const enviroment = txtEnviroment.$input.val().toUpperCase().trim();
        const to = txtTo.$input.val().trim();
        const visibility = txtVisibility.$checkbox.prop('checked');

        if (enviroment.length === 0 || to.length === 0) {
            return;
        }
        const newVariable: VariableColorSettings = {
            id: uuid(),
            enviroment,
            to: (+to).toString(),
            color: this.newColorSelected,
            visible: visibility,
        };

        if (variable === '') {
            this.newVariableSettingsColor.push(newVariable);
            this.newVariableSettingsColor.sort((a, b) => (+a.to > +b.to ? 1 : -1));
        } else {
            const variableToUpdate = this.variableColors.find((v) => v.variable === variable);
            if (variableToUpdate) {
                variableToUpdate.settings.push(newVariable);
                this.variableColors.find((v) => v.variable === variable)?.settings.sort((a, b) => (+a.to > +b.to ? 1 : -1));

                this.updateVariableColors()
                this.editingVariableColorEnviroment = '';
            }
        }

        txtEnviroment.$input.val('');
        txtTo.$input.val('');

        this.newColorSelected = INITIAL_COLOR_SELECTED;
    }

    saveNewVariableSettingColor() {
        const variableExists = this.variableColors?.findIndex((v) => v.variable === this.selectedVariableCompleteFQN);

        if (variableExists && variableExists !== -1) {
            this.variableColorAlreadyExists = `Already exists a Color setting for variable ${this.selectedVariableCompleteFQN}.`;
            return;
        }

        if (this.applyForAllVariablesCheck.$checkbox.prop('checked') && !this.confirmationApplyToAllDone) {
            this.confirmationMessage = {
                messageId: 1,
                parameters: null,
                text: `This configuration will apply to <strong>ALL</strong> variables with name <strong>${this.selectedVariable?.split('-')[0]}</strong>.`,
            };
            this.confirmationModal.openModal();
            return;
        }
        this.confirmationApplyToAllDone = false;

        const newVariableSettingColorToAdd: VariableColors = {
            variable: this.selectedVariableCompleteFQN,
            settings: this.newVariableSettingsColor,
            dataType: this.selectedVariableDataType
        };

        this.variableColors.push(newVariableSettingColorToAdd);

        this.variableColors.sort((a, b) => (a.variable > b.variable ? 1 : -1));

        this.newVariableSettingsColor = [];

        this.updateVariableColors()
    }

    editingValue(setting, txtControl, enviromentOrTo) {
        if (setting.enviroment.toUpperCase() !== INITIAL_ENVIROMENT.toUpperCase()) {
            const value = enviromentOrTo === 'to' ? setting.to : setting.enviroment;

            txtControl.$input.val(value);
            txtControl.$input.trigger('blur');

            if (enviromentOrTo === 'enviroment') {
                this.editingVariableColorEnviroment = setting.id;
            }
            if (enviromentOrTo === 'to') {
                this.editingVariableColorTo = setting.id;
            }
        }
    }

    saveValue(id, variable, txtControl, enviromentOrTo) {
        let newValue;
        if (enviromentOrTo === 'enviroment') {
            newValue = txtControl.$input.val().toUpperCase() || '';
            if (!newValue) {
                return;
            }
        } else if (enviromentOrTo === 'to') {
            newValue = txtControl.$input.val() || 0;
        }

        const variableToUpdate = this.variableColors.find((v) => v.variable === variable)?.settings?.find((s) => s.id === id);
        if (variableToUpdate) {
            if (enviromentOrTo === 'enviroment') {
                variableToUpdate.enviroment = newValue;
                this.editingVariableColorEnviroment = '';
            }
            if (enviromentOrTo === 'to') {
                variableToUpdate.to = newValue;
                this.editingVariableColorTo = '';

                this.variableColors.find((v) => v.variable === variable)?.settings.sort((a, b) => (+a.to > +b.to ? 1 : -1));
            }

            this.updateVariableColors()
        }
    }

    trackById(index: number, element: any) {
        return element?.id;
    }
    trackByVariable(index: number, element: any) {
        return element?.variable;
    }

    clickChkApplyForAll() {
        this.configureSelectedVariableCompleteFQN();
    }

    clickChkSettingVariablesVisibility(variable, id, visibility) {
        const variableToUpdate = this.variableColors.find((v) => v.variable === variable)?.settings?.find((s) => s.id === id);
        if (variableToUpdate) {
            this.variableColors.find((v) => v.variable === variable).settings.find((s) => s.id === id).visible = !visibility;

            this.updateVariableColors()
        }
    }

    clickChkSettingBooleanTypeVisibility(element, id) {
        const elementToUpdate = this.newVariableSettingsColor.find(setting => setting.id === id);
        elementToUpdate.visible = element.$checkbox.prop('checked');
    }

    configureSelectedVariableCompleteFQN() {
        this.variableColorAlreadyExists = '';

        let [floorId, areaId, zoneId, lineId] = this.selectedLine.split('-') || [];
        [floorId, areaId, zoneId, lineId] = setIdentifiers(floorId, areaId, zoneId, lineId);
        const [equipmentSystem, equipmentType] = this.selectedEquipment.split('-') || [];
        const [variable, type] = this.selectedVariable.split('-');

        if (this.applyForAllVariablesCheck.$checkbox.prop('checked')) {
            const myDefaultChar = WILD_CARD_CHARACTER;
            this.selectedVariableCompleteFQN = [...myDefaultChar.repeat(7).split(''), variable].join('.');
        } else {
            this.selectedVariableCompleteFQN = [areaId, zoneId, floorId, lineId, equipmentSystem, equipmentType, type, variable].join('.');
        }
    }

    onClickAddNewVariableColor() {
        this.newVariableColorModal.openModal();
    }







    updateVariableColors() {
        this.newVariableColor.emit({
            variableColors: [...this.variableColors]
        });
    }

    deleteConfirmation(messageId: number, id: string, variable: string, enviroment: string) {
        this.variableColorDeleteConfirmation.emit({
            messageId, id, variable, enviroment
        });
    }

    confirmationEmited(value) {
        if (value) {
            switch (this.confirmationMessage.messageId) {
                case 1: {
                    this.confirmationApplyToAllDone = true;
                    this.saveNewVariableSettingColor();
                    break;
                }
                default: {
                }
            }
        }
        this.confirmationMessage = { messageId: 0, parameters: null, text: '' };
        this.confirmationModal.closeModal();
    }

}
