import * as _ from 'lodash-es';

export class Utils {
    public static removeHighlight(data) {
        const parsedData = _.cloneDeep(data);
        for (const key in parsedData) {
            if (parsedData.hasOwnProperty(key)) {
                if (typeof parsedData[key] === 'string') {
                    parsedData[key] = parsedData[key].replaceAll('<span class="highlight">', '').replaceAll('</span>', '');
                }
            }
        }
        return parsedData;
    }
    public static removeHighlightFromString(data) {
        return data.replaceAll('<span class="highlight">', '').replaceAll('</span>', '');
    }

    public static numericSortingFloors(data) {
        try {
            const sortedFloors = data.floors?.sort((a, b) => {
                const firstOperand = a.id?.replace('F', '') || 0;
                const secondOperand = b.id?.replace('F', '') || 0;
                return parseInt(firstOperand) - parseInt(secondOperand);
                // if (!isNaN(+firstOperand) && !isNaN(+secondOperand)) {
                //     return (+firstOperand > +secondOperand ? 1 : -1)
                // } else {
                //     return (firstOperand > secondOperand ? 1 : -1)
                // }
            });
            return { ...data, floors: sortedFloors };
        } catch (error) {
            console.log(`%c Error sortingFloors => ${error}`, `background: ; color: red`);
            return data
        }
    }
}
