<div #contextMenu class="dropdown-menu dropdown-menu-right shadow-sm contextMenuHtml" id="contextMenu{{ contextId }}">
    <a class="dropdown-item" (click)="showLine()"
        >Show Line {{ this.keySelectedWithRightClick.split('-')[3] || '' | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]] }}</a
    >
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" (click)="goToLine()"
        >Go to Line {{ this.keySelectedWithRightClick.split('-')[3] || '' | fazlDecorator: [[], [], [], ['EQL', identifiersPreferences.Line]] }}</a
    >
    <a *ngIf="hideLink !== GoToLink.ZONE" class="dropdown-item" (click)="goToZone()"
        >Go to Zone {{ this.keySelectedWithRightClick.split('-')[2] || '' | fazlDecorator: [[], [], ['Z', identifiersPreferences.Zone], []] }}</a
    >
    <a *ngIf="hideLink !== GoToLink.AREA" class="dropdown-item" (click)="goToArea()"
        >Go to Area {{ this.keySelectedWithRightClick.split('-')[1] || '' | fazlDecorator: [[], ['A', identifiersPreferences.Area], [], []] }}</a
    >
    <a *ngIf="hideLink !== GoToLink.FLOOR" class="dropdown-item" (click)="goToFloor()"
        >Go to Floor {{ this.keySelectedWithRightClick.split('-')[0] || '' | fazlDecorator: [['F', identifiersPreferences.Floor], [], [], []] }}</a
    >
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" (click)="copyLineURL()">Copy Line URL</a>
</div>
