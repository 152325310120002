import { NgModule } from '@angular/core';
import { UserManualComponent } from './user-manual.component';
import { RouterModule } from '@angular/router';
import { childRoutes } from './user-manual.routes';
import { MarkdownModule } from 'ngx-markdown';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule, RouterModule.forChild(childRoutes), MarkdownModule.forChild()],
    declarations: [UserManualComponent],
    providers: [],
})
export class UserManualModule {}
