/* eslint-disable @typescript-eslint/naming-convention */
import { Component, AfterViewInit, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SelectMultilevelComponent } from '@app/shared/components/forms/select-multilevel/select-multilevel.component';
import { Variable, VariableType, VARIABLES_MODE } from '@variables/shared/variable';
import { VariablesActiveStatusService } from '@app/notifications/shared/handlers/variablesActive-status-service';
import { InputState, VariableInputState } from '@app/shared/components/variable-input/variable-input.component';
import {
    VARIABLE_EMPTY_REQUEST_ID,
    VariableFilter,
    VariableFilters,
    VariableNotification,
    VariableValueType,
} from '@app/notifications/shared/events/variable-status';
import { SelectComponent } from '@ays/lib/components/forms/select/select.component';
import { NavigationService } from '@app/core/shared/navigation/navigation.service';
import { InputComponent } from '@ays/lib/components/forms/input/input.component';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { TableComponentDirective } from '@ays';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppLoadService } from '@app/app-load.service';
import { AuthenticationService } from '@app/core/shared/authentication/authentication.service';
import { environment } from '@environments/environment';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Warehouse } from '@app/core/shared/warehouse';
//import { PageLifecycleService } from '@app/shared/services/page-lifecycle.service';
import { UserConfiguration } from '@app/shared/models/configurations';
import { VariablesStatusClient } from '@app/notifications/shared/clients/variables-status.client';
import { VariablesService } from '@variables/shared/variables.service';
import { IdentifiersPreferences } from '@app/map/home/shared/structure';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash-es';
import * as dayjs from 'dayjs';

const MAX_ROWS = 500;
const ALL = 'All';
const EQUALS = 'Equals';
const NOT_EQUALS = 'NotEquals';
const GREATER = 'Greater';
const GREATER_OR_EQUALS = 'GreaterOrEquals';
const LESSER = 'Lesser';
const LESSER_OR_EQUALS = 'LesserOrEquals';
const BETWEEN = 'Between';
const ACTIVE = 'Active';
const INACTIVE = 'Inactive';
const RUNNING = 'running';
const PAUSED = 'paused';
const UPDATE_REALTIME = 'update-realtime';
const { ALARM, COMMAND, WARNING, STATUS } = VariableType;
const { EDITING, REFRESHED } = InputState;
const RT_VARIABLES = 'Real Time Variable Status';

@Component({
    selector: 'app-variables-active',
    templateUrl: './variables-active.component.html',
    styleUrls: ['./variables-active.component.scss'],
    providers: [VariablesActiveStatusService, VariablesStatusClient],
})
export class VariablesActiveComponent extends TableComponentDirective<Variable> implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('variablesType') variablesType: SelectComponent;
    @ViewChild('variablesName') variablesName: InputComponent;
    @ViewChild('fqnFilter') fqnFilter: InputComponent;
    @ViewChild('valueOperators') valueOperators: SelectComponent;
    @ViewChild('valueInput') valueInput: InputComponent;
    @ViewChild('valueMax') valueMax: InputComponent;
    @ViewChild('valueMin') valueMin: InputComponent;
    @ViewChild('valueFixed') valueFixed: SelectComponent;
    @ViewChild('equipmentName') equipmentName: InputComponent;
    @ViewChild('equipmentType') equipmentType: SelectComponent;
    @ViewChild('line') line: InputComponent;
    @ViewChild('fazFilter') fazFilter: SelectMultilevelComponent;

    warehouse: Warehouse;
    equipmentTypes: string[];

    $variablesType: JQuery;
    $variablesName: JQuery;
    $fqnFilter: JQuery;
    $valueOperators: JQuery;
    $valueInput: JQuery;
    $valueMax: JQuery;
    $valueMin: JQuery;
    $valueFixed: JQuery;
    $equipmentName: JQuery;
    $equipmentType: JQuery;
    $line: JQuery;
    $fazFilter: JQuery;
    identifiersPreferences: IdentifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    currentRequestId: string;
    realTimeVariables: Variable[] = [];
    currentFilters: VariableFilter[] = [];
    state: State = {};
    waitForResponse = false;
    waitForResponseTimeout = null;
    type$: Array<string> = [ALL, ALARM, COMMAND, STATUS, WARNING];
    pageSizes: Array<number> = [15, 50];
    pageSize = 15;
    operators$: Array<string> = [EQUALS, NOT_EQUALS, GREATER, GREATER_OR_EQUALS, LESSER, LESSER_OR_EQUALS, BETWEEN];
    operatorVal = EQUALS;
    activeValues$ = [ALL, INACTIVE, ACTIVE];
    isFilterSet = false;
    subscribed = false;
    condition: any = undefined;
    realTimeStatus = RUNNING;
    orderColumn = 'variableSourceTimestamp';
    orderType = 'desc';
    typeVal: VariableType = null;
    fqnVal = '';
    nameVal = '';
    floorVal = '';
    areaVal = '';
    zoneVal = '';
    valueVal = '';
    valueMinVal = '';
    valueMaxVal = '';
    valueFixedVal = '';
    eqNameVal = '';
    eqTypeVal = ALL;
    lineVal = '';
    RUNNING = RUNNING;
    PAUSED = PAUSED;
    UPDATE_REALTIME = UPDATE_REALTIME;
    configuration: any;
    userName: string;
    userEmail: string;

    availableColumnsActive = [
        { position: 0, id: 'variableSourceTimestamp', label: 'Date', visible: true },
        { position: 1, id: 'floor', label: 'Floor', visible: true },
        { position: 2, id: 'area', label: 'Area', visible: true },
        { position: 3, id: 'zone', label: 'Zone', visible: true },
        { position: 4, id: 'line', label: 'Line', visible: true },
        { position: 5, id: 'equipmentId', label: 'Equipment', visible: true },
        { position: 6, id: 'variableName', label: 'Variable Name', visible: true },
        { position: 7, id: 'variableType', label: 'Type', visible: true },
        { position: 8, id: 'opcErrorType', label: 'OPC Error', visible: true },
        { position: 9, id: 'variableValue', label: 'Value', visible: true },
    ];

    variableSourceTimestampVisible = true;
    floorVisible = true;
    areaVisible = true;
    zoneVisible = true;
    lineVisible = true;
    equipmentIdVisible = true;
    variableNameVisible = true;
    variableTypeVisible = true;
    opcErrorTypeVisible = true;
    variableValueVisible = true;

    selectedRow = [];

    header = RT_VARIABLES;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private variablesActiveStatusService: VariablesActiveStatusService,
        private navigationService: NavigationService,
        private authenticationService: AuthenticationService,
        private configurationsService: ConfigurationsService,
        private location: Location,
        private router: Router,
        private appLoadService: AppLoadService,
        //private pageLifeCycleService: PageLifecycleService,
        private variablesStatusClient: VariablesStatusClient,
        private variablesService: VariablesService,
    ) {
        super();

        this.warehouse = this.route.snapshot.data.variables.warehouse;
        this.equipmentTypes = ['All', ...(this.route.snapshot.data.variables.equipmentTypes || []).sort()];

        const value =
            environment.mode === 'front'
                ? {
                    name: 'Oscar Lijo Busto',
                    userName: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
        if (value) {
            this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
            this.userEmail = value.userName ? value.userName : '';
        }
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };

            this.availableColumnsActive = this.userConfiguration?.columnsVisible?.variableAvailableColumnsActive
                ? JSON.parse(this.userConfiguration?.columnsVisible?.variableAvailableColumnsActive)
                : this.availableColumnsActive;

            this.visibleColumns();
        });
    }
    getScreenWidth(): string {
        if (screen.availWidth > 768) {
            return '35%';
        } else if (screen.availWidth < 576) {
            return '85%';
        }
    }

    getRealTimeVariablesLength(): boolean {
        return this.realTimeVariables.length === 0;
    }

    ngOnInit() {
        this.variablesStatusClient.connectToHub().then(() => {
            console.warn('Connected to VariablesActive Hub');
        });
        // take url params
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => this.handleUrlParams(params));
    }

    async handleUrlParams(params: any) {
        if (!params.mode || params.mode === VARIABLES_MODE.RT) {
            // get filters from query params, configuration or empty in that order
            let filters: VariableFilter[] = params.f
                ? JSON.parse(params.f)
                : this.userConfiguration?.activeVariablesFilter
                    ? JSON.parse(this.userConfiguration.activeVariablesFilter)
                    : [];
            // check if filters are valid for this warehouse
            const isFilterValid = filters.reduce((acc, filter) => acc && filter.warehouseId === this.warehouse.warehouse, true);
            if (!isFilterValid) {
                filters = [];
            }
            // set filter in the url if not present
            if (!isFilterValid || !params.f) {
                const queryParams = {
                    mode: VARIABLES_MODE.RT,
                    f: JSON.stringify(filters.map(this.removeEmpty)),
                };
                const url = this.router
                    .createUrlTree([], {
                        relativeTo: this.route,
                        queryParams: { ..._.omitBy(queryParams, (v) => !v || v === '') },
                    })
                    .toString();
                this.location.go(url);
            }
            // apply filters
            const currentFilters: VariableFilter[] = [...filters].map(this.removeEmpty);
            if (JSON.stringify(currentFilters) !== JSON.stringify(this.currentFilters)) {
                this.currentFilters = [...currentFilters];
                if (this.currentFilters.length > 0) {
                    this.condition = this.currentFilters[0].condition ? this.currentFilters[0].condition : undefined;
                    this.loadActiveVariables();
                } else {
                    await this.variablesActiveStatusService.unsubscribeFromVariable();
                    this.currentRequestId = 'stop-processing-notifications';
                    this.setDatatableItems([]);
                }
                this.saveFilterConfiguration();
            }
        }
    }

    saveFilterConfiguration() {
        this.userConfiguration.activeVariablesFilter = JSON.stringify(this.currentFilters.map(this.removeEmpty));
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
    }

    ngAfterViewInit(): void {
        this.$variablesType = this.variablesType.$select;
        this.$fqnFilter = this.fqnFilter.$input;
        this.$fqnFilter.attr('maxlength', 25);
        this.$variablesName = this.variablesName.$input;
        this.$variablesName.attr('maxlength', 25);
        this.$equipmentName = this.equipmentName.$input;
        this.$equipmentName.attr('maxlength', 25);
        this.$equipmentType = this.equipmentType.$select;
        this.$line = this.line.$input;
        this.$line.attr('maxlength', 40);
        this.$fazFilter = this.fazFilter.$selectMultilevel;
        this.$valueOperators = this.valueOperators.$select;
        this.$valueInput = this.valueInput.$input;
        this.$valueInput.attr('maxlength', 40);
        this.$valueMin = this.valueMin.$input;
        this.$valueMin.attr('maxlength', 25);
        this.$valueMax = this.valueMax.$input;
        this.$valueMax.attr('maxlength', 25);
        this.$valueFixed = this.valueFixed.$select;

        const setSelected = (event) => {
            if (event.key === 'Enter') {
                this.addFilterAndApply();
            }
        };

        this.$fqnFilter.off('keyup').on('keyup', setSelected);
        this.$variablesName.off('keyup').on('keyup', setSelected);
        this.$equipmentName.off('keyup').on('keyup', setSelected);
        this.$line.off('keyup').on('keyup', setSelected);
        this.$variablesType.val(ALL).trigger('change'); //initial value ALL
        this.$variablesType.on('change', (e) => this.cleanInputFilter());
        this.$valueOperators.on('change', (e) => {
            this.operatorVal = String(this.$valueOperators.val());
        });
        this.$valueInput.off('keyup').on('keyup', setSelected);
        this.$valueMin.off('keyup').on('keyup', setSelected);
        this.$valueMax.off('keyup').on('keyup', setSelected);
        this.$valueFixed.val(ACTIVE).trigger('change');
        this.$valueFixed.on('change', (e) => this.cleanInputFilter());
        this.$equipmentType.val(ALL).trigger('change');
        this.$equipmentType.on('change', (e) => this.cleanInputFilter());
        this.$fazFilter.on('change', () => {
            const valueGroups = this.$fazFilter.val().toString().split('-');
            switch (valueGroups.length) {
                case 1:
                    if (valueGroups[0] === '') {
                        this.floorVal = '';
                        this.areaVal = '';
                        this.zoneVal = '';
                    } else {
                        this.floorVal = String(valueGroups[0]);
                        this.areaVal = '';
                        this.zoneVal = '';
                    }
                    break;
                case 2:
                    this.floorVal = String(valueGroups[0]);
                    this.areaVal = String(valueGroups[1]);
                    this.zoneVal = '';
                    break;
                case 3:
                    this.floorVal = String(valueGroups[0]);
                    this.areaVal = String(valueGroups[1]);
                    this.zoneVal = String(valueGroups[2]);
                    break;
                default:
                    break;
            }
            this.cleanInputFilter();
        });
        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     if (
        //         this.realTimeStatus !== PAUSED &&
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.variablesActiveStatusService.isConnected()
        //     ) {
        //         this.handleReconnection();
        //         //this.cleanInputFilter();
        //     }
        // });
    }

    async ngOnDestroy() {
        this.currentRequestId = 'stop-processing-notifications';
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        this.variablesStatusClient.disconnectFromHub();

        this.$variablesType.off();
        this.$fqnFilter.off();
        this.$variablesName.off();
        this.$equipmentName.off();
        this.$equipmentType.off();
        this.$line.off();
        this.$fazFilter.off();
        this.$valueOperators.off();
        this.$valueInput.off();
        this.$valueMin.off();
        this.$valueMax.off();
        this.$valueFixed.off();
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    cleanInputFilter() {
        const fqnVal = String(this.$fqnFilter.val());
        this.fqnVal = fqnVal && !!fqnVal.match(/^(\d+)\*/) ? `F${fqnVal}` : fqnVal;
        this.nameVal = String(this.$variablesName.val());
        this.eqNameVal = String(this.$equipmentName.val());
        this.eqTypeVal = String(this.$equipmentType.val());
        this.lineVal = String(this.$line.val());
        this.valueVal = String(this.$valueInput.val());
        this.valueMinVal = String(this.$valueMin.val());
        this.valueMaxVal = String(this.$valueMax.val());
        const fixedValue = String(this.$valueFixed.val());
        this.valueFixedVal = fixedValue === ALL ? '' : fixedValue === ACTIVE ? '1' : '0'; //'true' : 'false';
        const variableType = String(this.$variablesType.val());
        const type = variableType === ALL ? null : variableType;
        this.typeVal = type as VariableType;

        const fixedValueCondition = { [EQUALS]: { Value: this.valueFixedVal } };
        const betweenCondition = this.valueMinVal && this.valueMaxVal  ? { [BETWEEN]: { MinValue:  this.valueMinVal, MaxValue: this.valueMaxVal  } } : null;
        const otherCondition = { [this.operatorVal]: { Value: this.valueVal } };
        this.condition =
            [ALARM, WARNING].includes(this.typeVal) && this.valueFixedVal !== ''
                ? fixedValueCondition
                : this.operatorVal === BETWEEN
                    ? betweenCondition
                    : ![ALARM, WARNING].includes(this.typeVal) && this.valueVal !== ''
                        ? otherCondition
                        : undefined;
    }

    addFilter() {
        this.cleanInputFilter();

        const isFilterSet = !(
            this.fqnVal === '' &&
            this.nameVal === '' &&
            this.eqNameVal === '' &&
            this.eqTypeVal === ALL &&
            this.lineVal === '' &&
            this.floorVal === '' &&
            this.areaVal === '' &&
            this.zoneVal === '' &&
            this.typeVal === null &&
            this.valueVal === '' &&
            (this.valueMinVal === '' || this.valueMaxVal === '')
        );

        return isFilterSet
            ? {
                warehouseId: this.warehouse.warehouse,
                floorId: this.floorVal || null,
                areaId: this.areaVal || null,
                zoneId: this.zoneVal || null,
                lineId: this.lineVal || null,
                equipmentId: this.eqNameVal || null,
                equipmentType: this.eqTypeVal !== ALL ? this.eqTypeVal : null,
                fqnPattern: this.fqnVal || null,
                variableType: this.typeVal || null,
                variableName: this.nameVal || null,
                condition: this.condition /* Demo without condition*/,
            }
            : null;
    }

    addFilterAndApply() {
        const filter = this.addFilter();
        const newFilters = [...this.currentFilters.filter((f) => !this.compareFilters(filter, f)), filter];
        this.applyDataFilters(newFilters);
    }

    async handleReconnection() {
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        this.subscribed = false;
        this.realTimeVariables = [];
        this.loadActiveVariables();
    }

    removeFilter(filter: VariableFilter) {
        const newFilters = this.currentFilters.filter((f) => !this.compareFilters(filter, f));
        this.applyDataFilters(newFilters);
    }

    compareFilters(f1: VariableFilter, f2: VariableFilter): boolean {
        return JSON.stringify(f1) === JSON.stringify(f2);
    }

    applyDataFilters(filters: VariableFilter[]) {
        this.setDatatableItems([]);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                ..._.omitBy(
                    {
                        mode: VARIABLES_MODE.RT,
                        f: JSON.stringify(filters.map(this.removeEmpty)),
                    },
                    (v) => !v || v === '',
                ),
            },
        });

        this.saveFilterConfiguration();
        this.cleanInputFilter();
    }

    removeEmpty(obj) {
        return Object.entries(obj)
            .filter(([k, v]) => (v instanceof Array ? v.length > 0 : v !== null || v === ''))
            .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    }

    loadActiveVariables() {
        this.currentRequestId = 'VariablesActive---' + uuid();
        if (this.currentFilters.length > 0 && !this.waitForResponse) {
            this.waitForResponse = true;

            const filters: VariableFilters = {
                requestId: this.currentRequestId,
                filters: this.currentFilters,
                maxNotificationItems: MAX_ROWS,
                orderColumn: this.orderColumn,
                orderType: this.orderType,
            };
            if (!this.subscribed) {
                this.subscribed = true;
                const initialFilters = filters;
                this.variablesActiveStatusService.subscribeToVariable(
                    initialFilters,
                    this.receiveRealTimeVariables.bind(this),
                    this.handleReconnection.bind(this),
                );
            } else {
                this.realTimeVariables = [];
                this.setDatatableItems(this.realTimeVariables);
                this.variablesActiveStatusService.applyFilter(filters);
            }
            this.loadInitialVariables(filters);

            this.refreshSelected();

            this.waitForResponseTimeout = setTimeout(() => {
                this.waitForResponse = false;
                this.waitForResponseTimeout = null;
            }, 3000);
        }
    }

    loadInitialVariables(initialFilters) {
        this.variablesService
            .getVariables(initialFilters)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((variables) => {
                variables.forEach((v: VariableNotification) => {
                    v.requestId = this.currentRequestId;
                    if (this.variablesActiveStatusService.isNewerNotificationFromService(v)) {
                        this.receiveRealTimeVariables(v)
                    }
                });
            });
    }

    receiveRealTimeVariables(item: VariableNotification) {
        try {
            /* START Demo without condition */
            //Check if received variable has the wishable VALUE. (JUST FOR NOW only wroks with condition EQUALs and VALUE True or False, check in back-end, if exists the code to control the other comparations `equal, notEqual, greater, lesser, between, etc` and use the same here)
            //  If has it, mustAdd will be TRUE y be added.
            //  If DONT have it, check if previusly exist a VARIABLE with same name and fqn. If exists, will DELETE it from table (this.realTimeVariables)
            let mustAdd = false;
            if (this.condition) {
                //If it's a boolean variable, maybe it value is in string format 'true' or 'false'. Inside this.conditions, values are 1 and 0 for booleans 
                let variableValue = item.variableValue;
                if (item.variableValueType.toUpperCase() === VariableValueType.Boolean.toUpperCase()) {
                    variableValue = item.variableValue.toUpperCase() === 'TRUE' ? 1 : item.variableValue.toString().toUpperCase() === 'FALSE' ? 0 : item.variableValue;
                }
                //mustAdd = item.variableValue.toString().toUpperCase() === this.condition?.Equals?.Value.toString().toUpperCase();
                mustAdd = variableValue.toString().toUpperCase() === this.condition?.Equals?.Value.toString().toUpperCase();
                if (!mustAdd) {
                    const variableIndex = _.findIndex(this.realTimeVariables, (i: Variable) => i.fqn === item.fqn && i.variableName === item.variableName);
                    if (variableIndex >= 0) {
                        this.realTimeVariables[variableIndex].variableValue = item.variableValue; //UPDATING variableValue with new one
                        this.realTimeVariables = this.realTimeVariables.filter((rt) => !(rt.fqn === item.fqn && rt.variableName === item.variableName));
                        this.setDatatableItems(_.sortBy(this.realTimeVariables, ['variableSourceTimestamp']).reverse());
                    }
                }
            } else {
                mustAdd = true;
            }
            /* END Demo without condition*/

            if (mustAdd && (item.requestId === this.currentRequestId || item.requestId === VARIABLE_EMPTY_REQUEST_ID)) {
                /* Demo without condition*/
                // if (item.sourceTimeStamp === undefined && ![undefined, null, 'ApiDefault'].includes(item.source)) {
                //     console.error('SourceTimeStamp from backend is null/undefined.', item);
                // }
                const ts = dayjs(item.sourceTimeStamp).toDate();
                const variable = new Variable(
                    item.floorId,
                    item.areaId,
                    item.zoneId,
                    item.lineId,
                    item.equipmentId,
                    item.equipmentType,
                    item.fqn,
                    item.source,
                    item.variableName,
                    ts,
                    item.variableType.toUpperCase() === 'FAILURE' ? ALARM : item.variableType,
                    item.variableValue,
                    item.variableValueType,
                    item.isOpcError,
                    item.opcErrorType,
                );
                const variableIndex = _.findIndex(this.realTimeVariables, (i: Variable) => i.fqn === variable.fqn && i.variableName === variable.variableName);
                if (variableIndex < 0) {
                    if (this.realTimeVariables.length >= MAX_ROWS) {
                        this.realTimeVariables.pop();
                    }
                    this.realTimeVariables.unshift(variable);
                } else {
                    this.removeDuplicatedRealTimeVariables(variable);
                    this.realTimeVariables.unshift(variable);
                }
                this.setDatatableItems(_.sortBy(this.realTimeVariables, ['variableSourceTimestamp']).reverse());
                this.waitForResponse = false;
                if (this.waitForResponseTimeout) {
                    clearTimeout(this.waitForResponseTimeout);
                    this.waitForResponseTimeout = null;
                }

                this.refreshSelected();
            }
        } catch (error) {
            console.log(`%c receiveRealTimeVariables variable active error => ${error}`, `background: ; color: red`);
        }
    }

    removeDuplicatedRealTimeVariables(variable: Variable) {
        // tslint:disable-next-line: max-line-length
        const variableIndex = _.findIndex(this.realTimeVariables, (i: Variable) => i.fqn === variable.fqn && i.variableName === variable.variableName);
        if (variableIndex >= 0) {
            this.realTimeVariables.splice(variableIndex, 1);
            this.removeDuplicatedRealTimeVariables(variable);
        }
    }

    goToFloor(data) {
        this.navigationService.goToFloor(this.warehouse.warehouse, data);
    }

    goToArea(data) {
        this.navigationService.goToFloorWithSelected(this.warehouse.warehouse, data, 'AREA');
        //this.navigationService.goToArea(this.warehouse.warehouse, data);
    }

    goToZone(data) {
        this.navigationService.goToFloorWithSelected(this.warehouse.warehouse, data, 'ZONE');
        //this.navigationService.goToZone(this.warehouse.warehouse, data);
    }

    goToLine(data) {
        this.navigationService.goToLine(this.warehouse.warehouse, data);
    }

    changePageSize(event) {
        super.changePageSize(event);
        this.pageSize = +event.target.value;
    }

    setSort(pageInfo) {
        this.orderColumn = pageInfo.sorts[0].prop;
        this.orderType = pageInfo.sorts[0].dir;
        this.loadActiveVariables();
    }

    updateValue(event) {
        const { variable, state } = event;
        this.updateState(variable.fqn, () => state);
    }

    undoEditValue(event) {
        const { variable, state } = event;
        this.updateState(variable.fqn, () => state);
    }

    focusLost(event) {
        const { variable, state } = event;
        this.updateState(variable.fqn, () => state);
    }

    saveValue(event) {
        const { variable, state, newValue } = event;
        if (state.error) {
            this.setDatatableItems(_.sortBy(this.realTimeVariables, ['variableSourceTimestamp']).reverse());
        } else if (newValue !== undefined) {
            const variableValue = newValue === true ? '1' : newValue === false ? '0' : newValue;
            this.realTimeVariables = this.realTimeVariables.map((realTimeVariable) =>
                event.variable.fqn === realTimeVariable.fqn ? { ...realTimeVariable, variableValue } : realTimeVariable,
            );
            this.setDatatableItems(_.sortBy(this.realTimeVariables, ['variableSourceTimestamp']).reverse());
        }
        this.updateState(variable.fqn, () => state);

        this.refreshSelected();
    }

    editValue(event) {
        if (event.variable.variableType.toLowerCase() === COMMAND.toLowerCase()) {
            Object.keys(this.state)
                .filter((key) => this.getState(key).inputState.includes(EDITING))
                .forEach((key) =>
                    this.updateState(key, (stateItem) => ({
                        ...stateItem,
                        inputState: stateItem.inputState.filter((state) => state !== EDITING),
                    })),
                );
            this.updateState(event.variable.fqn, (stateItem) => ({
                ...stateItem,
                inputState: [...stateItem.inputState, EDITING],
            }));
        }
    }

    refreshValue(event) {
        const { variable, state, newValue } = event;
        if (state.error) {
            this.setDatatableItems(_.sortBy(this.realTimeVariables, ['variableSourceTimestamp']).reverse());
        } else if (state.inputState.includes(REFRESHED)) {
            const refreshedValue = newValue;
            const finalValue = refreshedValue === true ? '1' : refreshedValue === false ? '0' : refreshedValue;
            this.realTimeVariables = this.realTimeVariables.map((realTimeVariable) =>
                variable.fqn === realTimeVariable.fqn ? { ...realTimeVariable, variableValue: finalValue } : realTimeVariable,
            );
            this.setDatatableItems(this.realTimeVariables);
        }
        this.updateState(variable.fqn, () => state);

        this.refreshSelected();
    }

    isEditableRow({ row }): any {
        const variableType = row.variableType || '';
        const isEditable =
            variableType.toLowerCase() === COMMAND.toLowerCase() &&
            !row.variableName?.toUpperCase().includes('RESET') &&
            !(row.variableValueType.toLowerCase() === VariableValueType.Boolean.toLowerCase());
        /*const isEditable =
            variableType.toLowerCase() === COMMAND.toLowerCase() &&
            !(
                row.variableName?.toUpperCase().includes('RESET') ||
                row.variableValueType.toLocaleLowerCase() === row.VariableValueType.String.toLocaleLowerCase() ||
                row.variableValueType.toLocaleLowerCase() === row.VariableValueType.Boolean.toLocaleLowerCase()
            );*/

        return {
            'is-editable': isEditable,
        };
    }

    async pauseRealTimeNotifications() {
        const currentStatus = this.realTimeStatus;
        this.realTimeStatus = UPDATE_REALTIME;
        if (RUNNING === currentStatus) {
            this.currentRequestId = 'VariablesActive---' + uuid();
            await this.variablesActiveStatusService.pauseNotifications();
            this.realTimeStatus = PAUSED;
        } else {
            // await this.applyDataFilters();
            this.loadActiveVariables();
            this.realTimeStatus = RUNNING;
        }
    }

    getState(fqn: string): VariableInputState {
        if (!this.state[fqn]) {
            this.state[fqn] = {
                value: null,
                error: null,
                inputState: [],
            };
        }
        return this.state[fqn];
    }

    updateState(fqn: string, action: (variable: VariableInputState) => VariableInputState) {
        this.state[fqn] = action(this.getState(fqn));
    }

    checkErrors(row) {
        return row.isOpcError ? 'on-error' : '';
    }

    refreshSelected() {
        if (this.selectedRow && this.selectedRow.length !== 0) {
            const { equipmentId, equipmentType, line, variableName } = this.selectedRow[0];

            const filtered = this.itemsVariable.filter(
                (currentAlert) =>
                    currentAlert.equipmentId === equipmentId &&
                    currentAlert.equipmentType === equipmentType &&
                    currentAlert.line === line &&
                    currentAlert.variableName === variableName,
            );
            if (filtered.length !== 0) this.selectedRow = filtered;
        }
    }

    visibleColumns() {
        if (this.availableColumnsActive) {
            this.variableSourceTimestampVisible = this.availableColumnsActive.find((x) => x.id === 'variableSourceTimestamp').visible;
            this.floorVisible = this.availableColumnsActive.find((x) => x.id === 'floor').visible;
            this.areaVisible = this.availableColumnsActive.find((x) => x.id === 'area').visible;
            this.zoneVisible = this.availableColumnsActive.find((x) => x.id === 'zone').visible;
            this.lineVisible = this.availableColumnsActive.find((x) => x.id === 'line').visible;
            this.equipmentIdVisible = this.availableColumnsActive.find((x) => x.id === 'equipmentId').visible;
            this.variableNameVisible = this.availableColumnsActive.find((x) => x.id === 'variableName').visible;
            this.variableTypeVisible = this.availableColumnsActive.find((x) => x.id === 'variableType').visible;
            this.opcErrorTypeVisible = this.availableColumnsActive.find((x) => x.id === 'opcErrorType').visible;
            this.variableValueVisible = this.availableColumnsActive.find((x) => x.id === 'variableValue').visible;
        }
    }

    changeVisibleColumns(columns) {
        this.availableColumnsActive = columns;

        this.userConfiguration = {
            ...this.userConfiguration,
            columnsVisible: {
                ...this.userConfiguration.columnsVisible,
                variableAvailableColumnsActive: JSON.stringify(this.availableColumnsActive),
            },
        };
        this.saveConfiguration();
    }

    generateLocalCSV(): void {
        const headers = ['VARIABLESOURCETIMESTAMP', 'FLOOR', 'AREA', 'ZONE', 'LINE', 'EQUIPMENT', 'VARIABLE NAME', 'TYPE', 'OPC ERROR', 'VALUE'];

        const itemsCSVProcessed = _.cloneDeep(this.realTimeVariables).map((item: Variable) => {
            return {
                variableSourceTimeStamp: dayjs(item.variableSourceTimestamp).toDate(),
                floor: item.floor,
                area: item.area,
                zone: item.zone,
                line: item.line,
                equipment: item.equipmentId,
                variableName: item.variableName,
                type: item.equipmentType,
                opcError: item.opcErrorType ?? 'N/A',
                value: item.variableValue,
            };
        });

        const options = {
            fieldSeparator: ';',
            decimalseparator: ',',
            headers,
        };
        const name = `RealTimeVariables_${dayjs().format('DD.MM.YYYY HHmmss')}`;
        new AngularCsv(itemsCSVProcessed, name, options);
    }

    saveConfiguration() {
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.userConfiguration);
    }
}
interface State {
    [fqnId: string]: VariableInputState;
}
