import { Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { combineLatestWith } from 'rxjs/operators';

export interface ColorStructure {
    styleId: string;
    color: string;
}

@Injectable({
    providedIn: 'root',
})
export class ColorsService {
    private colors: ColorStructure[] = [
        //{ styleId: 'default-status-boxes', color: '#8b572a' },
        { styleId: 'status-boxes', color: '#8b572a' },
        { styleId: 'status-starts', color: '#fcde82' },
        { styleId: 'status-running', color: '#2da45d' },
        { styleId: 'status-on', color: '#2da45d' },
        { styleId: 'status-status', color: '#17a2b8' },
        { styleId: 'status-warning', color: '#fcde82' },
        { styleId: 'status-errors', color: '#fe840e' },
        { styleId: 'status-critical', color: '#ff2800' },
        { styleId: 'status-disconnected', color: '#949494' },
        { styleId: 'status-no-data', color: '#eee' },
        { styleId: 'status-lwdo', color: '#a938cc' },
        { styleId: 'status-waiting', color: '#2a3a4a' },
    ];

    constructor(/*private appLoadService: AppLoadService*/) {
        // this.appLoadService.getCurrentConfiguration
        //     .pipe(combineLatestWith(this.appLoadService.getGlobalConfiguration))
        //     .subscribe(([userConfiguration, warehouseConfiguration]) => {
        //         //Box_Detected Color Priority: 1) userConfiguration color, 2) warehouseConfiguration color, 3) default color
        //         if (userConfiguration?.darkMode) {
        //             if (userConfiguration?.boxDetectedColors?.darkMode && userConfiguration?.boxDetectedColors?.darkMode !== '') {
        //                 this.setColor('status-boxes', userConfiguration.boxDetectedColors.darkMode);
        //             } else {
        //                 if (warehouseConfiguration?.boxDetectedColors?.darkMode && warehouseConfiguration?.boxDetectedColors?.darkMode !== '') {
        //                     this.setColor('status-boxes', warehouseConfiguration.boxDetectedColors.darkMode);
        //                 }
        //             }
        //         } else {
        //             if (userConfiguration?.boxDetectedColors?.lightMode && userConfiguration?.boxDetectedColors?.lightMode !== '') {
        //                 this.setColor('status-boxes', userConfiguration.boxDetectedColors.lightMode);
        //             } else {
        //                 if (warehouseConfiguration?.boxDetectedColors?.lightMode && warehouseConfiguration?.boxDetectedColors?.lightMode !== '') {
        //                     this.setColor('status-boxes', warehouseConfiguration.boxDetectedColors.lightMode);
        //                 }
        //             }
        //         }
        //         document.querySelector('body').style.setProperty('--status-boxes', this.getColor('status-boxes'));
        //     });
    }

    getColors(): ColorStructure[] {
        return this.colors;
    }

    getColor(styleId: string): string {
        return this.colors.find((s) => s.styleId === styleId)?.color || '';
    }

    setColor(styleId: string, color: string) {
        const colorToSet = this.colors.findIndex((s) => s.styleId === styleId);
        this.colors[colorToSet].color = color;
    }
}
