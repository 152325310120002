<div class="row mt-3">
    <div class="col-12 col-md-6">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <a
                    class="nav-link active"
                    id="user-tab"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#user-tab-pane"
                    aria-controls="user-tab-pane"
                    aria-selected="true"
                >
                    User
                </a>
            </li>
            <li class="nav-item d-none d-lg-block" role="presentation">
                <a
                    class="nav-link"
                    id="warehouse-tab"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#warehouse-tab-pane"
                    aria-controls="warehouse-tab-pane"
                    aria-selected="false"
                >
                    Warehouse
                </a>
            </li>
        </ul>
    </div>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active p-3" id="user-tab-pane" role="tabpanel" style="margin: 0 auto" aria-labelledby="user-tab">
            <div class="row mt-3 mb-3 justify-content-center">
                <div class="col-12 col-md-8 col-lg-6 mt-3 p-3 settings-box">
                    <div class="row mt-4 mb-3 justify-content-center">
                        <div class="col-md-8 col-lg-6">
                            <h5>MODE</h5>
                            <div class="col-12 col-md-8 col-lg-6 mt-3">
                                <div (click)="toggleDarkMode($event)">
                                    <app-toggle leftLabel="LIGHT" rightLabel="DARK" [checked]="darkMode"></app-toggle>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row mt-4 mb-3 justify-content-center">
                        <div class="col-md-8 col-lg-6">
                            <h5>VIEW</h5>
                            <div class="col-12 col-md-8 col-lg-6 mt-3">
                                <div (click)="toggleViewMode($event)">
                                    <app-toggle leftLabel="MAP" rightLabel="LITE" [checked]="liteView"></app-toggle>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <settings-identifiers
                        [identifiers]="userConfigurationIdentifiersPreferences"
                        (newIdentifiers)="updateIdentifiers($event)"
                    ></settings-identifiers>
                </div>
                <!-- <settings-boxdetected
                    [boxDetectedColors]="userConfigurationBoxDetectedColors"
                    [parentBoxDetectedColors]="warehouseConfigurationBoxDetectedColors"
                    [userConfiguration]="true"
                    (newColor)="updateBoxDetectedColor($event)"
                ></settings-boxdetected> -->
            </div>
        </div>
        <div class="tab-pane fade p-3" id="warehouse-tab-pane" role="tabpanel" style="margin: 0 auto" aria-labelledby="warehouse-tab">
            <settings-heatmap [currentHeatMap]="warehouseConfigurationHeatMap" (newHeatMap)="updateHeatMap($event)"></settings-heatmap>
            <settings-variable-color
                [variableColors]="warehouseConfigurationVariableColors"
                [identifiersPreferences]="userConfigurationIdentifiersPreferences"
                [variableColorToDelete]="variableColorToDelete"
                (newVariableColor)="updateVariableColor($event)"
                (variableColorDeleteConfirmation)="variableColorDeleteConfirmation($event)"
            ></settings-variable-color>
            <settings-alerts-zones
                [alertsZonesColors]="warehouseConfigurationAlertsZonesColors"
                [alertsZonesColorsToDelete]="alertsZonesColorsToDelete"
                (newAlertZone)="updateAlertsZonesColor($event)"
                (alertsZonesDeleteConfirmation)="alertsZonesDeleteConfirmation($event)"
            ></settings-alerts-zones>
            <settings-scanners-trend
                [scannerTrendColors]="warehouseConfigurationScannerTrendColors"
                [scannerTrendColorsToDelete]="scannerTrendColorsToDelete"
                (newScannerTrend)="updateScannersTrendColor($event)"
                (scannerTrendDeleteConfirmation)="scannerTrendDeleteConfirmation($event)"
            >
            </settings-scanners-trend>
            <settings-errors-trend [errorTrendColors]="warehouseConfigurationErrorTrendColors" (newErrorTrend)="updateErrorTrendColor($event)">
            </settings-errors-trend>

            <!-- <settings-boxdetected
                [boxDetectedColors]="warehouseConfigurationBoxDetectedColors"
                [userConfiguration]="false"
                (newColor)="updateBoxDetectedColor($event)"
            ></settings-boxdetected> -->
        </div>
        <app-modal-confirmation #confirmationModal key="automationConfirmationModal" (responseChanged)="confirmationEmited($event)" [dangerMode]="dangerMode">
            <span [innerHTML]="confirmationMessage.text"></span>
        </app-modal-confirmation>
    </div>
</div>
