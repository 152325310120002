import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { WarehouseStatusClient } from './notifications/shared/clients/warehouse-status.client';
import { AuthenticationService } from './core/shared/authentication/authentication.service';
import { ActiveAlertsClient } from './notifications/shared/clients/active-alerts.client';
import { ReportStatusClient } from './notifications/shared/clients/report-status.client';
import { ArchivesService } from './core/shared/archives/archives.service';
import { EquipmentsService } from './map/equipments/equipments.service';
import { StateService } from './core/shared/state/state.service';
import { LinesService } from './map/lines/shared/lines.service';
import { filter, catchError, takeUntil, take } from 'rxjs/operators';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { environment } from '@environments/environment';
import { AppLoadService } from './app-load.service';
import { ErrorService, TableComponentDirective } from '@ays';
import { forkJoin, of, Subject } from 'rxjs';
import * as _ from 'lodash-es';
import * as $ from 'jquery';
import { SettingsService } from './core/shared/settings/settings.service';
import { ConfigurationsService } from './core/shared/configurations/configurations.service';
import { PageLifecycleService } from './shared/services/page-lifecycle.service';
import { ColorsService, ColorStructure } from './shared/services/colors.service';
import { ServiceStatusClient } from './notifications/shared/clients/service-status.client';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends TableComponentDirective<any> implements OnInit, AfterViewInit, OnDestroy {
    pageNaked = false;
    auwaStart = false;
    isDisconnected = false;
    isReconnecting = false;
    currentUser: any;
    lines: any = [];
    equipments: any = [];
    testActivity = false;
    getReportList: () => void;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private msalBroadcastService: MsalBroadcastService,
        private configurationsService: ConfigurationsService,
        private settingsService: SettingsService,
        private router: Router,
        private location: Location,
        private state: StateService,
        private appLoadService: AppLoadService,
        private activeAlertsClient: ActiveAlertsClient,
        private serviceStatusClient: ServiceStatusClient,
        private authenticationService: AuthenticationService,
        private reportStatusClient: ReportStatusClient,
        private archivesService: ArchivesService,
        private linesService: LinesService,
        private equipmentsService: EquipmentsService,
        private warehouseStatusClient: WarehouseStatusClient,
        private errorService: ErrorService,
        private pageLifeCycleService: PageLifecycleService,
        private colorsService: ColorsService,
    ) {
        super();
        if (this.router.url === '/unauthorized') {
            this.pageNaked = true;
        } else {
            this.appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
                const path = this.location.path();
                if (!path.startsWith(`/warehouse/${res.warehouse}`)) {
                    this.router.navigate([`warehouse/${res.warehouse}`]);
                }
            });
        }
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            if (res.darkMode) {
                this._document.documentElement.classList.add('dark');
            } else {
                this._document.documentElement.classList.remove('dark');
            }
        });

        this.currentUser =
            environment.mode === 'front'
                ? {
                    name: 'Óscar Lijó Busto',
                    username: 'oscar.lijo@inditex.es',
                }
                : this.authenticationService.getUser();
    }
    ngAfterViewInit(): void {
        //https://github.com/vitejs/vite/discussions/9601
        //https://sergiocarracedo.es/2020/07/17/sharing-variables-between-scss-and-typescript/
        //https://developer.mozilla.org/en-US/docs/Web/API/Window/getComputedStyle
        const bodyRootStyles = window.getComputedStyle(document.body); //we can use an individual element
        const colorsToSet: ColorStructure[] = this.colorsService.getColors();
        colorsToSet.forEach((c) => {
            const prefix = '--';
            if (bodyRootStyles.getPropertyValue(prefix + c.styleId)) {
                this.colorsService.setColor(c.styleId, bodyRootStyles.getPropertyValue(prefix + c.styleId));
            }
        });

        this.getReportList = () => this.archivesService
                .getArchivesRemoteStorage()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    this.setDatatableItems(res);
                });
    
        const myOffcanvas = document.getElementById('offcanvasArchives');
        myOffcanvas.addEventListener('shown.bs.offcanvas', this.getReportList);
        if (
            ['fernandoph@inditex.com', 'cesargs@ext.inditex.com', 'angelapen@ext.inditex.com', 'josecd@servicioexterno.stradivarius.es'].includes(
                this.currentUser.userName,
            )
        ) {
            $('.navbar-brand')[0].innerHTML = `
            <span id="previousState" class="badge bg-secondary" style="font-size:10px;margin-left:5px;">Previous state</span>
            <span id="currentState" class="badge bg-success" style="font-size:10px">Current state</span>
            <span id="messageTime" class="badge bg-primary" style="font-size:10px;"></span>
            <span id="jsTime" class="badge bg-warning" style="font-size:10px;margin-right:5px;">${new Date().toLocaleTimeString()}</span>`;
            this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
                try {
                    $('#previousState')[0].innerHTML = `${prevState}`;
                    $('#currentState')[0].innerHTML = `${state}`;
                } catch (error) { }
            });
            setInterval(() => {
                $('#jsTime')[0].innerHTML = `${new Date().toLocaleTimeString()}`;
            }, 60000);
        }
    }

    ngOnInit(): Promise<void> {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(() => {
                this.checkUserAndRoles();
            });

        forkJoin({
            lines: this.linesService.getLinesNames().pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar los nombres de equipos');
                    return of(null);
                }),
            ),
            equipments: this.equipmentsService.getEquipmentNames().pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar los nombres de equipos');
                    return of(null);
                }),
            ),
        })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(({ lines, equipments }) => {
                this.lines = lines;
                this.equipments = equipments.filter((eq) => eq !== null);
            });
        const onReconnected = _.throttle(this.onReconnected.bind(this));
        const onDisconnected = _.throttle(this.onDisconnected.bind(this));
        const onReconnecting = _.throttle(this.onReconnecting.bind(this));
        const onLockFailed = _.throttle(this.onAcquireLockFailed.bind(this));
        return Promise.all([
            this.activeAlertsClient.connectToHub(onReconnected, onDisconnected, onReconnecting, onLockFailed),
            this.reportStatusClient.connectToHub(),
            this.serviceStatusClient.connectToHub(),
            this.warehouseStatusClient.connectToHub(),
        ]).then(() => {
            console.log('All websockets connected');
            console.log('Localstorage signalRProtocol. Need to be 0/null for Binary messages or 1 for Json messages.');
        });
    }

    ngOnDestroy(): void {
        const myOffcanvas = document.getElementById('offcanvasArchives');
        myOffcanvas.removeEventListener('shown.bs.offcanvas', this.getReportList);
        this.activeAlertsClient.disconnectFromHub();
        this.reportStatusClient.disconnectFromHub();
        this.serviceStatusClient.disconnectFromHub();
        this.warehouseStatusClient.disconnectFromHub();
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    checkUserAndRoles() {
        const value = this.authenticationService.getUser();
        if (value) {
            this.currentUser = this.authenticationService.getUser();
            this.authenticationService.setGroups().then(() => {
                this.authenticationService.getGroups.subscribe((groups) => {
                    this.appLoadService.rolesComplete = true;
                    if (!groups || groups.length === 0) {
                        this.router.navigate(['/403']);
                    } else {
                        this.settingsService.getBackendConfiguration().subscribe((backendConfiguration) => {
                            this.settingsService.authenticationSettings.groups = groups;
                            this.settingsService.globalSettings = backendConfiguration.version;
                            this.settingsService.applicationInsightsSettings = backendConfiguration.instrumentationKey;
                            this.appLoadService.warehouses = this.settingsService.filterWarehouses(backendConfiguration);
                            this.appLoadService.warehouses = this.appLoadService.castWarehouse(this.appLoadService.warehouses);
                            this.appLoadService.setWarehouse(this.appLoadService.warehouses);
                            this.appLoadService.getCurrentWarehouse.subscribe((res) => {
                                this.configurationsService.readConfiguration(res.hostName, this.currentUser.userName).subscribe((userConfiguration) => {
                                    if (userConfiguration) {
                                        const configuration = this.appLoadService.castConfiguration(userConfiguration);
                                        this.appLoadService.setCurrentConfiguration(configuration);
                                    }
                                    // read warehouse configuration
                                    this.configurationsService.readConfiguration(res.hostName, res.warehouse).subscribe((whConfiguration) => {
                                        if (whConfiguration) {
                                            const configuration = this.appLoadService.castGlobalConfiguration(whConfiguration);
                                            this.appLoadService.setGlobalConfiguration(configuration);
                                        }
                                        this.auwaStart = true;
                                        this.router.initialNavigation();
                                    });
                                });
                            });
                        });
                    }
                });
            });
        } else {
            this.authenticationService.msalService.loginRedirect();
        }
    }

    onReconnected() {
        this.isDisconnected = false;
        this.isReconnecting = false;
    }

    onDisconnected() {
        this.isDisconnected = true;
        this.isReconnecting = true;
    }

    onReconnecting() {
        this.isReconnecting = true;
    }

    onAcquireLockFailed() {
        const errorInstance = {
            type: 'Warning',
            title: 'Web Lock acquisition failed',
            code: '-',
            msg: 'Your browser does not support Web Locks. This could cause that some notifications would be missed.',
        };
        this.errorService.add(errorInstance);
    }

    saveFullscreen(fullscreen) {
        this.state.setFullscreen(fullscreen);
    }
}
