import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppLoadService } from '@app/app-load.service';
import { Floor, IdentifiersPreferences, Zone } from '@app/map/home/shared/structure';
import { LinesService } from '@app/map/lines/shared/lines.service';
import { isInViewport } from '@app/map/shared/svg-functions';
import { VariablesStatusClient } from '@app/notifications/shared/clients/variables-status.client';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import { VARIABLE_EMPTY_REQUEST_ID, VariableFilter, VariableFilters, VariableNotification, VariableValueType } from '@app/notifications/shared/events/variable-status';
import { WarehouseStatusSupportedEvents } from '@app/notifications/shared/events/warehouse-status';
import { VariablesActiveStatusService } from '@app/notifications/shared/handlers/variablesActive-status-service';
import { WarehouseStatusService } from '@app/notifications/shared/handlers/warehouse-status-service';
import { getEventTS } from '@app/notifications/shared/mappers/notification.mapper';
import { UserConfiguration } from '@app/shared/models/configurations';
// import { yieldToMain } from '@app/shared/performance/auwa-scheduler';
import { TextColorPipe } from '@app/shared/pipes/text-color.pipe';
import { VisibilityStates } from '@app/shared/services/page-lifecycle.service';
import { VariableColors, findVariableColor, findVariableToShow } from '@variables/shared/variable';
import { Observable, Subject, of } from 'rxjs';
import { bufferTime, switchMap, take, takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import * as Svg from '@svgdotjs/svg.js';
import '@svgdotjs/svg.panzoom.js';
import fastdom from 'fastdom';
import '@svgdotjs/svg.panzoom.js';

const { LINE_STATE_CHANGED } = WarehouseStatusSupportedEvents;

const MAX_NOTIFICATIONS = 50;
const ORDER_COLUMN = 'SourceTimeStamp';
const ORDER_TYPE = 'desc';
@Component({
    selector: 'app-zones-map',
    templateUrl: './zones-map.component.html',
    styleUrls: ['./zones-map.component.scss'],
    providers: [VariablesActiveStatusService, VariablesStatusClient, TextColorPipe],
})
export class ZonesMapComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() map: Floor;
    @Input() markedLine: any;
    @Input() alternativeIds?: any;
    @Input() warehouse: string;
    @Input() visibleStates: Array<string>;
    @Input() lineChange$: Observable<LineStatusNotification>;
    @Input() tabState: VisibilityStates;
    @Input() activateZoom?: boolean = true;
    @Input() isCustomView?: boolean = false;
    @Output() newLineSelected = new EventEmitter();
    @Output() clickLine = new EventEmitter();
    @Output() visibleLines = new EventEmitter();
    @ViewChild('svg', { read: ElementRef, static: true }) svg;
    @ViewChild('lines', { read: ElementRef, static: true }) lines;
    @ViewChild('states', { read: ElementRef, static: true }) states;
    @ViewChild('freeBacks', { read: ElementRef }) freeBacks;
    @ViewChild('freeFronts', { read: ElementRef }) freeFronts;
    @ViewChild('buttons', { read: ElementRef, static: false }) buttons;
    @ViewChild('buttonsOverMap', { read: ElementRef, static: false }) buttonsOverMap;
    $svg: JQuery;
    $lines: JQuery;
    $states: JQuery;
    $freeBacks: JQuery;
    $freeFronts: JQuery;
    $buttons: JQuery;
    $buttonsOverMap: JQuery;
    identifiersPreferences: IdentifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    zone: Zone = { id: null, freeBacksLines: null, freeBacksZones: null, freeFrontsLines: null, freeFrontsZones: null, lines: [] };
    setViewBox: string;
    zoneStates: any;
    floorId = '0';
    areaId = '0';
    liteView: boolean;
    zoneSVG: any;
    linesOverMap: Array<any> = [];
    currentZoom = 1;
    viewExtraData;
    viewFullSize = false;
    zoneSubscriptions: Array<string> = [];
    groupName: string;
    firstRender = true;

    currentRequestId: string;
    currentFilters: VariableFilter[] = [];
    subscribed = false;
    waitForResponse = false;
    waitForResponseTimeout = null;
    isVisibilityEnabled = false;
    metadataBlocks: any = {};
    shapesVariables: any = [];
    textVariables: string[];
    allVariablesToShow: string[];
    variableColorsConfiguration: VariableColors[] = [];
    linesCache: Map<string, JQuery<HTMLElement>> = new Map<string, JQuery<HTMLElement>>();
    firstMapLoad = true;

    keySelectedWithRightClick = '';
    rightClickX = 0;
    rightClickY = 0;

    private acceptedArrows = ['hl', 'hr', 'vu', 'vd'];
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private appLoadService: AppLoadService,
        private variablesActiveStatusService: VariablesActiveStatusService,
        private variablesStatusClient: VariablesStatusClient,
        private route: ActivatedRoute,
        //private pageLifeCycleService: PageLifecycleService,
        private warehouseStatusService: WarehouseStatusService,
        private textColorPipe: TextColorPipe,
        private lineService: LinesService,
    ) {
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.viewExtraData = this.userConfiguration.viewExtraData;
            this.liteView = this.userConfiguration.liteMode;
            this.identifiersPreferences.Zone = this.userConfiguration.identifiersPreferences.Zone;
            this.identifiersPreferences.Line = this.userConfiguration.identifiersPreferences.Line;
        });
        this.appLoadService.getGlobalConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.variableColorsConfiguration = res['variableColors'] ?? [];
        });
    }

    ngOnInit() {
        // ONLY CONNECT if the zone has variables
        // this.variablesStatusClient.connectToHub().then(() => {
        //     console.warn('Connected to Variables status Hub');
        // });
        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
            if (params.floorId && params.areaId && params.zoneId) {
                this.floorId = params.floorId;
                this.areaId = params.areaId;
                const area = this.map.areas.filter((a) => a.id === this.areaId)[0];
                this.zone = area.zones.filter((z) => z.id === params.zoneId)[0];
            } else {
                this.floorId = this.alternativeIds.floorId;
                this.areaId = this.alternativeIds.areaId;
                const area = this.map.areas.filter((a) => a.id === this.areaId)[0];
                this.zone = area.zones.filter((z) => z.id === this.alternativeIds.zoneId)[0];
            }
            this.groupName = `${this.warehouse}-${this.floorId}-${this.areaId}-${this.zone.id}`;
        });

        this.zone.lines.forEach((line) => {
            if (line.data && line.data !== 'null') {
                if (line.orientation) {
                    const allCoords = line.data.split(' ').filter((el) => {
                        return el != null && el.trim() !== '';
                    });
                    // CALCULAR LAS COORDENADAS X
                    const allXCoords = allCoords
                        .map((coord) => {
                            let finalCoord = coord.split(',')[0];
                            if (finalCoord.indexOf('L') !== -1) {
                                const checkLvalues = finalCoord.split('L');
                                finalCoord = checkLvalues.length > 1 ? checkLvalues[1] : checkLvalues[0];
                            }
                            if (finalCoord.indexOf('l') !== -1) {
                                const checkLvalues = finalCoord.split('l');
                                finalCoord = checkLvalues.length > 1 ? checkLvalues[1] : checkLvalues[0];
                            }
                            if (finalCoord.indexOf('M') !== -1) {
                                const checkMvalues = finalCoord.split('M');
                                finalCoord = checkMvalues[0];
                            }
                            if (finalCoord.indexOf('m') !== -1) {
                                const checkMvalues = finalCoord.split('m');
                                finalCoord = checkMvalues[0];
                            }
                            if (finalCoord.indexOf('Z') !== -1 || finalCoord.indexOf('z') !== -1) {
                                finalCoord = null;
                            }
                            return Number(finalCoord);
                        })
                        .filter((el) => el !== 0 && !isNaN(el));

                    // CALCULAR LAS COORDENADAS Y
                    const allYCoords = allCoords
                        .map((coord) => {
                            const splittedCord = coord.split(',');
                            let finalCoord = splittedCord.length > 1 ? splittedCord[1] : splittedCord[0];
                            if (finalCoord.indexOf('L') !== -1) {
                                const checkLvalues = finalCoord.split('L');
                                finalCoord = checkLvalues[0];
                            }
                            if (finalCoord.indexOf('l') !== -1) {
                                const checkLvalues = finalCoord.split('l');
                                finalCoord = checkLvalues[0];
                            }
                            if (finalCoord.indexOf('Z') !== -1) {
                                const checkZvalues = finalCoord.split('Z');
                                finalCoord = checkZvalues[0];
                            }
                            if (finalCoord.indexOf('z') !== -1) {
                                const checkZvalues = finalCoord.split('z');
                                finalCoord = checkZvalues[0];
                            }
                            if (finalCoord.indexOf('M') !== -1 || finalCoord.indexOf('m') !== -1) {
                                finalCoord = null;
                            }
                            return Number(finalCoord);
                        })
                        .filter((el) => el !== 0 && !isNaN(el));

                    if (line.orientation === 'HL') {
                        line.xArrow = Math.max.apply(null, allXCoords) - 8;
                    } else if (line.orientation === 'HR') {
                        line.xArrow = Math.min.apply(null, allXCoords);
                    }
                    if (line.orientation === 'HL' || line.orientation === 'HR') {
                        let firstNode = line.data.split(' ')[0].split(',')[1];
                        firstNode = firstNode ? firstNode : line.data.split(' ')[1].split(',')[0];
                        const splited = firstNode.toUpperCase().split('L');
                        line.yArrow = Number(splited.length > 1 ? splited[0] : firstNode) + 1;
                    }

                    if (line.orientation === 'VU') {
                        line.yArrow = Math.max.apply(null, allYCoords) - 8;
                    } else if (line.orientation === 'VD') {
                        line.yArrow = Math.min.apply(null, allYCoords);
                    }
                    if (line.orientation === 'VU' || line.orientation === 'VD') {
                        const bufferX = line.data
                            .toUpperCase()
                            .split(' ')[0]
                            .split(',')[0]
                            .split('M')
                            .filter((el) => el !== '');
                        line.xArrow = bufferX[0] ? Number(bufferX[0]) + 1 : 1;
                    }
                }
            }
        });

        this.setViewBox = this.zone.viewBox || '0 0 3200 1800';
        this.$svg = $(this.svg.nativeElement);
        this.$lines = $(this.lines.nativeElement);
        this.$states = $(this.states.nativeElement);
        //this.$buttons = $(this.buttons.nativeElement).add(this.buttonsOverMap.nativeElement);
        this.linesOverMap = this.zone.lines.filter((line) => !line.data || line.data === 'null');
        if (this.zone.lines.length === this.linesOverMap.length || !this.setViewBox) {
            this.liteView = true;
        }
        // } else {
        //     this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
        //         this.liteView = res.liteMode;
        //     });
        // }

        this.isVisibilityEnabled = this.map?.variablesLines?.variablesVisibility?.Zone;
        this.metadataBlocks.metadata = [];
        if (this.isVisibilityEnabled) {
            this.map?.variablesLines?.metadata?.forEach((data) => {
                const dataJsonFormat = JSON.parse(data);
                if (dataJsonFormat.variable && dataJsonFormat.variable !== '') this.metadataBlocks.metadata.push(dataJsonFormat);
            });
            this.textVariables = this.map?.variablesLines?.texts?.map((t) => t.variable).filter((v) => v && v !== '');
            this.shapesVariables = this.map?.variablesLines?.shapes.map((s) => s.variable).filter((v) => v && v !== '');
            const metadataVariables: string[] = this.metadataBlocks?.metadata?.map((t) => t.variable).filter((v) => v && v !== '');
            this.allVariablesToShow = [...new Set<string>(metadataVariables.concat(this.textVariables).concat(this.shapesVariables))];
        }
        $(() => {
            $('[data-element="line"]').tooltip({
                container: 'body',
                placement: 'top',
            });
        });
    }

    async ngAfterViewInit(): Promise<void> {
        this.$buttons = this.liteView ? $(this.buttons.nativeElement) : null;
        this.$buttonsOverMap = !this.liteView ? $(this.buttonsOverMap.nativeElement) : null;

        this.$freeBacks = $(this.freeBacks?.nativeElement);
        this.$freeFronts = $(this.freeFronts?.nativeElement);

        this.zoneSVG = Svg.adopt(this.svg.nativeElement);
        this.zoneStates = Svg.adopt(this.states.nativeElement);
        this.filterShapes();
        this.currentZoom = this.zoneSVG.zoom();

        this.zoneSVG.panZoom({ zoomMax: 10, zoomMin: this.currentZoom, zoomFactor: 0.5 });

        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     if (
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.variablesActiveStatusService.isConnected()
        //     ) {
        //         this.handleReconnection();
        //     }
        // });
        if (this.isVisibilityEnabled && this.map.variablesLines) {
            setTimeout(() => {
                this.loadZoneStateVariables();
                this.loadVariableRT();
            });
        }
    }

    async ngOnDestroy() {
        this.$svg?.remove();
        $('[data-element="line"]').tooltip('dispose');
        if (this.markedLine) {
            const key = `${this.floorId}-${this.areaId}-${this.zone.id}-${this.markedLine}`;
            if (this.$svg) {
                const el = this.$svg.find(`[data-key="${key}"]`);
                el.tooltip('dispose');
            }
        }
        if (this.$buttons) {
            this.$states?.add(this.$buttons)?.find('[data-element="marker"]').popover('hide').off();
            this.$buttons = null;
        }
        if (this.$buttonsOverMap) {
            this.$states?.add(this.$buttonsOverMap)?.find('[data-element="marker"]').popover('hide').off();
            this.$buttonsOverMap = null;
        }
        await this.zoneSubscriptions.forEach(async (fqn: string) => {
            await this.warehouseStatusService.endSubscription(fqn, [LINE_STATE_CHANGED]);
        });
        this.setViewBox = null;
        this.$svg = null;
        this.$lines = null;
        this.$states = null;
        this.$freeBacks = null;
        this.$freeFronts = null;

        this.currentRequestId = 'stop-processing-notifications';
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        if (this.variablesStatusClient.connection) {
            this.variablesStatusClient.disconnectFromHub();
        }

        this.ngUnsubscribe?.next(true);
        this.ngUnsubscribe.complete();
        this.linesCache.clear();
        this.linesCache = null;
    }

    highlightMarkedLine() {
        setTimeout(() => {
            if (this.markedLine && this.$svg) {
                const key = `${this.floorId}-${this.areaId}-${this.zone.id}-${this.markedLine}`;
                const el = this.$svg.find(`[data-key="${key}"]`);
                el.addClass('markedLine');
                this.$lines.append(el);
                if (this.firstRender) {
                    // el.tooltip('dispose');
                    el.tooltip({
                        placement: 'top',
                        trigger: 'hover',
                    });
                    el.tooltip('show');
                    setTimeout(() => el.tooltip('hide'), 5000);
                    this.firstRender = false;
                }
            }
        }, 100);
    }

    async ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (changes.visibleStates?.currentValue || changes.lineChange$?.currentValue) {
            try {
                if (!this.lineChange$) return;
                await this.startRealtimeNotifications();
                this.highlightMarkedLine();
            } catch (error) {
                console.error(error);
            }
        }
    }

    toogleFullScreen(): void {
        this.viewFullSize = !this.viewFullSize;
        this.viewFullSize ? $('html').addClass('no-scroll') : $('html').removeClass('no-scroll');
    }

    filterShapes() {
        if (this.zone.viewBox) {
            const viewboxProps = this.zone.viewBox.split(' ');
            const minWidth = viewboxProps[0];
            const maxWidth = viewboxProps[0] + viewboxProps[2];
            const minHeight = viewboxProps[1];
            const maxHeight = viewboxProps[1] + viewboxProps[3];
            this.$freeBacks?.find('path, text').each(function () {
                const currentBbox = Svg.adopt(this).bbox();
                if (
                    currentBbox.x < Number(minWidth) ||
                    currentBbox.x2 > Number(maxWidth) ||
                    currentBbox.y < Number(minHeight) ||
                    currentBbox.y2 > Number(maxHeight)
                ) {
                    this.remove();
                }
            });
            this.$freeFronts?.find('path, text').each(function () {
                const currentBbox = Svg.adopt(this).bbox();
                if (
                    currentBbox.x < Number(minWidth) ||
                    currentBbox.x2 > Number(maxWidth) ||
                    currentBbox.y < Number(minHeight) ||
                    currentBbox.y2 > Number(maxHeight)
                ) {
                    this.remove();
                }
            });
        }
    }

    async subscribeToSurroundZones() {
        try {
            // subscribe to other visible lines
            if (!this.$svg) return;
            this.zoneSubscriptions.forEach(async (fqn: string) => {
                await this.warehouseStatusService.endSubscription(fqn, [LINE_STATE_CHANGED]);
            });

            // calculate other visible lines
            const visibleLines = this.$svg
                ?.find(`[data-key]`)
                .get()
                .filter((svg: HTMLElement) => this.isInViewport(svg))
                .map((svg: HTMLElement) => {
                    return svg.dataset?.['key'];
                });

            this.zoneSubscriptions = [
                ...new Set(
                    visibleLines
                        .map((line) => {
                            const [floorId, areaId, zoneId] = line.split('-');
                            return `${this.warehouse}-${floorId}-${areaId}-${zoneId}`;
                        })
                        .filter((line) => this.groupName !== line),
                ),
            ];

            // just suscribe to notifications of zones that may be visible
            this.zoneSubscriptions.forEach(async (fqn: string) => {
                await this.warehouseStatusService.startSubscription(fqn, [LINE_STATE_CHANGED]);
                const lineObservable = await this.warehouseStatusService.listenNotifications(fqn, LINE_STATE_CHANGED);
                lineObservable
                    .pipe(
                        bufferTime(200),
                        switchMap((notifications) => of(this.$svg ? notifications : [])),
                        switchMap((notifications) => of(notifications.filter((notification) => this.mustPaintLine(notification)))),
                        switchMap((allNotificationsOfFloor: LineStatusNotification[]) => {
                            return of(allNotificationsOfFloor.filter((notification) => {
                                const notificationTS = getEventTS(LINE_STATE_CHANGED, notification);
                                return this.warehouseStatusService.isNewer(this.groupName, LINE_STATE_CHANGED, notification, notificationTS)
                                //return NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, LINE_STATE_CHANGED, notificationTS, notification);
                            }));
                        }),
                        switchMap((notifications) => of(notifications.map((notification) => this.getElementToUpdateState(notification)))),
                        takeUntil(this.ngUnsubscribe),
                    )
                    .subscribe({
                        next: this.drawLineState.bind(this),
                    });
            });
        } catch (error) {
            console.error(error);
        }
    }

    emitClickLine(event) {
        this.clickLine.emit(event);
    }

    async startRealtimeNotifications() {
        try {
            if (!this.lineChange$) return;
            //NotificationsMap.clearNotificationState('WarehouseStatusService', this.groupName, LINE_STATE_CHANGED);
            await this.cleanZoneMapStates();
            this.lineChange$
                ?.pipe(
                    bufferTime(200),
                    switchMap((notifications) => of(this.$svg ? notifications : [])),
                    switchMap((notifications) => of(notifications.filter((notification) => this.mustPaintLine(notification)))),
                    switchMap((allNotificationsOfFloor: LineStatusNotification[]) => {
                        return of(allNotificationsOfFloor.filter((notification) => {
                            const notificationTS = getEventTS(LINE_STATE_CHANGED, notification);
                            return this.warehouseStatusService.isNewer(this.groupName, LINE_STATE_CHANGED, notification, notificationTS)
                            //return NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, LINE_STATE_CHANGED, notificationTS, notification);
                        }));
                    }),
                    switchMap((notifications) => of(notifications.map((notification) => this.getElementToUpdateState(notification)))),
                    takeUntil(this.ngUnsubscribe),
                )
                .subscribe({
                    next: this.drawLineState.bind(this),
                    error: console.error,
                });
            await this.subscribeToSurroundZones();
            this.highlightMarkedLine();
            this.loadZoneState(this.floorId, this.areaId, this.zone.id);
        } catch (error) {
            console.error(error);
        }
    }

    loadZoneState(floorId: string, areaId: string, zoneId: string) {
        const lineStates$ = this.lineService.getLineStatus(floorId); //all the floor (initial value), becouse the user could drag throw the entire floor
        this.warehouseStatusService.clearNotifications(this.groupName, LINE_STATE_CHANGED);

        lineStates$
            .pipe(
                take(1),
                switchMap((allNotifications) =>
                    of(
                        allNotifications.filter(
                            (notification) => floorId === notification.floorId /*&& areaId === notification.areaId && zoneId === notification.zoneId*/,
                        ),
                    ),
                ), //all the floor (initial value), becouse the user could drag throw the entire floor
                switchMap((allNotificationsOfFloor: LineStatusNotification[]) => {
                    return allNotificationsOfFloor.filter((notification) => {
                        const notificationTS = getEventTS(LINE_STATE_CHANGED, notification);
                        return this.warehouseStatusService.isNewer(this.groupName, LINE_STATE_CHANGED, notification, notificationTS)
                        //return NotificationsMap.isNewerNotification('WarehouseStatusService', this.groupName, LINE_STATE_CHANGED, notificationTS, notification);
                    });
                }),
                switchMap((newestNotification) => {
                    return of(this.getElementToUpdateState(newestNotification));
                }),
            )
            .subscribe({
                next: (notificationWithElement) => {
                    this.drawLineState([notificationWithElement]); // true)
                },
                error: console.error,
            });
    }

    loadZoneStateVariables() {
        const initialLineMapStatesVariables$ = this.lineService.getLineStatusVariableLines(this.floorId);

        initialLineMapStatesVariables$
            .pipe(
                take(1),
                switchMap((allNotifications) => of(allNotifications.filter((notification) => this.floorId === notification.floorId))),
                switchMap((allNotificationsOfFloor: VariableNotification[]) => {
                    return of(allNotificationsOfFloor.filter((notification) => {
                        return this.variablesActiveStatusService.isNewerNotificationFromService(notification)
                    }));
                })
            )
            .subscribe({
                next: (notificationWithElement) => {
                    notificationWithElement.forEach(v => {
                        this.receiveRealTimeVariables(v)
                    }
                    );
                },
                error: console.error,
            });
    }

    mustPaintLine(notification) {
        const { floorId, areaId, zoneId } = notification;
        return (
            this.floorId === floorId /*&& this.areaId === areaId && this.zone.id === zoneId*/ ||
            this.zoneSubscriptions.includes(`${this.warehouse}-${notification.floorId}-${notification.areaId}-${notification.zoneId}`) //all the floor (initial value), becouse the user could drag throw the entire floor
        );
    }

    async cleanZoneMapStates() {
        if (!this.$svg) return;
        if (this.firstMapLoad) {
            this.firstMapLoad = false;
            return;
        }
        this.$svg
            ?.find(`[data-key]`)
            .get()
            .filter((svg: HTMLElement) => this.isInViewport(svg))
            .forEach((svg) => {
                const key = svg.dataset?.['key'];
                const el = this.getOrAddLineCache(key);
                if (el) el.removeClass().removeData();

                if (this.$buttons) {
                    const button = this.$buttons.find(`[data-key="${key}"]`);
                    if (button) button.removeClass().removeData();
                }
                if (this.$buttonsOverMap) {
                    const button = this.$buttonsOverMap.find(`[data-key="${key}"]`);
                    if (button) button.removeClass().removeData();
                }
            });

        // await yieldToMain();
    }

    getElementToUpdateState(notification) {
        const key = `${notification.floorId}-${notification.areaId}-${notification.zoneId}-${notification.lineId}`;
        if (this.$svg) {
            const el = this.getOrAddLineCache(key);
            let button;
            if (this.$buttons) {
                button = this.$buttons.find(`[data-key="${key}"]`);
            } else if (this.$buttonsOverMap) {
                button = this.$buttonsOverMap.find(`[data-key="${key}"]`);
            }
            return { notification, el, button };
        } /*else if (this.$buttons) {
            const button = this.$buttons.find(`[data-key="${key}"]`);
            return { notification, button };
        }*/
        return undefined;
    }

    // async drawZoneState(line, retry = 0) {
    drawLineState(items: Array<any>) {
        for (const item of items) {
            if (!item) continue;
            const { notification, el, button } = item;
            const state = notification.state.filter((s) => s === 'unknown' || this.visibleStates?.includes(s)).join(' ');
            const element = this.liteView ? button : el.length > 0 ? el : button;
            const newClass = this.liteView ? `btn btn-default ${state}` : el.length > 0 ? state : `btn btn-default ${state}`;
            const classesToAdd = element.hasClass('markedLine') ? `${newClass} markedLine` : newClass;

            fastdom.mutate(() => element.removeClass().addClass(classesToAdd).data('state', state));
        }
    }

    resetZoom() {
        if (this.zoneSVG) {
            this.zoneSVG.zoom(this.currentZoom);
            this.$svg.attr('viewBox', this.zone.viewBox);
            this.refreshVisibleLines();
        }
    }
    // createArrow(data) {
    //     if (data) {
    //         const orientation = data.toLowerCase();
    //         if (this.acceptedArrows.includes(orientation)) {
    //             return `assets/img/arrows/${orientation}.svg#${orientation}-full`;
    //         } else {
    //             return ``;
    //         }
    //     } else {
    //         return ``;
    //     }
    // }
    setTitleY() {
        return Number(this.setViewBox.split(' ')[1]) - 8;
    }

    // https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/
    isInViewport(el: HTMLElement): boolean {
        try {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        } catch (error) {
            console.error(error);
            // if there is an error, ensure all elements are addressed as visible
            return true;
        }
    }
    refreshVisibleLines() {
        const rectSVG = this.svg.nativeElement.getBoundingClientRect();
        const visibleLines = this.$svg
            ?.find(`[data-key]`)
            .get()
            .filter((svg: HTMLElement) => isInViewport(svg, rectSVG))
            .map((svg: HTMLElement) => {
                return svg.dataset?.['key'];
            });
        this.visibleLines.emit(visibleLines);
    }

    async handleReconnection(this) {
        await this.variablesActiveStatusService.unsubscribeFromVariable();
        this.subscribed = false;
        this.currentFilters = [];
        //this.selectedVariablesFromDT.forEach((element) => this.loadVariableRT(element.selectedVariable));
        this.loadVariableRT();
    }

    loadVariableRT() {
        if (!this.allVariablesToShow || this.allVariablesToShow.length === 0) return;

        // ONLY CONNECT if the floor has variables
        if (!this.variablesStatusClient.connection) {
            this.variablesStatusClient.connectToHub().then(() => {
                console.warn(`Connected to Variables status Hub ${this.zone?.id}`);
            });
        }

        if (!this.subscribed) this.currentRequestId = 'ZoneMap---' + uuid();

        this.allVariablesToShow.forEach((variableToShow) => {
            let [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = variableToShow?.split('.');
            variableType = variableType === 'FAILURE' ? 'ALARM' : variableType;
            const firstFilter: VariableFilter = {
                warehouseId: this.warehouse,
                floorId,
                areaId,
                zoneId,
                lineId,
                equipmentId,
                //equipmentType, //in DT this property is *
                variableType,
                variableName,
            };
            this.currentFilters.push(firstFilter);
        });
        const filters: VariableFilters = {
            requestId: this.currentRequestId,
            filters: this.currentFilters,
            maxNotificationItems: MAX_NOTIFICATIONS,
            orderColumn: ORDER_COLUMN,
            orderType: ORDER_TYPE,
        };

        if (!this.subscribed) {
            this.subscribed = true;
            this.variablesActiveStatusService.subscribeToVariable(filters, this.receiveRealTimeVariables.bind(this), this.handleReconnection.bind(this));
        } else {
            this.variablesActiveStatusService.applyFilter(filters);
        }
        this.waitForResponseTimeout = setTimeout(() => {
            this.waitForResponse = false;
            this.waitForResponseTimeout = null;
        }, 3000);
    }

    receiveRealTimeVariables(notification: VariableNotification) {
        if (notification.requestId === this.currentRequestId || notification.requestId === VARIABLE_EMPTY_REQUEST_ID) {
            if (notification.sourceTimeStamp === undefined && ![undefined, null, 'ApiDefault'].includes(notification.source)) {
                console.error('SourceTimeStamp from backend is null/undefined.', notification);
            }

            const variableToShow = findVariableToShow(this.allVariablesToShow, notification);

            if (variableToShow) {
                const [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = variableToShow.split('.');

                //cause in DT u dont know the EquipmentType, is used an * for this value
                const key = `${areaId}.${zoneId}.${floorId}.${lineId}.${equipmentId}.*.${variableType}.${variableName}`.toUpperCase();

                //SET value tu text variableLines element
                this.map.variablesLines?.texts?.filter((v) => v['variable'] === key).forEach((text) => (text['text'] = String(notification.variableValue)));
                //SET value tu metadataBlock text variableLines element
                this.metadataBlocks.metadata
                    .filter((b) => b['variable'] === key)
                    .forEach((block) => {
                        block.texts.filter((v) => v['variable'] === key).forEach((text) => (text['text'] = String(notification.variableValue)));
                    });

                const icon = this.$svg?.find(`path.shape[data-key="${key}"]`);
                const blockElement = this.$svg?.find(`g.shape.block > path.withVariable[data-key="${key}"]`);
                const blockTextElement = this.$svg?.find(`[data-key="${'text' + key}"]`);

                if ((blockElement?.length && blockTextElement?.length) || icon?.length) {
                    let pathTextFillColor = '#eeeeee';

                    const variableFound: VariableColors = findVariableColor(
                        areaId,
                        zoneId,
                        floorId,
                        lineId,
                        equipmentId,
                        equipmentType,
                        variableType,
                        variableName,
                        this.variableColorsConfiguration
                    );

                    if (variableFound) {
                        const variableValue = notification.variableValueType.toUpperCase() !== VariableValueType.Boolean.toUpperCase() ? notification.variableValue : notification.variableValue.toString().toUpperCase() === 'TRUE' ? 1 : notification.variableValue.toString().toUpperCase() === 'FALSE' ? 0 : notification.variableValue;
                        const defaultColor = variableFound.settings?.find((color) => +color.to === 0);
                        const foundedColor = variableFound.settings?.find((color) => +variableValue <= +color.to);
                        const finalColor = foundedColor ?? defaultColor;
                        if (finalColor) {
                            pathTextFillColor = finalColor.color;
                            if (icon?.length) {
                                if (finalColor.visible !== false) {
                                    icon.attr('style', 'fill:' + pathTextFillColor);
                                } else {
                                    icon.attr('style', 'display:none');
                                }
                            }
                            if (blockTextElement?.length) {
                                const foreColor = this.textColorPipe.transform(pathTextFillColor);
                                blockTextElement.attr('style', 'fill: ' + foreColor + '; stroke: ' + foreColor);
                            }
                        }
                        blockElement?.attr('style', 'fill:' + pathTextFillColor);
                    }
                }
            }
            this.waitForResponse = false;
            if (this.waitForResponseTimeout) {
                clearTimeout(this.waitForResponseTimeout);
                this.waitForResponseTimeout = null;
            }
        }
    }

    emitLine(event, newLineObject) {
        this.activateZoom = false;
        if (newLineObject) {
            this.newLineSelected.emit(newLineObject);
        } else if (event) {
            const key = $(event.target).data('key');
            const keys = key.split('-');

            this.newLineSelected.emit({
                warehouseId: this.warehouse,
                floorId: keys[0],
                areaId: keys[1],
                zoneId: keys[2],
                lineId: keys[3],
            });
        }
    }

    getOrAddLineCache(key: string): JQuery<HTMLElement> {
        if (!this.linesCache?.has(key)) {
            const el = this.$svg.find(`[data-key="${key}"]`);
            this.linesCache.set(key, el);
        }
        return this.linesCache.get(key);
    }

    onRightClick(e) {
        this.$svg?.find('path').tooltip('hide');

        this.keySelectedWithRightClick = $(e.target).data('key');

        this.rightClickX = e.pageX;
        this.rightClickY = e.pageY;

        return false;
    }
    hideContextMenu() {
        this.keySelectedWithRightClick = '';
        this.rightClickX = 0;
        this.rightClickY = 0;
    }
}
