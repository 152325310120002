<app-lines-map-icons [icons]="iconsContent"></app-lines-map-icons>
<div class="svg-wrapper" (click)="filterAlarms(null, null, null)" [hidden]="liteView || viewLineMap" [ngClass]="viewFullSize ? 'expand' : ''">
    <h4>
        <button
            [hidden]="viewLineMap"
            class="btn btn-light float-start ms-0 px-1"
            (click)="viewLineIconsText = !viewLineIconsText"
            [title]="viewLineIconsText ? 'Hide equipment names' : 'Show equipment names'"
        >
            <i class="fas" [ngClass]="viewLineIconsText ? 'fa-remove-format' : 'fa-font'"></i>
        </button>
        <button
            *ngIf="haveLinePhoto"
            class="btn btn-light float-end me-2 px-1"
            (click)="toogleMap()"
            [title]="viewLineMap ? 'Show line Map' : 'Show line Photo'"
        >
            <i class="fa" [ngClass]="viewLineMap ? 'fa-eye' : 'fa-eye-slash'"></i>
        </button>
        <button class="btn btn-light float-end me-2 px-1" (click)="resetZoom()" title="Reposition map" *ngIf="viewZoom">
            <i class="fa fa-crosshairs" aria-hidden="true"></i>
        </button>
        <button class="btn btn-light float-end me-2 px-1" (click)="toogleFullScreen()" [title]="viewFullSize ? 'Hide extra info' : 'Show extra info'">
            <i class="fa" [ngClass]="viewFullSize ? 'fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'"></i>
        </button>
        <a
            class="float-end mt-2 me-2"
            [routerLink]="['/warehouse', warehouse, 'floor', floorId, { selectedAreaZone: floorId + '-' + areaId + '-' + zoneId + '-' + lineId }]"
            >View line in floor&nbsp;&nbsp;|</a
        >
    </h4>

    <!-- <h6 class="navigate-to-floor">
        <a [routerLink]="['/warehouse', warehouse, 'floor', floorId, { selectedAreaZone: floorId + '-' + areaId + '-' + zoneId + '-' + lineId }]"
            >View line in floor</a
        >
    </h6> -->
    <!-- <button *ngIf="haveLinePhoto" class="btn btn-light view-eye" (click)="toogleMap()" [title]="viewLineMap ? 'Show line Map' : 'Show line Photo'">
        <i class="fa" [ngClass]="viewLineMap ? 'fa-eye' : 'fa-eye-slash'"></i>
    </button> -->
    <!-- <button class="btn btn-light view-full-size" (click)="toogleFullScreen()" [title]="viewFullSize ? 'Hide extra info' : 'Show extra info'">
        <i class="fa" [ngClass]="viewFullSize ? 'fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'"></i>
    </button>
    <button class="btn btn-light ms-2 view-zoom" (click)="resetZoom()" title="Reposition map" *ngIf="viewZoom">
        <i class="fa fa-crosshairs" aria-hidden="true"></i>
    </button> -->
    <!-- <button
        [hidden]="viewLineMap"
        class="btn btn-light ms-2"
        (click)="viewLineIconsText = !viewLineIconsText"
        [title]="viewLineIconsText ? 'Hide equipment names' : 'Show equipment names'"
    >
        <i class="fas" [ngClass]="viewLineIconsText ? 'fa-remove-format' : 'fa-font'"></i>
    </button> -->
    <svg
        #svg
        auwaSvgZoom
        directiveCurrentZoom="{{ currentZoom }}"
        directiveViewBox="{{ setViewBox }}"
        directiveReduceZoom="{{ reduceZoom }}"
        (svgZoomEventEmmiter)="refreshVisibleLines()"
        [attr.viewBox]="setViewBox"
        class="icon"
    >
        <ng-container *ngFor="let equipment of line.equipments; let i = index">
            {{ i }}
            <ng-container *ngIf="equipment.grid">
                <svg:use
                    [attr.xlink:href]="createPathUrl(equipment)"
                    [attr.x]="equipment.grid.x"
                    [attr.y]="equipment.grid.y"
                    [attr.width]="1"
                    [attr.height]="1"
                ></svg:use>
                <svg:use
                    class="my_piece"
                    data-state=""
                    [attr.xlink:href]="createEquipmentUrl(equipment)"
                    attr.data-key="{{ equipment.id }}-{{ equipment.type | lowercase }}"
                    [attr.x]="equipment.grid.x"
                    [attr.y]="equipment.grid.y"
                    [attr.width]="1"
                    [attr.height]="1"
                ></svg:use>
                <svg:rect
                    class="my_rect"
                    *ngIf="equipment.type !== 'end'"
                    attr.title="{{ equipment.id }} <small class='tooltip-equipment-type'>{{ equipment.type }}</small>"
                    attr.data-key="{{ equipment.id }}-{{ equipment.type | lowercase }}-wrapper"
                    [attr.data-bs-toogle]="'tooltip'"
                    [attr.data-bs-html]="'true'"
                    [attr.x]="equipment.grid.x"
                    [attr.y]="equipment.grid.y"
                    [attr.width]="1"
                    [attr.height]="1"
                    (click)="displayVariables($event, equipment.id, equipment.type)"
                ></svg:rect>
                <ng-container *ngIf="i === 1">
                    <svg:use
                        class="arrow"
                        [attr.xlink:href]="line.orientation | arrow: 'O' : false"
                        [attr.x]="equipment.grid.x | arrow: 'X'"
                        [attr.y]="equipment.grid.y | arrow: 'Y'"
                        [attr.width]="1"
                        [attr.height]="1"
                    ></svg:use>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let equipment of line.equipments; let i = index">
            <svg:text
                *ngIf="equipment.grid && viewLineIconsText"
                class="label-text"
                [attr.font-size]="'0.15'"
                [attr.x]="equipment.grid.x"
                [attr.y]="equipment.grid.y"
            >
                <tspan
                    [attr.dy]="line.equipments[i - 1]?.grid.x === equipment.grid.x ? 0.5 : i % 2 === 0 ? 0.05 : 1.015"
                    [attr.dx]="line.equipments[i - 1]?.grid.y === equipment.grid.y ? 0.5 : 0.95"
                    [attr.text-anchor]="line.equipments[i - 1]?.grid.y === equipment.grid.y ? 'middle' : 'start'"
                >
                    {{ equipment.id }}
                </tspan>
            </svg:text>
        </ng-container>
    </svg>
</div>
<div class="svg-wrapper" [hidden]="!viewLineMap">
    <!-- <button *ngIf="haveLinePhoto" class="btn btn-light view-eye" (click)="toogleMap()" [title]="viewLineMap ? 'Show line Map' : 'Show line Photo'">
        <i class="fa" [ngClass]="viewLineMap ? 'fa-eye' : 'fa-eye-slash'"></i>
    </button> -->
    <h4>
        <button
            *ngIf="haveLinePhoto"
            class="btn btn-light float-end me-2 px-1"
            (click)="toogleMap()"
            [title]="viewLineMap ? 'Show line Map' : 'Show line Photo'"
        >
            <i class="fa" [ngClass]="viewLineMap ? 'fa-eye' : 'fa-eye-slash'"></i>
        </button>
    </h4>
    <app-loading-spinner *ngIf="isLoadingLineView" [extraClasses]="'mt-2 mb-5'"></app-loading-spinner>
    <div class="row mt-1 mb-3" [hidden]="isLoadingLineView">
        <!-- id="equipmentview" style="align-items: center"-->
        <div class="col-12 col-md-8 offset-md-2 text-center mt-3" [hidden]="backgroundImage !== ''">
            <p>We don't have view for this line...</p>
        </div>
        <div class="col-12" [hidden]="backgroundImage === ''">
            <svg #svg2 [attr.viewBox]="setViewBoxSVG2" class="metadataLastLevel">
                <svg:image *ngIf="backgroundImage !== ''" [attr.href]="backgroundImage" height="100%" width="100%" x="0" y="0"></svg:image>
                <svg:g class="variablesGroup">
                    <ng-container *ngFor="let block of metadata?.variables?.blocks">
                        <svg:g
                            class="shape block"
                            [attr.transform]="block?.transform"
                            [attr.variable]="block?.variable"
                            [attr.description]="block?.description"
                        >
                            <ng-container *ngFor="let item of block?.shapes">
                                <svg:path
                                    *ngIf="item && item.data !== 'null'"
                                    [attr.data-element]="'shape'"
                                    [attr.data-key]="item.variable"
                                    [ngClass]="{ withVariable: item.variable, highlightPath: item.highlightPath }"
                                    [attr.d]="item.data"
                                    [attr.variable]="item.variable"
                                    [attr.description]="item.description"
                                >
                                    <title>{{ block.variable }} - {{ block.description }}</title>
                                </svg:path>
                            </ng-container>
                            <ng-container *ngFor="let item of block?.texts">
                                <svg:text
                                    class="shape"
                                    [attr.data-element]="'shape'"
                                    [attr.font-size]="item.size || '16'"
                                    [attr.x]="item.x"
                                    [attr.y]="item.y"
                                    [attr.data-key]="item.variable ? 'text' + item.variable : ''"
                                    [attr.variable]="item.variable"
                                    [attr.description]="item.description"
                                >
                                    <tspan [attr.text-anchor]="item.variable ? 'middle' : 'left'" dy="20">{{ item.text }}</tspan>
                                    <title>{{ item.variable }}</title>
                                </svg:text>
                            </ng-container>
                        </svg:g>
                    </ng-container>
                    <ng-container *ngFor="let item of metadata?.variables?.shapes">
                        <svg:path
                            [attr.data-key]="item.variable"
                            [attr.class]="item.class"
                            [attr.data-element]="'shape'"
                            [attr.d]="item.data"
                            [attr.variable]="item.variable"
                            [attr.description]="item.description"
                            *ngIf="item.data !== 'null'"
                            (click)="displayVariablesFromLineView($event, item.variable)"
                        >
                            <title>{{ item.variable }} - {{ item.description }} - {{ item.class }}</title>
                        </svg:path>
                    </ng-container>
                    <ng-container *ngFor="let item of metadata?.variables?.texts">
                        <svg:text
                            class="shape"
                            [attr.data-element]="'shape'"
                            [attr.font-size]="item.size || '16'"
                            [attr.x]="item.x"
                            [attr.y]="item.y"
                            [attr.variable]="item.variable"
                            [attr.description]="item.description"
                        >
                            <tspan dy="20">{{ item.text }}</tspan>
                        </svg:text>
                    </ng-container>
                </svg:g>
            </svg>
        </div>
    </div>
</div>
<div (click)="filterAlarms(null, null, null)" *ngIf="liteView">
    <div #buttons class="col-12 buttons-wrapper">
        <div class="row" *ngIf="line.equipments.length > 0" style="margin: 0">
            <!-- <ng-template ngFor let-equipment [ngForOf]="line.equipments"> -->
            <ng-container *ngFor="let equipment of line.equipments">
                <div class="btn-zones col-6 col-md-4 col-lg-3" *ngIf="equipment.id">
                    <div
                        role="button"
                        class="btn btn-default"
                        data-state=""
                        attr.data-key="{{ equipment.id }}-{{ equipment.type | lowercase }}"
                        (click)="displayVariables($event, equipment.id, equipment.type)"
                    >
                        {{ equipment.id }}
                    </div>
                </div>
            </ng-container>
        </div>
        <h5 class="text-center py-5" *ngIf="line.equipments.length === 0">No hay equipos</h5>
    </div>
</div>
