import { Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

interface AnalyticsTableState {
    filters: Array<any>;
    from: string;
    to: string;
    orderColumn: string;
    orderType: string;
}

@Injectable()
export class PowerBiService {
    baseUrl: string;
    baseUrl2: string;

    equipmentState: AnalyticsTableState = {
        filters: [],
        from: dayjs().subtract(1, 'days').startOf('hour').format('YYYY-MM-DD HH:mm:ss'),
        to: dayjs().startOf('hour').format('YYYY-MM-DD HH:mm:ss'),
        orderColumn: 'id',
        orderType: 'desc',
    };

    constructor(
        private http: HttpClient,
        private appLoadService: AppLoadService,
    ) {
        appLoadService.getCurrentWarehouse.subscribe((res) => {
            const base = `${res.hostName}/api`.toLowerCase();
            this.baseUrl = `${base}/kpireports/${res.warehouse}`;
            this.baseUrl2 = `${base}/reports/${res.warehouse}/errors/alarms`;
        });
    }

    getData(queryParams): Observable<any[]> {
        const params = `?TopLevel=${queryParams.mode}&from=${dayjs(queryParams.start, 'D/M/YY HH:mm').toISOString()}&to=${dayjs(
            queryParams.end,
            'D/M/YY HH:mm',
        ).toISOString()}`;
        // const data = this.http.get<any[]>(`http://localhost:3001/powerBi`);
        const data = this.http.get<any[]>(`${this.baseUrl}${params}`);
        return data;
    }
    getErrorsData(queryParams): Observable<any[]> {
        const params = `?from=${dayjs(queryParams.start, 'D/M/YY HH:mm').toISOString()}&to=${dayjs(queryParams.end, 'D/M/YY HH:mm').toISOString()}`;
        // const params = `?from=2022-05-15T10:00:00.000Z&to=2022-05-16T10:00:00.000Z`;
        // const data = this.http.get<any[]>(`http://localhost:3001/powerBi`);
        const data = this.http.get<any[]>(`${this.baseUrl2}${params}`);
        return data;
    }

    getTableData(queryParams): Observable<any[]> {
        const mode = queryParams.mode === 'Equipments' ? 'Lines' : queryParams.mode;
        const params = `?TopLevel=${mode}&from=${dayjs(queryParams.start, 'D/M/YY HH:mm').toISOString()}&to=${dayjs(
            queryParams.end,
            'D/M/YY HH:mm',
        ).toISOString()}&GroupingType=Day`;
        // const data = this.http.get<any[]>(`http://localhost:3001/powerBi`, { params });
        const data = this.http.get<any[]>(`${this.baseUrl}${params}`);
        return data;
    }
}
