<div class="row" *ngIf="structure">
    <div class="col-xl-6 col-lg-6 col-12 svg-wrapper home" *ngFor="let custom of structure.custom">
        <app-custom-map
            [warehouse]="warehouse.warehouse"
            [structure]="structure"
            [activateZoom]="activateZoom"
            [custom]="custom"
            [visibleStates]="visibleStates"
            [lineChange$]="customsChange$[custom.name]"
            [tabState]="tabState"
            [wheelZoom]="false"
            [mode]="mode"
            (newLineSelected)="newLineSelected($event)"
        >
        </app-custom-map>
    </div>
</div>
<app-home-lines-minified-container [lineToShowOff]="lineToShow" [visibleStatesOff]="visibleStates" (offcanvasToggle)="offcanvasToggle($event)">
</app-home-lines-minified-container>
