interface NotificationDetails {
    timestamp: string;
    notification: any;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NotificationsMap = (() => {
    const notificationsMap = new Map<string, NotificationDetails>();

    const setLastNotification = (serviceName: string, groupName: string, eventName: string, key: string, notificationDetails: NotificationDetails) => {
        notificationsMap.set(mapKey(serviceName, groupName, eventName, key), notificationDetails);
    };

    const getLastNotification = (serviceName: string, groupName: string, eventName: string, notification: any): NotificationDetails => {
        const key = generateKey(notification);
        return notificationsMap.get(mapKey(serviceName, groupName, eventName, key));
    };

    const checkDates = (candidate, current) => {
        return current?.getTime && candidate?.getTime ? candidate.getTime() <= current.getTime() : candidate <= current;
    };

    const isNewerNotification = (serviceName: string, groupName: string, eventName: string, notificationTimestamp: any, notification: any): boolean => {
        const key = generateKey(notification);
        const current = notificationsMap.get(mapKey(serviceName, groupName, eventName, key));
        let isNewer = true;
        if (current && JSON.stringify(current.notification) === JSON.stringify(notification)) {
            // skip duplicated messages
            isNewer = false;
        } else if (!notificationTimestamp && notification.source) {
            // if ts isn't provided
            isNewer = true;
        } else if (current) {
            // check if is a new notification
            const currentTimestamp: any = current.timestamp;
            if (checkDates(notificationTimestamp, currentTimestamp)) {
                isNewer = false;
            }
        }

        if (isNewer) {
            const n = notification;
            setLastNotification(serviceName, groupName, eventName, key, { timestamp: notificationTimestamp, notification: n });
        }
        return isNewer;
    };

    const appendProperty = (currentKey: string, property: string) => {
        return !property ? currentKey : currentKey ? `${currentKey}-${property}` : property;
    };

    const generateKey = (notification: any) => {
        let key = ``;
        key = appendProperty(key, notification.requestId);
        key = appendProperty(key, notification.warehouseId);
        key = appendProperty(key, notification.whid);
        key = appendProperty(key, notification.floorId);
        key = appendProperty(key, notification.areaId);
        key = appendProperty(key, notification.zoneId);
        key = appendProperty(key, notification.lineId);
        key = appendProperty(key, notification.equipmentId);
        key = appendProperty(key, notification.fieldGatewayId);
        key = appendProperty(key, notification.opcServerId);
        key = appendProperty(key, notification.nodeId);
        key = appendProperty(key, notification.variableName);
        key = appendProperty(key, notification.variableType);
        return key;
    };

    const clearNotificationState = (constructorName: string, groupName: string, eventName: string) => {
        const keyToDelete = `lastNotification::${constructorName}.${groupName}.${eventName}`;
        notificationsMap.forEach((notification, notificationKey, map) => {
            if (notificationKey.startsWith(keyToDelete)) {
                map.delete(notificationKey);
            }
        });
    };

    const mapKey = (serviceName: string, groupName: string, eventName: string, key: string) => {
        return `lastNotification::${serviceName}.${groupName}.${eventName}-${key}`;
    };

    return {
        getLastNotification,
        setLastNotification,
        isNewerNotification,
        clearNotificationState,
        generateKey,
        mapKey,
    };
})();
