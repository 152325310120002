<div class="row mt-3 mb-3 justify-content-center">
    <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
        <div class="row mt-4 mb-5">
            <div class="col-12 col-md-6 col-lg-7">
                <h5 class="float-start text-start ms-5 me-3 mt-2">ERRORS TREND COLORS</h5>
            </div>
            <div class="container">
                <div class="row ms-md-5 mt-2 mb-2">
                    <div class="col-12 ms-5 mt-0">
                        <table class="table-compact table-hover w-75 mt-1 mb-5">
                            <thead>
                                <tr>
                                    <th style="width: 30%">Errors</th>
                                    <th style="width: 60%" class="text-center">Ranges & Colors</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let errorsColor of errorTrendColors">
                                    <td>
                                        <span>{{ errorsColor.fqn }}</span>
                                    </td>
                                    <td class="h6">
                                        <shared-config-color-picker
                                            dimension=""
                                            [firstRange]="errorsColor.firstRange"
                                            [secondRange]="errorsColor.secondRange"
                                            extraClasses="mt-0 mb-1 ms-5"
                                            extraClassesInternalDiv="col-10"
                                            [firstColorRange]="errorsColor.firstColorRange"
                                            [secondColorRange]="errorsColor.secondColorRange"
                                            [thirdColorRange]="errorsColor.thirdColorRange"
                                            (colorSelected)="saveColorPickerErrors($event, errorsColor.fqn)"
                                            (applyRanges)="calculateNewRangesErrors($event, errorsColor.fqn)"
                                        ></shared-config-color-picker>
                                    </td>
                                    <td class="text-center"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
