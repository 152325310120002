<div class="row mt-3 mb-3 justify-content-center">
    <div class="col-12 col-md-10 col-lg-8 mt-3 mb-3 p-2 settings-box">
        <div class="row mt-4 mb-5">
            <div class="col-12 col-md-6 col-lg-7">
                <h5 class="float-start text-start ms-5 me-3 mt-2">SCANNERS TREND COLORS</h5>
            </div>
            <div class="col-6 offset-3 col-md-6 col-lg-3 offset-md-0">
                <app-search
                    #scannerTrendFilter
                    key="txtFindScannerTrendConfiguration"
                    placeholder="Filter Scanners"
                    columns="3"
                    icon="fas fa-search"
                    (searchValueDeleted)="scannerTrendFilterValue = ''"
                >
                </app-search>
            </div>
            <div class="container">
                <div class="row ms-md-5 mt-2 mb-2">
                    <div class="col-12 ms-5 mt-0">
                        <table class="table-compact table-hover w-75 mt-1 mb-5">
                            <thead>
                                <tr>
                                    <th style="width: 30%">Scanner</th>
                                    <th style="width: 60%" class="text-center">Ranges & Colors</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let scannerColor of scannerTrendColors; index as i; trackBy: trackByScanner"
                                    [hidden]="scannerTrendFilterValue !== '' && !scannerColor.scanner.includes(scannerTrendFilterValue)"
                                >
                                    <td>
                                        <span>{{ scannerColor.scanner }}</span>
                                    </td>
                                    <td class="h6">
                                        <shared-config-color-picker
                                            dimension=""
                                            [firstRange]="scannerColor.firstRange"
                                            [secondRange]="scannerColor.secondRange"
                                            extraClasses="mt-0 mb-1 ms-5"
                                            extraClassesInternalDiv="col-10"
                                            [firstColorRange]="scannerColor.firstColorRange"
                                            [secondColorRange]="scannerColor.secondColorRange"
                                            [thirdColorRange]="scannerColor.thirdColorRange"
                                            (colorSelected)="saveColorPickerScanner($event, scannerColor.scanner)"
                                            (applyRanges)="calculateNewRangesScanner($event, scannerColor.scanner)"
                                        ></shared-config-color-picker>
                                    </td>
                                    <td class="text-center">
                                        <button
                                            class="btn btn-sm btn-danger my-1"
                                            title="Delete Scanner Color"
                                            [hidden]="scannerColor.scanner === initialEnviroment"
                                            (click)="deleteConfirmation(6, '', scannerColor.scanner, '')"
                                        >
                                            <i class="fas fa-times-circle"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <app-select
                                            #scannersSelector
                                            key="settings-scanners-search"
                                            label="Scanner"
                                            [item$]="scanner$"
                                            columns="8"
                                            placeholder="Select scanner"
                                            searchable="true"
                                        ></app-select>
                                    </td>
                                    <td class="h6">
                                        <shared-config-color-picker
                                            dimension=""
                                            [firstRange]="scannerRangeColorsTemporal.firstRange"
                                            [secondRange]="scannerRangeColorsTemporal.secondRange"
                                            extraClasses="mt-0 mb-1 ms-5"
                                            extraClassesInternalDiv="col-10"
                                            [firstColorRange]="scannerRangeColorsTemporal.firstColorRange"
                                            [secondColorRange]="scannerRangeColorsTemporal.secondColorRange"
                                            [thirdColorRange]="scannerRangeColorsTemporal.thirdColorRange"
                                            (colorSelected)="saveColorPickerScanner($event)"
                                            (applyRanges)="calculateNewRangesScanner($event)"
                                        ></shared-config-color-picker>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-primary my-1" (click)="addNewScanner()">
                                            <i class="fas fa-check" title="Add Zone Color"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
