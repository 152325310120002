import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { IdentifiersPreferences } from '@app/map/home/shared/structure';
import { CheckboxComponent } from '@ays/commons/lib/components/forms/checkbox/checkbox.component';


const FLOOR_IDENTIFIER_VALUE = 'F0';
const AREA_IDENTIFIER_VALUE = 'AWH';
const ZONE_IDENTIFIER_VALUE = 'Z04TD101';
const LINE_IDENTIFIER_VALUE = 'EQLTRASLO_01';

@Component({
    selector: 'settings-identifiers',
    templateUrl: './settings-identifiers.component.html',
    styleUrls: ['./settings-identifiers.component.scss'],
})
export class SettingsIdentifiersComponent implements AfterViewInit, OnInit {
    @Input({ required: true }) identifiers: IdentifiersPreferences;
    @Output() newIdentifiers = new EventEmitter();

    @ViewChild('floorCheck', { static: false }) floorCheck: CheckboxComponent;
    @ViewChild('areaCheck', { static: false }) areaCheck: CheckboxComponent;
    @ViewChild('zoneCheck', { static: false }) zoneCheck: CheckboxComponent;
    @ViewChild('lineCheck', { static: false }) lineCheck: CheckboxComponent;

    isColoringTextFloor = false;
    isColoringTextArea = false;
    isColoringTextZone = false;
    isColoringTextLine = false;

    floorIdentifierValue = FLOOR_IDENTIFIER_VALUE;
    areaIdentifierValue = AREA_IDENTIFIER_VALUE;
    zoneIdentifierValue = ZONE_IDENTIFIER_VALUE;
    lineIdentifierValue = LINE_IDENTIFIER_VALUE;

    constructor() { }

    ngOnInit(): void {
        this.identifiers.Floor = this.identifiers?.Floor ?? true;
        this.identifiers.Area = this.identifiers?.Area ?? true;
        this.identifiers.Zone = this.identifiers?.Zone ?? true;
        this.identifiers.Line = this.identifiers?.Line ?? true;
    }

    ngAfterViewInit() {
        this.floorCheck.$checkbox.prop('checked', this.identifiers.Floor);
        this.areaCheck.$checkbox.prop('checked', this.identifiers.Area);
        this.zoneCheck.$checkbox.prop('checked', this.identifiers.Zone);
        this.lineCheck.$checkbox.prop('checked', this.identifiers.Line);
    }

    handleCheckBoxes(name: string) {
        switch (name) {
            case 'Floor':
                this.identifiers.Floor = this.floorCheck.$checkbox.prop('checked');
                this.isColoringTextFloor = true;
                setTimeout(() => (this.isColoringTextFloor = false), 2000);
                break;
            case 'Area':
                this.identifiers.Area = this.areaCheck.$checkbox.prop('checked');
                this.isColoringTextArea = true;
                setTimeout(() => (this.isColoringTextArea = false), 2000);
                break;
            case 'Zone':
                this.identifiers.Zone = this.zoneCheck.$checkbox.prop('checked');
                this.isColoringTextZone = true;
                setTimeout(() => (this.isColoringTextZone = false), 2000);
                break;
            case 'Line':
                this.identifiers.Line = this.lineCheck.$checkbox.prop('checked');
                this.isColoringTextLine = true;
                setTimeout(() => (this.isColoringTextLine = false), 2000);
                break;
        }

        this.updateIdentifiers()
    }


    updateIdentifiers() {
        this.newIdentifiers.emit(this.identifiers);
    }
}
