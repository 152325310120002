import { Component, EventEmitter, Input, Output, ElementRef, ViewChild, AfterViewInit, OnInit, OnChanges, SimpleChanges } from '@angular/core';

const FIRST_DEFAULT_COLOR = '#2da45d';
const SECOND_DEFAULT_COLOR = '#fe840e';
const THIRD_DEFAULT_COLOR = '#ff2800';

const MIN_DEFAULT_RANGE = 20;
const ERRORS_MIDDLE_DEFAULT_RANGE = 60;
const MAX_DEFAULT_RANGE = 80;

const FIRST_DEFAULT_NAME = 'Range 1';
const SECOND_DEFAULT_NAME = 'Range 2';
const THIRD_DEFAULT_NAME = 'Range 3';

interface ErrorsDimensionRanges {
    rangeId: string;
    name: string;
    range: number;
}

@Component({
    selector: 'shared-config-color-picker',
    templateUrl: './config-color-picker.component.html',
    styleUrls: ['./config-color-picker.component.scss'],
})
export class ConfigColorPickerComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('firstElement', { static: false, read: ElementRef }) firstElement: ElementRef;
    @ViewChild('secondElement', { static: false, read: ElementRef }) secondElement: ElementRef;
    @ViewChild('thirdElement', { static: false, read: ElementRef }) thirdElement: ElementRef;
    @Input() columns: number;
    @Input() dimension: string;
    @Input() firstRange: number;
    @Input() secondRange: number;
    @Input() errorsMiddleRange: number;
    @Input() firstColorRange: string;
    @Input() secondColorRange: string;
    @Input() thirdColorRange: string;
    @Input() firstName: string;
    @Input() secondName: string;
    @Input() thirdName: string;
    @Input() extraClasses = 'ms-5 mt-4 mb-3';
    @Input() extraClassesInternalDiv = 'col-12 col-md-8 col-lg-6';
    @Output() colorSelected = new EventEmitter();
    @Output() applyRanges = new EventEmitter();
    @Output() updatedErrorsNameValue = new EventEmitter();
    @Output() updatedErrorsFromValue = new EventEmitter();

    rangeEdition = false;
    errorsDimensionRanges: Array<ErrorsDimensionRanges> = [];
    editingErrorsFrom = '';
    editingErrorsName = '';
    constructor() {}
    ngOnInit() {
        if (!this.firstRange) this.firstRange = MIN_DEFAULT_RANGE;
        if (!this.secondRange) this.secondRange = MAX_DEFAULT_RANGE;
        if (this.dimension === 'Errors') {
            this.errorsDimensionRanges.push(
                { rangeId: '0', name: this.firstName ?? FIRST_DEFAULT_NAME, range: this.firstRange ?? MIN_DEFAULT_RANGE },
                { rangeId: '1', name: this.secondName ?? SECOND_DEFAULT_NAME, range: this.errorsMiddleRange ?? ERRORS_MIDDLE_DEFAULT_RANGE },
                { rangeId: '2', name: this.thirdName ?? THIRD_DEFAULT_NAME, range: this.secondRange ?? MAX_DEFAULT_RANGE },
            );
        }
    }

    ngAfterViewInit() {
        this.firstElement.nativeElement.style.backgroundColor = this.firstColorRange || FIRST_DEFAULT_COLOR;
        this.secondElement.nativeElement.style.backgroundColor = this.secondColorRange || SECOND_DEFAULT_COLOR;
        this.thirdElement.nativeElement.style.backgroundColor = this.thirdColorRange || THIRD_DEFAULT_COLOR;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.dimension === 'Errors') {
            if (changes.firstName?.previousValue) {
                this.errorsDimensionRanges[0].name = changes.firstName.currentValue;
            }
            if (changes.secondName?.previousValue) {
                this.errorsDimensionRanges[1].name = changes.secondName.currentValue;
            }
            if (changes.thirdName?.previousValue) {
                this.errorsDimensionRanges[2].name = changes.thirdName.currentValue;
            }
            if (changes.firstRange?.previousValue) {
                this.errorsDimensionRanges[0].range = changes.firstRange.currentValue;
            }
            if (changes.errorsMiddleRange?.previousValue) {
                this.errorsDimensionRanges[1].range = changes.errorsMiddleRange.currentValue;
            }
            if (changes.secondRange?.previousValue) {
                this.errorsDimensionRanges[2].range = changes.secondRange.currentValue;
            }
        }
    }

    applyingRanges(currentDimension, event) {
        if (event.minVal && event.maxVal) {
            this.firstRange = event.minVal;
            this.secondRange = event.maxVal;
        }
        currentDimension = this.checkCurrentDimensionName(currentDimension);
        this.applyRanges.emit([currentDimension, event]);
        this.rangeEdition = false;
    }
    saveColorPicker(currentDimension, colorRange, event) {
        this.setElementBackgroundColor(colorRange);
        currentDimension = this.checkCurrentDimensionName(currentDimension);
        this.colorSelected.emit([currentDimension, colorRange, event]);
    }
    setElementBackgroundColor(colorRange) {
        switch (colorRange) {
            case 'firstColorRange':
                this.firstElement.nativeElement.style.backgroundColor = this.firstColorRange;
                break;
            case 'secondColorRange':
                this.secondElement.nativeElement.style.backgroundColor = this.secondColorRange;
                break;
            case 'thirdColorRange':
                this.thirdElement.nativeElement.style.backgroundColor = this.thirdColorRange;
        }
    }
    checkCurrentDimensionName(currentDimension: string): string {
        if (currentDimension === 'Availability') {
            currentDimension = 'No error';
        } else if (currentDimension === 'On Error') {
            currentDimension = 'On error';
        } else {
            return currentDimension;
        }
        return currentDimension;
    }

    editingErrorsNameValue(rangeId: string, rangeValue: string, errorsTxtNewName) {
        errorsTxtNewName.$input.val(rangeValue);
        errorsTxtNewName.$input.trigger('blur');
        this.editingErrorsName = rangeId;
    }
    saveErrorsNameValue(rangeId, currentItemValue: string, errorsTxtNewName) {
        const newValue = errorsTxtNewName.$input.val() || currentItemValue;
        if (newValue !== currentItemValue) {
            this.updatedErrorsNameValue.emit({
                rangeId,
                newValue,
            });
        }
        this.editingErrorsName = '';
    }

    editingErrorsFromValue(rangeId: string, rangeValue: string, errorsTxtNewFrom) {
        errorsTxtNewFrom.$input.val(rangeValue);
        errorsTxtNewFrom.$input.trigger('blur');
        this.editingErrorsFrom = rangeId;
    }

    saveErrorsFromValue(rangeId, currentItemValue: string, errorsTxtNewFrom) {
        const newValue = errorsTxtNewFrom.$input.val() || currentItemValue;
        if (newValue !== currentItemValue && this.checkValidRange(rangeId, newValue)) {
            this.updatedErrorsFromValue.emit({
                rangeId,
                newValue,
            });
        }
        this.editingErrorsFrom = '';
    }
    //Checking if range values do not overlap one another
    checkValidRange(rangeId, newValue) {
        switch (rangeId) {
            case '0':
                return Number(this.secondRange) > Number(newValue) && Number(this.errorsMiddleRange) > Number(newValue);
            case '1':
                return Number(newValue) > Number(this.firstRange) && Number(this.secondRange) > Number(newValue);
            case '2':
                return Number(newValue) > Number(this.firstRange) && Number(newValue) > Number(this.errorsMiddleRange);
        }
    }
}
