<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="col-12 custom-title">
                <span class="float-start p-1">
                    <small
                        *ngIf="dashboardItem.variableIcon; else noIconAvailable"
                        class="force-one-line ps-2"
                        style="font-size: 12px; font-weight: 700"
                        [innerHTML]="dashboardItem.variableIcon | variableTypeIcon: dashboardItem.name"
                        [title]="dashboardItem.fqn"
                    ></small>
                    <ng-template #noIconAvailable>
                        <small class="force-one-line ps-2" style="font-size: 12px" [title]="dashboardItem.fqn"
                            ><strong>{{ dashboardItem.name }}</strong>
                        </small>
                    </ng-template>
                </span>
                <a class="float-end icon-close p-1 me-1" (click)="closeView(dashboardItem.name)"></a>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table m-0 dashboard-table">
                        <tbody>
                            <tr *ngFor="let variableEntry of dashboardItem.values">
                                <td [ngClass]="variableEntry.variableSource === variableDashboardType.historic ? 'historic' : 'realTime'">
                                    {{ variableEntry?.value || '' }}
                                </td>
                                <td class="time-column" [ngClass]="variableEntry.variableSource === variableDashboardType.historic ? 'historic' : 'realTime'">
                                    {{ variableEntry?.timestamp | date: 'dd MMM H:mm:ss' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
