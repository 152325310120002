import { Directive, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as _ from 'lodash-es';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class TableComponent<T> {
    @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
    public itemsVariable: any[];
    filters: any = {};
    messages = {
        emptyMessage: `<div class='my-4'>
            <img src='assets/img/empty-table.svg'>
            <i class="icon-alert_confirmation empty-table-icon" aria-hidden="true"></i>
        </div>
        <div class='mb-5'>
            <strong>There aren't items to show</strong>
            <p>Everything is all right now</p>
        </div>`,
    };
    private itemsInmutable: any[];

    constructor() {}

    setDatatableItems(items: T[]): void {
        this.itemsInmutable = items ? _.cloneDeep(items) : [];
        this.itemsVariable = items ? _.cloneDeep(items) : [];
    }

    addDatatableItems(items: T[]): void {
        this.itemsInmutable = _.concat(this.itemsInmutable, items);
        this.applyDatatableFilters();
    }

    addDatatableItem(item: any): void {
        const search = _.find(this.itemsInmutable, {
            equipmentId: item.equipmentId,
            description: item.description,
        });
        if (search) {
            this.itemsInmutable?.map((obj) => {
                if (obj.equipmentId === item.equipmentId && obj.description === item.description) {
                    obj.state = item.state;
                }
            });
        } else {
            this.itemsInmutable = _.concat(this.itemsInmutable, item);
        }
        this.applyDatatableFilters();
    }

    removeDatatableItem(item: any): void {
        this.itemsInmutable?.map((obj) => {
            if (`${obj.equipmentId}_${obj.equipmentType}` === `${item.equipmentId}_${item.equipmentType}`) {
                if (obj.state !== 'resolved') {
                    obj.state = 'resolved';
                }
            }
        });
        this.applyDatatableFilters();
    }

    removeForceDatatableItem(item: any): void {
        if (item) {
            this.itemsInmutable = this.itemsInmutable?.filter((obj) => {
                // tslint:disable-next-line: max-line-length
                return obj.equipmentId !== item.equipmentId || (obj.equipmentId === item.equipmentId && obj.description !== item.description);
            });
            this.applyDatatableFilters();
        }
    }

    setDatatableNumericFilter(propertyName: string, value: any, operator: string, value2?: any): void {
        this.filters[propertyName] = operator !== 'Between' ? `${operator}__${value}` : `${operator}__${value}__${value2}`;
        this.applyDatatableNumericFilters();
    }
    removeDatatableNumericFilter(propertyName: string) {
        delete this.filters[propertyName];
        this.applyDatatableNumericFilters();
    }
    applyDatatableNumericFilters() {
        const that = this;
        this.itemsVariable = _.cloneDeep(this.itemsInmutable);
        for (const key in this.filters) {
            if (this.filters.hasOwnProperty(key)) {
                const bufferOpts = that.filters[key].split('__');
                const operator = bufferOpts[0];
                const value = bufferOpts[1];
                const value2 = bufferOpts[2];
                that.itemsVariable = that.itemsVariable.filter((item) => {
                    let matchItem = false;
                    switch (operator) {
                        case 'Equals':
                            matchItem = Number(item[key]) === Number(value) ? true : false;
                            break;
                        case 'Not equals':
                            matchItem = Number(item[key]) !== Number(value) ? true : false;
                            break;
                        case 'Less':
                            matchItem = Number(item[key]) < Number(value) ? true : false;
                            break;
                        case 'Less or equals':
                            matchItem = Number(item[key]) <= Number(value) ? true : false;
                            break;
                        case 'Greater':
                            matchItem = Number(item[key]) > Number(value) ? true : false;
                            break;
                        case 'Greater or equals':
                            matchItem = Number(item[key]) >= Number(value) ? true : false;
                            break;
                        case 'Between':
                            matchItem = Number(item[key]) >= Number(value) && Number(item[key]) <= Number(value2) ? true : false;
                            break;
                    }
                    return matchItem;
                });
            }
        }
    }

    setDatatableFilter(propertyName: string, value: any, unhighlight?: boolean): void {
        if (_.trim(value) === '') {
            delete this.filters[propertyName];
        } else {
            this.filters[propertyName] = unhighlight ? `${value}__${unhighlight}` : value;
        }
        this.applyDatatableFilters();
    }

    getDatatableFilters(): any {
        return this.filters;
    }

    applyDatatableFilters(): void {
        const that = this;
        this.itemsVariable = _.cloneDeep(this.itemsInmutable);
        if (this.filters.hasOwnProperty('All')) {
            const regexp = new RegExp(that.filters.All, 'gi');
            that.itemsVariable = that.itemsVariable
                .filter((item) => {
                    let foundIt = false;
                    for (const prop in item) {
                        if (String(item[prop]).search(regexp) !== -1 || !that.filters.All) {
                            foundIt = true;
                        }
                    }
                    return foundIt;
                })
                .map((item) => {
                    // eslint-disable-next-line guard-for-in
                    for (const prop in item) {
                        item[String(prop)] = String(item[prop]).replace(regexp, (match) => {
                            return `<span class="highlight">${match}</span>`;
                        });
                    }
                    return item;
                });
        } else {
            for (const key in this.filters) {
                if (this.filters.hasOwnProperty(key)) {
                    if (key === 'alarmSourceTimeStamp') {
                        const date = that.filters[key].split('-');
                        let minDate: any = _.trim(date[0]);
                        let maxDate: any = _.trim(date[1]);
                        minDate = dayjs(minDate, 'DD/MM/YY H:mm').toDate();
                        maxDate = dayjs(maxDate, 'DD/MM/YY H:mm').toDate();
                        that.itemsVariable = that.itemsVariable.filter((item) => {
                            const currentDate = dayjs(item[key]);
                            if (currentDate.isSameOrAfter(minDate) && currentDate.isSameOrBefore(maxDate)) {
                                return true;
                            }
                        });
                    } else {
                        const bufferOpts = that.filters[key].split('__');
                        const valuesSearch = bufferOpts[0].split('::');
                        const unhighlight: boolean = bufferOpts[1];
                        that.itemsVariable = that.itemsVariable
                            .filter((item) => {
                                let matchItem = false;
                                valuesSearch.forEach((valueSearch) => {
                                    const negation: boolean = valueSearch.substring(0, 1) === '!' ? true : false;
                                    const hasWildCard = valueSearch.indexOf('*') >= 0;
                                    const itemText = String(item[key]).replace(/\*/g, '');
                                    matchItem =
                                        !negation && !hasWildCard
                                            ? String(item[key]) === valueSearch || !valueSearch
                                            : !negation
                                              ? this.textMatchesRegEx(String(item[key]), valueSearch)
                                              : negation && !hasWildCard
                                                ? itemText !== valueSearch.substr(1)
                                                : negation
                                                  ? !this.textMatchesRegEx(String(item[key]), valueSearch.substr(1))
                                                  : false;
                                });
                                return matchItem;
                            })
                            .map((item) => {
                                if (!unhighlight) {
                                    valuesSearch.forEach((valueSearch) => {
                                        // tslint:disable-next-line:max-line-length
                                        item[String(key)] = String(item[key]).replace(valueSearch, `<span class="highlight">${valueSearch}</span>`);
                                    });
                                }
                                return item;
                            });
                    }
                }
            }
        }
    }

    // https://stackoverflow.com/a/32402438
    textMatchesRegEx(str, rule) {
        const escapeRegex = (text) => text.replace(/([.*+?^=!:${}()|\[\]\/\\])/gi, '\\$1');
        return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str);
    }

    changePageSize(event) {
        this.table.pageSize = +event.target.value;
    }

    getRowClass(row) {
        return {
            resolved: row.state === 'resolved',
        };
    }

    generateCSV(event, hiddenColumns: Array<string>) {
        if (this.table.rows.length > 0) {
            const headers = [];
            Object.keys(this.table.rows[0]).forEach((key) => {
                if (!hiddenColumns.includes(key)) {
                    headers.push(key.toUpperCase());
                }
            });
            let itemsCSVProcessed = _.cloneDeep(this.table.rows);
            if (this.table.sorts.length === 1) {
                itemsCSVProcessed = _.orderBy(itemsCSVProcessed, [this.table.sorts[0].prop], [this.table.sorts[0].dir]);
            }
            itemsCSVProcessed = itemsCSVProcessed.map((item) => {
                Object.keys(item).map((key) => {
                    if (
                        dayjs(item[key], 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
                        dayjs(item[key], 'YYYY-MM-DDTHH:mm:ss.SZ', true).isValid() ||
                        dayjs(item[key], 'YYYY-MM-DDTHH:mm:ss.SSZ', true).isValid() ||
                        dayjs(item[key], 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()
                    ) {
                        item[key] = new Date(new Date(item[key]).toUTCString() + ' UTC').toLocaleString();
                    }
                    item[key] = _.replace(item[key], /<span class=\"highlight\">/g, '');
                    item[key] = _.replace(item[key], /<\/span>/g, '');
                });
                return _.omit(item, hiddenColumns);
            });
            const options = {
                fieldSeparator: ';',
                headers,
            };
            const prefix = event.target.title ? event.target.title : event.target.parentElement.title;
            const name = `${prefix}_${dayjs().format('DD.MM.YYYY_HH.mm')}`;
            return new AngularCsv(itemsCSVProcessed, name, options);
        }
    }

    enableLoader() {
        $(this.table.element).addClass('loading');
    }

    disableLoader() {
        $(this.table.element).removeClass('loading');
    }

    enableReport() {
        $(this.table.element).addClass('loading2');
    }
    disableReport() {
        $(this.table.element).removeClass('loading2');
    }
}
