import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppLoadService } from '@app/app-load.service';
import { UserConfiguration } from '../models/configurations';
import { IdentifiersPreferences } from '@app/map/home/shared/structure';
import memo from 'memo-decorator';

@Pipe({
    name: 'fqdnName',
})
export class FqdnNamePipe implements PipeTransform, OnDestroy {
    icon: string;
    name: string;
    identifiersPreferences: IdentifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private appLoadService: AppLoadService,
        private sanitizer: DomSanitizer,
    ) {
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
        });
    }

    @memo({
        resolver: (value, mode, withOutIcon) => ({ value, mode, withOutIcon }),
        cache: new WeakMap(),
    })
    transform(value: any, mode: string, whitOutIcon: boolean = false): any {
        let { floorId = value.floor, areaId = value.area, zoneId = value.zone, lineId = value.line, equipmentId, equipmentType } = value;
        if (!this.identifiersPreferences.Floor) {
            floorId = floorId.replace('F', '');
        }
        if (!this.identifiersPreferences.Area) {
            areaId = areaId.replace('A', '');
        }
        if (!this.identifiersPreferences.Zone) {
            zoneId = zoneId.replace('Z', '');
        }
        if (!this.identifiersPreferences.Line) {
            lineId = lineId.replace('EQL', '');
        }
        switch (value.equipmentType.toUpperCase()) {
            case 'CONVEYOR':
                this.icon = `<i class="fa fa-cog" title="${equipmentType}"></i>`;
                break;
            case 'SCANNER':
                this.icon = `<i class="icon-ico_sorter" title="${equipmentType}"></i>`;
                break;
            case 'RFID':
                this.icon = `<i class="icon-ico_volumetrica" title="${equipmentType}"></i>`;
                break;
            default:
                this.icon = `<i class="fa fa-circle" title="${equipmentType}"></i>`;
        }
        switch (mode) {
            case 'floor':
                this.name = `${areaId}.${zoneId}.${lineId}.<strong>${equipmentId}</strong>`;
                break;
            case 'area':
                this.name = `${zoneId}.${lineId}.<strong>${equipmentId}</strong>`;
                break;
            case 'zone':
                this.name = `${lineId}.<strong>${equipmentId}</strong>`;
                break;
            case 'line':
                this.name = `<strong>${equipmentId}</strong>`;
                break;
            default:
                this.name = `${floorId}.${areaId}.${zoneId}.${lineId}.${equipmentId}`;
        }
        if (whitOutIcon) {
            return this.name;
        } else {
            const response = `${this.icon}&nbsp&nbsp;${this.name}`;
            return this.sanitizer.bypassSecurityTrustHtml(response);
        }
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }
}
